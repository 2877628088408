import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_readdir";

export default function ReadDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP readdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_opendir',
            'next': '/php/func_rewinddir'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP readdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">readdir()</span> function in PHP is used to read the entries (files or directories) in an opened directory. It returns the name of the next file or directory inside the directory handle that was opened with the <span className="background-grey">opendir()</span> function. Each time you call <span className="background-grey">readdir()</span>, it moves to the next item in the directory until all items are read.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">readdir</span>(dir_handle)</p>
                        `
                    }}></div>
                </div>
                <p>Where, dir_handle resource returned by the <span className="background-grey">opendir()</span> function. If this parameter is omitted, it assumes the last directory opened by <span className="background-grey">opendir()</span>.</p>

                <p>On success, it returns the name of the file or directory and it returns false if there are no more items to read.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">readdir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dir</span> = <span class="color-green">"/path/to/directory"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Open the directory</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$handle</span> = <span class="color-red">opendir</span>(<span class="color-pink">$dir</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Directory contents:&#92;n"</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Read and display each file/directory</p>
                        <p class="ml-60">        <span class="color-blue">while</span> ((<span class="color-pink">$file</span> = <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>)) !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File: $file&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Close the directory handle</p>
                        <p class="ml-60">        <span class="color-red">closedir</span>(<span class="color-pink">$handle</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to open directory.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">opendir()</span> function opens the directory <span className="background-grey">/path/to/directory</span> and returns a directory handle (<span className="background-grey">$handle</span>), which is passed to <span className="background-grey">readdir()</span>.</li>
                    <li>The <span className="background-grey">readdir()</span> function reads the directory contents, returning one file or directory name at a time. The loop continues until <span className="background-grey">readdir()</span> returns false, indicating there are no more entries.</li>
                    <li>After reading the directory, <span className="background-grey">closedir()</span> is called to close the directory handle.</li>
                </ul>


            </div>
        </section>
    )
}