import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_pow";

export default function Pow() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP pow() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_min',
            'next': '/php/func_sqrt'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP pow() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The pow() function in PHP is used to calculate the power of a number. It raises one number (the base) to the power of another number (the exponent). It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">pow</span>(base, exponent)</p>
                        `
                    }}></div>
                </div>
                <p>Where, </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>base</strong>: The base number to be raised to a power.</li>
                    <li><strong>exponent</strong>: The power to which the base number is raised.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">pow</span>(<span class="color-pink">2</span>, <span class="color-pink">3</span>);  <span class="color-grey">// Output: 8</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">pow(2, 3)</span> means 2 raised to the power of 3 (2^3), which results in 8</p>

                
            </div>
        </section>
    )
}