import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/built-in/functions";

export default function PHPFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Built-in PHP Functions | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/functions',
            'next': '/php/statements'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Built-in PHP Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    PHP provides a vast collection of built-in functions that are readily available for use in your scripts. These functions cover a wide range of functionalities, from string manipulation and mathematical operations to working with arrays, files, and dates. Here’s a breakdown of some of the most commonly used built-in functions in PHP, categorized by their functionality:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5><Link to="/php/array" className='noUnderline'>Array Functions</Link></h5>
                        <p>PHP provides a wide range of functions to work with arrays. These functions allow developers to manipulate arrays efficiently and perform tasks like searching, sorting, and modifying arrays.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_chdir" className='noUnderline'>Directory Functions</Link></h5>
                        <p>PHP provides several functions to work with directories. These functions allow you to create, delete, read, and navigate directories within the file system.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_debug_backtrace" className='noUnderline'>Error Functions</Link></h5>
                        <p>PHP provides a set of error handling functions to help developers control how errors are displayed, logged, and handled. These functions can be used to manage and respond to different types of errors, including warnings, notices, and fatal errors.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_exception" className='noUnderline'>Exception Functions</Link></h5>
                        <p>PHP offers several functions to handle exceptions, allowing developers to manage errors more gracefully and prevent scripts from crashing unexpectedly. Exceptions are a way to handle errors or unexpected conditions in a more structured manner.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_fopen" className='noUnderline'>Filesystem Functions</Link></h5>
                        <p>PHP provides a set of filesystem functions to interact with the file system, allowing you to create, read, update, and delete files and directories. These functions are essential when working with files and folders, enabling you to perform operations such as checking if a file exists, writing to a file, reading from a file, and much more.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_filter_has_var" className='noUnderline'>Filter Functions</Link></h5>
                        <p>PHP filter functions are used to validate and sanitize data, especially when dealing with external input such as form data, cookies, server variables, or user input. They ensure that the data meets certain criteria before it is used in an application, which helps prevent issues like SQL injection, cross-site scripting (XSS), and other security vulnerabilities.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_ftp_connect" className='noUnderline'>FTP Functions</Link></h5>
                        <p>FTP functions in PHP allow developers to work with remote file servers via the FTP (File Transfer Protocol) protocol. These functions are essential for managing files on remote servers, including uploading, downloading, renaming, and deleting files.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_json_decode" className='noUnderline'>JSON Functions</Link></h5>
                        <p>PHP provides several built-in JSON functions to encode (convert data to JSON format) and decode (convert JSON back to PHP data) data. JSON (JavaScript Object Notation) is a lightweight data-interchange format, widely used for APIs, web services, and data transmission due to its simplicity and human-readable format.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_abs" className='noUnderline'>Math Functions</Link></h5>
                        <p>PHP offers a variety of built-in math functions for performing mathematical operations like calculating the square root, power, rounding numbers, and generating random numbers. These functions are easy to use and help solve many mathematical problems in a web application.</p>
                    </li>
                    <li className='operator'>
                        <h5><Link to="/php/func_strlen" className='noUnderline'>String Functions</Link></h5>
                        <p>PHP provides a rich set of built-in string functions for manipulating and working with strings. These functions allow you to perform various operations such as finding the length of a string, converting strings to lowercase or uppercase, replacing parts of strings, splitting strings, and more.</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}