import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/password/hashing";

export default function PasswordHashing() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Password Hashing - PHP Security | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/input/validation',
            'next': '/php/session/security'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Password Hashing - PHP Security</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Password hashing is a critical part of securing user passwords in PHP. It ensures that even if someone gains access to the database, they cannot easily retrieve the actual passwords. Hashing transforms the password into an irreversible string, making it hard to decode. PHP provides built-in functions to securely hash and verify passwords.
                </p>
                <h5 className='mt-4 mb-3'>Why Use Password Hashing?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Security</strong>: Plain-text passwords stored in the database are vulnerable if someone gains unauthorized access. Hashing adds a layer of protection.</li>
                    <li><strong>Irreversibility</strong>: A good hash function is one-way, meaning it cannot be "unhashed" back into the original password.</li>
                    <li><strong>Unique Hashes</strong>: Even the same password will generate different hashes due to <strong>salting</strong> (adding a unique random value to each password before hashing).</li>
                </ul>

                <h5 className='mt-4 mb-3'>Steps for Secure Password Hashing in PHP</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Using <span className='background-grey'>password_hash()</span> for Hashing</li>
                    <li>Using <span className='background-grey'>password_verify()</span> for Verification</li>
                    <li>Using <span className='background-grey'>password_needs_rehash()</span> for Updating Hashes</li>
                </ul>

                <ul className='mt-5' style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5>Hashing Passwords with <span className='background-grey'>password_hash()</span></h5>
                        <p>
                            PHP provides the <span className='background-grey'>password_hash()</span> function, which generates a secure hash of a password. By default, it uses the <span className='background-grey'>BCRYPT</span> algorithm (a strong and slow hashing algorithm), which makes it suitable for password storage.
                        </p>
                        <p>Syntax will be following -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">password_hash</span>(<span class="color-blue">string</span> <span class="color-pink">$password</span>, <span class="color-blue">int</span> <span class="color-pink">$algo</span>, <span class="color-blue">array</span> <span class="color-pink">$options</span> = [])</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>$password</strong>: The plain-text password to hash.</li>
                            <li><strong>$algo</strong>: The hashing algorithm (e.g., <span className='background-grey'>PASSWORD_BCRYPT</span>).</li>
                            <li><strong>$options</strong>: Optional array with additional options like <span className='background-grey'>cost</span> (computational expense).</li>
                        </ul>
                        <p className='mt-4'>For example,</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$password</span> = <span class="color-green">'my_secure_password'</span>;</p>
                                    <br />
                                    <p class="color-grey">// Generate a hash of the password</p>
                                    <p><span class="color-pink">$hashed_password</span> = <span class="color-red">password_hash</span>(<span class="color-pink">$password</span>, PASSWORD_BCRYPT);</p>
                                    <br />
                                    <p class="color-grey">// Store $hashed_password in the database</p>
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$hashed_password</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, <span className='background-grey'>PASSWORD_BCRYPT</span> creates a 60-character hash string using the Blowfish algorithm. It's slow and secure, which is ideal for password hashing.</p>

                        <p>You can customize the cost factor, A higher cost means stronger security but it is slower processing.</p>

                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$options</span> = [<span class="color-green">'cost'</span> => <span class="color-pink">12</span>];  <span class="color-grey">// Default is 10</span></p>
                                    <p><span class="color-pink">$hashed_password</span> = <span class="color-red">password_hash</span>(<span class="color-pink">$password</span>, PASSWORD_BCRYPT, <span class="color-pink">$options</span>);</p>
                                `
                            }}></div>
                        </div>

                    </li>

                    <li className='operator'>
                        <h5>Verifying Passwords with <span className='background-grey'>password_verify()</span></h5>
                        <p>
                            To verify if the provided password matches the stored hash, you use the <span className='background-grey'>password_verify()</span> function. This function compares the plain-text password to the hashed version stored in your database.
                        </p>
                        <p>Syntax will be following -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">password_verify</span>(<span class="color-blue">string</span> <span class="color-pink">$password</span>, <span class="color-blue">string</span> <span class="color-pink">$hash</span>)</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>$password</strong>: The plain-text password entered by the user.</li>
                            <li><strong>$hash</strong>: The hashed password retrieved from the database.</li>
                        </ul>

                        <p className='mt-4'>For example,</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$entered_password</span> = <span class="color-green">'my_secure_password'</span>;  <span class="color-grey">// User's input</span></p>
                                    <p><span class="color-pink">$stored_hash</span> = <span class="color-green">'$2y$10$XQF....'</span>;  <span class="color-grey">// Retrieved from the database</span></p>
                                    <br />
                                    <p class="color-grey">// Verify if the entered password matches the hash</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">password_verify</span>(<span class="color-pink">$entered_password</span>, <span class="color-pink">$stored_hash</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Password is correct!"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid password."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Rehashing Passwords with <span className='background-grey'>password_needs_rehash()</span></h5>
                        <p>
                            Over time, the hashing algorithms and security standards may improve. When this happens, you might want to upgrade the stored password hashes without requiring users to change their passwords. The <span className='background-grey'>password_needs_rehash()</span> function checks if the current hash was generated using a different algorithm or cost, and if so, it indicates that the password needs to be rehashed.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">password_needs_rehash</span>(<span class="color-blue">string</span> <span class="color-pink">$hash</span>, <span class="color-blue">int</span> <span class="color-pink">$algo</span>, <span class="color-blue">array</span> <span class="color-pink">$options</span> = [])</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>$hash</strong>: The current hash stored in the database.</li>
                            <li><strong>$algo</strong>: The algorithm used for hashing (e.g., <span className='background-grey'>PASSWORD_BCRYPT</span>).</li>
                            <li><strong>$options</strong>: Additional options like <span className='background-grey'>cost</span>.</li>
                        </ul>

                        <p className='mt-4'>For example,</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$stored_hash</span> = <span class="color-green">'$2y$10$XQF....'</span>;  <span class="color-grey">// Retrieved from the database</span></p>
                                    <p><span class="color-pink">$options</span> = [<span class="color-green">'cost'</span> => <span class="color-pink">12</span>];</p>
                                    <br />
                                    <p><span class="color-blue">if</span> (<span class="color-red">password_needs_rehash</span>(<span class="color-pink">$stored_hash</span>, PASSWORD_BCRYPT, <span class="color-pink">$options</span>)) {</p>
                                    <p class="ml-30 color-grey">    // Rehash the password with updated settings</p>
                                    <p class="ml-30">    <span class="color-pink">$new_hash</span> = <span class="color-red">password_hash</span>(<span class="color-green">'my_secure_password'</span>, PASSWORD_BCRYPT, <span class="color-pink">$options</span>);</p>
                                    <p class="ml-30 color-grey">    // Store $new_hash in the database</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}