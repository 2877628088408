import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_get";

export default function FtpGet() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_get() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_put',
            'next': '/php/func_ftp_delete'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_get() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_get()</span> function in PHP is used to download a file from a remote FTP server to the local machine. It retrieves a file from the server and saves it to the specified local path. This function is useful when you need to pull files from the server for processing or backup.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_get</span>(ftp_connection, local_file, remote_file, mode, startpos)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource that is returned from <span className="background-grey">ftp_connect()</span>. This represents the active connection to the FTP server.</li>
                    <li><strong>local_file</strong> (Required): The path to the local file where the downloaded file will be saved.</li>
                    <li><strong>remote_file</strong> (Required): The path to the file on the FTP server that you want to download.</li>
                    <li>
                        <strong>mode</strong> (Required): The transfer mode. It can be:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className="background-grey">FTP_ASCII</span>: For text files (ASCII mode).</li>
                            <li><span className="background-grey">FTP_BINARY</span>: For binary files (such as images, videos, executables).</li>
                        </ul>
                    </li>
                    <li><strong>startpos</strong> (Optional): The position in the remote file to start downloading from. This can be useful for resuming partial downloads. If not provided, the download will start from the beginning.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_get()</span> Function</h5>
                <p>This is an example of downloading a text file from an FTP server</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Local file where the downloaded file will be saved</p>
                        <p class="ml-30">    <span class="color-pink">$local_file</span> = <span class="color-green">"local_path/file.txt"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Remote file on the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$remote_file</span> = <span class="color-green">"server_path/file.txt"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Download the remote file</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_get</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$local_file</span>, <span class="color-pink">$remote_file</span>, FTP_ASCII)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File downloaded successfully and saved to $local_file.\n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File download failed.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We establish a connection to the FTP server using <span className="background-grey">ftp_connect()</span>.</li>
                    <li>We log in using <span className="background-grey">ftp_login()</span>.</li>
                    <li>We download a text file (<span className="background-grey">server_path/file.txt</span>) from the FTP server and save it to the local machine at <span className="background-grey">local_path/file.txt</span> using <span className="background-grey">ftp_get()</span> in FTP_ASCII mode.</li>
                    <li>The connection is then closed using <span className="background-grey">ftp_close()</span>.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>File downloaded successfully and saved to local_path/file.txt.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}