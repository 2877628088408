import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/working-with-apis";

export default function WorkingAPI() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Working with APIs in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/error/handling',
            'next': '/php/array'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Working with APIs</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Working with APIs in PHP involves sending HTTP requests and handling the responses, often in formats like JSON or XML. APIs (Application Programming Interfaces) allow different software systems to communicate with each other over the web. In PHP, you can use built-in functions like <span class="background-grey">file_get_contents()</span>, <span class="background-grey">cURL</span>, or libraries like Guzzle to interact with APIs.
                </p>

                <h5 className='mt-4 mb-3'>Understanding the Basics of APIs</h5>
                <p>Before diving into PHP, understand that APIs work using HTTP methods:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>GET</strong>: It is used to retrieve data from the server.</li>
                    <li><strong>POST</strong>: It is used to send data to the server.</li>
                    <li><strong>PUT/PATCH</strong>: It is used to update existing data on the server.</li>
                    <li><strong>DELETE</strong>: It is used to delete data on the server.</li>
                </ul>

                <h5 className='mt-4 mb-3'>Using <span class="background-grey">file_get_contents()</span> for Simple GET Requests</h5>
                <p><span class="background-grey">file_get_contents()</span> can be used to make simple API requests. This method is great for small requests but lacks flexibility for complex scenarios like sending headers or handling POST data.</p>

                <p>Let's see an example to fetching data from a public API</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="color-grey">// API URL</p>
                            <p><span class="color-pink">$url</span> = <span class="color-green">"https://jsonplaceholder.typicode.com/posts/1"</span>;</p>
                            <br />
                            <p class="color-grey">// Make a GET request</p>
                            <p><span class="color-pink">$response</span> = <span class="color-red">file_get_contents</span>(<span class="color-pink">$url</span>);
                            <br />
                            <p class="color-grey">// Convert JSON response to PHP associative array</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>);
                            <br />
                            <p class="color-grey">// Display the data</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Title: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'title'</span>];</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Body: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'body'</span>];</p>
                            ?>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Using <span class="background-grey">cURL</span> for More Complex Requests</h5>
                <p><span class="background-grey">cURL</span> is a flexible and powerful way to interact with APIs. It supports different HTTP methods, headers, and authentication.</p>

                <h6>Steps to Make a Request with cURL:</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Initialize a cURL session.</li>
                    <li>Set cURL options.</li>
                    <li>Execute the cURL request.</li>
                    <li>Close the cURL session.</li>
                </ul>

                <p><b>For example, GET Request using cURL</b></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="color-grey">// Initialize cURL</p>
                            <p><span class="color-pink">$ch</span> = <span class="color-red">curl_init</span>();</p>
                            <br />
                            <p class="color-grey">// Set the URL for the request</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_URL, <span class="color-green">"https://jsonplaceholder.typicode.com/posts/1"</span>);</p>
                            <br />
                            <p class="color-grey">// Return the response instead of printing it</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_RETURNTRANSFER, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Execute the request</p>
                            <p><span class="color-pink">$response</span> = <span class="color-red">curl_exec</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Close the cURL session</p>
                            <p><span class="color-red">curl_close</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Decode the JSON response to PHP array</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Output the data</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Title: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'title'</span>];</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Body: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'body'</span>];</p>
                            ?>
                        `
                    }}></div>
                </div>

                <p><b>POST Request using cURL</b></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="color-grey">// Initialize cURL</p>
                            <p><span class="color-pink">$ch</span> = <span class="color-red">curl_init</span>();</p>
                            <br />
                            <p class="color-grey">// Data to be sent in the POST request</p>
                            <p><span class="color-pink">$postData</span> = <span class="color-red">array</span>(</p>
                            <p class="ml-30">    <span class="color-green">'title'</span> => <span class="color-green">'My New Post'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'body'</span> => <span class="color-green">'This is the content of the new post.'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'userId'</span> => <span class="color-pink">1</span></p>
                            <p>);</p>
                            <br />
                            <p class="color-grey">// Set the URL and other options for the request</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_URL, <span class="color-green">"https://jsonplaceholder.typicode.com/posts"</span>);</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_POST, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_POSTFIELDS, <span class="color-red">json_encode</span>(<span class="color-pink">$postData</span>)); <span class="color-grey">// JSON encoded data</span></p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_RETURNTRANSFER, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_HTTPHEADER, <span class="color-red">array</span>(</p>
                            <p class="ml-30">    <span class="color-green">'Content-Type: application/json'</span>, <span class="color-grey">// Set the content type</span></p>
                            <p>));</p>
                            <br />
                            <p class="color-grey">// Execute the request</p>
                            <p><span class="color-pink">$response</span> = <span class="color-red">curl_exec</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Close the cURL session</p>
                            <p><span class="color-red">curl_close</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Decode and display the response</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$data</span>);</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>How to handle API Authentication ?</h5>
                <p>Many APIs require authentication (API keys, OAuth, or other methods). Here's how you can add an API key or authentication header to your cURL request.</p>
                <p>Let's see an example of how we handle authentication in api call. We simply send an <span class="background-grey">API Key</span> in the header.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="color-grey">// Initialize cURL</p>
                            <p><span class="color-pink">$ch</span> = <span class="color-red">curl_init</span>();</p>
                            <br />
                            <p class="color-grey">// Set the URL</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_URL, <span class="color-green">"https://api.example.com/protected-data"</span>);</p>
                            <br />
                            <p class="color-grey">// Return the response instead of printing it</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_RETURNTRANSFER, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Set the HTTP headers, including API key</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_HTTPHEADER, <span class="color-red">array</span>(</p>
                            <p class="ml-30">    <span class="color-green">'Authorization: Bearer your_api_key_here'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'Content-Type: application/json'</span>,</p>
                            <p>));</p>
                            <br />
                            <p class="color-grey">// Execute the request</p>
                            <p><span class="color-pink">$response</span> = <span class="color-red">curl_exec</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Close the cURL session</p>
                            <p><span class="color-red">curl_close</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p class="color-grey">// Decode and display the response</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$data</span>);</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>How to handle API Response ?</h5>
                <p>API responses are usually in JSON or XML format. PHP provides the json_decode() function to convert JSON responses to PHP arrays/objects.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-pink">$response</span> = <span class="color-green">'{"title":"My New Post","body":"This is the content of the new post."}'</span>;</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>); <span class="color-grey">// Decode to associative array</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$data</span>[<span class="color-green">'title'</span>];  <span class="color-grey">// Output: My New Post</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Guzzle - A PHP HTTP Client</h5>
                <p>For more advanced HTTP requests, you can use Guzzle, a PHP HTTP client library. Guzzle simplifies working with APIs, handling complex requests, streams, and asynchronous calls.</p>

                <p>You need to install the Guzzle first. To install Guzzle, use the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer require guzzlehttp/guzzle</p>
                        `
                    }}></div>
                </div>

                <p>Let see how we use Guzzle in our GET Request.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">require</span> <span class="color-green">'vendor/autoload.php'</span>;</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">GuzzleHttp</span>\<span class="color-red">Client</span>;</p>
                            <br />
                            <p><span class="color-pink">$client</span> = <span class="color-blue">new</span> <span class="color-red">Client</span>();</p>
                            <p><span class="color-pink">$response</span> = <span class="color-pink">$client</span>-><span class="color-red">request</span>(<span class="color-green">'GET'</span>, <span class="color-green">'https://jsonplaceholder.typicode.com/posts/1'</span>);</p>
                            <br />
                            <p class="color-grey">// Get the response body</p>
                            <p><span class="color-pink">$body</span> = <span class="color-pink">$response</span>-><span class="color-red">getBody</span>();</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$body</span>, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Output the response data</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Title: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'title'</span>];</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Body: "</span> . <span class="color-pink">$data</span>[<span class="color-green">'body'</span>];</p>
                            ?>
                        `
                    }}></div>
                </div>

                <p>Let see how we use Guzzle in our POST Request.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">require</span> <span class="color-green">'vendor/autoload.php'</span>;</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">GuzzleHttp</span>\<span class="color-red">Client</span>;</p>
                            <br />
                            <p><span class="color-pink">$client<span> = <span class="color-blue">new</span> <span class="color-red">Client</span>();</p>
                            <p><span class="color-pink">$response</span> = <span class="color-pink">$client</span>-><span class="color-red">request</span>(<span class="color-green">'POST'</span>, <span class="color-green">'https://jsonplaceholder.typicode.com/posts'</span>, [</p>
                            <p class="ml-30">    <span class="color-green">'json'</span> => [</p>
                            <p class="ml-60">        <span class="color-green">'title'</span> => <span class="color-green">'My New Post'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'body'</span> => <span class="color-green">'This is the content of the new post.'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'userId'</span> => <span class="color-pink">1</span></p>
                            <p class="ml-30">    ]</p>
                            <p>]);</p>
                            <br />
                            <p class="color-grey">// Get the response body</p>
                            <p><span class="color-pink">$body</span> = <span class="color-pink">$response</span>-><span class="color-red">getBody</span>();</p>
                            <p><span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$body</span>, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Output the response data</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$data</span>);</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>How to handle Error in API Request ?</h5>
                <p>While working with APIs, it's important to handle errors like timeouts, invalid responses, or server errors (like 404, 500).</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-pink">$ch</span> = <span class="color-red">curl_init</span>();</p>
                            <br />
                            <p class="color-grey">// Set the URL and return response instead of outputting it</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_URL, <span class="color-green">"https://api.example.com/some-endpoint"</span>);</p>
                            <p><span class="color-red">curl_setopt</span>(<span class="color-pink">$ch</span>, CURLOPT_RETURNTRANSFER, <span class="color-blue">true</span>);</p>
                            <br />
                            <p class="color-grey">// Execute and check for errors</p>
                            <p><span class="color-pink">$response</span> = <span class="color-red">curl_exec</span>(<span class="color-pink">$ch</span>);</p>
                            <br />
                            <p><span class="color-blue">if</span> (<span class="color-red">curl_errno</span>(<span class="color-pink">$ch</span>)) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">'Error: '</span> . <span class="color-red">curl_error</span>(<span class="color-pink">$ch</span>);  <span class="color-grey">// Output error if occurred</span></p>
                            <p>} <span class="color-blue">else</span> {</p>
                            <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$response</span>, <span class="color-blue">true</span>);</p>
                            <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$data</span>);</p>
                            <p>}</p>
                            <br />
                            <p class="color-grey">// Close the cURL session</p>
                            <p><span class="color-red">curl_close</span>(<span class="color-pink">$ch</span>);</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}