import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_nlist";

export default function FtpNlist() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_nlist() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_pwd',
            'next': '/php/func_ftp_rename'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_nlist() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_nlist()</span> function in PHP is used to get a list of file names from a specified directory on a remote FTP server. This function returns an array containing the names of files (and directories, depending on the FTP server) present in the given directory, without providing detailed information like file sizes or permissions.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_nlist</span>(ftp_connection, directory)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource created by <span className="background-grey">ftp_connect()</span>. It represents the connection to the remote FTP server.</li>
                    <li><strong>directory</strong> (Required): The directory on the FTP server from which you want to retrieve the list of files. You can provide an absolute or relative path. If you pass an empty string (""), it lists the files in the current directory.  </li> 
                </ul>

                
                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_nlist()</span> Function</h5>
                <p>This is an example of listing files in the current directory</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.&#92;n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Get the list of files in the current directory</p>
                        <p class="ml-60">        <span class="color-pink">$file_list</span> = <span class="color-red">ftp_nlist</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-green">"."</span>);</p>
                        <br />        
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$file_list</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Files in the current directory:&#92;n"</span>;</p>
                        <p class="ml-90">            <span class="color-red">print_r</span>(<span class="color-pink">$file_list</span>); <span class="color-grey">// Display the list of files</span></p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to list files.&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server using <span className="background-grey">ftp_connect()</span> and log in with <span className="background-grey">ftp_login()</span>.</li>
                    <li>We use <span className="background-grey">ftp_nlist()</span> to retrieve a list of files in the current directory (represented by ".").</li>
                    <li>If successful, an array of file names is returned and printed. If the operation fails, an error message is displayed.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>Files in the current directory:</p>
                            <p>Array</p>
                            <p>(</p>
                            <p class="ml-30">    [0] => file1.txt</p>
                            <p class="ml-30">    [1] => image.jpg</p>
                            <p class="ml-30">    [2] => index.html</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}