import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_rand";

export default function ArrayRand() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_rand() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_push',
            'next': '/php/func_array_reduce'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_rand() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_rand() function in PHP is used to pick one or more random keys from an array. This function is useful when you need to select random elements from an array without modifying the original array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_rand</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$num</span> = <span class="color-pink">1</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array from which you want to select random keys.</li>
                    <li><strong>$num</strong>: (Optional) The number of random keys you want to select. If not specified, the function selects one random key by default.</li>
                    <li><strong>Return Value</strong>: If <span className='background-grey'>$num</span> is 1, a single random key is returned. If <span className='background-grey'>$num</span> is greater than 1, an array of random keys is returned.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Selecting a Single Random Key</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$randomKey</span> = <span class="color-red">array_rand</span>(<span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-green">"Random key: $randomKey\n"</span>;</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Random value: "</span> . <span class="color-pink">$array</span>[<span class="color-pink">$randomKey</span>];</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Random key: <span class="color-pink">2</span></p>
                            <p>Random value: cherry</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function selects a random key (in this case, <span className='background-grey'>2</span>), which corresponds to the value <span className='background-grey'>"cherry"</span>.</li>
                    <li>The <span className='background-grey'>$randomKey</span> is used to retrieve the corresponding value from the array.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Selecting Multiple Random Keys</h5>
                <p>You can select more than one random key by specifying the number of keys you want.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$randomKeys</span> = <span class="color-red">array_rand</span>(<span class="color-pink">$array</span>, <span class="color-pink">2</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-green">"Random keys: "</span>;</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$randomKeys</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-green">"Random values: "</span> . <span class="color-pink">$array</span>[<span class="color-pink">$randomKeys</span>[<span class="color-pink">0</span>]] . <span class="color-green">", "</span> . <span class="color-pink">$array</span>[<span class="color-pink">$randomKeys</span>[<span class="color-pink">1</span>]];</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Random keys: <span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">3</span></p>
                            <p>)</p>
                            <p>Random values: banana, date</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function selects two random keys from the array.</li>
                    <li>You can use these keys to access the corresponding values (<span className='background-grey'>"banana"</span> and <span className='background-grey'>"date"</span> in this example).</li>
                </ul>
            </div>
        </section>
    )
}