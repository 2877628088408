import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_rmdir";

export default function FtpRmdir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_rmdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_mkdir',
            'next': '/php/func_ftp_chdir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_rmdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_rmdir()</span> function in PHP is used to remove a directory from a remote FTP server. This function is part of the FTP library in PHP and is used to manage directories on an FTP server by deleting them when they are no longer needed.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_rmdir</span>(ftp_connection, directory)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): This is the FTP connection resource, which is created by the <span className="background-grey">ftp_connect()</span> function. It represents the active FTP connection to the server.</li>
                    <li><strong>directory</strong> (Required): The directory that you want to remove from the FTP server. The directory must be empty in order to be deleted.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_rmdir()</span> Function</h5>
                <p>Here is an example of remove a directory from FTP server.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Directory to be removed from the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$directory_to_remove</span> = <span class="color-green">"server_path/folder_to_remove"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Try to remove the directory</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_rmdir</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$directory_to_remove</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Directory removed successfully.\n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to remove directory. Make sure it's empty.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server using <span className="background-grey">ftp_connect()</span> and log in with <span className="background-grey">ftp_login()</span>.</li>
                    <li>We attempt to remove the directory <span className="background-grey">server_path/folder_to_remove</span> using the <span className="background-grey">ftp_rmdir()</span> function.</li>
                    <li>If successful, a message is displayed confirming the directory's removal. Otherwise, an error message is shown.</li>
                    <li>The FTP connection is closed using <span className="background-grey">ftp_close()</span>.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>Directory removed successfully.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}