import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_mkdir";

export default function FtpMkdir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_mkdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_delete',
            'next': '/php/func_ftp_rmdir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_mkdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_mkdir()</span> function in PHP is used to create a new directory on a remote FTP server. This function allows you to manage and organize files on the server by creating directories as needed.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_mkdir</span>(ftp_connection, directory)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource, which is created by <span className="background-grey">ftp_connect()</span>. This represents the active connection to the FTP server.</li>
                    <li><strong>directory</strong> (Required): The name of the directory to be created on the remote FTP server. This can be an absolute or relative path.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_mkdir()</span> Function</h5>
                <p>This is an example of creating a directory on an FTP server</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Directory to be created on the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$new_directory</span> = <span class="color-green">"server_path/new_folder"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Create the new directory</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_mkdir</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$new_directory</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Directory created successfully.\n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to create directory.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server using <span className="background-grey">ftp_connect()</span> and log in using <span className="background-grey">ftp_login()</span>.</li>
                    <li>We attempt to create a new directory on the FTP server at the path <span className="background-grey">server_path/new_folder.</span></li>
                    <li>If the directory is created successfully, a success message is displayed. Otherwise, an error message is shown.</li>
                    <li>Finally, the connection to the FTP server is closed using <span className="background-grey">ftp_close()</span>.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>Directory created successfully.</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}