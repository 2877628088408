import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_list";

export default function FilterList() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_list() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_input_array',
            'next': '/php/func_filter_var'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_list() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_list()</span> function in PHP is used to retrieve a list of all available filters that can be used with the filtering functions, such as <span className="background-grey">filter_var()</span>, <span className="background-grey">filter_input()</span>, and <span className="background-grey">filter_input_array()</span>. PHP has built-in filters that help sanitize and validate data, and <span className="background-grey">filter_list()</span> provides a simple way to see all of them. 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_list</span>()</p>
                        `
                    }}></div>
                </div>
                <p>This function does not take any parameters and returns an indexed array where each element is the name of a filter available in PHP. It returns an indexed array containing the names of all available filters in PHP.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">filter_list()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Display all available filters</p>
                        <p class="ml-30">    <span class="color-pink">$filters</span> = <span class="color-red">filter_list</span>();</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">foreach</span> (<span class="color-pink">$filters</span> as <span class="color-pink">$id</span> => <span class="color-pink">$filter</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-pink">$id</span> + <span class="color-pink">1</span> . <span class="color-green">": "</span> . <span class="color-pink">$filter</span> . <span class="color-green">"<br>"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>1: int</p>
                            <p>2: boolean</p>
                            <p>3: float</p>
                            <p>4: validate_regexp</p>
                            <p>5: validate_domain</p>
                            <p>6: validate_url</p>
                            <p>7: validate_email</p>
                            <p>8: validate_ip</p>
                            <p>9: string</p>
                            <p>10: stripped</p>
                            <p>11: encoded</p>
                            <p>12: special_chars</p>
                            <p>13: full_special_chars</p>
                            <p>14: unsafe_raw</p>
                            <p>15: email</p>
                            <p>16: url</p>
                            <p>17: number_int</p>
                            <p>18: number_float</p>
                            <p>19: magic_quotes</p>
                            <p>20: callback</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}