import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_debug_print_backtrace";

export default function DebugPrintBacktrace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP debug_print_backtrace() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_debug_backtrace',
            'next': '/php/func_error_get_last'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP debug_print_backtrace() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">debug_print_backtrace()</span> function in PHP is a simple yet powerful tool used to display a backtrace of the function calls that led to the point where it is invoked. This function is useful for debugging purposes, especially when you want to see the flow of function calls and identify the origin of any issues in your code.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">debug_print_backtrace</span>(<span class="color-blue">int</span> <span class="color-pink">$options</span> = <span class="color-pink">0</span>, <span class="color-blue">int</span> <span class="color-pink">$limit</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'decimal' }} className='mb-4'>
                    <li>
                        <strong>options</strong> (Optional): This parameter allows you to control what kind of information will be included in the backtrace.
                        <ul style={{ listStyle: 'disc' }} className='mb-4'>
                            <li>
                                <span className="background-grey">DEBUG_BACKTRACE_IGNORE_ARGS</span>: If used, this option omits the function arguments from the backtrace output, simplifying the output for easier reading.
                            </li>
                        </ul>
                    </li>
                    <li><strong>limit</strong> (Optional): This parameter limits the number of stack frames to be displayed in the backtrace. A value of 0 means there is no limit, and the full backtrace is printed.</li>
                </ul>
                <p>The function does not return any value. It simply <strong>prints</strong> the backtrace to the output, typically the web browser or the command line, depending on where the script is running.</p>


                <h5 className="mt-5 mb-3">Basic Usage of <span className="background-grey">debug_print_backtrace()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">test1</span>() {</p>
                        <p class="ml-60">        <span class="color-red">test2</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">test2</span>() {</p>
                        <p class="ml-60">        <span class="color-red">debug_print_backtrace</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">test1</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">#0</span> <span class="color-yellow">test2</span>() called at <span class="color-pink">[/path/to/file.php:5]</span></p>
                            <p><span class="color-pink">#1</span> <span class="color-yellow">test1</span>() called at <span class="color-pink">[/path/to/file.php:9]</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>This example shows the function <span className="background-grey">test1()</span> calling <span className="background-grey">test2()</span>, which in turn calls <span className="background-grey">debug_print_backtrace()</span>.</li>
                    <li>The output lists the sequence of function calls, starting from <span className="background-grey">test1()</span> at line 9, then <span className="background-grey">test2()</span> at line 5, and so on.</li>
                    <li>Each entry in the backtrace shows the file name, the line number where the function was called, and the function being executed.</li>
                </ul>


            </div>
        </section>
    )
}