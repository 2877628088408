import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_shift";

export default function ArrayShift() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_shift() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_search',
            'next': '/php/func_array_slice'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_shift() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_shift() function in PHP is used to remove the first element from an array and return it. The function also reindexes the array, meaning the keys will be adjusted to start from <span className='background-grey'>0</span>. This function is particularly useful when you want to remove and work with the first element in a list while preserving the rest of the array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">array_shift</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The array from which you want to remove the first element. It is passed by reference, meaning the original array will be modified.</li>
                    <li><strong>Return Value</strong>: The first element of the array (which is removed) or <span className='background-grey'>null</span> if the array is empty.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$firstElement</span> = <span class="color-red">array_shift</span>(<span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$firstElement</span>;  <span class="color-grey">// Output: apple</span></p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>); </p> 
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>apple</p>
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => cherry</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first element <span className='background-grey'>"apple"</span> is removed from the array and returned by <span className='background-grey'>array_shift()</span>.</li>
                    <li>The array is then reindexed, so the remaining elements <span className='background-grey'>"banana"</span> and <span className='background-grey'>"cherry"</span> now have indices <span className='background-grey'>0</span> and <span className='background-grey'>1</span>, respectively.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Working with an Associative Array</h5>
                <p>When working with an associative array, <span className='background-grey'>array_shift()</span> removes the first element but preserves the original keys of the remaining elements.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$firstElement</span> = <span class="color-red">array_shift</span>(<span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$firstElement</span>;  <span class="color-grey">// Output: apple</span></p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>); </p>    
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>apple</p>
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [b] => banana</p>
                        <p class="ml-30">    [c] => cherry</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first element (<span className='background-grey'>"a" =&gt; "apple"</span>) is removed from the array.</li>
                    <li>The remaining elements (<span className='background-grey'>"b" =&gt; "banana"</span> and <span className='background-grey'>"c" =&gt; "cherry"</span>) are left with their original keys (<span className='background-grey'>"b"</span>, <span className='background-grey'>"c"</span>).</li>
                </ul>
            </div>
        </section>
    )
}