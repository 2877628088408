import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_fopen";

export default function FOpen() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP fopen() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getTraceAsString',
            'next': '/php/func_fread'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP fopen() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">fopen()</span> function in PHP is used to open a file or URL. It returns a file pointer resource, which is used for other file operations like reading, writing, or appending data to the file. This function is essential for handling files in PHP and provides control over how the file is accessed (read, write, or append).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fopen</span>(filename, mode, include_path, context);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>filename</strong> (required): Specifies the name of the file you want to open.</li>
                    <li>
                        <strong>mode</strong> (required): Specifies the mode in which the file should be opened. The common modes include:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className="background-grey">"r"</span>: Open for reading; file must exist.</li>
                            <li><span className="background-grey">"r+"</span>: Open for reading and writing; file must exist.</li>
                            <li><span className="background-grey">"w"</span>: Open for writing; creates the file if it doesn't exist or truncates the file if it does.</li>
                            <li><span className="background-grey">"w+"</span>: Open for reading and writing; creates the file if it doesn't exist or truncates the file if it does.</li>
                            <li><span className="background-grey">"a"</span>: Open for writing; creates the file if it doesn't exist. Writes data at the end of the file.</li>
                            <li><span className="background-grey">"a+"</span>: Open for reading and writing; creates the file if it doesn't exist. Reads and writes data at the end of the file.</li>
                            <li><span className="background-grey">"x"</span>: Open for writing; creates the file if it doesn't exist. If the file exists, <span className="background-grey">fopen()</span> returns <span className="background-grey">FALSE</span>.</li>
                            <li><span className="background-grey">"x+"</span>: Open for reading and writing; creates the file if it doesn't exist. If the file exists, <span className="background-grey">fopen()</span> returns <span className="background-grey">FALSE</span>.</li>
                            <li><span className="background-grey">"b"</span>: Binary mode (for binary files like images). Used with other modes like <span className="background-grey">"rb"</span> or <span className="background-grey">"wb"</span>.</li>
                        </ul>
                    </li>
                    <li><strong>include_path</strong> (optional): If set to <span className="background-grey">true</span>, the <span className="background-grey">fopen()</span> function will also search for the file in the include path.</li>
                    <li><strong>context</strong> (optional): Specifies the context of the file stream. This is an advanced feature that allows you to modify how file operations are handled.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage </h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"r"</span>) or <span class="color-red">die</span>(<span class="color-green">"Unable to open file!"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">fread</span>(<span class="color-pink">$file</span>, <span class="color-red">filesize</span>(<span class="color-green">"example.txt"</span>));</p>
                        <p class="ml-30">    <span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">"r"</span>: Opens the file for reading.</li>
                    <li><span className="background-grey">fread()</span>: Reads the file content.</li>
                    <li><span className="background-grey">fclose()</span>: Closes the file after reading it.</li>
                </ul>

                <p>The <span className="background-grey">fopen()</span> function is one of the most essential file handling functions in PHP. It provides a wide range of file modes that allow you to read, write, and append to files. Proper usage of <span className="background-grey">fopen()</span> with the correct file mode ensures that files are managed safely and efficiently in your PHP application.</p>



            </div>
        </section>
    )
}