import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_reduce";

export default function ArrayReduce() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_reduce() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_rand',
            'next': '/php/func_array_replace'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_reduce() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_reduce() function in PHP applies a user-defined function to the elements of an array, reducing the array to a single value. It processes the elements of the array one by one, combining them in some way, such as summing, concatenating, or performing more complex transformations.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_reduce</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">callable</span> <span class="color-pink">$callback</span>, <span class="color-blue">mixed</span> <span class="color-pink">$initial</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array.</li>
                    <li><strong>$callback</strong>: A user-defined function that accepts two parameters: the carry (the accumulator) and the current array element. This function is called recursively for each element in the array.</li>
                    <li><strong>$initial</strong>: (Optional) The initial value of the carry (accumulator). If not specified, the first element of the array is used as the initial value.</li>
                    <li><strong>Return Value</strong>: The result of applying the callback to all the elements in the array. If the array is empty and no initial value is provided, <span className='background-grey'>null</span> is returned.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Summing Array Values</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <br />
                            <p><span class="color-pink">$sum</span> = <span class="color-red">array_reduce</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$carry</span>, <span class="color-pink">$item</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$carry</span> + <span class="color-pink">$item</span>;</p>
                            <p>}, <span class="color-pink">0</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$sum</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-pink">15</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>array_reduce()</span> function adds each element of the array (<span className='background-grey'>$item</span>) to the carry value (<span className='background-grey'>$carry</span>), which starts at <span className='background-grey'>0</span> (the initial value).</li>
                    <li>The result is the sum of all elements: <span className='background-grey'>1 + 2 + 3 + 4 + 5 = 15</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Concatenating Strings</h5>
                <p>You can use <span className='background-grey'>array_reduce()</span> to concatenate strings from an array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"Hello"</span>, <span class="color-green">" "</span>, <span class="color-green">"World"</span>, <span class="color-green">"!"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_reduce</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$carry</span>, <span class="color-pink">$item</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$carry</span> . <span class="color-pink">$item</span>;</p>
                            <p>}, <span class="color-green">''</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$result</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Hello World!</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The array elements are concatenated together into a single string (<span className='background-grey'>"Hello World!"</span>).</li>
                    <li>The <span className='background-grey'>carry</span> starts with an empty string (<span className='background-grey'>'&nbsp;'</span>), and each element is appended to it.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Multiplying Array Values</h5>
                <p>You can use <span className='background-grey'>array_reduce()</span> to multiply all elements of an array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                            <br />
                            <p><span class="color-pink">$product</span> = <span class="color-red">array_reduce</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$carry</span>, <span class="color-pink">$item</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$carry</span> * <span class="color-pink">$item</span>;</p>
                            <p>}, <span class="color-pink">1</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$product</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-pink">24</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function multiplies each element of the array by the carry.</li>
                    <li>The carry starts at <span className='background-grey'>1</span> (initial value), and the result is <span className='background-grey'>1 * 2 * 3 * 4 = 24</span></li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Finding the Longest String in an Array</h5>
                <p>You can use <span className='background-grey'>array_reduce()</span> used to find the longest string in an array..</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$longest</span> = <span class="color-red">array_reduce</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$carry</span>, <span class="color-pink">$item</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> (<span class="color-red">strlen</span>(<span class="color-pink">$carry</span>) > <span class="color-red">strlen</span>(<span class="color-pink">$item</span>)) ? <span class="color-pink">$carry</span> : <span class="color-pink">$item</span>;</p>
                            <p>}, <span class="color-green">''</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$longest</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>banana</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    The function compares the lengths of the current carry (longest string so far) and the current item.
                    It keeps the longer of the two strings as the carry.
                    In this case, <span className='background-grey'>"banana"</span> is the longest string in the array.
                </ul>
            </div>
        </section>
    )
}