import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/meter";

export default function Meter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <meter> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;meter&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;meter&gt;</span> tag in HTML represents a scalar measurement within a known range, or a fractional value, such as disk usage, battery level, or a score. Unlike the <span class="background-grey">&lt;progress&gt;</span> element, which indicates task completion, <span class="background-grey">&lt;meter&gt;</span> is used for data that represents a specific measurement or value within a range.
                </p>

                <h5 className='mt-5 mb-3'>Structure of <span class="background-grey">&lt;meter&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;meter&gt;</span> element uses attributes to specify the range and the current measurement:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">value</span>: The current measurement or value to represent.</li>
                    <li><span class="background-grey">min</span>: The minimum possible value (optional, default is <span class="background-grey">0</span>).</li>
                    <li><span class="background-grey">max</span>: The maximum possible value.</li>
                    <li><span class="background-grey">low</span>: A value representing the low range (optional).</li>
                    <li><span class="background-grey">high</span>: A value representing the high range (optional).</li>
                    <li><span class="background-grey">optimum</span>: A value indicating the ideal range (optional).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;meter <span class="color-pink">value</span>=<span class="color-green">"70"</span> <span class="color-pink">min</span>=<span class="color-green">"0"</span> <span class="color-pink">max</span>=<span class="color-green">"100"</span>&gt;70%&lt;/meter&gt;</p>
                        `
                    }}></div>
                </div>

                
                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Battery Level&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h2&gt;Battery Level&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;meter <span class="color-pink">value</span>=<span class="color-green">"0.6"</span> <span class="color-pink">min</span>=<span class="color-green">"0"</span> <span class="color-pink">max</span>=<span class="color-green">"1"</span> <span class="color-pink">low</span>=<span class="color-green">"0.2"</span> <span class="color-pink">high</span>=<span class="color-green">"0.8"</span> <span class="color-pink">optimum</span>=<span class="color-green">"0.7"</span>&gt;60%&lt;/meter&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This meter shows the battery level, with ideal usage between 20% and 80%.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;meter&gt;</span> element represents a battery level with a <span class="background-grey">value</span> of 60% (or 0.6 in decimal), indicating how much charge is remaining.</li>
                    <li>The <span class="background-grey">low</span> attribute (20%) and <span class="background-grey">high</span> attribute (80%) define ranges where the battery might be low or fully charged.</li>
                    <li>The <span class="background-grey">optimum</span> attribute (70%) indicates the ideal battery level.</li>
                </ul>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Battery Level</title>
                            </head>
                            <body>
                                <h2>Battery Level</h2>
                                <meter value="0.6" min="0" max="1" low="0.2" high="0.8" optimum="0.7">60%</meter>
                                <p>This meter shows the battery level, with ideal usage between 20% and 80%.</p>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}