import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_json_decode";

export default function FtpConnect() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP json_decode() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_chmod',
            'next': '/php/func_json_encode'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP json_decode() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>json_decode()</span> function in PHP is used to convert a JSON string into a PHP variable (such as an array or an object). It is the reverse of <span className='background-grey'>json_encode()</span>, which transforms PHP data into a JSON string. It has a basic syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">json_decode</span>(<span class="color-blue">string</span> <span class="color-pink">$json<s/pan>, <span class="color-blue">bool</span> <span class="color-pink">$assoc</span> = <span class="color-blue">false</span>, <span class="color-blue">int</span> <span class="color-pink">$depth</span> = <span class="color-pink">512</span>, <span class="color-blue">int</span> <span class="color-pink">$options</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>$json</span>: The JSON string that you want to decode (convert into a PHP variable).</li>
                    <li><span className='background-grey'>$assoc</span> (optional): When <span className='background-grey'>true</span>, the function returns an associative array instead of an object. The default is <span className='background-grey'>false</span>, which returns an object.</li>
                    <li><span className='background-grey'>$depth</span> (optional): The maximum depth to which the function will decode the JSON structure. The default depth is 512.</li>
                    <li><span className='background-grey'>$options</span> (optional): Bitmask of options (e.g., <span className='background-grey'>JSON_BIGINT_AS_STRING</span> to handle big integers as strings).</li>
                </ul>
                <p>
                    <span className='background-grey'>On success</span> it returns a PHP object or array depending on the <span className='background-grey'>$assoc</span> parameter. And on failure it returns <span className='background-grey'>NULL</span> if the JSON cannot be decoded or if it is malformed.
                </p>


                <h5 className="mt-5 mb-3">Example 1 : Decoding JSON to an Object</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$json</span> = <span class="color-green">'{"name": "John", "age": 30, "city": "New York"}'</span>;</span>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$decoded</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$json</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$decoded</span>->name;  <span class="color-grey">// Output: John</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$decoded</span>->age;   <span class="color-grey">// Output: 30</span></p>
                        ?>
                        `
                    }}></div>
                </div>
                <p>In this example, the JSON string is decoded into a PHP object. You can access properties using <span className='background-grey'>-&gt;</span> (object notation).</p>

                <h5 className="mt-5 mb-3">Example 2 : Decoding JSON to an Associative Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$json</span> = <span class="color-green">'{"name": "John", "age": 30, "city": "New York"}'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$decoded</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$json</span>, <span class="color-blue">true</span>);  <span class="color-grey">// true means convert to an associative array</span></p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$decoded</span>[<span class="color-green">'name'</span>];  <span class="color-grey">// Output: John</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$decoded</span>[<span class="color-green">'age'</span>];   <span class="color-grey">// Output: 30</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>When the second parameter (<span className='background-grey'>$assoc</span>) is set to <span className='background-grey'>true</span>, the JSON is decoded into an associative array, and you access values with array notation (<span className='background-grey'>[]</span>).</p>

                <h5 className="mt-5 mb-3">Options for <span className='background-grey'>json_decode()</span></h5>
                <p><span className='background-grey'>JSON_BIGINT_AS_STRING</span>: Converts large integers in JSON to strings instead of numbers. This is useful for handling very large numbers that might exceed PHP's integer limit.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$json</span> = <span class="color-green">'{"large_number": 9223372036854775807}'</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$decoded</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$json</span>, <span class="color-blue">true</span>, <span class="color-pink">512</span>, JSON_BIGINT_AS_STRING);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$decoded</span>[<span class="color-green">'large_number'</span>];  <span class="color-grey">// Output: 9223372036854775807 as a string<s/pan></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Depth Parameter</h5>
                <p>The <span className='background-grey'>$depth</span> parameter allows you to limit how deep the function can parse the JSON. This prevents issues with excessively nested JSON that could cause a performance issue or stack overflow.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-pink">$json</span> = <span class="color-green">'[1, [2, [3, [4]]]]'</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$decoded</span> = <span class="color-red">json_decode</span>(<span class="color-pink">$json</span>, <span class="color-blue">true</span>, <span class="color-pink">2</span>);  <span class="color-grey">// Limit the depth to 2</span></p>
                            <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$decoded</span>);  <span class="color-grey">// Output: Array ( [0] => 1 [1] => Array ( [0] => 2 ) )</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}