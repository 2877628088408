import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/xml-parser";

export default function XMLParser() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("XML Parser in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_str_split',
            'next': '/php/regular-expressions'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is an XML Parser?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An <strong>XML Parser</strong> processes an XML document by breaking it down into its components, such as elements, attributes, and text nodes. An event-driven XML parser in PHP works by triggering certain callback functions whenever it encounters different parts of the XML structure (e.g., the start or end of an element).
                </p>

                <h5 className="mt-5 mb-3">Basic Workflow of PHP XML Parser</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Create an XML parser using <span className='background-grey'>xml_parser_create()</span>.</li>
                    <li>Define callback functions to handle different XML events (such as start and end of elements, and character data).</li>
                    <li>Parse the XML document using <span className='background-grey'>xml_parse()</span>.</li>
                    <li>Free the parser resources with <span className='background-grey'>xml_parser_free()</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Key Functions in PHP XML Parser</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>xml_parser_create()</span>: Creates a new XML parser.</li>
                    <li><span className='background-grey'>xml_set_element_handler()</span>: Sets handlers for start and end tags in the XML document.</li>
                    <li><span className='background-grey'>xml_set_character_data_handler()</span>: Sets the handler for character data (text between tags).</li>
                    <li><span className='background-grey'>xml_parse()</span>: Parses the XML document and triggers the associated event handlers.</li>
                    <li><span className='background-grey'>xml_parser_free()</span>: Frees the parser after parsing is complete.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example of Parsing an XML Document in PHP</h5>
                <p>Let's take an example XML document, and parse it using PHP's XML Parser functions.</p>

                <h6>XML Document (<span className='background-grey'>books.xml</span>)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;library&gt;</p>
                        <p class="ml-30">    &lt;book&gt;</p>
                        <p class="ml-60">        &lt;title&gt;PHP for Beginners&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;author&gt;John Doe&lt;/author&gt;</p>
                        <p class="ml-60">        &lt;year&gt;2021&lt;/year&gt;</p>
                        <p class="ml-30">    &lt;/book&gt;</p>
                        <p class="ml-30">    &lt;book&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Advanced PHP Techniques&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;author&gt;Jane Doe&lt;/author&gt;</p>
                        <p class="ml-60">        &lt;year&gt;2020&lt;/year&gt;</p>
                        <p class="ml-30">    &lt;/book&gt;</p>
                        <p>&lt;/library&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here's how you would parse the <span className='background-grey'>books.xml</span> file using PHP's XML Parser:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Define the callback functions for start and end elements and character data</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">startElement</span>(<span class="color-pink">$parser</span>, <span class="color-pink">$name</span>, <span class="color-pink">$attrs</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Start tag: <"</span> . <span class="color-pink">$name</span> . <span class="color-green">">\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">endElement</span>(<span class="color-pink">$parser</span>, <span class="color-pink">$name</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"End tag: </"</span> . <span class="color-pink">$name</span> . <span class="color-green">">\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">characterData</span>(<span class="color-pink">$parser</span>, <span class="color-pink">$data</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">trim</span>(<span class="color-pink">$data</span>)) { <span class="color-grey">// Skip empty data</span></p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Data: "</span> . <span class="color-pink">$data</span> . <span class="color-green">"\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Create a new XML parser</p>
                        <p class="ml-30">    <span class="color-pink">$parser</span> = <span class="color-red">xml_parser_create</span>();</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the element handler functions (start and end tags)</p>
                        <p class="ml-30">    <span class="color-red">xml_set_element_handler</span>(<span class="color-pink">$parser</span>, <span class="color-green">"startElement"</span>, <span class="color-green">"endElement"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the character data handler function (text data)</p>
                        <p class="ml-30">    <span class="color-red">xml_set_character_data_handler</span>(<span class="color-pink">$parser</span>, <span class="color-green">"characterData"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Open the XML file</p>
                        <p class="ml-30">    <span class="color-pink">$xmlFile</span> = <span class="color-green">"books.xml"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (!(<span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-pink">$xmlFile</span>, <span class="color-green">"r"</span>))) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Cannot open XML file."</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Read and parse the XML file</p>
                        <p class="ml-30">    <span class="color-blue">while</span> (<span class="color-pink">$data</span> = <span class="color-red">fread</span>(<span class="color-pink">$file</span>, <span class="color-pink">4096</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (!<span class="color-red">xml_parse</span>(<span class="color-pink">$parser</span>, <span class="color-pink">$data</span>, <span class="color-red">feof</span>(<span class="color-pink">$file</span>))) {</p>
                        <p class="ml-90">            <span class="color-red">die</span>(<span class="color-red">sprintf</span>(<span class="color-green">"XML Error: %s at line %d"</span>,</p>
                        <p class="ml-120">                <span class="color-red">xml_error_string</span>(<span class="color-red">xml_get_error_code</span>(<span class="color-pink">$parser</span>)),</p>
                        <p class="ml-150">                <span class="color-red">xml_get_current_line_number</span>(<span class="color-pink">$parser</span>)));</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="color-grey">    // Free the XML parser</p>
                        <p>    <span class="color-red">xml_parser_free</span>(<span class="color-pink">$parser</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <div className='mt-4 mb-4'>
                    <p>In this example, </p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>
                            <span className='background-grey'>xml_parser_create()</span> creates a new XML parser.
                        </li>
                        <li>
                            Then using <span className='background-grey'>xml_set_element_handler()</span>, it sets two callback functions (<span className='background-grey'>startElement</span> and <span className='background-grey'>endElement</span>) that handle start and end tags in the XML document. When the parser encounters a start tag (&lt;book&gt;, &lt;title&gt;, etc.), the <span className='background-grey'>startElement()</span> function is called, and when it encounters an end tag (&lt;/book&gt;, &lt;/title&gt;, etc.), the <span className='background-grey'>endElement()</span> function is called.
                        </li>
                        <li>
                            <span className='background-grey'>xml_set_character_data_handler()</span> function sets the callback function <span className='background-grey'>characterData()</span> to handle the text between XML tags (such as the title and author names).
                        </li>
                        <li>
                            <span className='background-grey'>xml_parse()</span> function parses the XML document chunk by chunk (as it reads it) and triggers the callback functions for each element or piece of character data.
                        </li>
                        <li>
                            <span className='background-grey'>xml_parser_free()</span> function frees the XML parser resources when done.
                        </li>
                    </ul>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;LIBRARY&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;BOOK&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;TITLE&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">PHP for Beginners</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/TITLE&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;AUTHOR&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">John Doe</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/AUTHOR&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;YEAR&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">2021</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/YEAR&gt;</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/BOOK&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;BOOK&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;TITLE&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">Advanced PHP Techniques</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/TITLE&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;AUTHOR&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">Jane Doe</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/AUTHOR&gt;</span></p>
                            <p><span class="color-pink">Start tag</span>: <span class="color-green">&lt;YEAR&gt;</span></p>
                            <p><span class="color-pink">Data</span>: <span class="color-green">2020</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/YEAR&gt;</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/BOOK&gt;</span></p>
                            <p><span class="color-pink">End tag</span>: <span class="color-green">&lt;/LIBRARY&gt;</span></p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}