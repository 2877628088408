import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/form-handling";

export default function FormHandling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Form Handling in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/get/post/methods',
            'next': '/php/functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Form Handling</h3>
            <div className='mt-4 mb-5'>
                <p>To handle form data, PHP uses superglobal arrays like <span class="background-grey">$_GET</span> and <span class="background-grey">$_POST</span>.</p>
                <p>
                    Let's check this with an example how we can handle form data in PHP. 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"process_form.php"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                        <p class="ml-30">&lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-30">&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span> <span class="color-pink">required</span>&gt;&lt;br&gt;&lt;br&gt;</p>
                        <br />            
                        <p class="ml-30">&lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-30"> &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> <span class="color-pink">required</span>&gt;&lt;br&gt;&lt;br&gt;</p>
                        <br />
                        <p class="ml-30">&lt;label <span class="color-pink">for</span>=<span class="color-green">"message"</span>&gt;Message:&lt;/label&gt;</p>
                        <p class="ml-30"> &lt;textarea <span class="color-pink">id</span>=<span class="color-green">"message"</span> <span class="color-pink">name</span>=<span class="color-green">"message"</span> <span class="color-pink">required</span>&gt;&lt;textarea&gt;&lt;br&gt;&lt;br&gt;</p>
                        <br />          
                        <p class="ml-30">&lt;input <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">value</span>=<span class="color-green">"Submit"</span>&gt;</p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    In the PHP script specified in the <span class="background-grey">action</span> attribute, you can access the form data using the $_POST which is used here.
                </p>
                <p>In the page <span class="background-grey">process_form.php</span> you can write the below code to get the value from the form after sumbit.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">if</span> (<span class="color-pink">$_SERVER</span>[<span class="color-green">"REQUEST_METHOD"</span>] == <span class="color-green">"POST"</span>) {</p>
                            <p class="ml-30 color-grey">    // Collect form data</p>
                            <p class="ml-30">    <span class="color-pink">$name</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'name'</span>];</p>
                            <p class="ml-30">   <span class="color-pink"> $email</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'email'</span>];</p>
                            <p class="ml-30">    <span class="color-pink">$message</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'message'</span>];</p>
                            <br />
                            <p class="ml-30 color-grey">    // Process the data (e.g., save to database, send email, etc.)</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Name: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$name</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Email: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$email</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Message: "</span> . <span class="color-red">nl2br</span>(<span class="color-red">htmlspecialchars</span>(<span class="color-pink">$message</span>)) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p>}</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">$_POST['name']</span>: Retrieves the value of the input field with the name attribute <span class="background-grey">name</span>.
                    </li>
                    <li>
                        <span class="background-grey">$_POST['email']</span>: Retrieves the value of the input field with the name attribute <span class="background-grey">email</span>.
                    </li>
                    <li>
                        <span class="background-grey">$_POST['message']</span>: Retrieves the value of the input field with the name attribute <span class="background-grey">message</span>.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Validation Form Data</h5>
                <p>
                    Validation is crucial to ensure that the data received is correct and secure. You can perform validation in PHP before processing the data.
                </p>
                <p>
                    Below, is the simple example of validation of your input fields. We can check the required field by validating field before submit.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">if</span> (<span class="color-pink">$_SERVER</span>[<span class="color-green">"REQUEST_METHOD"</span>] == <span class="color-green">"POST"</span>) {</p>
                            <p class="ml-30">    <span class="color-pink">$name</span> = <span class="color-red">trim</span>(<span class="color-pink">$_POST</span>[<span class="color-green">'name'</span>]);</p>
                            <p class="ml-30">    <span class="color-pink">$email</span> = <span class="color-red">trim</span>(<span class="color-pink">$_POST</span>[<span class="color-green">'email'</span>]);</p>
                            <p class="ml-30">    <span class="color-pink">$message</span> = <span class="color-red">trim</span>(<span class="color-pink">$_POST</span>[<span class="color-green">'message'</span>]);</p>
                            <p class="ml-30">    <span class="color-pink">$errors</span> = [];</p>
                            <br />
                            <p class="ml-30 color-grey">    // Check if name is empty</p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-blue">empty</span>(<span class="color-pink">$name</span>)) {</p>
                            <p class="ml-60">        <span class="color-pink">$errors</span>[] = <span class="color-green">"Name is required."</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />
                            <p class="ml-30 color-grey">    // Validate email format</p>
                            <p class="ml-30">    <span class="color-blue">if</span> (!<span class="color-red">filter_var</span>(<span class="color-pink">$email</span>, FILTER_VALIDATE_EMAIL)) {</p>
                            <p class="ml-60">        <span class="color-pink">$errors</span>[] = <span class="color-green">"Invalid email format."</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />
                            <p class="ml-30 color-grey">    // Check if message is empty</p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-blue">empty</span>(<span class="color-pink">$message</span>)) {</p>
                            <p class="ml-60">        <span class="color-pink">$errors</span>[] = <span class="color-green">"Message is required."</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-blue">empty</span>(<span class="color-pink">$errors</span>)) {</p>
                            <p class="ml-60 color-grey">        // Process the data</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Name: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$name</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Email: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$email</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Message: "</span> . <span class="color-red">nl2br</span>(<span class="color-red">htmlspecialchars</span>(<span class="color-pink">$message</span>)) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                            <p class="ml-60 color-grey">        // Display errors</p>
                            <p class="ml-60">        <span class="color-blue">foreach</span> (<span class="color-pink">$errors</span> <span class="color-blue">as</span> <span class="color-pink">$error</span>) {</p>
                            <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-pink">$error</span> . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-60">        }</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                            ?>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Handling Form Submission with GET Method</h5>
                <p>
                    If you use the <span class="background-grey">GET</span> method, data is passed in the URL and can be accessed using the <span class="background-grey">$_GET</span> superglobal.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">method</span>=<span class="color-green">"get"</span> <span class="color-pink">action</span>=<span class="color-green">"process_form.php"</span>&gt;</p>
                            <p class="ml-30">    Name: &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span>&gt;&lt;br&gt;</p>
                            <p class="ml-30">    Email: &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span>&gt;&lt;br&gt;</p>
                            <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">value</span>=<span class="color-green">"Submit"</span>&gt;</p>
                            <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <p>How to access data from GET method, you can see in the below example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">if</span> (<span class="color-pink">$_SERVER</span>[<span class="color-green">"REQUEST_METHOD"</span>] == <span class="color-green">"GET"</span>) {</p>
                            <p class="ml-30">    <span class="color-pink">$name</span> = <span class="color-pink">$_GET</span>[<span class="color-green">'name'</span>];</p>
                            <p class="ml-30">    <span class="color-pink">$email</span> = <span class="color-pink">$_GET</span>[<span class="color-green">'email'</span>];</p>
                            <br />
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Name: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$name</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Email: "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$email</span>) . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p>}</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}