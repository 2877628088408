import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_unique";

export default function ArrayUnique() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_unique() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_product',
            'next': '/php/func_array_values'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_unique() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_unique() function in PHP is used to remove duplicate values from an array. This function compares the values of an array and returns a new array with only unique elements, preserving the first occurrence of each value. The keys of the original array are also preserved, though they may be re-indexed if the input array is non-associative.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_unique</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$flags</span> = SORT_STRING)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The array from which duplicates are removed.</li>
                    <li><strong>$flags</strong>: (Optional) This parameter can modify the behavior of value comparison. Common options are:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>SORT_STRING</span> (default): Compare items as strings.</li>
                            <li><span className='background-grey'>SORT_NUMERIC</span>: Compare items as numbers.</li>
                            <li><span className='background-grey'>SORT_REGULAR</span>: Compare items normally (without changing their types).</li>
                        </ul>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$fruits</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"apple"</span>, <span class="color-green">"orange"</span>, <span class="color-green">"banana"</span>];</p>
                        <p><span class="color-pink">$uniqueFruits</span> = <span class="color-red">array_unique</span>(<span class="color-pink">$fruits</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$uniqueFruits</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => orange</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function removes duplicate values <span className='background-grey'>"apple"</span> and <span className='background-grey'>"banana"</span>, keeping their first occurrences.</li>
                    <li>The original keys are preserved (0, 1, 3).</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Associative Array</h5>
                <p>When working with associative arrays, <span className='background-grey'>array_unique()</span> removes duplicates but keeps the original keys.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$students</span> = [</p>
                            <p class="ml-30">    <span class="color-green">"A"</span> => <span class="color-green">"John"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"B"</span> => <span class="color-green">"Alice"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"C"</span> => <span class="color-green">"John"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"D"</span> => <span class="color-green">"Bob"</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$uniqueStudents</span> = <span class="color-red">array_unique</span>(<span class="color-pink">$students</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$uniqueStudents</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [A] => John</p>
                            <p class="ml-30">    [B] => Alice</p>
                            <p class="ml-30">    [D] => Bob</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function removes the duplicate <span className='background-grey'>"John"</span> while keeping the first occurrence at key <span className='background-grey'>A</span>.</li>
                    <li>The associative keys (<span className='background-grey'>A</span>, <span className='background-grey'>B</span>, <span className='background-grey'>D</span>) are preserved.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Using <span className='background-grey'>SORT_NUMERIC</span> Flag</h5>
                <p>The <span className='background-grey'>SORT_NUMERIC</span> flag forces the function to compare values as numbers.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$numbers</span> = [<span class="color-green">"10"</span>, <span class="color-pink">10</span>, <span class="color-pink">20</span>, <span class="color-green">"20"</span>, <span class="color-pink">30</span>];</p>
                        <p><span class="color-pink">$uniqueNumbers</span> = <span class="color-red">array_unique</span>(<span class="color-pink">$numbers</span>, SORT_NUMERIC);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$uniqueNumbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">10</span></p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">20</span></p>
                        <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">30</span></p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function treats <span className='background-grey'>"10"</span> and <span className='background-grey'>10</span> as the same value when using <span className='background-grey'>SORT_NUMERIC</span>, so the string <span className='background-grey'>"10"</span> is removed.</li>
                    <li>Similarly, <span className='background-grey'>"20"</span> and <span className='background-grey'>20</span> are considered duplicates, and only the first one (<span className='background-grey'>20</span>) is kept.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Using <span className='background-grey'>SORT_STRING</span> Flag</h5>
                <p>The default <span className='background-grey'>SORT_STRING</span> flag compares values as strings, so numeric values that appear different as strings are treated as unique.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$numbers</span> = [<span class="color-pink">10</span>, <span class="color-green">"10"</span>, <span class="color-pink">20</span>, <span class="color-green">"20"</span>, <span class="color-pink">30</span>];</p>
                        <p><span class="color-pink">$uniqueNumbers</span> = <span class="color-red">array_unique</span>(<span class="color-pink">$numbers</span>, SORT_STRING);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$uniqueNumbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">10</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">10</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">20</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">20</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">30</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function compares values as strings, so <span className='background-grey'>10</span> (integer) and <span className='background-grey'>"10"</span> (string) are treated as different, and both are kept.</li>
                    <li>Similarly, <span className='background-grey'>20</span> and <span className='background-grey'>"20"</span> are considered unique when compared as strings.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 5: Case Sensitivity</h5>
                <p>By default, <span className='background-grey'>array_unique()</span> is case-sensitive, meaning <span className='background-grey'>"Apple"</span> and <span className='background-grey'>"apple"</span> are considered different values.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$fruits</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"Apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"Banana"</span>];</p>
                        <p><span class="color-pink">$uniqueFruits</span> = <span class="color-red">array_unique</span>(<span class="color-pink">$fruits</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$uniqueFruits</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => Apple</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => Banana</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Since <span className='background-grey'>"apple"</span> and <span className='background-grey'>"Apple"</span> are treated as different values (due to case sensitivity), both are kept in the resulting array.</li>
                    <li>Similarly, <span className='background-grey'>"banana"</span> and <span className='background-grey'>"Banana"</span> are treated as unique.</li>
                </ul>
            </div>
        </section>
    )
}