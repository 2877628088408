import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rmdir";

export default function Rmdir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rmdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_mkdir',
            'next': '/php/func_php_scandir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rmdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">rmdir()</span> function in PHP is used to remove (delete) an empty directory. It’s essential to note that <span className="background-grey">rmdir()</span> can only delete empty directories, meaning the directory must not contain any files or subdirectories. If you need to remove a directory and its contents, you will have to manually delete the files first or use other methods.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rmdir</span>(<span class="color-blue">string</span> <span class="color-pink">$directory</span>, resource|null <span class="color-pink">$context</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$directory</strong> (required): The path to the directory that you want to remove. It can be either an absolute path or a relative path.</li>
                    <li><strong>$context</strong> (optional): A stream context that can modify the behavior of the function. This parameter is rarely used in simple directory deletion tasks.</li>
                </ul>
                <p> It returns <span className="background-grey">true</span> if the directory is successfully removed and returns <span className="background-grey">false</span> if the directory cannot be removed (e.g., it’s not empty, or you lack the necessary permissions).</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>THere’s how to use <span className="background-grey">rmdir()</span> to delete an empty directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-green">'empty_folder'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">rmdir</span>(<span class="color-pink">$directory</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Directory '$directory' deleted successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to delete directory '$directory'."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, PHP tries to delete the folder named <span className="background-grey">empty_folder</span>. If successful, it prints a success message.</p>

                <p>The <span className="background-grey">rmdir()</span> function in PHP is a simple and useful tool for removing empty directories. While it can only delete empty directories, when combined with other file system functions, it can be part of more complex operations like recursive directory deletion. Just make sure to handle errors properly and validate paths to avoid security issues or unexpected failures.</p>



            </div>
        </section>
    )
}