import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_splice";

export default function ArraySplice() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_splice() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_slice',
            'next': '/php/func_array_sum'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_splice() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>array_splice()</span> function in PHP is used to remove, replace, or insert elements into an array. It directly modifies the original array, making it different from <span className='background-grey'>array_slice()</span>, which only extracts elements without changing the original array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_splice</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$offset</span>, ?<span class="color-blue">int</span> <span class="color-pink">$length</span> = <span class="color-blue">null</span>, <span class="color-blue">mixed</span> <span class="color-pink">$replacement</span> = [])</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array, which is passed by reference and will be modified.</li>
                    <li><strong>$offset</strong>: The starting position in the array where elements will be removed, replaced, or inserted.</li>
                    <li><strong>$length</strong>: (Optional) The number of elements to remove. If omitted, all elements from the offset to the end are removed.</li>
                    <li><strong>$replacement</strong>: (Optional) An array of elements to insert at the position where elements were removed. If no replacement is provided, the function simply removes the elements.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Removing Elements</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <p><span class="color-pink">$removed</span> = <span class="color-red">array_splice</span>(<span class="color-pink">$array</span>, <span class="color-pink">1</span>, <span class="color-pink">2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$removed</span>);  <span class="color-grey">// Output: Array of removed elements</span></p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);    <span class="color-grey">// Output: Remaining array after removing elements</span></p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array<s/pan></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => cherry</p>
                            <p>)</p>
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function starts at index <span className='background-grey'>1</span> ("banana") and removes <span className='background-grey'>2</span> elements: "banana" and "cherry".</li>
                    <li>The removed elements are returned, and the original array is updated to include only "apple" and "date".</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Inserting Elements</h5>
                <p>You can insert new elements at a specific position in the array using the <span className='background-grey'>replacement</span> parameter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <p><span class="color-red">array_splice</span>(<span class="color-pink">$array</span>, <span class="color-pink">2</span>, <span class="color-pink">0</span>, [<span class="color-green">"kiwi"</span>, <span class="color-green">"mango"</span>]);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => kiwi</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => mango</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">5</span>] => date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function starts at index <span className='background-grey'>2</span> and does not remove any elements (<span className='background-grey'>length = 0</span>).</li>
                    <li>It inserts the elements "kiwi" and "mango" at that position, shifting the rest of the array.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 3: Replacing Elements</h5>
                <p>You can replace elements in an array by specifying both the number of elements to remove and a replacement array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <p><span class="color-red">array_splice</span>(<span class="color-pink">$array</span>, <span class="color-pink">1</span>, <span class="color-pink">2</span>, [<span class="color-green">"kiwi"</span>, <span class="color-green">"mango"</span>]);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => kiwi</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => mango</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function starts at index <span className='background-grey'>1</span> ("banana"), removes <span className='background-grey'>2</span> elements ("banana" and "cherry"), and replaces them with "kiwi" and "mango".</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Removing All Elements from a Starting Point</h5>
                <p>If you omit the <span className='background-grey'>length</span> parameter, all elements from the specified offset to the end of the array will be removed.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>];</p>
                            <p><span class="color-red">array_splice</span>(<span class="color-pink">$array</span>, <span class="color-pink">2</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The function starts at index <span className='background-grey'>2</span> ("cherry") and removes all elements from that point onward.</p>
            </div>
        </section>
    )
}