import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_has_var";

export default function FilterHasVar() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_has_var() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_chmod',
            'next': '/php/func_filter_id'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_has_var() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The filter_has_var() function in PHP checks whether a specified external variable exists (like data from forms, cookies, or the server) and if it belongs to the specified input type. It is often used when dealing with user input, such as validating if a particular input field has been provided before applying filters to it. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_has_var</span>(type, variable_name)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>type</strong>: This is the input type you want to check. PHP offers several types:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">INPUT_GET</span>: For GET variables (from the URL query string).</li>
                            <li><span className="background-grey">INPUT_POST</span>: For POST variables (from form submissions).</li>
                            <li><span className="background-grey">INPUT_COOKIE</span>: For variables stored in cookies.</li>
                            <li><span className="background-grey">INPUT_SERVER</span>: For variables from the server.</li>
                            <li><span className="background-grey">INPUT_ENV</span>: For environment variables.</li>
                        </ul>
                    </li>
                    <li><strong>variable_name</strong>: This is the name of the variable you want to check for existence.</li>
                </ul>

                <p>If the variable exists in the specified input type it returns <span className="background-grey">true</span> otherwise it returns <span className="background-grey">false</span>.</p>

                <h5 className="mt-5 mb-3">Example 1: Checking a GET Variable</h5>
                <p>Suppose you have a form that sends data using the GET method. You want to check if the <span className="background-grey">name</span> variable exists in the URL.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">filter_has_var</span>(INPUT_GET, <span class="color-green">"name"</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The 'name' variable is set."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The 'name' variable is not set."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>If the URL is <span className="background-grey">example.com?name=John</span>, the output will be:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The <span class="color-green">'name'</span> variable is set.</p> 
                        `
                    }}></div>
                </div>


                <h5 className="mt-5 mb-3">Example 2: Checking a SERVER Variable</h5>
                <p>This example checks if the <span className="background-grey">REQUEST_METHOD</span> server variable is present.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">filter_has_var</span>(INPUT_SERVER, <span class="color-green">"REQUEST_METHOD"</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The 'REQUEST_METHOD' server variable is set."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The 'REQUEST_METHOD' server variable is not set."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The <span className="background-grey">filter_has_var()</span> function is a useful way to make sure certain data is present before applying filters or further processing it, helping to ensure your PHP application handles user input safely and effectively.
                </p>

            </div>
        </section>
    )
}