import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/oops/access-modifier";

export default function AccessModifiers() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Access Modifiers in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/oops/constructor/destructor',
            'next': '/php/oops/static-methods-properties'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Access Modifiers</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In PHP, access modifiers are keywords that determine the visibility and accessibility of properties and methods in a class. They control whether a property or method can be accessed from outside the class, within the class, or by inherited classes. PHP provides three main access modifiers:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <span className='background-grey'>public</span> Modifier
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Public properties and methods can be accessed from anywhere: inside the class, outside the class, and by inherited classes.</li>
                            <li>This is the most permissive access level.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Car</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public</span> <span class="color-pink">$color</span>;  <span class="color-grey">// Public property</span></p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">setColor</span>(<span class="color-pink">$color</span>) {  <span class="color-grey">// Public method</span></p>
                                    <p class="ml-60">        <span class="color-pink">$this</span>->color = <span class="color-pink">$color</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">getColor</span>() {  // Public method</p>
                                    <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>->color;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$myCar</span> = <span class="color-blue">new</span> <span class="color-yellow">Car</span>();</p>
                                    <p><span class="color-pink">$myCar</span>-><span class="color-red">setColor</span>(<span class="color-green">"Red"</span>);  <span class="color-grey">// Accessible outside the class</span></p>
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$myCar</span>-><span class="color-red">getColor</span>();  <span class="color-grey">// Outputs: Red</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>private</span> Modifier
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Private properties and methods can only be accessed within the class that defines them.</li>
                            <li>They cannot be accessed from outside the class or by inherited classes.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">BankAccount</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">private</span> <span class="color-pink">$balance</span>;  <span class="color-grey">// Private property</span></p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">deposit</span>(<span class="color-pink">$amount</span>) {  <span class="color-grey">// Public method</span></p>
                                    <p class="ml-60">        <span class="color-pink">$this</span>->balance += <span class="color-pink">$amount</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">private function</span> <span class="color-red">getBalance</span>() {  <span class="color-grey">// Private method</span></p>
                                    <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>->balance;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$account</span> = <span class="color-blue">new</span> <span class="color-yellow">BankAccount</span>();</p>
                                    <p><span class="color-pink">$account</span>-><span class="color-red">deposit</span>(<span class="color-pink">100</span>);</p>
                                    <p class="color-grey">// echo $account->getBalance(); // Error: Cannot access private method</p>
                                `
                            }}></div>
                        </div>
                        <p>In this example, the <span className='background-grey'>getBalance</span> method is private, so it cannot be called from outside the class.</p>
                    </li>
                    <li className='operator'>
                        <span className='background-grey'>protected</span> Modifier
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Protected properties and methods can be accessed within the class that defines them and by any class that inherits from it, but not from outside the class.</li>
                            <li>Protected members are useful when you want to allow access to inherited classes but not to external code.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Car</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">protected</span> <span class="color-pink">$model</span>;  <span class="color-grey">// Protected property</span></p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">setModel</span>(<span class="color-pink">$model</span>) {  <span class="color-grey">// Public method</span></p>
                                    <p class="ml-60">        <span class="color-pink">$this</span>->model = <span class="color-pink">$model</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">class</span> <span class="color-yellow">SportsCar</span> <span class="color-blue">extends</span> <span class="color-yellow">Car</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">getModel</span>() {  <span class="color-grey">// Public method in child class</span></p>
                                    <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>->model;  <span class="color-grey">// Accessing protected property</span></p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$myCar</span> = <span class="color-blue">new</span> <span class="color-red">SportsCar</span>();</p>
                                    <p><span class="color-pink">$myCar</span>-><span class="color-red">setModel</span>(<span class="color-green">"Porsche"</span>);</p>
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$myCar</span>-><span class="color-red">getModel</span>();  <span class="color-grey">// Outputs: Porsche</span></p>
                                    <p class="color-grey">// echo $myCar->model; // Error: Cannot access protected property</p>
                                `
                            }}></div>
                        </div>
                        <p>The <span className='background-grey'>model</span> property is protected, so it can be accessed within the <span className='background-grey'>SportsCar</span> class, which extends <span className='background-grey'>Car</span>, but not directly from outside the class.</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}