import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_abs";

export default function Abs() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP abs() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/mail',
            'next': '/php/func_ceil'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP abs() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The abs() function in PHP returns the absolute value of a number. The absolute value of a number is its non-negative value, regardless of whether it was originally positive or negative. It has a basic simple sintax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">abs</span>(<span class="color-blue">mixed</span> <span class="color-pink">$number</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$number</strong> is the number you want to get the absolute value of. This can be a positive or negative integer or float.</p>


                <h5 className="mt-5 mb-3">Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$number1</span> = <span class="color-pink">-15</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$number2</span> = <span class="color-pink">42</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">abs</span>(<span class="color-pink">$number1</span>); <span class="color-grey">// Output: 15</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">abs</span>(<span class="color-pink">$number2</span>); <span class="color-grey">// Output: 42</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">abs(-15)</span> returns <span className="background-grey">15</span> because the absolute value of <span className="background-grey">-15</span> is <span className="background-grey">15</span>.</li>
                    <li><span className="background-grey">abs(42)</span> returns <span className="background-grey">42</span> because the absolute value of <span className="background-grey">42</span> is <span className="background-grey">42</span> itself.</li>
                </ul>


                <p>The main purpose of the <span className="background-grey">abs()</span> function is to remove the sign of a number and return it as a positive value. This can be useful in a variety of situations, such as:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Ensuring that calculations don’t include negative numbers.</li>
                    <li>Measuring distances between points where negative values don’t make sense.</li>
                    <li>Handling situations where only the magnitude of a number matters, not its direction (sign).</li>
                </ul>


            </div>
        </section>
    )
}