import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_input_array";

export default function FilterInputArray() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_input_array() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_input',
            'next': '/php/func_filter_list'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_input_array() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_input_array()</span> function in PHP is used to filter multiple input variables from external sources (such as GET, POST, COOKIE, SERVER, or ENV) all at once. It works similarly to filter_input(), but instead of filtering a single variable, it allows you to filter an array of variables in one call.
                </p>
                <p>
                    This is useful when you need to sanitize or validate multiple user inputs (like form data) in one go, and it simplifies the process compared to handling each input individually.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_input_array</span>(type, definition, add_empty)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>type</strong>: The type of input source you want to retrieve the variables from. It can be one of the following:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">INPUT_GET</span>: For GET variables.</li>
                            <li><span className="background-grey">INPUT_POST</span>: For POST variables.</li>
                            <li><span className="background-grey">INPUT_COOKIE</span>: For COOKIE variables.</li>
                            <li><span className="background-grey">INPUT_SERVER</span>: For SERVER variables.</li>
                            <li><span className="background-grey">INPUT_ENV</span>: For ENV variables.</li>
                        </ul>
                    </li>
                    <li><strong>definition</strong> (optional): This is an associative array that defines which filters to apply to each input variable. The keys of this array are the names of the variables, and the values are the filters to apply. If no filter is specified for a variable, FILTER_DEFAULT is used, which applies no filtering.</li>
                    <li><strong>add_empty</strong> (optional): If set to true, missing keys are added as null values in the result. By default, it's set to true.</li>
                </ul>
                <p>It returns an <strong>associative array</strong> of filtered values and it returns <strong>null</strong> if no input source is found.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage of <span className="background-grey">filter_input_array()</span></h5>
                <p>Let’s say you have a form where users submit their name and email, and you want to filter both of these inputs in a single step.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filters</span> = <span class="color-red">array</span>(</p>
                        <p class="ml-60">        <span class="color-green">'name'</span>  => FILTER_SANITIZE_STRING,</p>
                        <p class="ml-60">        <span class="color-green">'email'</span> => FILTER_VALIDATE_EMAIL</p>
                        <p class="ml-30">    );</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$input</span> = <span class="color-red">filter_input_array</span>(INPUT_POST, <span class="color-pink">$filters</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$input</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Name: "</span> . <span class="color-pink">$input</span>[<span class="color-green">'name'</span>] . <span class="color-green">"&lt;br&gt;"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$input</span>[<span class="color-green">'email'</span>] === <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Invalid email address."</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Email: "</span> . <span class="color-pink">$input</span>[<span class="color-green">'email'</span>];</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">name</span> field is sanitized using <span className="background-grey">FILTER_SANITIZE_STRING</span> to remove any harmful characters.</li>
                    <li>The <span className="background-grey">email</span> field is validated using FILTER_VALIDATE_EMAIL.</li>
                    <li>The <span className="background-grey">filter_input_array()</span> function filters both fields at once, and you can access the filtered values from the <span className="background-grey">$input</span> array.</li>
                </ul>
                

            </div>
        </section>
    )
}