import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/crud-operation";

export default function CrudOperations() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("CRUD Operations in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/php-with-mysql',
            'next': '/php/prepared-statement'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>CRUD Operations</h3>
            <div className='mt-4 mb-5'>
                <p>
                    CRUD stands for <strong>Create</strong>, <strong>Read</strong>, <strong>Update</strong>, and <strong>Delete</strong>—the four basic operations you can perform on a database. In PHP, CRUD operations are commonly used when working with databases like MySQL. Let's explore each of these operations using PHP and MySQL.
                </p>
                <h5 className='mt-5 mb-3'>Create (Insert Data)</h5>
                <p>
                    The Create operation allows you to insert new data into a database table. Let's take an example. Suppose you have a table called <span class="background-grey">users</span> with columns <span class="background-grey">id</span>, <span class="background-grey">username</span>, and <span class="background-grey">email</span>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Database connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-green">"localhost"</span>, <span class="color-green">"username"</span>, <span class="color-green">"password"</span>, <span class="color-green">"database_name"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check connection</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                        <p class="ml-30">    }
                        <br />    
                        <p class="ml-30 color-grey">    // SQL query to insert data</p>
                        <p class="ml-30">    <span class="color-pink">$sql</span> = <span class="color-green">"INSERT INTO users (username, email) VALUES ('john_doe', 'john@example.com')"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>-><span class="color-red">query</span>(<span class="color-pink">$sql</span>) === <span class="color-blue">TRUE</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"New record created successfully"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error: "</span> . <span class="color-pink">$sql</span> . <span class="color-green">"&lt;br&gt;"</span> . <span class="color-pink">$conn</span>->error;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">INSERT INTO</span> SQL statement is used to add a new record to the users table.</li>
                    <li>The <span class="background-grey">query()</span> method executes the SQL query.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Read (Retrieve Data)</h5>
                <p>The Read operation allows you to retrieve data from the database. You can fetch all the fields data from the database.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Database connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-green">"localhost"</span>, <span class="color-green">"username"</span>, <span class="color-green">"password"</span>, <span class="color-green">"database_name"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check connection</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // SQL query to select data</p>
                        <p class="ml-30">    <span class="color-pink">$sql</span> = <span class="color-green">"SELECT id, username, email FROM users"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$result</span> = <span class="color-pink">$conn</span>-><span class="color-red">query</span>(<span class="color-pink">$sql</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$result</span>->num_rows > <span class="color-pink">0</span>) {</p>
                        <p class="ml-60 color-grey">        // Output data of each row</p>
                        <p class="ml-60">        <span class="color-blue">while</span>(<span class="color-pink">$row</span> = <span class="color-pink">$result</span>-><span class="color-red">fetch_assoc</span>()) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"ID: "</span> . <span class="color-pink">$row</span>[<span class="color-green">"id"</span>]. <span class="color-green">" - Username: "</span> . <span class="color-pink">$row</span>[<span class="color-green">"username"</span>]. <span class="color-green">" - Email: "</span> . <span class="color-pink">$row</span>[<span class="color-green">"email"</span>]. <span class="color-green">"&lt;br&gt;"</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"0 results"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">SELECT</span> SQL statement is used to fetch data from the users table.</li>
                    <li>The <span class="background-grey">fetch_assoc()</span> method retrieves each row of data as an associative array.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Update (Modify Data)</h5>
                <p>The Update operation allows you to modify existing records in the database.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Database connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-green">"localhost"</span>, <span class="color-green">"username"</span>, <span class="color-green">"password"</span>, <span class="color-green">"database_name"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check connection</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                        <p class="ml-30">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // SQL query to update data</p>
                        <p class="ml-30">    <span class="color-pink">$sql</span> = <span class="color-green">"UPDATE users SET email='johndoe@example.com' WHERE username='john_doe'"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>-><span class="color-red">query</span>(<span class="color-pink">$sql</span>) === <span class="color-blue">TRUE</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Record updated successfully"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error updating record: "</span> . <span class="color-pink">$conn</span>->error;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">UPDATE</span> SQL statement is used to modify the <span class="background-grey">email</span> field of the user with <span class="background-grey">username = 'john_doe'</span>.</li>
                    <li>The <span class="background-grey">query()</span> method executes the SQL query.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Delete (Remove Data)</h5>
                <p>The Delete operation allows you to remove data from the database.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Database connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-green">"localhost"</span>, <span class="color-green">"username"</span>, <span class="color-green">"password"</span>, <span class="color-green">"database_name"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check connection</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // SQL query to delete data</p>
                        <p class="ml-30">    <span class="color-pink">$sql</span> = <span class="color-green">"DELETE FROM users WHERE username='john_doe'"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>-><span class="color-red">query</span>(<span class="color-pink">$sql</span>) === <span class="color-blue">TRUE</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Record deleted successfully"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">       <span class="color-blue"> echo</span> <span class="color-green">"Error deleting record: "</span> . $conn->error;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">DELETE FROM</span> SQL statement is used to remove the record where <span class="background-grey">username = 'john_doe'</span>.</li>
                    <li>The <span class="background-grey">query()</span> method executes the SQL query.</li>
                </ul>
            </div>
        </section>
    )
}