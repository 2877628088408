import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strpos";

export default function Strpos() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strpos() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_substr',
            'next': '/php/func_strrpos'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strpos() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strpos() function is used to <strong>find the position of the first occurrence</strong> of a substring in a string. It returns the index where the substring first appears, starting from 0. If the substring is not found, it returns <span className="background-grey">false</span>. It has a simple syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strpos</span>(<span class="color-blue">string</span> <span class="color-pink">$haystack</span>, <span class="color-blue">string</span> <span class="color-pink">$needle</span>, <span class="color-blue">int</span> <span class="color-pink">$offset</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$haystack</strong>: The string to search in.</li>
                    <li><strong>$needle</strong>: The substring you are looking for within the <span className="background-grey">$haystack</span>.</li>
                    <li><strong>$offset</strong> (optional): The search will start from this position (default is 0).</li>
                    <li><strong>Return Value</strong>: Returns the position of the first occurrence of the substring in the string. If the substring is not found, it returns <span className="background-grey">false</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello, world!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$position</span> = <span class="color-red">strpos</span>(<span class="color-pink">$string</span>, <span class="color-green">"world"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$position</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The position of 'world' is: "</span> . <span class="color-pink">$position</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"'world' not found!"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The position of 'world' is: <span class="color-pink">7</span></p>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, <span className="background-grey">strpos()</span> finds that the substring <span className="background-grey">"world"</span> starts at index 7 in the string <span className="background-grey">"Hello, world!"</span>.
                </p>

                <h5 className="mt-5 mb-3">Using the <span className="background-grey">$offset</span> Parameter</h5>
                <p>The <span className="background-grey">$offset</span> parameter allows you to start the search from a specific position in the string.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello, world! world again!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$position</span> = <span class="color-red">strpos</span>(<span class="color-pink">$string</span>, <span class="color-green">"world"</span>, <span class="color-pink">10</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$position</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The second occurrence of 'world' is at position: "</span> . <span class="color-pink">$position</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The second occurrence of <span class="color-green">'world'</span> is at position: <span class="color-pink">14</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example, the search starts at index 10, skipping over the first occurrence of <span className="background-grey">"world"</span> and finding the second one at position 14.</p>

            </div>
        </section>
    )
}