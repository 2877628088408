import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_restore_exception_handler";

export default function RestoreExceptionHandler() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP restore_exception_handler() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_restore_error_handler',
            'next': '/php/func_set_error_handler'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP restore_exception_handler() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">restore_exception_handler()</span> function in PHP is used to restore the previous exception handler that was in place before any custom handler was set using <span className="background-grey">set_exception_handler()</span>. When a custom exception handler is no longer needed, this function reverts the exception handling behavior to its original state, which is usually the default built-in PHP exception handler.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">restore_exception_handler</span>();</p>
                        `
                    }}></div>
                </div>
                <p>If the previous exception handler was successfully restored it returns <strong>true</strong>. If there was no previous handler to restore, it returns <strong>false</strong>.</p>

                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>Let’s create a custom exception handler and then restore the original one after processing a specific part of the code.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Custom exception handler function</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">customExceptionHandler</span>(<span class="color-pink">$exception</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Custom Exception: "</span> . <span class="color-pink">$exception</span>-><span class="color-red">getMessage</span>() . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the custom exception handler</p>
                        <p class="ml-30">    <span class="color-red">set_exception_handler</span>(<span class="color-green">"customExceptionHandler"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Throw an exception to trigger the custom handler</p>
                        <p class="ml-30">    <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"This is a custom exception!"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Restore the previous exception handler (the default handler)</p>
                        <p class="ml-30">    <span class="color-red">restore_exception_handler</span>();</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Throw another exception to show the default handler is restored</p>
                        <p class="ml-30">    <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"This is a default exception!"</span>);</span>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">customExceptionHandler()</span> function is defined to handle exceptions in a custom way (in this case, printing the exception message with a prefix "Custom Exception").</li>
                    <li>The <span className="background-grey">set_exception_handler()</span> function sets <span className="background-grey">customExceptionHandler</span> as the active handler.</li>
                    <li>The <span className="background-grey">throw new Exception("This is a custom exception!")</span>; line triggers the custom handler, and the message is printed.</li>
                    <li>The <span className="background-grey">restore_exception_handler()</span> line restores the default PHP exception handler.</li>
                    <li>The next exception is handled by PHP's default handler, which will display the exception differently (usually with a full stack trace in non-production environments).</li>
                </ul>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Custom Exception</span>: This is a custom exception!</p>
                            <p><span class="color-red">Fatal error</span>: Uncaught Exception: This is a default exception! in...</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}