import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_diff";

export default function ArrayDiff() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_diff() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_count_values',
            'next': '/php/func_array_diff_assoc'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_diff() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_diff() function in PHP compares two or more arrays and returns an array containing the values from the first array that are not present in any of the other arrays. It performs a value-based comparison, ignoring the keys. This function is useful when you want to find differences between multiple arrays.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_diff</span>(<span class="color-blue">array</span> <span class="color-pink">$array1</span>, <span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array1</strong>: The first array to compare.</li>
                    <li><strong>$arrays</strong>: One or more arrays to compare against $array1.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"orange"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => orange</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function compares <span className='background-grey'>$array1</span> (<span className='background-grey'>["apple", "banana", "orange"]</span>) with <span className='background-grey'>$array2</span> (<span className='background-grey'>["banana", "cherry"]</span>).</li>
                    <li>The value <span className='background-grey'>"banana"</span> exists in both arrays, so it is excluded from the result.</li>
                    <li>The values <span className='background-grey'>"apple"</span> and <span className='background-grey'>"orange"</span> exist only in <span className='background-grey'>$array1</span>, so they are included in the result.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Comparing Multiple Arrays</h5>
                <p>You can compare more than two arrays by passing additional arrays as arguments.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"red"</span>, <span class="color-green">"green"</span>, <span class="color-green">"blue"</span>, <span class="color-green">"yellow"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"green"</span>, <span class="color-green">"blue"</span>];</p>
                            <p><span class="color-pink">$array3</span> = [<span class="color-green">"yellow"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>, <span class="color-pink">$array3</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => red</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function compares <span className='background-grey'>$array1</span> with both <span className='background-grey'>$array2</span> and <span className='background-grey'>$array3</span>.</li>
                    <li>The values <span className='background-grey'>"green"</span>, <span className='background-grey'>"blue"</span>, and <span className='background-grey'>"yellow"</span> exist in either <span className='background-grey'>$array2</span> or <span className='background-grey'>$array3</span>, so they are excluded from the result.</li>
                    <li>The only value left in <span className='background-grey'>$array1</span> that is not in the other arrays is <span className='background-grey'>"red"</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Numeric Arrays</h5>
                <p>The function also works with numeric arrays.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers1</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <p><span class="color-pink">$numbers2</span> = [<span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">6</span>, <span class="color-pink">7</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff</span>(<span class="color-pink">$numbers1</span>, <span class="color-pink">$numbers2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">5</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The values <span className='background-grey'>3</span> and <span className='background-grey'>4</span> appear in both arrays, so they are excluded from the result.</li>
                    <li>The values <span className='background-grey'>1</span>, <span className='background-grey'>2</span>, and <span className='background-grey'>5</span> appear only in <span className='background-grey'>$numbers1</span>, so they are returned in the result.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Associative Arrays</h5>
                <p>Although <span className='background-grey'>array_diff()</span> does not compare array keys, it will still return values from an associative array, while preserving the original keys.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"orange"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [b] => banana</p>
                            <p class="ml-30">    [c] => cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value <span className='background-grey'>"apple"</span> appears in both arrays, so it is excluded.</li>
                    <li>The values <span className='background-grey'>"banana"</span> and <span className='background-grey'>"cherry"</span> are present only in $array1, so they are returned.</li>
                    <li>The original keys (<span className='background-grey'>"b"</span> and <span className='background-grey'>"c"</span>) are preserved in the result.</li>
                </ul>
            </div>
        </section>
    )
}