import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getcwd";

export default function GetCwd() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getcwd() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_dir',
            'next': '/php/func_opendir'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getcwd() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getcwd()</span> function in PHP is used to get the current working directory. It returns the absolute path of the directory in which the script is currently executing.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">getcwd</span>()</p>
                        `
                    }}></div>
                </div>
                <p>It returns the absolute path of the current working directory on success. If the current directory is not accessible, it returns false.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">getcwd()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Get the current working directory</p>
                        <p class="ml-30">    <span class="color-pink">$currentDir</span> = <span class="color-red">getcwd</span>();</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Current working directory is: "</span> . <span class="color-pink">$currentDir</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Current working directory is: /path/to/current/directory</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}