import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_slice";

export default function ArraySlice() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_slice() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_shift',
            'next': '/php/func_array_splice'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_slice() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_slice() function in PHP is used to extract a portion (a "slice") of an array. It allows you to specify the starting point, the length of the slice, and whether or not to preserve the keys of the original array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">array_slice</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$offset</span>, ?<span class="color-blue">int</span> <span class="color-pink">$length</span> = <span class="color-blue">null</span>, <span class="color-blue">bool</span> <span class="color-pink">$preserve_keys</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$offset</strong>: Specifies the starting position of the slice. Positive numbers start from the beginning of the array, while negative numbers start from the end.</li>
                    <li><strong>$length</strong>: Controls how many elements are extracted. If omitted, the slice goes to the end of the array.</li>
                    <li><strong>$preserve_keys</strong>: By default, the keys are reindexed, but if you want to keep the original keys, you can set this parameter to <span className='background-grey'>true</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>, <span class="color-green">"elderberry"</span>];</p>
                            <p><span class="color-pink">$slice</span> = <span class="color-red">array_slice</span>(<span class="color-pink">$array</span>, <span class="color-pink">1</span>, <span class="color-pink">3</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$slice</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The slice starts at index <span className='background-grey'>1</span> (second element, "banana") and extracts <span className='background-grey'>3</span> elements: "banana", "cherry", and "date".</li>
                    <li>The array is reindexed, so the keys start from <span className='background-grey'>0</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Omitting the Length</h5>
                <p>If you don't specify the length, <span className='background-grey'>array_slice()</span> will return all elements from the offset to the end of the array:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>, <span class="color-green">"elderberry"</span>];</p>
                            <p><span class="color-pink">$slice</span> = <span class="color-red">array_slice</span>(<span class="color-pink">$array</span>, <span class="color-pink">2</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$slice</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => date</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => elderberry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The slice starts at index <span className='background-grey'>2</span> (third element, "cherry") and extracts all the remaining elements.</p>


                <h5 className="mt-5 mb-3">Example 3: Using a Negative Offset</h5>
                <p>You can use a negative offset to start counting from the end of the array::</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>, <span class="color-green">"elderberry"</span>];</p>
                        <p><span class="color-pink">$slice</span> = <span class="color-red">array_slice</span>(<span class="color-pink">$array</span>, <span class="color-pink">-3</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$slice</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => date</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => elderberry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The slice starts from the third-to-last element ("cherry") and continues to the end of the array.</p>
            </div>
        </section>
    )
}