import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strrpos";

export default function Strrpos() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strrpos() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strpos',
            'next': '/php/func_strstr'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strrpos() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strrpos() function in PHP is used to <strong>find the position of the last occurrence</strong> of a substring within a string. This function is particularly useful when you need to locate the last instance of a substring, as opposed to the first instance (which is done using strpos()). It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strrpos</span>(<span class="color-blue">string</span> <span class="color-pink">$haystack</span>, <span class="color-blue">string</span> <span class="color-pink">$needle</span>, <span class="color-blue">int</span> <span class="color-pink">$offset</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$haystack</strong>: The string to search within.</li>
                    <li><strong>$needle</strong>: The substring to search for.</li>
                    <li><strong>$offset</strong> (optional): The position from which to start the search. The default is 0, meaning the search starts from the beginning of the string.</li>
                    <li><strong>Return Value</strong>: Returns the position of the last occurrence of the substring within the string. If the substring is not found, it returns <span className="background-grey">false</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello, world! Welcome to the world of PHP."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$lastPosition</span> = <span class="color-red">strrpos</span>(<span class="color-pink">$string</span>, <span class="color-green">"world"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$lastPosition</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The last occurrence of 'world' is at position: "</span> . <span class="color-pink">$lastPosition</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"'world' not found!";</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The last occurrence of <span class="color-green">'world'</span> is at position: <span class="color-pink">27</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span className="background-grey">strrpos()</span> function finds that the last occurrence of the substring <span className="background-grey">"world"</span> starts at index 27 in the string.</p>


            </div>
        </section>
    )
}