import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_diff_assoc";

export default function ArrayDiffAssoc() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_diff_assoc() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_diff',
            'next': '/php/func_array_diff_key'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_diff_assoc() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_diff_assoc() function in PHP compares two or more arrays and returns the differences, but unlike array_diff(), it also compares the keys as well as the values. This means that even if two arrays have the same value, they are considered different if their keys don't match.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_diff_assoc</span>(<span class="color-blue">array</span> <span class="color-pink">$array1</span>, <span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array1</strong>: The first array to compare.</li>
                    <li><strong>$arrays</strong>: One or more arrays to compare against $array1.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"orange"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_assoc</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [b] => banana</p>
                            <p class="ml-30">    [c] => cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function compares <span className='background-grey'>$array1</span> with <span className='background-grey'>$array2</span>.</li>
                    <li>The key-value pair <span className='background-grey'>"a" =&gt; "apple"</span> is present in both arrays, so it is excluded.</li>
                    <li>The value <span className='background-grey'>"banana"</span> has the same key <span className='background-grey'>"b"</span> in both arrays, but its value is different, so it is included in the result.</li>
                    <li>The value <span className='background-grey'>"cherry"</span> is not present in <span className='background-grey'>$array2</span>, so it is also included in the result.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Numeric Keys</h5>
                <p>If the arrays have numeric keys, the function still compares both keys and values.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-pink">0</span> => <span class="color-green">"red"</span>, <span class="color-pink">1</span> => <span class="color-green">"green"</span>, <span class="color-pink">2</span> => <span class="color-green">"blue"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-pink">0</span> => <span class="color-green">"red"</span>, <span class="color-pink">1</span> => <span class="color-green">"yellow"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_assoc</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => green</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => blue</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value <span className='background-grey'>"red"</span> is present in both arrays with the same key (<span className='background-grey'>0</span>), so it is excluded.</li>
                    <li>The value <span className='background-grey'>"green"</span> is present in <span className='background-grey'>$array1</span> with the key <span className='background-grey'>1</span>, but in <span className='background-grey'>$array2</span>, the key <span className='background-grey'>1</span> contains <span className='background-grey'>"yellow"</span>, so <span className='background-grey'>"green"</span> is included in the result.</li>
                    <li>The value <span className='background-grey'>"blue"</span> with the key <span className='background-grey'>2</span> exists only in <span className='background-grey'>$array1</span>, so it is also included.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Multiple Arrays</h5>
                <p>You can compare multiple arrays at once by passing additional arrays as arguments.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"name"</span> => <span class="color-green">"Alice"</span>, <span class="color-green">"age"</span> => <span class="color-pink">25</span>, <span class="color-green">"city"</span> => <span class="color-green">"New York"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"name"</span> => <span class="color-green">"Alice"</span>, <span class="color-green">"age"</span> => <span class="color-pink">30</span>];</p>
                            <p><span class="color-pink">$array3</span> = [<span class="color-green">"city"</span> => <span class="color-green">"Los Angeles"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_assoc</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>, <span class="color-pink">$array3</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [age] => <span class="color-pink">25</span></p>
                            <p class="ml-30">    [city] => New York</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    The <span className='background-grey'>"name"</span> key with the value <span className='background-grey'>"Alice"</span> is present in both <span className='background-grey'>$array1</span> and <span className='background-grey'>$array2</span>, so it is excluded.
                    The <span className='background-grey'>"age"</span> key has different values (<span className='background-grey'>25</span> in <span className='background-grey'>$array1</span> and <span className='background-grey'>30</span> in <span className='background-grey'>$array2</span>), so the key-value pair from <span className='background-grey'>$array1</span> is included in the result.
                    The <span className='background-grey'>"city"</span> key has different values in <span className='background-grey'>$array1</span> (<span className='background-grey'>"New York"</span>) and <span className='background-grey'>$array3</span> (<span className='background-grey'>"Los Angeles"</span>), so it is included in the result.
                </ul>

            </div>
        </section>
    )
}