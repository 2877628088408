import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/head";

export default function Head() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <head> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;head&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;head&gt;</span> tag in HTML is used to contain metadata and links to external resources for an HTML document. It appears within the <span class="background-grey">&lt;html&gt;</span> element and above the <span class="background-grey">&lt;body&gt;</span> tag. Content inside <span class="background-grey">&lt;head&gt;</span> is not displayed on the web page itself but provides crucial information for browsers, search engines, and other external services.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;head&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;head&gt;</span> tag holds metadata about the document, including the title, character set, links to stylesheets, scripts, and other resources.</li>
                    <li>Elements within <span class="background-grey">&lt;head&gt;</span> do not directly appear in the page content but impact how the page is displayed and indexed.</li>
                    <li>The resources in <span class="background-grey">&lt;head&gt;</span>, such as CSS files, load before the visible content, improving the initial page rendering.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Common Elements Inside <span class="background-grey">&lt;head&gt;</span> tag</h5>
                <p>Here are some of the common elements typically included within the <span class="background-grey">&lt;head&gt;</span> tag:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">&lt;title&gt;</span>: Defines the title shown in the browser tab and when the page is bookmarked.</li>
                    <li><span class="background-grey">&lt;meta&gt;</span>: Provides metadata about the HTML document.</li>
                    <li><span class="background-grey">&lt;link&gt;</span>: Links to external resources like stylesheets or icons.</li>
                    <li><span class="background-grey">&lt;style&gt;</span>: Allows for embedding internal CSS directly in the HTML document.</li>
                    <li><span class="background-grey">&lt;script&gt;</span>: Links or embeds JavaScript code. Scripts in <span class="background-grey">&lt;head&gt;</span> often include those that need to load before the body or with <span class="background-grey">defer</span> or <span class="background-grey">async</span> attributes.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;head&gt;</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Sample HTML Document&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"description"</span> <span class="color-pink">content</span>=<span class="color-green">"This is a sample HTML document with metadata."</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"keywords"</span> <span class="color-pink">content</span>=<span class="color-green">"HTML, CSS, JavaScript, sample document"</span>&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"icon"</span> <span class="color-pink">href</span>=<span class="color-green">"favicon.ico"</span> <span class="color-pink">type</span>=<span class="color-green">"image/x-icon"</span>&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            h1 {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: navy;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span> <span class="color-pink">defer</span>&gt;&lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This document includes a variety of head elements.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}