import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_fread";

export default function FRead() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP fread() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_fopen',
            'next': '/php/func_fwrite'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP fread() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">fread()</span> function in PHP is used to read a specified number of bytes from an open file. It is typically used after opening a file with <span className="background-grey">fopen()</span> to retrieve data from the file.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fread</span>(file, length);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>file</strong> (required): The file pointer resource that is created by fopen(). This file must be opened successfully before you can read it using fread().</li>
                    <li><strong>length</strong> (required): The number of bytes you want to read from the file. This should be a positive integer.</li>
                </ul>
                <p>It returns the string that contains the data read from the file, or <span className="background-grey">FALSE</span> on failure.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">fread()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"r"</span>) or <span class="color-red">die</span>(<span class="color-green">"Unable to open file!"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$content</span> = <span class="color-red">fread</span>(<span class="color-pink">$file</span>, <span class="color-red">filesize</span>(<span class="color-green">"example.txt"</span>));</p>
                        <p class="ml-30">    <span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$content</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">fopen("example.txt", "r")</span>: Opens the file <span className="background-grey">example.txt</span> in read-only mode.</li>
                    <li><span className="background-grey">filesize("example.txt")</span>: Returns the size of the file in bytes, which is passed to <span className="background-grey">fread()</span> to ensure that the entire file content is read.</li>
                    <li><span className="background-grey">fread()</span>: Reads the file content.</li>
                    <li><span className="background-grey">fclose()</span>: Closes the file after reading to free up system resources.</li>
                    <li><span className="background-grey">echo $content</span>: Displays the content of the file.</li>
                </ul>



            </div>
        </section>
    )
}