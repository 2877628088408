import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_max";

export default function Max() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP max() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_round',
            'next': '/php/func_min'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP max() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The max() function in PHP is used to find the maximum value from a set of values or an array. It can be used with multiple parameters or an array to return the highest value. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">max</span>(value1, value2, value3, ...)</p>
                            <p><span class="color-red">max</span>(<span class="color-blue">array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>value1, value2, ...</strong>: These are the values from which you want to find the maximum.</li>
                    <li><strong>array</strong>: This can also be an array from which the function will return the maximum value.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Finding the Maximum from Multiple Values</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">max</span>(<span class="color-pink">3</span>, <span class="color-pink">5</span>, <span class="color-pink">2</span>, <span class="color-pink">8</span>, <span class="color-pink">1</span>);  <span class="color-grey">// Output: 8</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">max(3, 5, 2, 8, 1)</span> returns 8 because 8 is the largest number in the list.</p>


                <h5 className="mt-5 mb-3">Example 2: Finding the Maximum Value in an Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$numbers</span> = <span class="color-red">array</span>(<span class="color-pink">7</span>, <span class="color-pink">2</span>, <span class="color-pink">10</span>, <span class="color-pink">4</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">max</span>(<span class="color-pink">$numbers</span>);  <span class="color-grey">// Output: 10</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">max($numbers)</span> returns <span className="background-grey">10</span> because <span className="background-grey">10</span> is the largest number in the array</p>


                <h5 className="mt-5 mb-3">Example 3: Using Strings with <span className="background-grey">max()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">max</span>(<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>);  <span class="color-grey">// Output: cherry</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">max("apple", "banana", "cherry")</span> returns cherry because it comes last alphabetically.</p>

            </div>
        </section>
    )
}