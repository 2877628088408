import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_mkdir";

export default function Mkdir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP mkdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_is_dir',
            'next': '/php/func_rmdir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP mkdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">mkdir()</span> function in PHP is used to create a new directory. This function is essential when working with file systems, as it allows developers to create folders dynamically, often necessary for organizing files or handling file uploads.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">mkdir</span>(<span class="color-blue">string</span> <span class="color-pink">$pathname</span>, <span class="color-blue">int</span> <span class="color-pink">$mode</span> = <span class="color-pink">0777</span>, <span class="color-blue">bool</span> <span class="color-pink">$recursive</span> = <span class="color-blue">false</span>, resource|null <span class="color-pink">$context</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$pathname</strong> (required): The name of the directory to be created. This can be either a relative or absolute path.</li>
                    <li><strong>$mode</strong> (optional): The permissions for the new directory. The default is 0777, which gives full access to the directory (read, write, and execute) to everyone. It’s typically used in Unix-like systems. Note that the actual permissions are affected by the umask.</li>
                    <li><strong>$recursive</strong> (optional): If true, PHP will create nested directories if they don't exist. This allows for the creation of multiple levels of directories at once.</li>
                    <li><strong>$context</strong> (optional): A valid context resource that modifies the behavior of the stream. In most cases, this parameter is omitted.</li>
                </ul>

                <p>It returns <span className="background-grey">true</span> if the directory was created successfully and returns <span className="background-grey">false</span> if the directory could not be created.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>The simplest way to use <span className="background-grey">mkdir()</span> is to create a directory with the default mode.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-green">'new_folder'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">mkdir</span>(<span class="color-pink">$directory</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Directory '$directory' created successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to create directory '$directory'."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>This example attempts to create a directory named <span className="background-grey">new_folder</span> in the current directory. If the directory is successfully created, it will display:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Directory 'new_folder' created successfully.</p>
                        `
                    }}></div>
                </div>
                <p>
                    The <span className="background-grey">mkdir()</span> function in PHP is an essential tool for creating directories dynamically. Whether you are organizing uploaded files, logs, backups, or any other data, <span className="background-grey">mkdir()</span> provides a straightforward way to create directories in a variety of situations. By understanding how to use it with permissions, nested directories, and error handling, you can enhance the file management capabilities of your PHP applications.
                </p>


            </div>
        </section>
    )
}