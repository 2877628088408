import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_addslashes";

export default function AddSlashes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP addslashes() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_htmlspecialchars',
            'next': '/php/func_str_repeat'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP addslashes() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The addslashes() function in PHP is used to escape certain characters in a string by adding backslashes before them. This is particularly useful for preparing strings for insertion into databases or for safely displaying data that contains special characters. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">addslashes</span>(<span class="color-blue">string</span> <span class="color-pink">$str</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$str</strong> is the input string that you want to escape</p>
                <p>The function returns the modified string with backslashes added before the characters: single quotes (<span className="background-grey">'</span>), double quotes (<span className="background-grey">"</span>), backslashes (<span className="background-grey">\</span>), and <span className="background-grey">NULL</span> characters.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$input</span> = <span class="color-green">"He said, 'Hello, World!'"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$escaped</span> = <span class="color-red">addslashes</span>(<span class="color-pink">$input</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$escaped</span>; <span class="color-grey">// Output: He said, \'Hello, World!'</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the function adds backslashes before the single and double quotes, making the string safe for use in certain contexts.</p>

                <p>The main purpose of <span className="background-grey">addslashes()</span> is to escape special characters in a string, which is especially important when dealing with strings that are used in SQL queries or displayed in HTML.</p>
            </div>
        </section>
    )
}