import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ceil";

export default function Ceil() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ceil() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_abs',
            'next': '/php/func_floor'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ceil() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The ceil() function in PHP is used to round a floating-point number up to the nearest integer. This function always rounds the number upward, regardless of the decimal part. If the number is already an integer, it will remain unchanged. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ceil</span>(<span class="color-blue">float</span> <span class="color-pink">$value</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$value</strong> is the input number, which can be a positive or negative floating-point number or an integer.</p>
                <p>The function returns the next highest integer value of the input number. It always rounds the number upwards.</p>


                <h5 className="mt-5 mb-3">Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">ceil</span>(<span class="color-pink">4.2</span>);  <span class="color-grey">// Output: 5</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">ceil</span>(<span class="color-pink">9.999</span>); <span class="color-grey">// Output: 10</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">ceil</span>(<span class="color-pink">-3.7</span>);  <span class="color-grey">// Output: -3</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">ceil(4.2)</span> returns <span className="background-grey">5</span>, as it rounds <span className="background-grey">4.2</span> up to the nearest whole number.</li>
                    <li><span className="background-grey">ceil(9.999)</span> returns <span className="background-grey">10</span>, as it rounds <span className="background-grey">9.999</span> up to <span className="background-grey">10</span>.</li>
                    <li><span className="background-grey">ceil(-3.7)</span> returns <span className="background-grey">-3</span>, as the function rounds upward (towards 0), even for negative numbers.</li>
                </ul>

                <p>The main purpose of the <span className="background-grey">ceil()</span> function is to round numbers up to the nearest integer. This is especially useful when you need to ensure that a floating-point number is rounded to the next whole number.</p>
            </div>
        </section>
    )
}