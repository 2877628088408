import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_debug_backtrace";

export default function DebugBacktrace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP debug_backtrace() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_scandir',
            'next': '/php/func_debug_print_backtrace'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP debug_backtrace() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">debug_backtrace()</span> function in PHP is used to generate a backtrace, which is essentially a detailed report of the function calls that led to the current point of execution. This function is particularly useful for debugging, as it helps developers track down the origin of errors or unexpected behaviors in their code by showing the call stack.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">debug_backtrace</span>(<span class="color-blue">int</span> <span class="color-pink">$options</span> = DEBUG_BACKTRACE_PROVIDE_OBJECT, <span class="color-blue">int</span> <span class="color-pink">$limit</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }} className='mb-4'>
                    <li>
                        <strong>options</strong> (Optional): This controls what kind of information will be returned. The available options are:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className="background-grey">DEBUG_BACKTRACE_PROVIDE_OBJECT</span>: Includes object information in the backtrace.</li>
                            <li><span className="background-grey">DEBUG_BACKTRACE_IGNORE_ARGS</span>: Does not include the function's arguments in the backtrace.</li>
                        </ul>
                    </li>
                    <li><strong>limit</strong> (Optional): This limits the number of stack frames to return. If set to 0, there is no limit (returns the entire call stack).</li>
                </ul>


                <p>The <span className="background-grey">debug_backtrace()</span> function returns an array that contains information about the backtrace, including details like:</p>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>Function</strong>: The name of the function that was called.</li>
                    <li><strong>File</strong>: The file where the function was called.</li>
                    <li><strong>Line</strong>: The line number in the file where the function was called.</li>
                    <li><strong>Args</strong>: An array of the arguments passed to the function.</li>
                </ul>


                <h5 className="mt-5 mb-3">Basic Usage of <span className="background-grey">debug_backtrace()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">third_function</span>() {</p>
                        <p class="ml-60">        <span class="color-red">print_r</span>(<span class="color-red">debug_backtrace</span>());</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">second_function</span>() {</p>
                        <p class="ml-60">        <span class="color-red">third_function</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">first_function</span>() {</p>
                        <p class="ml-60">        <span class="color-red">second_function</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">first_function</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Array</p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [file] => /path/to/file.php</p>
                        <p class="ml-90">            [line] => <span class="color-pink">4</span></p>
                        <p class="ml-90">            [function] => third_function</p>
                        <p class="ml-90">            [args] => <span class="color-red">Array</span></p>
                        <p class="ml-120">                (</p>
                        <p class="ml-120">                )</p>
                        <p class="ml-60">        )</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [file] => /path/to/file.php</p>
                        <p class="ml-90">            [line] => <span class="color-pink">8</span></p>
                        <p class="ml-90">            [function] => second_function</p>
                        <p class="ml-90">            [args] => <span class="color-red">Array</span></p>
                        <p class="ml-120">                (</p>
                        <p class="ml-120">                )</p>
                        <p class="ml-60">        )</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [file] => /path/to/file.php</p>
                        <p class="ml-90">            [line] => <span class="color-pink">12</span></p>
                        <p class="ml-90">            [function] => first_function</p>
                        <p class="ml-90">            [args] => <span class="color-red">Array</span></p>
                        <p class="ml-120">                (</p>
                        <p class="ml-120">                )</p>
                        <p class="ml-60">        )</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">debug_backtrace()</span> function outputs the series of function calls that led to the <span className="background-grey">third_function()</span> execution.</li>
                    <li>The array shows that <span className="background-grey">third_function()</span> was called on line 4, by <span className="background-grey">second_function()</span> on line 8, which was in turn called by <span className="background-grey">first_function()</span> on line 12.</li>
                    <li>The function arguments (empty in this case) are shown in the <span className="background-grey">args</span> field.</li>
                </ul>

            </div>
        </section>
    )
}