import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_fwrite";

export default function FWrite() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP fwrite() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_fread',
            'next': '/php/func_fclose'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP fwrite() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">fwrite()</span> function in PHP is used to write data to a file. It is particularly useful when you want to create or modify files programmatically.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fwrite</span>(file, string, length);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>file</strong> (required): The file pointer resource that was created by <span className="background-grey">fopen()</span>. The file must be opened in write or append mode before you can use <span className="background-grey">fwrite()</span>.</li>
                    <li><strong>string</strong> (required): The string data you want to write to the file.</li>
                    <li><strong>length</strong> (optional): Specifies the maximum number of bytes to write. If omitted, the entire string is written to the file.</li>
                </ul>
                <p><span className="background-grey">fwrite()</span> returns the number of bytes written, or <span className="background-grey">FALSE</span> on failure.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">fwrite()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"w"</span>) or <span class="color-red">die</span>(<span class="color-green">"Unable to open file!"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello, World!"</span>;</p>
                        <p class="ml-30">    <span class="color-red">fwrite</span>(<span class="color-pink">$file</span>, <span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">fopen("example.txt", "w")</span>: Opens <span className="background-grey">example.txt</span> in write mode. If the file does not exist, it will be created. If it exists, the content will be erased.</li>
                    <li><span className="background-grey">fwrite($file, $text)</span>: Writes the string "Hello, World!" to the file.</li>
                    <li><span className="background-grey">fclose($file)</span>: Closes the file to free up system resources.</li>
                </ul>



            </div>
        </section>
    )
}