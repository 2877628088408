import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/main";

export default function Main() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <main> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;main&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;main&gt;</span> tag in HTML is used to define the primary content of a document. It represents the central or most important part of the page, excluding any repetitive content like headers, footers, navigation links, sidebars, or advertisements. The <span class="background-grey">&lt;main&gt;</span> element is a semantic tag introduced in HTML5 to help improve accessibility and assistive technologies by clearly indicating the main focus of the content.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;main&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Only one <span class="background-grey">&lt;main&gt;</span> tag should be used per page, as it defines the primary content for that specific page.</li>
                    <li>The <span class="background-grey">&lt;main&gt;</span> content should not include elements like <span class="background-grey">&lt;header&gt;</span>, <span class="background-grey">&lt;footer&gt;</span>, <span class="background-grey">&lt;nav&gt;</span>, or <span class="background-grey">&lt;aside&gt;</span>.</li>
                    <li>Screen readers can skip the <span class="background-grey">&lt;header&gt;</span>, <span class="background-grey">&lt;footer&gt;</span>, and other structural elements to go directly to the main content by detecting the <span class="background-grey">&lt;main&gt;</span> element.</li>
                </ul>   
                

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;main&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Main content goes here --&gt;</p>
                        <p>&lt;/main&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example of <span class="background-grey">&lt;main&gt;</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-grey">html&gt;</p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Main Tag Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;header&gt;</p>
                        <p class="ml-90">            &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;/header&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;nav&gt;</p>
                        <p class="ml-90">            &lt;ul&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#home"</span>&gt;Home&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#about"</span>&gt;About&lt;/a&lt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#contact"</span>&gt;Contact&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;/nav&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;main&gt;</p>
                        <p class="ml-90">            &lt;h2&gt;Main Content Area&lt;/h2&gt;</p>
                        <p class="ml-90">            &lt;p&gt;This is where the main content of the page goes, such as articles, text, and any other primary content intended for users.&lt;/p&lt;</p>
                        <p class="ml-60">        &lt;/main&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;footer&gt;</p>
                        <p class="ml-90">            &lt;p&gt;&copy; 2024 My Website. All rights reserved.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/footer&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <title>Main Tag Example</title>
                        </head>
                        <body>
                        
                        <header>
                          <h1>Welcome to My Website</h1>
                        </header>
                        
                        <nav>
                          <ul>
                            <li><a href="#home">Home</a></li>
                            <li><a href="#about">About</a></li>
                            <li><a href="#contact">Contact</a></li>
                          </ul>
                        </nav>
                        
                        <main>
                          <h2>Main Content Area</h2>
                          <p>This is where the main content of the page goes, such as articles, text, and any other primary content intended for users.</p>
                        </main>
                        
                        <footer>
                          <p>&copy; 2024 My Website. All rights reserved.</p>
                        </footer>
                        
                        </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;main&gt;</span> tag is used to enclose the primary content, which is the core focus of the page.</li>
                    <li>The header, footer, and navigation links are kept outside <span class="background-grey">&lt;main&gt;</span>, as they are repetitive or non-essential for understanding the main content.</li>
                </ul>


            </div>
        </section>
    )
}