import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_fill_keys";

export default function ArrayDiffKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_fill_keys() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_fill',
            'next': '/php/func_array_filter'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_fill_keys() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_fill_keys() function in PHP creates a new array using a given set of keys and assigns a specified value to each of those keys. This function is particularly useful when you want to create an associative array where all keys have the same value.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_fill_keys</span>(<span class="color-blue">array</span> <span class="color-pink">$keys</span>, <span class="color-blue">mixed</span> <span class="color-pink">$value</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$keys</strong>: An array of keys that will be used in the new array.</li>
                    <li><strong>$value</strong>: The value that will be assigned to each of the keys.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$keys</span> = [<span class="color-green">"a"</span>, <span class="color-green">"b"</span>, <span class="color-green">"c"</span>];</p>
                            <p><span class="color-pink">$value</span> = <span class="color-green">"fruit"</span>;</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_fill_keys</span>(<span class="color-pink">$keys</span>, <span class="color-pink">$value</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [a] => fruit</p>
                            <p class="ml-30">    [b] => fruit</p>
                            <p class="ml-30">    [c] => fruit</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The array <span className='background-grey'>$keys</span> contains the keys <span className='background-grey'>["a", "b", "c"]</span>.</li>
                    <li>The value <span className='background-grey'>"fruit"</span> is assigned to each of the keys.</li>
                    <li>The result is an associative array where each key has the value <span className='background-grey'>"fruit"</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Filling Keys with Numeric Value</h5>
                <p>You can assign any data type as the value, including numbers.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$keys</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-pink">$value</span> = <span class="color-pink">100</span>;</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_fill_keys</span>(<span class="color-pink">$keys</span>, <span class="color-pink">$value</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">100</span></p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">100</span></p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">100</span></p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The numeric keys <span className='background-grey'>[1, 2, 3]</span> are filled with the value <span className='background-grey'>100</span>.</li>
                    <li>The result is an associative array where each key has the value <span className='background-grey'>100</span>.</li>
                </ul>
            </div>
        </section>
    )
}