import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getCode";

export default function GetCode() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getCode() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_exception',
            'next': '/php/func_getFile'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getCode() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getCode()</span> function in the PHP <span className="background-grey">Exception</span> class is used to retrieve the exception code that was passed when the exception was thrown. While the <span className="background-grey">getMessage()</span> method retrieves the error message, the <span className="background-grey">getCode()</span> method allows you to obtain the associated error code, which can be useful for categorizing or identifying specific types of errors.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getCode</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getCode()</strong> returns the error code that was passed to the exception during its creation.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example: Handling Exception Codes</h5>
                <p>The <span className="background-grey">getCode()</span> method can be useful when you have multiple error types and need to handle them differently based on their codes.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60 color-grey">        // Throwing exceptions with different codes</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"File not found"</span>, <span class="color-pink">404</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$e</span>-><span class="color-red">getCode</span>() == <span class="color-pink">404</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Error: Resource not found (Code: 404)."</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"An error occurred. Code: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getCode</span>();</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Error</span>: Resource not found (Code: <span class="color-pink">404</span>).</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We throw an exception with a specific code (<span className="background-grey">404</span>).</li>
                    <li>In the <span className="background-grey">catch</span> block, we check the exception's code using <span className="background-grey">getCode()</span>. If the code is <span className="background-grey">404</span>, we display a custom message for missing resources.</li>
                </ul>


            </div>
        </section>
    )
}