import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_set_exception_handler";

export default function SetExceptionHandler() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP set_exception_handler() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_set_error_handler',
            'next': '/php/func_trigger_error'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP set_exception_handler() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">set_exception_handler()</span> function in PHP is used to define a user-defined function to handle any uncaught exceptions. When an exception is thrown and is not caught by a <span className="background-grey">try...catch</span> block, the custom exception handler set by <span className="background-grey">set_exception_handler()</span> is invoked to manage the exception.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">set_exception_handler</span>(<span class="color-blue">callable</span> <span class="color-pink">$exception_handler</span>);</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$exception_handler</strong> is the name of the function that will be invoked to handle uncaught exceptions. This function must accept a single parameter, which is the exception object.</p>
                <p>It returns the previous exception handler (if one exists) or NULL if there was no previous handler.</p>


                <h5 className="mt-5 mb-3">Example: Setting a Custom Exception Handler</h5>
                <p>Here’s an example of how to use <span className="background-grey">set_exception_handler()</span> to create a custom exception handler.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Custom exception handler function</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">customExceptionHandler</span>(<span class="color-pink">$exception</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Uncaught Exception: "</span> . <span class="color-pink">$exception</span>-><span class="color-red">getMessage</span>() . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the custom exception handler</p>
                        <p class="ml-30">    <span class="color-red">set_exception_handler</span>(<span class="color-green">"customExceptionHandler"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Throw an uncaught exception</p>
                        <p class="ml-30">    <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"This is an uncaught exception!"</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>A function <span className="background-grey">customExceptionHandler()</span> is created, which will handle any uncaught exceptions. It receives the exception object and can access details like the message, code, file, and line.</li>
                    <li>The <span className="background-grey">set_exception_handler()</span> function registers <span className="background-grey">customExceptionHandler</span> as the handler for uncaught exceptions.</li>
                    <li>When the <span className="background-grey">throw new Exception()</span> statement is executed, the exception is not caught by a <span className="background-grey">try...catch</span> block, so the custom handler is invoked.</li>
                </ul>

                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Uncaught Exception: This is an uncaught exception!</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}