import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ltrim";

export default function LTrim() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ltrim() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_trim',
            'next': '/php/func_rtrim'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ltrim() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The ltrim() function in PHP is used to remove whitespace or specific characters from the <strong>left</strong> side (beginning) of a string. It's helpful when you need to clean up user input or other strings where leading spaces or characters are unnecessary. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ltrim</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">string</span> <span class="color-pink">$characters</span> = <span class="color-green">" \t\n\r\0\x0B"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string from which leading whitespace or characters will be removed.</li>
                    <li><strong>$characters</strong>: (Optional) A list of characters to remove from the beginning of the string. If not specified, the default characters to be removed are spaces, tabs (<span className="background-grey">\t</span>), newlines (<span className="background-grey">\n</span>), carriage returns (<span className="background-grey">\r</span>), null bytes (<span className="background-grey">\0</span>), and vertical tabs (<span className="background-grey">\x0B</span>).</li>
                    <li><strong>Return Value</strong>: It returns the string with the specified characters removed from the start.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1 : Cleaning User Input</h5>
                <p>When users submit forms, they may accidentally include leading spaces in fields like names or email addresses. You can use <span className="background-grey">ltrim()</span> to clean those inputs.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"   JohnDoe"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$trimmedUsername</span> = <span class="color-red">ltrim</span>(<span class="color-pink">$username</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$trimmedUsername</span>; <span class="color-grey">// Outputs: "JohnDoe"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Cleaning URL or Path</h5>
                <p>You might need to clean up URLs or paths that have unwanted characters at the beginning.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-green">"/var/www/html/"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$cleanedUrl</span> = <span class="color-red">ltrim</span>(<span class="color-pink">$url</span>, <span class="color-green">"/"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$cleanedUrl</span>; <span class="color-grey">// Outputs: "var/www/html/"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this case, the leading <span className="background-grey">/</span> is removed from the path.</p>

                <h5 className="mt-5 mb-3">Example 3 : Removing Symbols</h5>
                <p>If you have a string that starts with unwanted symbols, you can use <span className="background-grey">ltrim()</span> to clean it up.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$symbolText</span> = <span class="color-green">"###Hello, world!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$cleanedText</span> = <span class="color-red">ltrim</span>(<span class="color-pink">$symbolText</span>, <span class="color-green">"#"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$cleanedText</span>; <span class="color-grey">// Outputs: "Hello, world!"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}