import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/create/form";

export default function CreateForm() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Creating a Simple HTML Form in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/loop/control/structure',
            'next': '/php/get/post/methods'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Creating a Simple HTML Form</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Creating a simple form in PHP involves two key steps: designing the HTML form and writing the PHP script to handle the form's data. I'll walk you through the process with a basic example where we create a form that collects a user's name and email address, then processes this data using PHP.
                </p>
                <p>
                    The first step is to create an HTML form where users can input their data. Here's a simple example:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Simple PHP Form&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h2&gt;User Information Form&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;form <span class="color-pink">action</span>=<span class="color-green">"process_form.php"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                        <p class="ml-90">            &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                        <p class="ml-90">            &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span> <span class="color-pink">required</span>&gt;&lt;br&gt;&lt;br&gt;</p>
                        <br />            
                        <p class="ml-90">            &lt;label <span class="color-pink">for</span>=<span class="color-green">"email"</span>&gt;Email:&lt;/label&gt;</p>
                        <p class="ml-90">            &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">id</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> <span class="color-pink">required</span>&gt;&lt;br&gt;&lt;br&gt;</p>
                        <br />            
                        <p class="ml-90">            &lt;input <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">value</span>=<span class="color-green">"Submit"</span>&gt;</p>
                        <p class="ml-60">        &lt;/form&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">&lt;form&gt;</span> tag: This is the HTML element that creates the form. The <span class="background-grey">action</span> attribute specifies the file where the form data will be sent when the form is submitted. In this case, it points to <span class="background-grey">process_form.php</span>. The <span class="background-grey">method="post"</span> attribute means that the form data will be sent using the POST method, which is generally more secure for sensitive data.
                    </li>
                    <li>
                        <span class="background-grey">&lt;label&gt;</span> tags: These are used to define labels for the form inputs, making it easier for users to understand what information they need to provide.
                    </li>
                    <li>
                        <span class="background-grey">&lt;input&gt;</span> tags: These are the fields where users enter their data. In this example, there are two inputs:
                        <ul style={{listStyle:'disc'}}>
                            <li>A text input for the user's name (<span class="background-grey">type="text"</span>).</li>
                            <li>An email input for the user's email address (<span class="background-grey">type="email"</span>), which ensures that the input is a valid email format.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="background-grey">&lt;input type="submit"&gt;</span>: This is the button that submits the form. When clicked, the form data is sent to the PHP script specified in the <span class="background-grey">action</span> attribute.
                    </li>
                </ul>       
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Simple PHP Form</title>
                            </head>
                            <body>
                                <h2>User Information Form</h2>
                                <form action="process_form.php" method="post">
                                    <label for="name">Name:</label>
                                    <input type="text" id="name" name="name" required><br><br>
                                    
                                    <label for="email">Email:</label>
                                    <input type="email" id="email" name="email" required><br><br>
                                    
                                    <input type="submit" value="Submit">
                                </form>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}