import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_cal_days_in_month";

export default function CalDayInMonth() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP cal_days_in_month() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_arsort',
            'next': '/php/func_cal_from_jd'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP cal_days_in_month() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The cal_days_in_month() function in PHP is used to find the number of days in a specific month of a given year. It is particularly useful for handling date calculations when you need to account for the varying number of days in different months, including leap years.
                </p>
                <p>The Syntax is given below -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">cal_days_in_month</span>(<span class="color-pink">$calendar</span>, <span class="color-pink">$month</span>, <span class="color-pink">$year</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$calendar</strong>: The calendar system to be used. The function supports multiple calendar types, with the most common being <span className='background-grey'>CAL_GREGORIAN</span> (for the Gregorian calendar, which is the standard calendar in most of the world).</li>
                    <li><strong>$month</strong>: The month (1-12) for which you want to find the number of days.</li>
                    <li><strong>$year</strong>: The year (e.g., 2024, 2023) to which the month belongs.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Get the Number of Days in February for Leap and Non-Leap Years</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30 color-grey">    // For a leap year</p>
                            <p class="ml-30">    <span class="color-pink">$year</span> = <span class="color-pink">2024</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$month</span> = <span class="color-pink">2</span>; <span class="color-grey">// February</span></p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cal_days_in_month</span>(CAL_GREGORIAN, <span class="color-pink">$month</span>, <span class="color-pink">$year</span>); <span class="color-grey">// Output: 29 (because 2024 is a leap year)</span></p>
                            <br />    
                            <p class="ml-30 color-grey">    // For a non-leap year</p>
                            <p class="ml-30">    <span class="color-pink">$year</span> = <span class="color-pink">2023</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$month</span> = <span class="color-pink">2</span>; <span class="color-grey">// February</span></p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cal_days_in_month</span>(CAL_GREGORIAN, <span class="color-pink">$month</span>, <span class="color-pink">$year</span>); <span class="color-grey">// Output: 28 (because 2023 is not a leap year)</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2: Number of Days in Different Months</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cal_days_in_month</span>(CAL_GREGORIAN, <span class="color-pink">1</span>, <span class="color-pink">2024</span>); <span class="color-grey">// Output: 31 (January)</span></p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cal_days_in_month</span>(CAL_GREGORIAN, <span class="color-pink">4</span>, <span class="color-pink">2024</span>); <span class="color-grey">// Output: 30 (April)</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}