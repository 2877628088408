import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/writing-a-file";

export default function WritingFile() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Writing a file in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/reading-a-file',
            'next': '/php/file-uploads'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Writing a file</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To write to a file, you can use the <span class="background-grey">fwrite()</span> function. You can either overwrite the existing content or append new content.
                </p>
                <h5 className='mt-5 mb-3'>Writing to a File (Overwrite Mode)</h5>
                <p>
                    If you want to overwrite the file’s content, use the <span class="background-grey">"w"</span> mode with <span class="background-grey">fopen()</span>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"w"</span>);</p>
                            <p><span class="color-red">fwrite</span>(<span class="color-pink">$file</span>, <span class="color-green">"This is a new content.\n"</span>);</p>
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Writing to a File (Append Mode)</h5>
                <p>
                    If you want to append content to the file without overwriting the existing data, use the <span class="background-grey">"a"</span> mode.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"a"</span>);</p>
                            <p><span class="color-red">fwrite</span>(<span class="color-pink">$file</span>, <span class="color-green">"This line will be appended.\n"</span>);</p>
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Writing an Array to a File</h5>
                <p>You can write an array to a file by looping through the array and using <span class="background-grey">fwrite()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"w"</span>);</p>
                            <p><span class="color-pink">$lines</span> = [<span class="color-green">"First line\n"</span>, <span class="color-green">"Second line\n"</span>, <span class="color-green">"Third line\n"</span>];</p>
                            <p><span class="color-blue">foreach</span> (<span class="color-pink">$lines</span> <span class="color-blue">as</span> <span class="color-pink">$line</span>) {</p>
                            <p class="ml-30">    <span class="color-red">fwrite</span>(<span class="color-pink">$file</span>, <span class="color-pink">$line</span>);</p>
                            <p>}</p>
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}