import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getTraceAsString";

export default function GetTraceAsString() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getTraceAsString() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getTrace',
            'next': '/php/func_fopen'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getTraceAsString() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getTraceAsString()</span> function in PHP is a method in the <span className="background-grey">Exception</span> class that provides a string representation of the backtrace, which is easier to read compared to the array format returned by <span className="background-grey">getTrace()</span>. It shows the sequence of function calls that led to the exception, including file names and line numbers, in a single formatted string.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getTraceAsString</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getTraceAsString()</strong> returns a string representation of the exception's backtrace.</li>
                </ul>
                <p>
                    The <span className="background-grey">getTraceAsString()</span> method is useful when you want to log or display the error trace in a simple, readable string format, without having to manually parse the array returned by <span className="background-grey">getTrace()</span>.
                </p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getTraceAsString()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">firstFunction</span>() {</p>
                        <p class="ml-60">        <span class="color-red">secondFunction</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">secondFunction</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"An error occurred"</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-red">firstFunction</span>();</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-pink">$e</span>-><span class="color-red">getTraceAsString</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>#<span class="color-pink">0</span> /path/to/your/file.php(<span class="color-pink">7</span>): secondFunction()</p>
                            <p>#<span class="color-pink">1</span> /path/to/your/file.php(<span class="color-pink">3</span>): firstFunction()</p>
                            <p>#<span class="color-pink">2</span> {main}</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>It indicates that the error occurred in <span className="background-grey">/path/to/your/file.php</span> at line 7 in the <span className="background-grey">secondFunction()</span>.</li>
                    <li>The functions that were called before the exception occurred, starting with <span className="background-grey">secondFunction()</span> being called by <span className="background-grey">firstFunction()</span> at line 3.</li>
                    <li><strong>&#123;main&#125;</strong> represents the main script execution starting point.</li>
                </ul>



            </div>
        </section>
    )
}