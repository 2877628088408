import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_set_error_handler";

export default function SetErrorHandler() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP set_error_handler() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_restore_exception_handler',
            'next': '/php/func_set_exception_handler'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP set_error_handler() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">set_error_handler()</span> function in PHP is used to set a user-defined function as the error handler for your script. This allows you to define custom logic for handling different types of errors in your application, rather than relying on PHP's default error-handling mechanism. It gives developers more control over how errors are processed, displayed, or logged.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">set_error_handler</span>(<span class="color-blue">callback</span> <span class="color-pink">$error_handler</span>, <span class="color-blue">int</span> <span class="color-pink">$error_types</span> = E_ALL);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$error_handler</strong>: A callback function that you define to handle errors. This function will be called whenever an error occurs.</li>
                    <li><strong>$error_types</strong> (optional): This specifies which errors should be passed to the custom error handler. By default, it is set to <span className="background-grey">E_ALL</span>, meaning all errors will be handled by the custom error handler.</li>
                </ul>
                <p>The function returns the previous error handler, which could be useful if you want to restore it later.</p>


                <h5 className="mt-5 mb-3">Custom Error Handler Function</h5>
                <p>The custom error handler function should accept the following parameters:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">customErrorHandler</span>(<span class="color-pink">$errno</span>, <span class="color-pink">$errstr</span>, <span class="color-pink">$errfile</span>, <span class="color-pink">$errline</span>, <span class="color-pink">$errcontext</span>) {</p>
                        <p class="ml-30 color-grey">    // Custom logic to handle the error</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$errno</strong>: The error number (e.g., <span className="background-grey">E_WARNING</span>, <span className="background-grey">E_NOTICE</span>, <span className="background-grey">E_ERROR</span>, etc.).</li>
                    <li><strong>$errstr</strong>: The error message as a string.</li>
                    <li><strong>$errfile</strong>: The filename where the error occurred.</li>
                    <li><strong>$errline</strong>: The line number where the error occurred.</li>
                    <li><strong>$errcontext</strong> (optional): An array that contains every variable and its value in the scope where the error occurred (this parameter is deprecated as of PHP 7.2.0).</li>
                </ul>

                
                <h5 className="mt-5 mb-3">Example: Setting a Custom Error Handler</h5>
                <p>Here’s an example that sets a custom error handler for handling different types of errors.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Define the custom error handler function</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">customErrorHandler</span>(<span class="color-pink">$errno</span>, <span class="color-pink">$errstr</span>, <span class="color-pink">$errfile</span>, <span class="color-pink">$errline</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"<b>Error [$errno]:</b> $errstr in $errfile on line $errline&lt;br&gt;"</span>;</p>
                        <p class="ml-60 color-grey">        // Log the error or take other action if needed</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the custom error handler</p>
                        <p class="ml-30">    <span class="color-red">set_error_handler</span>(<span class="color-green">"customErrorHandler"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Trigger a notice</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$undefinedVariable</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Error</span> [<span class="color-pink">8</span>]: Undefined variable: undefinedVariable in /path/to/script.php on line <span class="color-pink">11</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example, an undefined variable is referenced, which triggers a notice. Instead of using PHP's default error handler, the <span className="background-grey">customErrorHandler()</span> function is invoked to handle and display the error in a custom format.</p>


            </div>
        </section>
    )
}