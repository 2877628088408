import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_chdir";

export default function ChDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP chdir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_time',
            'next': '/php/func_chroot'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP chdir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">chdir()</span> function in PHP is used to change the current working directory of the script. This can be helpful when working with files and directories, especially if you need to work with files located in different directories during the script's execution. Once the working directory is changed using <span className="background-grey">chdir()</span>, PHP will use that new directory as the reference point for relative paths in subsequent file operations (like opening, reading, or writing files).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">chdir</span>(directory)</p>
                        `
                    }}></div>
                </div>
                <p>Where, directory is the path to the new directory you want to switch to. This can be a relative or an absolute path.</p>
                <p>It returns true on success and returns false on failure.</p>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">chdir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Show the current directory</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Current directory: "</span> . <span class="color-red">getcwd</span>() . <span class="color-green">"&#92;n"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Change the working directory</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">chdir</span>(<span class="color-green">"/path/to/new/directory"</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Successfully changed directory.&#92;n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to change directory.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Show the new directory</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"New directory: "</span> . <span class="color-red">getcwd</span>() . <span class="color-green">"&#92;n"</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">getcwd()</span> function returns the current working directory, which is displayed before and after calling <span className="background-grey">chdir()</span>.</li>
                    <li>The <span className="background-grey">chdir()</span> function attempts to change the working directory to <span className="background-grey">/path/to/new/directory</span>. If successful, it returns true; otherwise, it returns false.</li>
                </ul>

            </div>
        </section>
    )
}