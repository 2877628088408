import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strrev";

export default function Strrev() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strrev() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strstr',
            'next': '/php/func_number_format'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strrev() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strrev() function in PHP is a simple yet powerful utility used to <strong>reverse a string</strong>. This function takes a single string as input and returns a new string that is the reverse of the original. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strrev</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string that you want to reverse.</li>
                    <li><strong>Return Value</strong>: The function returns the reversed string.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$originalString</span> = <span class="color-green">"Hello, World!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$reversedString</span> = <span class="color-red">strrev</span>(<span class="color-pink">$originalString</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Original: "</span> . <span class="color-pink">$originalString</span> . "\n";</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Reversed: "</span> . <span class="color-pink">$reversedString</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Original: Hello, World!</p>
                            <p>Reversed: !dlroW ,olleH</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span className="background-grey">strrev()</span> function reverses the original string <span className="background-grey">"Hello, World!"</span>, resulting in <span className="background-grey">"!dlroW ,olleH"</span>.</p>



                <h5 className="mt-5 mb-3">Example : Palindrome Check</h5>
                <p>You can use <span className="background-grey">strrev()</span> to check if a string is a palindrome (a string that reads the same forward and backward).</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">isPalindrome</span>(<span class="color-pink">$str</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$str</span> === <span class="color-red">strrev</span>(<span class="color-pink">$str</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$word</span> = <span class="color-green">"madam"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">isPalindrome</span>(<span class="color-pink">$word</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$word is a palindrome."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$word is not a palindrome."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>madam is a palindrome.</p>
                        `
                    }}></div>
                </div>

                
            </div>
        </section>
    )
}