import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/regular-expressions";

export default function RegularExpression() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Regular Expression in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/xml-parser',
            'next': '/php/input/validation'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Regular Expression in PHP</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Regular expressions, often called <strong>regex</strong> or <strong>regexp</strong>, are powerful tools used to find, match, and manipulate strings based on specific patterns. In PHP, regular expressions are used for a variety of tasks, such as validating input, searching for patterns, and performing search-and-replace operations in strings.
                </p>
                <p>PHP supports two types of regular expressions:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>POSIX Regular Expressions</strong> (deprecated in PHP 5.3.0 and removed in PHP 7)</li>
                    <li><strong>Perl-Compatible Regular Expressions</strong> (PCRE) — This is the preferred method.</li>
                </ul>
                <p>
                    PCRE (Perl-Compatible Regular Expressions) in PHP is implemented using functions that begin with <span className='background-grey'>preg_</span>. These functions allow you to define a pattern (the regular expression) and then perform operations such as matching, replacing, or splitting strings based on the pattern.
                </p>

                <h5 className="mt-5 mb-3">Regular Expression Basics</h5>
                <p>A <strong>regular expression pattern</strong> consists of:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Literal characters</strong> (e.g., <span className='background-grey'>a</span>, <span className='background-grey'>1</span>, <span className='background-grey'>@</span>).</li>
                    <li><strong>Meta-characters</strong> that define patterns like wildcards, ranges, repetitions, and boundaries.</li>
                </ul>
                <p>Some common meta-characters include:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>.</span> (dot) - matches any single character (except newline).</li>
                    <li><span className='background-grey'>^</span> (caret) - matches the beginning of a string.</li>
                    <li><span className='background-grey'>$</span> (dollar sign) - matches the end of a string.</li>
                    <li><span className='background-grey'>[]</span> (square brackets) - matches any one of the characters inside the brackets.</li>
                    <li><span className='background-grey'>\d</span> - matches any digit.</li>
                    <li><span className='background-grey'>\w</span> - matches any word character (alphanumeric and underscore).</li>
                    <li><span className='background-grey'>\s</span> - matches any whitespace character.</li>
                    <li><span className='background-grey'>*</span>, <span className='background-grey'>+</span>, <span className='background-grey'>?</span> - quantifiers to match one or more occurrences of a character or pattern.</li>
                </ul>

                <h5 className="mt-5 mb-3">Common <span className='background-grey'>preg_</span> Functions in PHP</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mb-4'>
                        <span className='background-grey'>preg_match()</span>
                        <p>It performs a regular expression match and returns whether the pattern was found in the string. This function searches for a pattern in a string and returns <span className='background-grey'>1</span> if a match is found, otherwise <span className='background-grey'>0</span>. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">preg_match</span>(<span class="color-green">"/pattern/"</span>, <span class="color-pink">$subject</span>, <span class="color-pink">$matches</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>/pattern/</span> - The regular expression.</li>
                            <li><span className='background-grey'>$subject</span> - The string to search in.</li>
                            <li><span className='background-grey'>$matches</span> - (Optional) An array to store matches.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <span className='background-grey'>preg_match_all()</span>
                        <p>It is similar to <span className='background-grey'>preg_match()</span> but finds all matches, not just the first one.</p>
                        <p>This function is used to find all occurrences of a pattern in a string. It returns the number of matches found and stores all matches in an array. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">preg_match_all</span>(<span class="color-green">"/pattern/"</span>, <span class="color-pink">$subject</span>, <span class="color-pink">$matches</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className="mb-4">
                        <span className='background-grey'>preg_replace()</span>
                        <p>It replaces text that matches a pattern with a replacement string.</p>
                        <p>The <span className='background-grey'>preg_replace()</span> function searches for a pattern in a string and replaces it with a replacement string. It returns the modified string. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">preg_replace</span>(<span class="color-green">"/pattern/"</span>, <span class="color-green">"replacement"</span>, <span class="color-pink">$subject</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <span className='background-grey'>preg_split()</span>
                        <p>It splits a string based on a pattern. The <span className='background-grey'>preg_split()</span> function splits a string into an array based on a regular expression. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">preg_split</span>(<span class="color-green">"/pattern/"</span>, <span class="color-pink">$subject</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <span className='background-grey'>preg_grep()</span>
                        <p>It returns array elements that match a given pattern. The <span className='background-grey'>preg_grep()</span> function searches an array for elements that match a given pattern and returns the matching elements. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">preg_grep</span>(<span class="color-green">"/pattern/"</span>, <span class="color-pink">$array</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Some Examples of Regular Expression</h5>
                <p>Here are some common regular expression patterns you might use in PHP</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Matching a valid email address</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$pattern</span> = <span class="color-green">"/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/"</span>;</p>
                                    <p><span class="color-pink">$email</span> = <span class="color-green">"test@example.com"</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-pink">$pattern</span>, <span class="color-pink">$email</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid email address!"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid email address!"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Matching a phone number (e.g., US format)</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$pattern</span> = <span class="color-green">"/^\(\d{3}\) \d{3}-\d{4}$/"</span>;</p>
                                    <p><span class="color-pink">$phone</span> = <span class="color-green">"(123) 456-7890"</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-pink">$pattern</span>, <span class="color-pink">$phone</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid phone number!"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid phone number!"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Matching a URL</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$pattern</span> = <span class="color-green">"/\b(?:https?|ftp):\/\/\S+\b/"</span>;</p>
                                    <p><span class="color-pink">$url</span> = <span class="color-green">"https://example.com"</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-pink">$pattern</span>, <span class="color-pink">$url</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid URL!"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid URL!"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Matching digits only</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$pattern</span> = <span class="color-green">"/^\d+$/"</span>;</p>
                                    <p><span class="color-pink">$string</span> = <span class="color-green">"12345"</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-pink">$pattern</span>, <span class="color-pink">$string</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"String contains only digits!"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"String does not contain only digits!"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}