import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_map";

export default function ArrayMap() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_map() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_keys',
            'next': '/php/func_array_merge'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_map() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_map() function in PHP applies a callback function to each element of one or more arrays and returns an array containing the results. It allows you to modify or transform the elements of an array based on a function.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_map</span>(<span class="color-blue">callable</span> <span class="color-pink">$callback</span>, <span class="color-blue">array</span> <span class="color-pink">$array1</span>, <span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$callback</strong>: The callback function to apply to each element of the arrays.</li>
                    <li><strong>$array1</strong>: The first array to which the callback function will be applied.</li>
                    <li><strong>$arrays</strong>: Optional additional arrays to which the callback will also be applied. In case multiple arrays are provided, the callback function is applied to corresponding elements of the arrays.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">square</span>(<span class="color-pink">$n</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$n</span> * <span class="color-pink">$n</span>;</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_map</span>(<span class="color-green">"square"</span>, <span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">4</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">9</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">16</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">25</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>square()</span> function is applied to each element of the array <span className='background-grey'>[1, 2, 3, 4, 5]</span>.</li>
                    <li>The resulting array contains the squares of each number: <span className='background-grey'>[1, 4, 9, 16, 25]</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Using an Anonymous Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_map</span>(<span class="color-blue">function</span>(<span class="color-pink">$n</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$n</span> * <span class="color-pink">2</span>;</p>
                            <p>}, <span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">4</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">6</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">8</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">10</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The anonymous function multiplies each element by <span className='background-grey'>2</span>.</li>
                    <li>The new array contains doubled values of the original array: <span className='background-grey'>[2, 4, 6, 8, 10]</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Multiple Arrays</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-pink">10</span>, <span class="color-pink">20</span>, <span class="color-pink">30</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_map</span>(<span class="color-blue">function</span>(<span class="color-pink">$a</span>, <span class="color-pink">$b</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$a</span> + <span class="color-pink">$b</span>;</p>
                            <p>}, <span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">11</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">22</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">33</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The callback function takes two arguments, one from <span className='background-grey'>$array1</span> and one from <span className='background-grey'>$array2</span>, and adds them together.</li>
                    <li>The result is an array with the sums of the corresponding elements: <span className='background-grey'>[11, 22, 33]</span>.</li>
                </ul>
            </div>
        </section>
    )
}