import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/audio-element";

export default function AudioElement() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Audio Element | Aspirant's Home");
        const urls = {
            'previous': '/html/video-element',
            'next': '/html/embed-multimedia'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Audio Element</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>&lt;audio&gt;</span> element in HTML is used to embed audio content on a webpage. Like the <span className='background-grey'>&lt;video&gt;</span> element, it allows you to play audio files without relying on third-party plugins. Here's a detailed overview of its features and how to use it.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>Here’s a simple example of the <span className='background-grey'>&lt;audio&gt;</span> element:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;audio <span class="color-pink">controls&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.mp3"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/mpeg"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/ogg"</span>&gt;</p>
                        <p class="ml-30">    Your browser does not support the audio tag.</p>
                        <p>&lt;/audio&gt;</p>
                      
                        `
                    }}></div>
                </div>
                <p>Here is an output of above example -</p>
                <div className='codePalateBox mt-2 mb-4' style={{'background':'none'}}>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <audio controls>
                            <source src="../audio.mp3" type="audio/mpeg">
                            <source src="audio.ogg" type="audio/ogg">
                            Your browser does not support the audio tag.
                        </audio>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Attributes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span className='background-grey'>src</span>: Specifies the URL of the audio file. This is used in the &lt;source&gt; elements.</li>

                    <li><span className='background-grey'>controls</span>: Adds audio controls, such as play, pause, and volume.</li>

                    <li><span className='background-grey'>autoplay</span>: Automatically starts playing the audio when the page loads. (Note: it may be muted in some browsers.)</li>

                    <li><span className='background-grey'>loop</span>: Causes the audio to repeat indefinitely once it reaches the end.</li>

                    <li><span className='background-grey'>muted</span>: Starts the audio with the sound muted.</li>

                    <li>
                        <span className='background-grey'>preload</span>: Provides a hint to the browser about whether to load the audio when the page loads. Options include:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className='background-grey'>"none"</span>: Don’t preload the audio.</li>
                            <li><span className='background-grey'>"metadata"</span>: Preload only the metadata (like duration).</li>
                            <li><span className='background-grey'>"auto"</span>: Preload the whole audio file.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Source Elements</h5>
                <p>You can include multiple &lt;source&gt; elements to provide different audio formats. This helps ensure compatibility across various browsers, as different browsers may support different audio formats.</p>


                <h5 className='mt-5 mb-3'>Fallback Content</h5>
                <p>If the browser doesn’t support the &lt;audio&gt; element, any content between the opening and closing &lt;audio&gt; tags (like the message "Your browser does not support the audio tag.") will be displayed.</p>


                <h5 className='mt-5 mb-3'>Example with Attributes</h5>
                <p>Here’s a more detailed example with various attributes:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;audio <span class="color-pink">controls autoplay loop muted preload</span>=<span class="color-green">"auto"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.mp3"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/mpeg"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/ogg"</span>&gt;</p>
                        <p class="ml-30">    Your browser does not support the audio tag.</p>
                        <p>&lt;/audio&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Here is an output of above example -</p>
                <div className='codePalateBox mt-2 mb-4' style={{'background':'none'}}>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <audio controls autoplay loop muted preload="auto">
                            <source src="../audio.mp3" type="audio/mpeg">
                            <source src="audio.ogg" type="audio/ogg">
                            Your browser does not support the audio tag.
                        </audio>
                        `
                    }}></div>
                </div>
                <p>The <span className='background-grey'>&lt;audio&gt;</span> element provides an easy and effective way to add audio content to your web pages. With its built-in controls and support for various formats, it enhances user engagement without the need for external plugins.</p>


            </div>
        </section>
    )
}