import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/variables";

export default function VariablesDataTypes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Variables & Data Types in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/first/script',
            'next': '/php/operators'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Variables in PHP</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A variable in PHP is a storage container that holds data which can be used and manipulated throughout your code. Variables are dynamically typed, meaning you don't have to declare the type of a variable before assigning a value to it.
                </p>

                <h5 className="mt-5 mb-3">Some Rules of Creating & Using Variable</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Start with a Dollar Sign ($)</strong>: All PHP variables begin with the $ symbol.
                    </li>
                    <li>
                        <strong>Follow with a Letter or Underscore</strong>: The name of a variable must start with a letter (a-z, A-Z) or an underscore (_).
                    </li>
                    <li>
                        <strong>Contain Letters, Numbers, or Underscores</strong>: After the first character, you can use letters, numbers (0-9), or underscores.
                    </li>
                    <li>
                        <strong>Case-Sensitive</strong>: Variable names in PHP are case-sensitive, so $variable and $Variable are considered different variables.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Assigning a Variable</h5>
                <p>
                    Values are assigned to variables using the <span class="background-grey">=</span> operator. You can assign any type of value to an variable. For example,
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-pink">$name</span> = <span class="color-green">"John"</span>;</p>
                                <p><span class="color-pink">$age</span> = <span class="color-pink">25</span>;</p>
                                <p><span class="color-pink">$is_student</span> = <span class="color-blue">true</span>;</p>
                            `
                        }}></div>
                    </div>
                </p>

                <h5 className="mt-5 mb-3">Different Scope of a Variable</h5>
                <p>
                    Different types of scope are there. 
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Local Scope</strong>: Variables declared inside a function have local scope and are accessible only within that function.
                    </li>
                    <li>
                        <strong>Global Scope</strong>: Variables declared outside of any function have global scope and are accessible throughout the script, but not inside functions unless explicitly declared as global.
                    </li>
                    <li>
                        <strong>Superglobals</strong>: PHP provides several built-in global arrays like <span class="background-grey">$_POST</span>, <span class="background-grey">$_GET</span>, <span class="background-grey">$_SESSION</span>, etc., that are accessible globally.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Datatypes in PHP</h5>
                <p>
                    PHP supports several data types, each serving a different purpose:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>String</strong>: Represents a sequence of characters.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$message</span> = <span class="color-green">"Hello, World!"</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Integer</strong>: Represents whole numbers without decimal points.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$age</span> = <span class="color-pink">25</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Float</strong>: Represents numbers with decimal points.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$price</span> = <span class="color-pink">19.99</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Boolean</strong>: Represents true or false values.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$isAvailable</span> = <span class="color-blue">true</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Array</strong>: Represents a collection of values.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$colors</span> = <span class="color-blue">array</span>(<span class="color-green">"red"</span>, <span class="color-green">"green"</span>, <span class="color-green">"blue"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Object</strong>: It is a instance of Class. Objects allow you to create more complex structures to represent data and functionality.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Car</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">Car</span>() {</p>
                                    <p class="ml-60">        <span class="color-pink">$this</span>->model = <span class="color-green">"Toyota"</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <p><span class="color-pink">$myCar</span> = <span class="color-blue">new</span> <span class="color-red">Car</span>();</p>
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$myCar</span>->model;  <span class="color-grey">// Outputs "Toyota"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>NULL</strong>: A special type representing a variable with no value. A variable is considered <span class="background-grey">NULL</span> if it has been assigned the constant <span class="background-grey">NULL</span>, has not been assigned a value yet, or has been unset.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$var</span> = <span class="color-blue">NULL</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Resource</strong>: A special type representing a reference to an external resource, such as a file, database connection, etc.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"test.txt"</span>, <span class="color-green">"r"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Type Juggling</h5>
                <p>
                    PHP automatically converts variables to the correct data type depending on the context in which they are used. For example, adding a string containing a number to an integer will result in PHP treating the string as an integer.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$sum</span> = <span class="color-green">"3"</span> + <span class="color-pink">4</span>;  <span class="color-grey">// $sum will be 7 (integer)</span></p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Type Casting</h5>
                <p>
                    You can explicitly convert a variable from one type to another by casting.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$num</span> = (<span class="color-blue">int</span>)<span class="color-green">"5"</span>;  <span class="color-grey">// Casts the string "5" to an integer</span></p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}