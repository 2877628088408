import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/article";

export default function Article() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <article> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;article&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;article&gt;</span> tag in HTML is used to define a self-contained, independent piece of content that can stand on its own. This tag is commonly used for content that could be independently distributed or republished, such as blog posts, news articles, comments, or forum entries.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;article&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;article&gt;</span> tag is a <strong>semantic HTML5 element</strong> that helps define a block of content that has independent meaning.</li>
                    <li>An <span class="background-grey">&lt;article&gt;</span> is typically intended for content that makes sense when viewed in isolation from the rest of the page.</li>
                    <li>Each <span class="background-grey">&lt;article&gt;</span> can contain its own headings, images, links, and other HTML elements, as it is treated as an independent section.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;article&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Independent content, like a news story or blog post --&gt;</p>
                        <p>&lt;/article&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Articles Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Latest News&lt;/h1&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;article&gt;</p>
                        <p class="ml-90">            &lt;header&gt;</p>
                        <p class="ml-120">                &lt;h2&gt;Breaking News: New HTML Tag Introduced&lt;/h2&gt;</p>
                        <p class="ml-120">                &lt;p&gt;Published on October 25, 2024&lt;/p&gt;</p>
                        <p class="ml-90">            &lt;/header&gt;</p>
                        <p class="ml-90">            &lt;p&gt;The HTML5 specification introduces new semantic tags to improve web structure...&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/article&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;article&gt;</p>
                        <p class="ml-90">            &lt;header&gt;</p>
                        <p class="ml-120">                &lt;h2&gt;How to Use Semantic HTML&lt;/h2&gt;</p>
                        <p class="ml-120">                &lt;p&gt;Published on October 20, 2024&lt;/p&gt;</p>
                        <p class="ml-90">            &lt;/header&gt;</p>
                        <p class="ml-90">            &lt;p&gt;Learn how semantic HTML helps improve accessibility, SEO, and readability...&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/article&gt;</p>
                        <br />        
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Articles Example</title>
                            </head>
                            <body>
                            
                                <h1>Latest News</h1>
                            
                                <article>
                                    <header>
                                        <h2>Breaking News: New HTML Tag Introduced</h2>
                                        <p>Published on October 25, 2024</p>
                                    </header>
                                    <p>The HTML5 specification introduces new semantic tags to improve web structure...</p>
                                </article>
                                
                                <article>
                                    <header>
                                        <h2>How to Use Semantic HTML</h2>
                                        <p>Published on October 20, 2024</p>
                                    </header>
                                    <p>Learn how semantic HTML helps improve accessibility, SEO, and readability...</p>
                                </article>
                                
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>In this example-</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Each <span class="background-grey">&lt;article&gt;</span> represents a separate news story.</li>
                    <li>Each article has its own heading, date, and content, making each one a standalone piece.</li>
                </ul>


                <p>The <span class="background-grey">&lt;article&gt;</span> tag is an essential HTML5 tool for creating organized, accessible, and SEO-friendly content on websites with standalone sections. Use <span class="background-grey">&lt;article&gt;</span> for content that makes sense independently, like blog posts, news items, or individual comments. <span class="background-grey">&lt;article&gt;</span> elements can be nested to represent hierarchical content, like main posts and comments.</p>



            </div>
        </section>
    )
}