import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_is_file";

export default function IsFile() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP is_file() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rename',
            'next': '/php/func_is_dir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP is_file() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">is_file()</span> function in PHP is used to check whether a specified path refers to a regular file or not. This function is useful when you need to ensure that a given file exists and is indeed a file, not a directory or other file type like a link.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">is_file</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$filename</strong> is the path to the file that you want to check. This can be either an absolute path or a relative path.</p>
                <p> It returns <span className="background-grey">true</span> if the given path is a regular file and returns <span className="background-grey">false</span> if the path does not exist, or if it is not a regular file (e.g., it is a directory).</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>Let's check if a file exists and is a regular file.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filename</span> = <span class="color-green">'example.txt'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">is_file</span>(<span class="color-pink">$filename</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$filename is a regular file."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$filename does not exist or is not a file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span className="background-grey">is_file()</span> function will return <span className="background-grey">true</span> if <span className="background-grey">example.txt</span> exists and is a file. Otherwise, it will return <span className="background-grey">false</span>.</p>

            </div>
        </section>
    )
}