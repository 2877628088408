import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_fclose";

export default function FClose() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP fclose() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_fwrite',
            'next': '/php/func_file_get_contents'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP fclose() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">fclose()</span> function in PHP is used to close an open file pointer. Closing a file is essential for freeing up system resources and ensuring that all data is properly written and saved.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fclose</span>(resource <span class="color-pink">$handle</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>handle</strong> (required) is the file pointer resource that you want to close. This resource is typically created by using the <span className="background-grey">fopen()</span> function.</p>
                <p>The function returns <span className="background-grey">TRUE</span> on success and <span className="background-grey">FALSE</span> on failure.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">fclose()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Open a file in write mode</p>
                        <p class="ml-30">    <span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"w"</span>) or <span class="color-red">die</span>(<span class="color-green">"Unable to open file!"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Write some data to the file</p>
                        <p class="ml-30">    <span class="color-red">fwrite</span>(<span class="color-pink">$file</span>, <span class="color-green">"Hello, World!"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the file</p>
                        <p class="ml-30">    <span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File written and closed successfully."</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">fopen("example.txt", "w")</span>: Opens <span className="background-grey">example.txt</span> in write mode.</li>
                    <li><span className="background-grey">fwrite($file, "Hello, World!")</span>: Writes the string to the file.</li>
                    <li><span className="background-grey">fclose($file)</span>: Closes the file, ensuring data integrity and freeing system resources.</li>
                </ul>



            </div>
        </section>
    )
}