import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/prepared-statement";

export default function PreparedStatements() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Prepared Statements in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/crud-operation',
            'next': '/php/working-with-session'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Prepared Statements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Prepared statements in PHP are a way to handle database queries safely and efficiently. They help protect your application from common security issues like SQL injection, where harmful code can be inserted into your database through user inputs.
                </p>

                <h5 className='mt-5 mb-3'>Why Use Prepared Statements?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        They protect your database from harmful code by treating user input as data, not as part of the SQL command.
                    </li>
                    <li>
                        If you need to run the same query multiple times with different data, a prepared statement lets you reuse the query without having to prepare it from scratch each time.
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example of Prepared Statements with MySQLi</h5>
                <p>Let's say you want to add a new user to your database.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Connect to the database</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-green">"localhost"</span>, <span class="color-green">"username"</span>, <span class="color-green">"password"</span>, <span class="color-green">"database_name"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check if the connection works</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Prepare the SQL statement with placeholders</p>
                        <p class="ml-30">    <span class="color-pink">$stmt</span> = <span class="color-pink">$conn</span>-><span class="color-red">prepare</span>(<span class="color-green">"INSERT INTO users (username, email) VALUES (?, ?)"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Replace the placeholders with real data</p>
                        <p class="ml-30">    <span class="color-pink">$stmt</span>-><span class="color-red">bind_param</span>(<span class="color-green">"ss"</span>, <span class="color-pink">$username</span>, <span class="color-pink">$email</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the data</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"john_doe"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$email</span> = <span class="color-green">"john@example.com"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Run the query</p>
                        <p class="ml-30">    <span class="color-pink">$stmt</span>-><span class="color-red">execute</span>();</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"New record created successfully"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the statement and connection</p>
                        <p class="ml-30">    <span class="color-pink">$stmt</span>-><span class="color-red">close</span>();</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example of Prepared Statements with PDO</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Connect to the database</p>
                        <p class="ml-30">    <span class="color-pink">$dsn</span> = <span class="color-green">"mysql:host=localhost;dbname=database_name"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$password</span> = <span class="color-green">"password"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">PDO</span>(<span class="color-pink">$dsn</span>, <span class="color-pink">$username</span>, <span class="color-pink">$password</span>);</p>
                        <p class="ml-60">        <span class="color-pink">$conn</span>-><span class="color-red">setAttribute</span>(PDO::<span class="color-pink">ATTR_ERRMODE</span>, PDO::<span class="color-pink">ERRMODE_EXCEPTION</span>);</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Prepare the SQL statement with placeholders</p>
                        <p class="ml-60">        <span class="color-pink">$stmt</span> = <span class="color-pink">$conn</span>-><span class="color-red">prepare</span>(<span class="color-green">"INSERT INTO users (username, email) VALUES (:username, :email)"</span>);</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Replace the placeholders with real data</p>
                        <p class="ml-60">        <span class="color-pink">$stmt</span>-><span class="color-red">bindParam</span>(<span class="color-green">':username'</span>, <span class="color-pink">$username</span>);</p>
                        <p class="ml-60">        <span class="color-pink">$stmt</span>-><span class="color-red">bindParam</span>(<span class="color-green">':email'</span>, <span class="color-pink">$email</span>);</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Set the data</p>
                        <p class="ml-60">        <span class="color-pink">$username</span> = <span class="color-green">"john_doe"</span>;</p>
                        <p class="ml-60">        <span class="color-pink">$email</span> = <span class="color-green">"john@example.com"</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Run the query</p>
                        <p class="ml-60">        <span class="color-pink">$stmt</span>-><span class="color-red">execute</span>();</p>
                        <br />    
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"New record created successfully"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span>(PDOException <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">null</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}