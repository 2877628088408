import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_input";

export default function FilterInput() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_input() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_id',
            'next': '/php/func_filter_input_array'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_input() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_input()</span> function in PHP is used to retrieve and optionally sanitize or validate input from various sources such as GET, POST, COOKIE, SERVER, or ENV. This function provides a convenient way to ensure that input data is safe to use, especially when dealing with form data or user input. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_input</span>(type, variable_name, filter, options)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>type</strong>: The type of input source you want to retrieve the variable from. It can be one of the following:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">INPUT_GET</span>: For GET variables (data from URL query strings).</li>
                            <li><span className="background-grey">INPUT_POST</span>: For POST variables (data from form submissions).</li>
                            <li><span className="background-grey">INPUT_COOKIE</span>: For variables stored in cookies.</li>
                            <li><span className="background-grey">INPUT_SERVER</span>: For server-related variables.</li>
                            <li><span className="background-grey">INPUT_ENV</span>: For environment variables.</li>
                        </ul>
                    </li>
                    <li><strong>variable_name</strong>: The name of the variable you want to retrieve.</li>
                    <li>
                        <strong>filter</strong> (optional): The filter to apply to the variable. PHP provides a variety of filters, such as:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">FILTER_SANITIZE_STRING</span>: Removes or encodes unwanted characters.</li>
                            <li><span className="background-grey">FILTER_VALIDATE_EMAIL</span>: Validates if the variable is a valid email.</li>
                            <li><span className="background-grey">FILTER_VALIDATE_INT</span>: Validates if the variable is an integer.</li>
                            <li><span className="background-grey">FILTER_SANITIZE_URL</span>: Sanitizes the variable as a URL.</li>
                        </ul>
                        If no filter is specified, FILTER_DEFAULT will be applied, which is essentially no filtering.
                    </li>
                    <li><strong>options</strong> (optional): An associative array of options to customize the filtering behavior. It can be used to specify things like default values, range limits, etc.</li>
                </ul>



                <h5 className="mt-5 mb-3">Example : Retrieving and Validating a GET Variable</h5>
                <p>Suppose you have a URL like this: <span className="background-grey">example.com?age=25</span>. You can retrieve and validate the <span className="background-grey">age</span> variable as an integer using <span className="background-grey">filter_input()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$age</span> = <span class="color-red">filter_input</span>(INPUT_GET, <span class="color-green">'age'</span>, FILTER_VALIDATE_INT);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$age</span> === <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Invalid age provided."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Age is valid: "</span> . <span class="color-pink">$age</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">age</span> value is retrieved from the URL using <span className="background-grey">INPUT_GET</span>.</li>
                    <li>It is validated to check if it's an integer using <span className="background-grey">FILTER_VALIDATE_INT</span>.</li>
                    <li>If <span className="background-grey">age</span> is a valid integer, it prints the value; otherwise, it prints an error message.</li>
                </ul>

                <h5 className="mt-5 mb-3">Common Filters in <span className="background-grey">filter_input()</span></h5>
                <table className='table table-stripe my-table'>
                    <thead>
                        <th>Filter Name</th>
                        <th>Description</th>
                    </thead>
                    <tr>
                        <td>FILTER_VALIDATE_INT</td>
                        <td>Validates if the variable is an integer.</td>
                    </tr>
                    <tr>
                        <td>FILTER_VALIDATE_EMAIL</td>
                        <td>Validates if the variable is a valid email address.</td>
                    </tr>
                    <tr>
                        <td>FILTER_VALIDATE_URL</td>
                        <td>Validates if the variable is a valid URL</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_STRING</td>
                        <td>Removes or encodes unwanted characters from a string.</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_EMAIL</td>
                        <td>Removes illegal characters from an email address.</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_URL</td>
                        <td>Removes illegal characters from a URL.</td>
                    </tr>
                    <tr>
                        <td>FILTER_VALIDATE_BOOLEAN</td>
                        <td>Validates if the variable is a boolean (true/false).</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_NUMBER_INT</td>
                        <td>Removes all characters except digits, plus, and minus.</td>
                    </tr>
                </table>

                <p>The <span className="background-grey">filter_input()</span> function provides a reliable and easy way to ensure that input is both validated and sanitized, making it an essential tool in building secure web applications.</p>

            </div>
        </section>
    )
}