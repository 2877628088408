import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_file_get_contents";

export default function FileGetContents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP file_get_contents() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_fclose',
            'next': '/php/func_file_put_contents'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP file_get_contents() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">file_get_contents()</span> function in PHP is a convenient way to read the entire contents of a file into a string. This function is commonly used for reading files, including local files or files accessible via a URL. It is particularly useful when you need to quickly load a file's content without having to manually open, read, and close the file.
                </p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">file_get_contents</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>, <span class="color-blue">bool</span> <span class="color-pink">$use_include_path</span> = <span class="color-blue">false</span>, resource <span class="color-pink">$context</span> = <span class="color-blue">null</span>, <span class="color-blue">int</span> <span class="color-pink">$offset</span> = <span class="color-pink">0</span>, <span class="color-blue">int</span> <span class="color-pink">$maxlen</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">$filename</span> (required): This is the path to the file you want to read. It can be a local file path or a URL.</li>

                    <li><span className="background-grey">$use_include_path</span> (optional): A boolean value. If set to <span className="background-grey">true</span>, the function will search for the file in the include path, which is useful for including files without providing the full path. The default value is <span className="background-grey">false</span>.</li>

                    <li><span className="background-grey">$context</span> (optional): A context resource created with <span className="background-grey">stream_context_create()</span>, which allows you to modify the behavior of the file stream (e.g., setting headers for HTTP requests). The default is <span className="background-grey">null</span>.</li>

                    <li><span className="background-grey">$offset</span> (optional): An integer value that specifies the position in the file from which to start reading. The default is <span className="background-grey">0</span>.</li>

                    <li><span className="background-grey">$maxlen</span> (optional): An integer that limits the number of bytes to read. If not specified, the entire file will be read.</li>
                </ul>

                <p>The function returns the contents of the file as a string on success. If the function fails (e.g., if the file does not exist), it returns <span className="background-grey">false</span>.</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage of <span className="background-grey">file_get_contents()</span></h5>
                <p>Here's a simple example of how to use <span className="background-grey">file_get_contents()</span> to read the contents of a local file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Assuming 'example.txt' contains "Hello, World!"</p>
                        <p class="ml-30">    <span class="color-pink">$content</span> = <span class="color-red">file_get_contents</span>(<span class="color-green">"example.txt"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$content</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-pink">$content</span>; <span class="color-grey">// Outputs: Hello, World!</span></p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error reading the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className="mt-5 mb-3">Example 2: Reading from a URL</h5>
                <p>You can also use <span className="background-grey">file_get_contents()</span> to fetch content from a URL. For example, here’s how to read the contents of a webpage:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-green">"https://www.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$content</span> = <span class="color-red">file_get_contents</span>(<span class="color-pink">$url</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$content</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-pink">$content</span>; <span class="color-grey">// Outputs the HTML content of the webpage</span></p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error fetching the URL."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>


                <p>The <span className="background-grey">file_get_contents()</span> function is a powerful and simple way to read the contents of files in PHP. Whether you're working with local files or fetching data from URLs, it provides a straightforward interface for file handling. Remember to handle errors and consider security implications when dealing with user inputs and remote files.</p>




            </div>
        </section>
    )
}