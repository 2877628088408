import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rand";

export default function Rand() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rand() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_sqrt',
            'next': '/php/func_mt_rand'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rand() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The rand() function in PHP is used to generate a random integer. It can be called with or without arguments, and you can specify a range to get a random number between two values. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rand</span>()</p>
                            <p><span class="color-red">rand</span>(min, max)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>min: (Optional) The lower limit of the random number range. If not provided, the default is 0.</li>
                    <li>max: (Optional) The upper limit of the random number range. If not provided, the default is the maximum possible integer (<span className="background-grey">PHP_INT_MAX</span>).</li>
                </ul>

                <p>The <span className="background-grey">rand()</span> function returns a random integer within the specified range or, if no range is provided, between <span className="background-grey">0</span> and <span className="background-grey">PHP_INT_MAX</span>.</p>



                <h5 className="mt-5 mb-3">Example 1: Generating a Random Number Without Arguments</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">rand</span>();  <span class="color-grey">// Output: Random number like 832123456</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">rand()</span> generates a random number between <span className="background-grey">0</span> and <span className="background-grey">PHP_INT_MAX</span>. The result will vary every time you run it.</p>


                <h5 className="mt-5 mb-3">Example 2: Generating a Random Number Within a Range</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">rand</span>(<span class="color-pink">1</span>, <span class="color-pink">10</span>);  <span class="color-grey">// Output: Random number between 1 and 10</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">rand(1, 10)</span> generates a random number between <span className="background-grey">1</span> and <span className="background-grey">10</span>. Each time you run this code, the result will be a different number within this range.</p>

            </div>
        </section>
    )
}