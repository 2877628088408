import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strtolower";

export default function StrToLower() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strtolower() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strlen',
            'next': '/php/func_strtoupper'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strtolower() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strtolower() function in PHP is used to convert all alphabetic characters in a string to lowercase. This is useful when you want to make a string case-insensitive, normalize user input, or perform text processing where the case doesn't matter.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strtolower</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string that you want to convert to lowercase.</li>
                    <li><strong>Return Value</strong>: It returns a new string where all alphabetic characters have been converted to lowercase.</li>
                </ul>
                <h5 className="mt-5 mb-3">Basic Example of Usage</h5>
                <p>Here is a basic example of using the strtolower() function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello, WORLD!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$lowercaseText</span> = <span class="color-red">strtolower</span>(<span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$lowercaseText</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>hello, world!</p>
                        `
                    }}></div>
                </div>
                <p>n this example, the string <span className="background-grey">"Hello, WORLD!"</span> is converted to <span className="background-grey">"hello, world!"</span>. All uppercase letters in the original string are transformed into their lowercase equivalents.</p>
            </div>
        </section>
    )
}