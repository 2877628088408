import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_str_pad";

export default function StrPad() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP str_pad() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_wordwrap',
            'next': '/php/func_str_split'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP str_pad() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The str_pad() function in PHP is used to pad a string to a specified length with another string. This is particularly useful for formatting output, aligning text, or ensuring that strings meet certain length requirements in your applications. It has a basic syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">str_pad</span>(<span class="color-blue">string</span> <span class="color-pink">$input</span>, <span class="color-blue">int</span> <span class="color-pink">$length</span>, <span class="color-blue">string</span> <span class="color-pink">$pad_string</span> = <span class="color-green">" "</span>, <span class="color-blue">int</span> <span class="color-pink">$pad_type</span> = STR_PAD_RIGHT)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$input</strong>: The input string that you want to pad.</li>
                    <li><strong>$length</strong>: The desired length of the output string after padding.</li>
                    <li><strong>$pad_string</strong>: The string to pad the input with. The default is a space (" ").</li>
                    <li>
                        <strong>$pad_type</strong>: The type of padding to apply. It can be one of the following:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">STR_PAD_RIGHT</span>: Pads the input on the right (default).</li>
                            <li><span className="background-grey">STR_PAD_LEFT</span>: Pads the input on the left.</li>
                            <li><span className="background-grey">STR_PAD_BOTH</span>: Pads the input on both sides.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"PHP"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$paddedText</span> = <span class="color-red">str_pad</span>(<span class="color-pink">$text</span>, <span class="color-pink">10</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$paddedText</span>; <span class="color-grey">// Output: "PHP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string <span className="background-grey">"Hello"</span> is padded with spaces to reach a total length of 10 characters.</p>

                <h5 className="mt-5 mb-3">Example 2 : Padding on the Left</h5>
                <p>If you want to pad the string on the left, you can set <span className="background-grey">$pad_type</span> to <span className="background-grey">STR_PAD_LEFT</span></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"PHP"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$paddedText</span> = <span class="color-red">str_pad</span>(<span class="color-pink">$text</span>, <span class="color-pink">10</span>, "*", STR_PAD_LEFT);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$paddedText</span>; <span class="color-grey">// Output: "*******PHP"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>This example pads the string with asterisks (<span className="background-grey">*</span>) on the left side until it reaches a total length of 10 characters.</p>


                <h5 className="mt-5 mb-3">Example 3 : Padding on Both Sides</h5>
                <p>If you want to pad on both sides, set <span className="background-grey">$pad_type</span> to <span className="background-grey">STR_PAD_BOTH</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$paddedText</span> = <span class="color-red">str_pad</span>(<span class="color-pink">$text</span>, <span class="color-pink">15</span>, <span class="color-green">"-"</span>, STR_PAD_BOTH);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">echo</span> <span class="color-pink">$paddedText</span>; <span class="color-grey">// Output: "---Hello----"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, the string is padded with dashes (<span className="background-grey">-</span>) on both sides, evenly distributing the padding.</p>


            </div>
        </section>
    )
}