import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getMessage";

export default function GetMessage() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getMessage() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getFile',
            'next': '/php/func_getLine'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getMessage() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getMessage()</span> function in the PHP Exception class is used to retrieve the error message that was passed when the exception was thrown. It provides details about the specific error that occurred and is one of the most commonly used methods for handling exceptions in PHP.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getMessage</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getMessage()</strong> returns the error message passed during the exception creation.</li>
                </ul>
                <p>The <span className="background-grey">getMessage()</span> function is helpful in debugging and error handling because it provides a human-readable message about what went wrong. When an exception is thrown, the error message can be used to inform the user or logged for troubleshooting.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getMessage()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60 color-grey">        // Throwing an exception with a custom error message</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"Something went wrong"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Error</span>: Something went wrong</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>An exception is thrown with the message <span className="background-grey">"Something went wrong"</span>.</li>
                    <li>The <span className="background-grey">getMessage()</span> method retrieves and displays this error message in the <span className="background-grey">catch</span> block.</li>
                </ul>



            </div>
        </section>
    )
}