import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_is_dir";

export default function IsDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP is_dir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_is_file',
            'next': '/php/func_mkdir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP is_dir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">is_dir()</span> function in PHP is used to check whether the specified path points to a directory. This function is helpful when you want to confirm if a given path is a directory before performing operations like reading, writing, or traversing its contents.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">is_dir</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$filename</strong> is the path to the file that you want to check. This can be either an absolute path or a relative path.</p>
                <p>It returns <span className="background-grey">true</span> if the given path is a directory and returns <span className="background-grey">false</span> if the path does not exist or is not a directory.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>This example checks whether a specified path is a directory.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dirname</span> = <span class="color-green">'my_directory'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">is_dir</span>(<span class="color-pink">$dirname</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$dirname is a directory."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$dirname is not a directory or does not exist."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>If <span className="background-grey">my_directory</span> exists and is a directory, the output will be:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>my_directory is a directory.</p>
                        `
                    }}></div>
                </div>
                <p>Otherwise, it will return:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>my_directory is not a directory or does not exist.</p>
                        `
                    }}></div>
                </div>


                <p>The <span className="background-grey">is_dir()</span> function in PHP is an essential tool when working with directories. It helps you ensure that a given path points to a directory, avoiding potential errors when performing file system operations. Whether you are checking for the existence of a directory before creating files, processing directory contents, or creating directories, <span className="background-grey">is_dir()</span> provides a reliable and efficient solution for directory validation.</p>


            </div>
        </section>
    )
}