import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/title";

export default function Title() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <title> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;title&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;title&gt;</span> tag in HTML specifies the title of a web page, which appears in the browser’s title bar or tab, and is also used by search engines as the clickable headline for the page in search results. The <span class="background-grey">&lt;title&gt;</span> tag is required in every HTML document and must be placed inside the <span class="background-grey">&lt;head&gt;</span> section.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;title&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The content inside <span class="background-grey">&lt;title&gt;</span> is used as the page's title.</li>
                    <li>Displays in the browser's tab, title bar, and bookmarks, helping users recognize the page.</li>
                    <li>Search engines use the <span class="background-grey">&lt;title&gt;</span> content as the main clickable link in search results, so a well-crafted title can improve search visibility and attract clicks.</li>
                    <li>Screen readers often read the title tag first, giving visually impaired users an overview of the page's content.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax of <span class="background-grey">&lt;title&gt;</span></h5>
                <p>The <span class="background-grey">&lt;title&gt;</span> tag is simple to use, and it can only contain text (not HTML tags or attributes):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;title&gt;Your Page Title Here&lt;/title&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;title&gt;</span></h5>
                <p>The <span class="background-grey">&lt;title&gt;</span> tag typically contains a short, descriptive text summarizing the content or purpose of the page.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Introduction to HTML Elements&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Welcome to HTML Basics&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This page introduces you to the basic HTML elements.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">&lt;title&gt;</span> tag is an essential HTML element that serves as the web page’s title. It appears in browser tabs, search results, and is critical for both SEO and user experience. Properly crafted, it helps attract users to the site and improves navigation and accessibility.</p>


            </div>
        </section>
    )
}