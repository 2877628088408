import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_htmlspecialchars";

export default function HTMLSpecialChars() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP htmlspecialchars() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strcasecmp',
            'next': '/php/func_addslashes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP htmlspecialchars() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The htmlspecialchars() function in PHP is used to convert special characters in a string to HTML entities. This is crucial for preventing cross-site scripting (XSS) attacks and ensuring that user input is displayed correctly in web browsers without being interpreted as HTML or JavaScript. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">htmlspecialchars</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$flags</span> = ENT_COMPAT, <span class="color-blue">string</span>|<span class="color-blue">null</span> <span class="color-pink">$encoding</span> = <span class="color-blue">null</span>, <span class="color-blue">bool</span> <span class="color-pink">$double_encode</span> = <span class="color-blue">true</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string that needs to be converted.</li>
                    <li>
                        <strong>$flags</strong> (optional): A bitmask of one or more of the following flags, combined using the bitwise OR (|) operator. The default is <span className="background-grey">ENT_COMPAT</span>.
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">ENT_COMPAT</span>: Convert double quotes and leave single quotes unconverted.</li>
                            <li><span className="background-grey">ENT_QUOTES</span>: Convert both double and single quotes.</li>
                            <li><span className="background-grey">ENT_NOQUOTES</span>: Do not convert any quotes.</li>
                        </ul>
                    </li>
                    <li><strong>$encoding</strong> (optional): An optional argument to define the character encoding. If omitted, it defaults to the value of the default_charset configuration option.</li>
                    <li><strong>$double_encode</strong> (optional): A boolean that determines whether to convert existing HTML entities to their respective codes. Defaults to true.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$input</span> = <span class="color-green">"&lt;h1&gt;Hello, World!&lt;/h1&gt;"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$output</span> = <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$input</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$output</span>; <span class="color-grey">// Output: &lt;h1&gt;Hello, World!&lt;/h1&gt;</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the <span className="background-grey">&lt;</span> and <span className="background-grey">&gt;</span> characters are converted to their corresponding HTML entities (<span className="background-grey">&#38;lt;</span> and <span className="background-grey">&#38;gt;</span>), preventing the browser from interpreting them as HTML tags.</p>

            </div>
        </section>
    )
}