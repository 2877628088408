import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/simple-xml";

export default function SimpleXML() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("SimpleXML in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rad2deg',
            'next': '/php/func_strlen'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is SimpleXML?</h3>
            <div className='mt-4 mb-5'>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>SimpleXML</strong> is a tool that converts XML documents into an object representation, making it easier to interact with XML data.</li>
                    <li>It represents XML elements as objects and attributes as properties, allowing you to traverse and manipulate XML in an intuitive manner.</li>
                </ul>
                <h5 className="mt-5 mb-3">How SimpleXML Works</h5>
                <p>SimpleXML represents XML as a tree of elements, and each XML element is turned into a PHP object. You can navigate the XML structure using the object and access nodes, attributes, and text content directly.</p>

                <p>Let's say we have the following XML file <span className='background-grey'>books.xml</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;books&gt;</p>
                        <p class="ml-30">    &lt;book&gt;</p>
                        <p class="ml-60">        &lt;title&gt;PHP for Beginners&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;author&gt;John Doe&lt;/author&gt;</p>
                        <p class="ml-60">        &lt;year&gt;2021&lt;/year&gt;</p>
                        <p class="ml-30">    &lt;/book&gt;</p>
                        <p class="ml-30">    &lt;book&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Advanced PHP Techniques&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;author&gt;Jane Smith&lt;/author&gt;</p>
                        <p class="ml-60">        &lt;year&gt;2023&lt;/year&gt;</p>
                        <p class="ml-30">    &lt;/book&gt;</p>
                        <p>&lt;/books&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Loading XML with SimpleXML</h5>
                <p>To load this XML and work with it in PHP, you can use the <span className='background-grey'>simplexml_load_file()</span> or <span className='background-grey'>simplexml_load_string()</span> function.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Loading from a file:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$xml</span> = <span class="color-red">simplexml_load_file</span>(<span class="color-green">"books.xml"</span>);</p>
                                <br />    
                                <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$xml</span> === <span class="color-blue">false</span>) {</p>
                                <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to load XML."</span>;</p>
                                <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                                <p class="ml-60">        <span class="color-red">print_r</span>(<span class="color-pink">$xml</span>);  <span class="color-grey">// Displays the structure of the XML as an object</span></p>
                                <p class="ml-30">    }</p>
                                <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Loading from a string:</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$xml_string</span> = <span class="color-green">'<books><book><title>PHP for Beginners</title><author>John Doe</author><year>2021</year></book></books>'</span>;
                                <p class="ml-30">    <span class="color-pink">$xml</span> = <span class="color-red">simplexml_load_string</span>(<span class="color-pink">$xml_string</span>);
                                <br />    
                                <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$xml</span>);  <span class="color-grey">// Outputs the SimpleXML object structure</span></p>
                                ?>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Accessing XML Data</h5>
                <p>Once the XML is loaded, you can easily access elements and attributes as properties of the object.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$xml</span> = <span class="color-red">simplexml_load_file</span>(<span class="color-green">"books.xml"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Accessing elements</p>
                        <p class="ml-30">    <span class="color-blue">foreach</span> (<span class="color-pink">$xml</span>->book <span class="color-blue">as</span> <span class="color-pink">$book</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Title: "</span> . <span class="color-pink">$book</span>->title . <span class="color-green">"\n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Author: "</span> . <span class="color-pink">$book</span>->author . <span class="color-green">"\n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Year: "</span> . <span class="color-pink">$book</span>->year . <span class="color-green">"\n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"----------\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>    <span class="color-pink">Title</span>: <span class="color-green">PHP for Beginners</span></p>
                        <p>    <span class="color-pink">Author</span>: <span class="color-green">John Doe</span></p>
                        <p>    <span class="color-pink">Year</span>: <span class="color-pink">2021</span></p>
                        <p>    <span class="color-green">----------</span></p>
                        <p>    <span class="color-pink">Title</span>: <span class="color-green">Advanced PHP</span></p>
                        <p>    <span class="color-pink">Author</span>: <span class="color-green">Jane Smith</span></p>
                        <p>    <span class="color-pink">Year</span>: <span class="color-pink">2023</span></p>
                        <p>    <span class="color-green">----------</span></p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}