import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strlen";

export default function StrLen() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strlen() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/simple-xml',
            'next': '/php/func_strtolower'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strlen() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strlen() function in PHP is used to calculate the length of a string. It returns the number of characters in the string, including spaces and special characters. This function is very useful when you need to know how long a string is, especially when validating user input or manipulating text. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strlen</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: This is the string whose length you want to calculate.</li>
                    <li><strong>Return Value</strong>: It returns an integer, which represents the number of characters in the given string.</li>
                </ul>

                <h5 className="mt-5 mb-3">Basic Example of Usage</h5>
                <p>Here is a basic example of using the strlen() function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello, World!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$length</span> = <span class="color-red">strlen</span>(<span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The length of the string is: "</span> . <span class="color-pink">$length</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>The length of the string is: <span class="color-pink">13</span></p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Usage in Validating Form Input</h5>
                <p>One common use of <span className="background-grey">strlen</span>() is validating form input, where you need to ensure that the user enters text of a certain length (e.g., a username or password):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"john"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">strlen</span>(<span class="color-pink">$username</span>) < <span class="color-pink">6</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Username must be at least 6 characters long."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Username is valid."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Username must be at least <span class="color-pink">6</span> characters long.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}