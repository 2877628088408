import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_unlink";

export default function Unlink() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP unlink() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_file_exists',
            'next': '/php/func_copy'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP unlink() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">unlink()</span> function in PHP is used to delete a file from the file system. This function removes the specified file and is commonly used when you want to delete files, such as temporary files or uploaded files, from your server.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">unlink</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$filename</strong> (required) is path to the file you want to delete. It can be either an absolute path or a relative path.</p>
                <p>The function returns <span className="background-grey">true</span> if the file is successfully deleted. The function returns <span className="background-grey">false</span> if the file cannot be deleted, for example, due to permission issues or if the file does not exist.</p>


                <h5 className="mt-5 mb-3">Example: Deleting a File</h5>
                <p>In this example, we'll delete a file named <span className="background-grey">example.txt</span> in the current directory.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filename</span> = <span class="color-green">'example.txt'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-pink">$filename</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">unlink</span>(<span class="color-pink">$filename</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"The file $filename has been deleted."</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Error: Unable to delete the file."</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The file $filename does not exist."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}