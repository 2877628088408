import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strstr";

export default function Strstr() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strstr() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strrpos',
            'next': '/php/func_strrev'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strstr() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strstr() function in PHP is used to <strong>find the first occurrence of a substring in a string</strong>. It returns the portion of the string starting from the first occurrence of the substring to the end of the string. This function is useful for extracting parts of strings when you know the starting point but not the endpoint. It has a basic syntax -
                </p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">strstr</span>(<span class="color-blue">string</span> <span class="color-pink">$haystack</span>, <span class="color-blue">string</span> <span class="color-pink">$needle</span>, <span class="color-blue">bool</span> <span class="color-pink">$before_needle</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$haystack</strong>: The string to search in.</li>
                    <li><strong>$needle</strong>: The substring you are looking for.</li>
                    <li><strong>$before_needle</strong> (optional): If set to <span className="background-grey">true</span>, <span className="background-grey">strstr()</span> will return the part of the string before the first occurrence of the substring. The default value is <span className="background-grey">false</span>.</li>
                    <li><strong>Return Value</strong>: Returns the portion of the string starting from the first occurrence of the substring. If the substring is not found, it returns <span className="background-grey">false</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello, world! Welcome to PHP."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$substring</span> = <span class="color-green">"world"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$result</span> = <span class="color-red">strstr</span>(<span class="color-pink">$string</span>, <span class="color-pink">$substring</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$result</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The result is: "</span> . <span class="color-pink">$result</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"'$substring' not found!"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The result is: world! Welcome to PHP.</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">strstr()</span> finds the substring <span className="background-grey">"world"</span> in the string and returns the portion from that point onward.</p>


                <h5 className="mt-5 mb-3">Using the <span className="background-grey">before_needle</span> Parameter</h5>
                <p>If you want to get the portion of the string before the specified substring, you can set the <span className="background-grey">before_needle</span> parameter to <span className="background-grey">true</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello, world! Welcome to PHP."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$substring</span> = <span class="color-green">"world"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$result</span> = <span class="color-red">strstr</span>(<span class="color-pink">$string</span>, <span class="color-pink">$substring</span>, <span class="color-blue">true</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$result</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The result is: "</span> . <span class="color-pink">$result</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"'$substring' not found!"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The result is: Hello, </p>
                        `
                    }}></div>
                </div>
                <p>In this example, since <span className="background-grey">before_needle</span> is set to <span className="background-grey">true</span>, <span className="background-grey">strstr()</span> returns the portion of the string before <span className="background-grey">"world"</span>.</p>

            </div>
        </section>
    )
}