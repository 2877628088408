import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/doctype-html";

export default function DoctypeHtml() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <!DOCTYPE html> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;!DOCTYPE html&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;!DOCTYPE html&gt;</span> declaration, known as the <strong>DOCTYPE</strong>, is an instruction to the web browser about which version of HTML the page is written in. It helps the browser render the page correctly. This declaration is placed at the very beginning of an HTML document, above the <span class="background-grey">&lt;html&gt;</span> tag, and it must be the first thing in the document.
                </p>

                <h5 className='mt-5 mb-3'>Purpose of <span class="background-grey">&lt;!DOCTYPE html&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The DOCTYPE tells the browser that the document is written in HTML5, which is the current standard.</li>
                    <li>The DOCTYPE ensures the page renders in "standards mode," which aligns with the web standards set by the World Wide Web Consortium (W3C). Without it, browsers might fall back to "quirks mode," a non-standard mode for older, outdated HTML and CSS.</li>
                    <li><span class="background-grey">&lt;!DOCTYPE&gt;</span> is not an HTML tag but a declaration. It doesn’t have an opening and closing tag like other HTML elements.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Syntax for HTML5</h5>
                <p>The DOCTYPE for HTML5 is simple and looks like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example of <span class="background-grey">&lt;!DOCTYPE html&gt;</span> Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Sample HTML5 Document&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h1&gt;Welcome to HTML5&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This document uses the HTML5 DOCTYPE declaration.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Why is <span class="background-grey">&lt;!DOCTYPE html&gt;</span> Important?</h5>
                <ul style={{ listStyle: 'decimsl' }}>
                    <li>It helps the browser render the document consistently across different browsers.</li>
                    <li>It indicates the document is written in HTML5, which includes new features and simplified syntax.</li>
                    <li>It avoids potential rendering issues, especially in older browsers, by enabling standards mode.</li>
                </ul>



            </div>
        </section>
    )
}