import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_json_encode";

export default function JsonEncode() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP json_encode() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_json_decode',
            'next': '/php/mail'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP json_encode() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>json_encode()</span> function in PHP is used to convert a PHP data structure (such as an array or object) into a JSON (JavaScript Object Notation) string. JSON is a lightweight data-interchange format that is easy for humans to read and write, and easy for machines to parse and generate. It has a basic syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">json_encode</span>(<span class="color-blue">mixed</span> <span class="color-pink">$value</span>, <span class="color-blue">int</span> <span class="color-pink">$options</span> = <span class="color-pink">0</span>, <span class="color-blue">int</span> <span class="color-pink">$depth</span> = <span class="color-pink">512</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$value</strong>: The PHP data (array or object) to be encoded into JSON format.</li>
                    <li><strong>$options</strong> (optional): A bitmask of options for controlling the JSON encoding process.</li>
                    <li><strong>$depth</strong> (optional): The maximum depth to traverse when encoding the structure. The default is 512.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Converting a PHP array to JSON</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(<span class="color-green">"name"</span> => <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">30</span>, <span class="color-green">"city"</span> => <span class="color-green">"New York"</span>);</p>
                            <p class="ml-30">    <span class="color-pink">$json_data</span> = <span class="color-red">json_encode</span>(<span class="color-pink">$data</span>);</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$json_data</span>;  <span class="color-grey">// Output: {"name":"John","age":30,"city":"New York"}</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Converting a PHP object to JSON</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">class</span> <span class="color-yellow">Person</span> {</p>
                        <p class="ml-60">        <span class="color-blue">public</span> <span class="color-pink">$name</span>;</p>
                        <p class="ml-60">        <span class="color-blue">public</span> <span class="color-pink">$age</span>;</p>
                        <p class="ml-60">        <span class="color-blue">public</span> <span class="color-pink">$city</span>;</p>
                        <br />        
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">__construct</span>(<span class="color-pink">$name</span>, <span class="color-pink">$age</span>, <span class="color-pink">$city</span>) {</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->name = <span class="color-pink">$name</span>;</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->age = <span class="color-pink">$age</span>;</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->city = <span class="color-pink">$city</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$person</span> = <span class="color-blue">new</span> <span class="color-red">Person</span>(<span class="color-green">"Jane"</span>, <span class="color-pink">25</span>, <span class="color-green">"Los Angeles"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$json_person</span> = <span class="color-red">json_encode</span>(<span class="color-pink">$person</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$json_person</span>;  <span class="color-grey">// Output: {"name":"Jane","age":25,"city":"Los Angeles"}</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Options for json_encode()</h5>
                <p><span className='background-grey'>json_encode()</span> supports various options to control the output. You can combine multiple options using bitwise OR (<span className='background-grey'>|</span>).</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span className='background-grey'>JSON_PRETTY_PRINT</span>: Formats the JSON string with spaces and line breaks to make it more readable.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(<span class="color-green">"name"</span> => <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">30</span>);</p>
                                <br />    
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">json_encode</span>(<span class="color-pink">$data</span>, JSON_PRETTY_PRINT);</p>
                                <p class="ml-30 color-grey">    // Output:</p>
                                <p class="ml-30 color-grey">    // {</p>
                                <p class="ml-30 color-grey">    //     "name": "John",</p>
                                <p class="ml-30 color-grey">    //     "age": 30</p>
                                <p class="ml-30 color-grey">    // }</p>
                                <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>JSON_FORCE_OBJECT</span>: Forces non-associative arrays to be encoded as JSON objects (instead of arrays).
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>);</p>
                                <br />    
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">json_encode</span>(<span class="color-pink">$data</span>, JSON_FORCE_OBJECT);</p>
                                <p class="ml-30 color-grey">    // Output: {"0":"apple","1":"banana"}</p>
                                ?>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>JSON_NUMERIC_CHECK</span>: Converts numeric strings into numbers in the JSON output.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(<span class="color-green">"price"</span> => <span class="color-green">"100"</span>);</p>
                                <br />    
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">json_encode</span>(<span class="color-pink">$data</span>, JSON_NUMERIC_CHECK);</p>
                                <p class="ml-30 color-grey">    // Output: {"price":100}</p>
                                <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span className='background-grey'>JSON_UNESCAPED_SLASHES</span>: Prevents escaping of slashes (/).
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-red">array</span>(<span class="color-green">"site"</span> => <span class="color-green">"https://example.com"</span>);</p>
                                <br />    
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">json_encode</span>(<span class="color-pink">$url</span>, JSON_UNESCAPED_SLASHES);</p>
                                <p class="ml-30 color-grey">    // Output: {"site":"https://example.com"}</p>
                                <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

            </div>
        </section>
    )
}