import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/dialog";

export default function Dialog() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <dialog> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;dialog&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;dialog&gt;</span> tag in HTML is used to create a dialog box or popup that can be shown to the user, typically for tasks like alerts, forms, or additional information without navigating away from the current page. The <span class="background-grey">&lt;dialog&gt;</span> element is useful for creating modals that the user can interact with and dismiss without needing additional JavaScript code for basic functionality.
                </p>

                <h5 className='mt-5 mb-3'>Features of <span class="background-grey">&lt;dialog&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Acts as a modal window where the user cannot interact with the rest of the page until the dialog is dismissed.</li>
                    <li>Can be displayed as a regular popup without blocking interaction with the rest of the page.</li>
                    <li>The <span class="background-grey">&lt;dialog&gt;</span> element has methods like <span class="background-grey">.show()</span> and <span class="background-grey">.showModal()</span> to open the dialog and <span class="background-grey">.close()</span> to close it.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">dialog</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;This is a dialog box.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">button</span> <span class="color-pink">id</span>=<span class="color-green">"closeBtn"</span>&gt;Close&lt;/<span class="color-blue">button</span>&gt;</p>
                        <p>&lt;/<span class="color-blue">dialog</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Methods and Attributes of <span class="background-grey">&lt;dialog&gt;</span></h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">.show()</span>: Opens the dialog as a non-modal, allowing interaction with the rest of the page.</li>
                    <li><span class="background-grey">.showModal()</span>: Opens the dialog as a modal, which prevents user interaction with the rest of the page until closed.</li>
                    <li><span class="background-grey">.close()</span>: Closes the dialog, removing it from view.</li>
                    <li><span class="background-grey">open</span> attribute: Indicates that the dialog is currently visible. This attribute is automatically added when the dialog is opened with <span class="background-grey">.show()</span> or <span class="background-grey">.showModal()</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Dialog Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60 color-grey">        &lt;!-- Dialog element --&gt;</p>
                        <p class="ml-60">        &lt;dialog <span class="color-pink">id</span>=<span class="color-green">"myDialog"</span>&gt;</p>
                        <p class="ml-90">            &lt;p&gt;This is a sample dialog box.&lt;/p&gt;</p>
                        <p class="ml-90">            &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"closeDialog()"</span>&gt;Close&lt;/button&gt;</p>
                        <p class="ml-60">        &lt;/dialog&gt;</p>
                        <br />        
                        <p class="ml-60 color-grey">        &lt;!-- Button to open the dialog --&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"openDialog()"</span>&gt;Open Dialog&lt;/button&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">const</span> dialog = document.<span class="color-red">getElementById</span>(<span class="color-green">"myDialog"</span>);</p>
                        <br />        
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">openDialog</span>() {</p>
                        <p class="ml-120">                dialog.<span class="color-red">showModal</span>(); <span class="color-grey">// Opens as a modal</span></p>
                        <p class="ml-90">            }</p>
                        <br />        
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">closeDialog</span>() {</p>
                        <p class="ml-120">                dialog.<span class="color-red">close</span>(); <span class="color-grey">// Closes the dialog</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;dialog&gt;</span> element contains content that is initially hidden.</li>
                    <li>Clicking "Open Dialog" triggers the <span class="background-grey">openDialog</span> function, which opens the dialog in modal mode using <span class="background-grey">showModal()</span>.</li>
                    <li>The "Close" button inside the dialog calls <span class="background-grey">closeDialog</span>, which hides the dialog using the <span class="background-grey">close()</span> method.</li>
                </ul>

            </div>
        </section>
    )
}