import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_explode";

export default function Explode() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP explode() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rtrim',
            'next': '/php/func_implode'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP explode() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The explode() function in PHP is used to split a string into an array. You provide a delimiter (a character or string that separates the data), and the function divides the input string wherever the delimiter is found. This function is extremely useful for breaking down large strings into manageable pieces, such as when processing CSV files or working with user input.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">explode</span>(<span class="color-blue">string</span> <span class="color-pink">$separator</span>, <span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$limit</span> = PHP_INT_MAX)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$separator</strong>: The delimiter or character used to break the string apart.</li>
                    <li><strong>$string</strong>: The string to be split into an array.</li>
                    <li><strong>$limit</strong>: (Optional) This parameter controls the maximum number of elements in the resulting array. If the limit is set, the function will split the string up to that many times. The rest of the string will be the final element.</li>
                    <li><strong>Return Value</strong>: An array of strings, split at each point where the delimiter occurs in the input string.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1 : Processing CSV Data</h5>
                <p>CSV files (Comma Separated Values) store data in plain text with values separated by commas. You can use <span className="background-grey">explode()</span> to convert each line of a CSV file into an array for further processing.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$csvLine</span> = <span class="color-green">"John,Doe,john@example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">explode</span>(<span class="color-green">","</span>, <span class="color-pink">$csvLine</span>);</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$data</span>); <span class="color-grey">// Outputs: Array ( [0] => John [1] => Doe [2] => john@example.com )</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Splitting URL Parameters</h5>
                <p>URLs often contain query parameters separated by <span className="background-grey">&</span>. You can split the URL into parts using <span className="background-grey">explode()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-green">"id=123&name=John&age=25"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$params</span> = <span class="color-red">explode</span>(<span class="color-green">"&"</span>, <span class="color-pink">$url</span>);</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$params</span>); <span class="color-grey">// Outputs: Array ( [0] => id=123 [1] => name=John [2] => age=25 )</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 3 : Breaking a Sentence into Words</h5>
                <p>You can split a sentence into individual words by specifying a space (<span className="background-grey">" "</span>) as the delimiter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$sentence</span> = <span class="color-green">"PHP is a powerful scripting language"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$words</span> = <span class="color-red">explode</span>(<span class="color-green">" "</span>, <span class="color-pink">$sentence</span>);</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$words</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Array</p>
                            <p>(</p>
                            <p class="ml-30">    [0] => PHP</p>
                            <p class="ml-30">    [1] => is</p>
                            <p class="ml-30">    [2] => a</p>
                            <p class="ml-30">    [3] => powerful</p>
                            <p class="ml-30">    [4] => scripting</p>
                            <p class="ml-30">    [5] => language</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>


                <h5 className="mt-5 mb-3">Example with <span className="background-grey">limit</span> Parameter</h5>
                <p>The limit parameter allows you to control the number of splits. If the limit is positive, the function splits the string into the specified number of elements. The last element will contain the rest of the string.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"apple,banana,orange,grape,kiwi"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$array</span> = <span class="color-red">explode</span>(<span class="color-green">","</span>, <span class="color-pink">$string</span>, <span class="color-pink">3</span>);</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Array</p>
                        <p>(</p>
                        <p class="ml-30">    [0] => apple</p>
                        <p class="ml-30">    [1] => banana</p>
                        <p class="ml-30">    [2] => orange,grape,kiwi</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string is split into three elements. The first two splits are based on the commas, and the rest of the string (<span className="background-grey">"orange,grape,kiwi"</span>) is assigned to the third element.</p>

            </div>
        </section>
    )
}