import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function CSSLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path) pathName.setPathName(path);
    }

    const OpenShowSubMenu = (e, path) => {
        //console.log(path, e.target);
        setPathName(path)
        e.currentTarget.nextSibling.style.display = e.currentTarget.nextSibling.style.display == 'block' ? 'none' : 'block';
    }

    useEffect(() => {
        console.log(pathname)
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
        console.log(subelement)
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            PHP Introduction
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/php/introduction" onClick={() => { setPathName('/php/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/php/setup/environment' ? 'activeMenu' : ''}>
                                    <Link to="/php/setup/environment" onClick={() => { setPathName('/php/setup/environment'); } }>Setting Up the Environment</Link>
                                </li>
                                <li className={pathname == '/php/first/script' ? 'activeMenu' : ''}>
                                    <Link to="/php/first/script" onClick={() => { setPathName('/php/first/script'); } }>Your First PHP Script</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP Basics
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/variables' ? 'activeMenu' : ''}>
                                    <Link to="/php/variables" onClick={() => { setPathName('/php/variables'); } }>Variables and Data Types</Link>
                                </li>
                                <li className={pathname == '/php/operators' ? 'activeMenu' : ''}>
                                    <Link to="/php/operators" onClick={() => { setPathName('/php/operators'); } }>Operators</Link>
                                </li>
                                <li className={pathname == '/php/loop/control/structure' ? 'activeMenu' : ''}>
                                    <Link to="/php/loop/control/structure" onClick={() => { setPathName('/php/loop/control/structure'); } }>Loop Control Structures</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP with Form
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/create/form' ? 'activeMenu' : ''}>
                                    <Link to="/php/create/form" onClick={() => { setPathName('/php/create/form'); } }>Creating a Simple HTML Form</Link>
                                </li>
                                <li className={pathname == '/php/get/post/methods' ? 'activeMenu' : ''}>
                                    <Link to="/php/get/post/methods" onClick={() => { setPathName('/php/get/post/methods'); } }>GET vs POST Methods</Link>
                                </li>
                                <li className={pathname == '/php/form-handling' ? 'activeMenu' : ''}>
                                    <Link to="/php/form-handling" onClick={() => { setPathName('/php/form-handling'); } }>Form Handling in PHP</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP Functions
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/functions' ? 'activeMenu' : ''}>
                                    <Link to="/php/functions" onClick={() => { setPathName('/php/functions'); } }>Creating and Using Functions</Link>
                                </li>
                                <li className={pathname == '/php/built-in/functions' ? 'activeMenu' : ''}>
                                    <Link to="/php/built-in/functions" onClick={() => { setPathName('/php/built-in/functions'); } }>Built-in PHP Functions</Link>
                                </li>
                                <li className={pathname == '/php/statements' ? 'activeMenu' : ''}>
                                    <Link to="/php/statements" onClick={() => { setPathName('/php/statements'); } }>Include and Require</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Working with Files
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/file-handling' ? 'activeMenu' : ''}>
                                    <Link to="/php/file-handling" onClick={() => { setPathName('/php/file-handling'); } }>File Handling in PHP</Link>
                                </li>
                                <li className={pathname == '/php/reading-a-file' ? 'activeMenu' : ''}>
                                    <Link to="/php/reading-a-file" onClick={() => { setPathName('/php/reading-a-file'); } }>Reading a file</Link>
                                </li>
                                <li className={pathname == '/php/writing-a-file' ? 'activeMenu' : ''}>
                                    <Link to="/php/writing-a-file" onClick={() => { setPathName('/php/writing-a-file'); } }>Writing a file</Link>
                                </li>
                                <li className={pathname == '/php/file-uploads' ? 'activeMenu' : ''}>
                                    <Link to="/php/file-uploads" onClick={() => { setPathName('/php/file-uploads'); } }>File Uploads</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP and MySQL
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/php-with-mysql' ? 'activeMenu' : ''}>
                                    <Link to="/php/php-with-mysql" onClick={() => { setPathName('/php/php-with-mysql'); } }>Introduction to MySQL</Link>
                                </li>
                                <li className={pathname == '/php/crud-operation' ? 'activeMenu' : ''}>
                                    <Link to="/php/crud-operation" onClick={() => { setPathName('/php/crud-operation'); } }>CRUD Operations</Link>
                                </li>
                                <li className={pathname == '/php/prepared-statement' ? 'activeMenu' : ''}>
                                    <Link to="/php/prepared-statement" onClick={() => { setPathName('/php/prepared-statement'); } }>Prepared Statements</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP Sessions and Cookies
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/working-with-session' ? 'activeMenu' : ''}>
                                    <Link to="/php/working-with-session" onClick={() => { setPathName('/php/working-with-session'); } }>Understanding Sessions</Link>
                                </li>
                                <li className={pathname == '/php/working-with-cookies' ? 'activeMenu' : ''}>
                                    <Link to="/php/working-with-cookies" onClick={() => { setPathName('/php/working-with-cookies'); } }>Working with Cookies</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Object-Oriented PHP
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/oops/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/php/oops/introduction" onClick={() => { setPathName('/php/oops/introduction'); } }>Introduction to OOP</Link>
                                </li>
                                <li className={pathname == '/php/oops/constructor/destructor' ? 'activeMenu' : ''}>
                                    <Link to="/php/oops/constructor/destructor" onClick={() => { setPathName('/php/oops/constructor/destructor'); } }>Constructor and Destructor</Link>
                                </li>
                                <li className={pathname == '/php/oops/access-modifier' ? 'activeMenu' : ''}>
                                    <Link to="/php/oops/access-modifier" onClick={() => { setPathName('/php/oops/access-modifier'); } }>Access Modifiers</Link>
                                </li>
                                <li className={pathname == '/php/oops/static-methods-properties' ? 'activeMenu' : ''}>
                                    <Link to="/php/oops/static-methods-properties" onClick={() => { setPathName('/php/oops/static-methods-properties'); } }>Static Methods and Properties</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Advanced PHP
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/error/handling' ? 'activeMenu' : ''}>
                                    <Link to="/php/error/handling" onClick={() => { setPathName('/php/error/handling'); } }>Error Handling</Link>
                                </li>
                                <li className={pathname == '/php/working-with-apis' ? 'activeMenu' : ''}>
                                    <Link to="/php/working-with-apis" onClick={() => { setPathName('/php/working-with-apis'); } }>Working with APIs</Link>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/array" onClick={(e) => OpenShowSubMenu(e, '/php/array')}>PHP Arrays
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/array' || pathname == '/php/func_array_change_key_case' || pathname == '/php/func_array_chunk' || pathname == '/php/func_array_column' || pathname == '/php/func_array_combine' || pathname == '/php/func_array_count_values' || pathname == '/php/func_array_diff' || pathname == '/php/func_array_diff_assoc' || pathname == '/php/func_array_diff_key' || pathname == '/php/func_array_fill' || pathname == '/php/func_array_fill_keys' || pathname == '/php/func_array_filter' || pathname == '/php/func_array_flip' || pathname == '/php/func_array_key_exists' || pathname == '/php/func_array_keys' || pathname == '/php/func_array_map' || pathname == '/php/func_array_merge' || pathname == '/php/func_array_pad' || pathname == '/php/func_array_pop' || pathname == '/php/func_array_push' || pathname == '/php/func_array_rand' || pathname == '/php/func_array_reduce' || pathname == '/php/func_array_replace' || pathname == '/php/func_array_reverse' || pathname == '/php/func_array_search' || pathname == '/php/func_array_shift' || pathname == '/php/func_array_slice' || pathname == '/php/func_array_splice' || pathname == '/php/func_array_sum' || pathname == '/php/func_array_product' || pathname == '/php/func_array_unique' || pathname == '/php/func_array_values' || pathname == '/php/func_asort' || pathname == '/php/func_arsort' || pathname == '/php/func_ksort' || pathname == '/php/func_krsort') ? 'block' : 'none' }}>
                                        <li className={pathname == '/php/array' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/array" onClick={() => setPathName('/php/array')}>
                                                <span className='ml-30'>array()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_change_key_case' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_change_key_case" onClick={() => setPathName('/php/func_array_change_key_case')}>
                                                <span className='ml-30'>array_change_key_case()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_chunk' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_chunk" onClick={() => setPathName('/php/func_array_chunk')}>
                                                <span className='ml-30'>array_chunk()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_column' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_column" onClick={() => setPathName('/php/func_array_column')}>
                                                <span className='ml-30'>array_column()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_combine' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_combine" onClick={() => setPathName('/php/func_array_combine')}>
                                                <span className='ml-30'>array_combine()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_count_values' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_count_values" onClick={() => setPathName('/php/func_array_count_values')}>
                                                <span className='ml-30'>array_count_values()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_diff' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_diff" onClick={() => setPathName('/php/func_array_diff')}>
                                                <span className='ml-30'>array_diff()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_diff_assoc' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_diff_assoc" onClick={() => setPathName('/php/func_array_diff_assoc')}>
                                                <span className='ml-30'>array_diff_assoc()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_diff_key' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_diff_key" onClick={() => setPathName('/php/func_array_diff_key')}>
                                                <span className='ml-30'>array_diff_key()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_fill' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_fill" onClick={() => setPathName('/php/func_array_fill')}>
                                                <span className='ml-30'>array_fill()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_fill_keys' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_fill_keys" onClick={() => setPathName('/php/func_array_fill_keys')}>
                                                <span className='ml-30'>array_fill_keys()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_filter' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_filter" onClick={() => setPathName('/php/func_array_filter')}>
                                                <span className='ml-30'>array_filter()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_flip' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_flip" onClick={() => setPathName('/php/func_array_flip')}>
                                                <span className='ml-30'>array_flip()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_key_exists' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_key_exists" onClick={() => setPathName('/php/func_array_key_exists')}>
                                                <span className='ml-30'>array_key_exists()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_keys' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_keys" onClick={() => setPathName('/php/func_array_keys')}>
                                                <span className='ml-30'>array_keys()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_map' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_map" onClick={() => setPathName('/php/func_array_map')}>
                                                <span className='ml-30'>array_map()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_merge' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_merge" onClick={() => setPathName('/php/func_array_merge')}>
                                                <span className='ml-30'>array_merge()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_pad' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_pad" onClick={() => setPathName('/php/func_array_pad')}>
                                                <span className='ml-30'>array_pad()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_pop' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_pop" onClick={() => setPathName('/php/func_array_pop')}>
                                                <span className='ml-30'>array_pop()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_push' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_push" onClick={() => setPathName('/php/func_array_push')}>
                                                <span className='ml-30'>array_push()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_rand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_rand" onClick={() => setPathName('/php/func_array_rand')}>
                                                <span className='ml-30'>array_rand()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_reduce' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_reduce" onClick={() => setPathName('/php/func_array_reduce')}>
                                                <span className='ml-30'>array_reduce()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_replace' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_replace" onClick={() => setPathName('/php/func_array_replace')}>
                                                <span className='ml-30'>array_replace()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_reverse' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_reverse" onClick={() => setPathName('/php/func_array_reverse')}>
                                                <span className='ml-30'>array_reverse()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_search' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_search" onClick={() => setPathName('/php/func_array_search')}>
                                                <span className='ml-30'>array_search()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_shift' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_shift" onClick={() => setPathName('/php/func_array_shift')}>
                                                <span className='ml-30'>array_shift()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_slice' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_slice" onClick={() => setPathName('/php/func_array_slice')}>
                                                <span className='ml-30'>array_slice()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_splice' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_splice" onClick={() => setPathName('/php/func_array_splice')}>
                                                <span className='ml-30'>array_splice()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_sum' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_sum" onClick={() => setPathName('/php/func_array_sum')}>
                                                <span className='ml-30'>array_sum()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_product' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_product" onClick={() => setPathName('/php/func_array_product')}>
                                                <span className='ml-30'>array_product()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_unique' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_unique" onClick={() => setPathName('/php/func_array_unique')}>
                                                <span className='ml-30'>array_unique()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_array_values' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_array_values" onClick={() => setPathName('/php/func_array_values')}>
                                                <span className='ml-30'>array_values()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ksort' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ksort" onClick={() => setPathName('/php/func_ksort')}>
                                                <span className='ml-30'>ksort()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_krsort' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_krsort" onClick={() => setPathName('/php/func_krsort')}>
                                                <span className='ml-30'>krsort()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_asort' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_asort" onClick={() => setPathName('/php/func_asort')}>
                                                <span className='ml-30'>asort()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_arsort' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_arsort" onClick={() => setPathName('/php/func_arsort')}>
                                                <span className='ml-30'>arsort()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className='subMenuList'>
                                    <Link to="/php/func_cal_days_in_month" onClick={(e) => OpenShowSubMenu(e, '/php/func_cal_days_in_month')}>PHP Calender
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_cal_days_in_month' || pathName == '/php/func_cal_from_jd' || pathname == '/php/func_cal_info' || pathName == '/php/func_cal_to_jd' || pathname == '/php/func_easter_date' || pathName == '/php/func_easter_days') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_cal_days_in_month' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_cal_days_in_month" onClick={() => setPathName('/php/func_cal_days_in_month')}>
                                                <span className='ml-30'>cal_days_in_month()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_cal_from_jd' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_cal_from_jd" onClick={() => setPathName('/php/func_cal_from_jd')}>
                                                <span className='ml-30'>cal_from_jd()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_cal_info' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_cal_info" onClick={() => setPathName('/php/func_cal_info')}>
                                                <span className='ml-30'>cal_info()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_cal_to_jd' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_cal_to_jd" onClick={() => setPathName('/php/func_cal_to_jd')}>
                                                <span className='ml-30'>cal_to_jd()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_easter_date' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_easter_date" onClick={() => setPathName('/php/func_easter_date')}>
                                                <span className='ml-30'>easter_date()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_easter_days' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_easter_days" onClick={() => setPathName('/php/func_easter_days')}>
                                                <span className='ml-30'>easter_days()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_checkdate" onClick={(e) => OpenShowSubMenu(e, '/php/func_checkdate')}>PHP Date
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_checkdate') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_checkdate' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_checkdate" onClick={() => setPathName('/php/func_checkdate')}>
                                                <span className='ml-30'>checkdate()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_add' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_add" onClick={() => setPathName('/php/func_date_add')}>
                                                <span className='ml-30'>date_add()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_create_from_format' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_create_from_format" onClick={() => setPathName('/php/func_date_create_from_format')}>
                                                <span className='ml-30'>date_create_from_format()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_create' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_create" onClick={() => setPathName('/php/func_date_create')}>
                                                <span className='ml-30'>date_create()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_default_timezone_get' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_default_timezone_get" onClick={() => setPathName('/php/func_date_default_timezone_get')}>
                                                <span className='ml-30'>date_default_timezone_get()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_default_timezone_set' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_default_timezone_set" onClick={() => setPathName('/php/func_date_default_timezone_set')}>
                                                <span className='ml-30'>date_default_timezone_set()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_diff' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_diff" onClick={() => setPathName('/php/func_date_diff')}>
                                                <span className='ml-30'>date_diff()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_format' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_format" onClick={() => setPathName('/php/func_date_format')}>
                                                <span className='ml-30'>date_format()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_modify' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_modify" onClick={() => setPathName('/php/func_date_modify')}>
                                                <span className='ml-30'>date_modify()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_parse' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_parse" onClick={() => setPathName('/php/func_date_parse')}>
                                                <span className='ml-30'>date_parse()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_sub' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_sub" onClick={() => setPathName('/php/func_date_sub')}>
                                                <span className='ml-30'>date_sub()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_time_set' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_time_set" onClick={() => setPathName('/php/func_date_time_set')}>
                                                <span className='ml-30'>date_time_set()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_timezone_set' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_timezone_set" onClick={() => setPathName('/php/func_date_timezone_set')}>
                                                <span className='ml-30'>date_timezone_set()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_timezone_get' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_timezone_get" onClick={() => setPathName('/php/func_date_timezone_get')}>
                                                <span className='ml-30'>date_timezone_get()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_timestamp_set' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_timestamp_set" onClick={() => setPathName('/php/func_date_timestamp_set')}>
                                                <span className='ml-30'>date_timestamp_set()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date_timestamp_get' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date_timestamp_get" onClick={() => setPathName('/php/func_date_timestamp_get')}>
                                                <span className='ml-30'>date_timestamp_get()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_date' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_date" onClick={() => setPathName('/php/func_date')}>
                                                <span className='ml-30'>date()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getdate' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getdate" onClick={() => setPathName('/php/func_getdate')}>
                                                <span className='ml-30'>getdate()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_gettimeofday' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_gettimeofday" onClick={() => setPathName('/php/func_gettimeofday')}>
                                                <span className='ml-30'>gettimeofday()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_gmdate' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_gmdate" onClick={() => setPathName('/php/func_gmdate')}>
                                                <span className='ml-30'>gmdate()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_localtime' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_localtime" onClick={() => setPathName('/php/func_localtime')}>
                                                <span className='ml-30'>localtime()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strtotime' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strtotime" onClick={() => setPathName('/php/func_strtotime')}>
                                                <span className='ml-30'>strtotime()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_time' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_time" onClick={() => setPathName('/php/func_time')}>
                                                <span className='ml-30'>time()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}
                                <li className='subMenuList'>
                                    <Link to="/php/func_chdir" onClick={(e) => OpenShowSubMenu(e, '/php/func_chdir')}>PHP Directory
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_chdir' || pathname == '/php/func_chroot' || pathname == '/php/func_closedir' || pathname == '/php/func_dir' || pathname == '/php/func_getcwd' || pathname == '/php/func_opendir' || pathname == '/php/func_readdir' || pathname == '/php/func_rewinddir' || pathname == '/php/func_scandir') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_chdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_chdir" onClick={() => setPathName('/php/func_chdir')}>
                                                <span className='ml-30'>chdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_chroot' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_chroot" onClick={() => setPathName('/php/func_chroot')}>
                                                <span className='ml-30'>chroot()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_closedir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_closedir" onClick={() => setPathName('/php/func_closedir')}>
                                                <span className='ml-30'>closedir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_dir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_dir" onClick={() => setPathName('/php/func_dir')}>
                                                <span className='ml-30'>dir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getcwd' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getcwd" onClick={() => setPathName('/php/func_getcwd')}>
                                                <span className='ml-30'>getcwd()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_opendir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_opendir" onClick={() => setPathName('/php/func_opendir')}>
                                                <span className='ml-30'>opendir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_readdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_readdir" onClick={() => setPathName('/php/func_readdir')}>
                                                <span className='ml-30'>readdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rewinddir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rewinddir" onClick={() => setPathName('/php/func_rewinddir')}>
                                                <span className='ml-30'>rewinddir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_scandir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_scandir" onClick={() => setPathName('/php/func_scandir')}>
                                                <span className='ml-30'>scandir()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_debug_backtrace" onClick={(e) => OpenShowSubMenu(e, '/php/func_debug_backtrace')}>PHP Error
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_debug_backtrace' || pathname == '/php/func_debug_print_backtrace' || pathname == '/php/func_error_get_last' || pathname == '/php/func_error_log' || pathname == '/php/func_error_reporting' || pathname == '/php/func_restore_error_handler' || pathname == '/php/func_restore_exception_handler' || pathname == '/php/func_set_error_handler' || pathname == '/php/func_set_exception_handler' || pathname == '/php/func_trigger_error') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_debug_backtrace' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_debug_backtrace" onClick={() => setPathName('/php/func_debug_backtrace')}>
                                                <span className='ml-30'>debug_backtrace()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_debug_print_backtrace' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_debug_print_backtrace" onClick={() => setPathName('/php/func_debug_print_backtrace')}>
                                                <span className='ml-30'>debug_print_backtrace()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_error_get_last' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_error_get_last" onClick={() => setPathName('/php/func_error_get_last')}>
                                                <span className='ml-30'>error_get_last()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_error_log' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_error_log" onClick={() => setPathName('/php/func_error_log')}>
                                                <span className='ml-30'>error_log()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_error_reporting' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_error_reporting" onClick={() => setPathName('/php/func_error_reporting')}>
                                                <span className='ml-30'>error_reporting()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_restore_error_handler' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_restore_error_handler" onClick={() => setPathName('/php/func_restore_error_handler')}>
                                                <span className='ml-30'>restore_error_handler()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_restore_exception_handler' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_restore_exception_handler" onClick={() => setPathName('/php/func_restore_exception_handler')}>
                                                <span className='ml-30'>restore_exception_handler()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_set_error_handler' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_set_error_handler" onClick={() => setPathName('/php/func_set_error_handler')}>
                                                <span className='ml-30'>set_error_handler()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_set_exception_handler' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_set_exception_handler" onClick={() => setPathName('/php/func_set_exception_handler')}>
                                                <span className='ml-30'>set_exception_handler()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_trigger_error' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_trigger_error" onClick={() => setPathName('/php/func_trigger_error')}>
                                                <span className='ml-30'>trigger_error()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_exception" onClick={(e) => OpenShowSubMenu(e, '/php/func_exception')}>PHP Exception
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_exception' || pathname == '/php/func_getCode' || pathname == '/php/func_getFile' || pathname == '/php/func_getMessage' || pathname == '/php/func_getLine' || pathname == '/php/func_getPrevious' || pathname == '/php/func_getTrace' || pathname == '/php/func_getTraceAsString') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_exception' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_exception" onClick={() => setPathName('/php/func_exception')}>
                                                <span className='ml-30'>Exception()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getCode' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getCode" onClick={() => setPathName('/php/func_getCode')}>
                                                <span className='ml-30'>getCode()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getFile' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getFile" onClick={() => setPathName('/php/func_getFile')}>
                                                <span className='ml-30'>getFile()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getMessage' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getMessage" onClick={() => setPathName('/php/func_getMessage')}>
                                                <span className='ml-30'>getMessage()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getLine' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getLine" onClick={() => setPathName('/php/func_getLine')}>
                                                <span className='ml-30'>getLine()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getPrevious' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getPrevious" onClick={() => setPathName('/php/func_getPrevious')}>
                                                <span className='ml-30'>getPrevious()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getTrace' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getTrace" onClick={() => setPathName('/php/func_getTrace')}>
                                                <span className='ml-30'>getTrace()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_getTraceAsString' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_getTraceAsString" onClick={() => setPathName('/php/func_getTraceAsString')}>
                                                <span className='ml-30'>getTraceAsString()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_fopen" onClick={(e) => OpenShowSubMenu(e, '/php/func_fopen')}>PHP Filesystem
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_fopen' || pathname == '/php/func_fread' || pathname == '/php/func_fwrite' || pathname == '/php/func_fclose' || pathname == '/php/func_file_get_contents' || pathname == '/php/func_file_put_contents' || pathname == '/php/func_file_exists' || pathname == '/php/func_unlink' || pathname == '/php/func_copy' || pathname == '/php/func_rename' || pathname == '/php/func_is_file' || pathname == '/php/func_is_dir' || pathname == '/php/func_mkdir' || pathname == '/php/func_rmdir' || pathname == '/php/func_php_scandir' || pathname == '/php/func_chmod') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_fopen' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_fopen" onClick={() => setPathName('/php/func_fopen')}>
                                                <span className='ml-30'>fopen()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_fread' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_fread" onClick={() => setPathName('/php/func_fread')}>
                                                <span className='ml-30'>fread()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_fwrite' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_fwrite" onClick={() => setPathName('/php/func_fwrite')}>
                                                <span className='ml-30'>fwrite()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_fclose' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_fclose" onClick={() => setPathName('/php/func_fclose')}>
                                                <span className='ml-30'>fclose()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_file_get_contents' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_file_get_contents" onClick={() => setPathName('/php/func_file_get_contents')}>
                                                <span className='ml-30'>file_get_contents()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_file_put_contents' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_file_put_contents" onClick={() => setPathName('/php/func_file_put_contents')}>
                                                <span className='ml-30'>file_put_contents()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_file_exists' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_file_exists" onClick={() => setPathName('/php/func_file_exists')}>
                                                <span className='ml-30'>file_exists()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_unlink' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_unlink" onClick={() => setPathName('/php/func_unlink')}>
                                                <span className='ml-30'>unlink()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_copy' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_copy" onClick={() => setPathName('/php/func_copy')}>
                                                <span className='ml-30'>copy()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rename' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rename" onClick={() => setPathName('/php/func_rename')}>
                                                <span className='ml-30'>rename()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_is_file' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_is_file" onClick={() => setPathName('/php/func_is_file')}>
                                                <span className='ml-30'>is_file()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_is_dir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_is_dir" onClick={() => setPathName('/php/func_is_dir')}>
                                                <span className='ml-30'>is_dir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_mkdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_mkdir" onClick={() => setPathName('/php/func_mkdir')}>
                                                <span className='ml-30'>mkdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rmdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rmdir" onClick={() => setPathName('/php/func_rmdir')}>
                                                <span className='ml-30'>rmdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_php_scandir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_php_scandir" onClick={() => setPathName('/php/func_php_scandir')}>
                                                <span className='ml-30'>scandir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_chmod' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_chmod" onClick={() => setPathName('/php/func_chmod')}>
                                                <span className='ml-30'>chmod()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_filter_has_var" onClick={(e) => OpenShowSubMenu(e, '/php/func_filter_has_var')}>PHP Filter
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_filter_has_var' || pathname == '/php/func_filter_id' || pathname == '/php/func_filter_input' || pathname == '/php/func_filter_input_array' || pathname == '/php/func_filter_list' || pathname == '/php/func_filter_var' || pathname == '/php/func_filter_var_array') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_filter_has_var' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_has_var" onClick={() => setPathName('/php/func_filter_has_var')}>
                                                <span className='ml-30'>filter_has_var()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_id' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_id" onClick={() => setPathName('/php/func_filter_id')}>
                                                <span className='ml-30'>filter_id()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_input' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_input" onClick={() => setPathName('/php/func_filter_input')}>
                                                <span className='ml-30'>filter_input()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_input_array' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_input_array" onClick={() => setPathName('/php/func_filter_input_array')}>
                                                <span className='ml-30'>filter_input_array()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_list' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_list" onClick={() => setPathName('/php/func_filter_list')}>
                                                <span className='ml-30'>filter_list()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_var' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_var" onClick={() => setPathName('/php/func_filter_var')}>
                                                <span className='ml-30'>filter_var()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_filter_var_array' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_filter_var_array" onClick={() => setPathName('/php/func_filter_var_array')}>
                                                <span className='ml-30'>filter_var_array()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_ftp_connect" onClick={(e) => OpenShowSubMenu(e, '/php/func_ftp_connect')}>PHP FTP
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_ftp_connect' || pathname == '/php/func_ftp_login' || pathname == '/php/func_ftp_close' || pathname == '/php/func_ftp_put' || pathname == '/php/func_ftp_get' || pathname == '/php/func_ftp_delete' || pathname == '/php/func_ftp_mkdir' || pathname == '/php/func_ftp_rmdir' || pathname == '/php/func_ftp_chdir' || pathname == '/php/func_ftp_pwd' || pathname == '/php/func_ftp_nlist' || pathname == '/php/func_ftp_rename' || pathname == '/php/func_ftp_size' || pathname == '/php/func_ftp_chmod' || pathname == '/php/func_ftp_fput')  ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_ftp_connect' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_connect" onClick={() => setPathName('/php/func_ftp_connect')}>
                                                <span className='ml-30'>ftp_connect()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_login' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_login" onClick={() => setPathName('/php/func_ftp_login')}>
                                                <span className='ml-30'>ftp_login()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_close' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_close" onClick={() => setPathName('/php/func_ftp_close')}>
                                                <span className='ml-30'>ftp_close()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_put' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_put" onClick={() => setPathName('/php/func_ftp_put')}>
                                                <span className='ml-30'>ftp_put()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_get' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_get" onClick={() => setPathName('/php/func_ftp_get')}>
                                                <span className='ml-30'>ftp_get()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_delete' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_delete" onClick={() => setPathName('/php/func_ftp_delete')}>
                                                <span className='ml-30'>ftp_delete()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_mkdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_mkdir" onClick={() => setPathName('/php/func_ftp_mkdir')}>
                                                <span className='ml-30'>ftp_mkdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_rmdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_rmdir" onClick={() => setPathName('/php/func_ftp_rmdir')}>
                                                <span className='ml-30'>ftp_rmdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_chdir' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_chdir" onClick={() => setPathName('/php/func_ftp_chdir')}>
                                                <span className='ml-30'>ftp_chdir()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_pwd' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_pwd" onClick={() => setPathName('/php/func_ftp_pwd')}>
                                                <span className='ml-30'>ftp_pwd()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_nlist' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_nlist" onClick={() => setPathName('/php/func_ftp_nlist')}>
                                                <span className='ml-30'>ftp_nlist()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_rename' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_rename" onClick={() => setPathName('/php/func_ftp_rename')}>
                                                <span className='ml-30'>ftp_rename()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_size' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_size" onClick={() => setPathName('/php/func_ftp_size')}>
                                                <span className='ml-30'>ftp_size()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ftp_chmod' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ftp_chmod" onClick={() => setPathName('/php/func_ftp_chmod')}>
                                                <span className='ml-30'>ftp_chmod()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_json_decode" onClick={(e) => OpenShowSubMenu(e, '/php/func_json_decode')}>PHP JSON
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_json_decode' || pathname == '/php/func_json_encode') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_json_decode' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_json_decode" onClick={() => setPathName('/php/func_json_decode')}>
                                                <span className='ml-30'>json_decode()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_json_encode' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_json_encode" onClick={() => setPathName('/php/func_json_encode')}>
                                                <span className='ml-30'>json_encode()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/php/mail' ? 'activeMenu' : ''}>
                                    <Link to="/php/mail" onClick={() => { setPathName('/php/mail'); } }>PHP Mail</Link>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_abs" onClick={(e) => OpenShowSubMenu(e, '/php/func_abs')}>PHP Math
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_abs' || pathname == '/php/func_ceil' || pathname == '/php/func_floor' || pathname == '/php/func_round' || pathname == '/php/func_max' || pathname == '/php/func_min' || pathname == '/php/func_pow' || pathname == '/php/func_sqrt' || pathname == '/php/func_rand' || pathname == '/php/func_mt_rand' || pathname == '/php/func_fmod' || pathname == '/php/func_pi' || pathname == '/php/func_exp' || pathname == '/php/func_log' || pathname == '/php/func_log10' || pathname == '/php/func_sin' || pathname == '/php/func_cos' || pathname == '/php/func_tan' || pathname == '/php/func_deg2rad' || pathname == '/php/func_rad2deg') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_abs' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_abs" onClick={() => setPathName('/php/func_abs')}>
                                                <span className='ml-30'>abs()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ceil' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ceil" onClick={() => setPathName('/php/func_ceil')}>
                                                <span className='ml-30'>ceil()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_floor' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_floor" onClick={() => setPathName('/php/func_floor')}>
                                                <span className='ml-30'>floor()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_round' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_round" onClick={() => setPathName('/php/func_round')}>
                                                <span className='ml-30'>round()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_max' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_max" onClick={() => setPathName('/php/func_max')}>
                                                <span className='ml-30'>max()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_min' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_min" onClick={() => setPathName('/php/func_min')}>
                                                <span className='ml-30'>min()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_pow' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_pow" onClick={() => setPathName('/php/func_pow')}>
                                                <span className='ml-30'>pow()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_sqrt' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_sqrt" onClick={() => setPathName('/php/func_sqrt')}>
                                                <span className='ml-30'>sqrt()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rand" onClick={() => setPathName('/php/func_rand')}>
                                                <span className='ml-30'>rand()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_mt_rand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_mt_rand" onClick={() => setPathName('/php/func_mt_rand')}>
                                                <span className='ml-30'>mt_rand()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_fmod' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_fmod" onClick={() => setPathName('/php/func_fmod')}>
                                                <span className='ml-30'>fmod()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_pi' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_pi" onClick={() => setPathName('/php/func_pi')}>
                                                <span className='ml-30'>pi()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_exp' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_exp" onClick={() => setPathName('/php/func_exp')}>
                                                <span className='ml-30'>exp()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_log' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_log" onClick={() => setPathName('/php/func_log')}>
                                                <span className='ml-30'>log()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_sin' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_sin" onClick={() => setPathName('/php/func_sin')}>
                                                <span className='ml-30'>sin()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_cos' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_cos" onClick={() => setPathName('/php/func_cos')}>
                                                <span className='ml-30'>cos()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_tan' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_tan" onClick={() => setPathName('/php/func_tan')}>
                                                <span className='ml-30'>tan()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_deg2rad' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_deg2rad" onClick={() => setPathName('/php/func_deg2rad')}>
                                                <span className='ml-30'>deg2rad()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rad2deg' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rad2deg" onClick={() => setPathName('/php/func_rad2deg')}>
                                                <span className='ml-30'>rad2deg()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/php/simple-xml' ? 'activeMenu' : ''}>
                                    <Link to="/php/simple-xml" onClick={() => { setPathName('/php/simple-xml'); } }>PHP SimpleXML</Link>
                                </li>
                                <li className='subMenuList'>
                                    <Link to="/php/func_strlen" onClick={(e) => OpenShowSubMenu(e, '/php/func_strlen')}>PHP String
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/php/func_strlen' || pathname == '/php/func_strtolower' || pathname == '/php/func_strtoupper' || pathname == '/php/func_ucfirst' || pathname == '/php/func_ucwords' || pathname == '/php/func_trim' || pathname == '/php/func_ltrim' || pathname == '/php/func_rtrim' || pathname == '/php/func_explode' || pathname == '/php/func_implode' || pathname == '/php/func_str_replace' || pathname == '/php/func_substr' || pathname == '/php/func_strpos' || pathname == '/php/func_strrpos' || pathname == '/php/func_strstr' || pathname == '/php/func_strrev' || pathname == '/php/func_number_format' || pathname == '/php/func_strcmp' || pathname == '/php/func_strcasecmp' || pathname == '/php/func_htmlspecialchars' || pathname == '/php/func_addslashes' || pathname == '/php/func_str_repeat' || pathname == '/php/func_wordwrap' || pathname == '/php/func_str_pad' || pathname == '/php/func_str_split') ? 'block' : 'none' }}>

                                        <li className={pathname == '/php/func_strlen' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strlen" onClick={() => setPathName('/php/func_strlen')}>
                                                <span className='ml-30'>strlen()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strtolower' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strtolower" onClick={() => setPathName('/php/func_strtolower')}>
                                                <span className='ml-30'>strtolower()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strtoupper' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strtoupper" onClick={() => setPathName('/php/func_strtoupper')}>
                                                <span className='ml-30'>strtoupper()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ucfirst' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ucfirst" onClick={() => setPathName('/php/func_ucfirst')}>
                                                <span className='ml-30'>ucfirst()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ucwords' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ucwords" onClick={() => setPathName('/php/func_ucwords')}>
                                                <span className='ml-30'>ucwords()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_trim' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_trim" onClick={() => setPathName('/php/func_trim')}>
                                                <span className='ml-30'>trim()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_ltrim' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_ltrim" onClick={() => setPathName('/php/func_ltrim')}>
                                                <span className='ml-30'>ltrim()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_rtrim' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_rtrim" onClick={() => setPathName('/php/func_rtrim')}>
                                                <span className='ml-30'>rtrim()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_explode' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_explode" onClick={() => setPathName('/php/func_explode')}>
                                                <span className='ml-30'>explode()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_implode' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_implode" onClick={() => setPathName('/php/func_implode')}>
                                                <span className='ml-30'>implode()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_str_replace' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_str_replace" onClick={() => setPathName('/php/func_str_replace')}>
                                                <span className='ml-30'>str_replace()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_substr' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_substr" onClick={() => setPathName('/php/func_substr')}>
                                                <span className='ml-30'>substr()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strpos' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strpos" onClick={() => setPathName('/php/func_strpos')}>
                                                <span className='ml-30'>strpos()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strrpos' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strrpos" onClick={() => setPathName('/php/func_strrpos')}>
                                                <span className='ml-30'>strrpos()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strstr' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strstr" onClick={() => setPathName('/php/func_strstr')}>
                                                <span className='ml-30'>strstr()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strrev' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strrev" onClick={() => setPathName('/php/func_strrev')}>
                                                <span className='ml-30'>strrev()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_number_format' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_number_format" onClick={() => setPathName('/php/func_number_format')}>
                                                <span className='ml-30'>number_format()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strcmp' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strcmp" onClick={() => setPathName('/php/func_strcmp')}>
                                                <span className='ml-30'>strcmp()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_strcasecmp' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_strcasecmp" onClick={() => setPathName('/php/func_strcasecmp')}>
                                                <span className='ml-30'>strcasecmp()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_htmlspecialchars' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_htmlspecialchars" onClick={() => setPathName('/php/func_htmlspecialchars')}>
                                                <span className='ml-30'>htmlspecialchars()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_addslashes' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_addslashes" onClick={() => setPathName('/php/func_addslashes')}>
                                                <span className='ml-30'>addslashes()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_str_repeat' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_str_repeat" onClick={() => setPathName('/php/func_str_repeat')}>
                                                <span className='ml-30'>str_repeat()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_wordwrap' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_wordwrap" onClick={() => setPathName('/php/func_wordwrap')}>
                                                <span className='ml-30'>wordwrap()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_str_pad' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_str_pad" onClick={() => setPathName('/php/func_str_pad')}>
                                                <span className='ml-30'>str_pad()</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/php/func_str_split' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/php/func_str_split" onClick={() => setPathName('/php/func_str_split')}>
                                                <span className='ml-30'>str_split()</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/php/xml-parser' ? 'activeMenu' : ''}>
                                    <Link to="/php/xml-parser" onClick={() => { setPathName('/php/xml-parser'); } }>PHP XML Parser</Link>
                                </li>
                                <li className={pathname == '/php/regular-expressions' ? 'activeMenu' : ''}>
                                    <Link to="/php/regular-expressions" onClick={() => { setPathName('/php/regular-expressions'); } }>Regular Expressions</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP Security
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/input/validation' ? 'activeMenu' : ''}>
                                    <Link to="/php/input/validation" onClick={() => { setPathName('/php/input/validation'); } }>Input Validation</Link>
                                </li>
                                <li className={pathname == '/php/password/hashing' ? 'activeMenu' : ''}>
                                    <Link to="/php/password/hashing" onClick={() => { setPathName('/php/password/hashing'); } }>Password Hashing</Link>
                                </li>
                                <li className={pathname == '/php/session/security' ? 'activeMenu' : ''}>
                                    <Link to="/php/session/security" onClick={() => { setPathName('/php/session/security'); } }>Session Security</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            PHP Frameworks
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/php/frameworks' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks" onClick={() => { setPathName('/php/frameworks'); } }>Introduction to PHP Frameworks</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/laravel' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/laravel" onClick={() => { setPathName('/php/frameworks/laravel'); } }>Getting Started with Laravel</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/symphony' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/symphony" onClick={() => { setPathName('/php/frameworks/symphony'); } }>Getting Started with Symphony</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/codeigniter' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/codeigniter" onClick={() => { setPathName('/php/frameworks/codeigniter'); } }>Getting Started with Codeigniter</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/yii' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/yii" onClick={() => { setPathName('/php/frameworks/yii'); } }>Getting Started with Yii</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/cakephp' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/cakephp" onClick={() => { setPathName('/php/frameworks/cakephp'); } }>Getting Started with Cakephp</Link>
                                </li>
                                <li className={pathname == '/php/frameworks/phalcon' ? 'activeMenu' : ''}>
                                    <Link to="/php/frameworks/phalcon" onClick={() => { setPathName('/php/frameworks/phalcon'); } }>Getting Started with Phalcon</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins className="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}