import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_var_array";

export default function FilterVarArray() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_var_array() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_var',
            'next': '/php/func_ftp_connect'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_var_array() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_var_array()</span> function in PHP is used to filter multiple variables in an array. It allows you to apply specific validation or sanitization filters to each element of the array. This is particularly useful when working with form inputs or other data sets where you need to validate or sanitize multiple fields at once.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_var_array</span>(array, definition, add_empty)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>array</strong>: (Required) The array of data that you want to filter. Each element in the array corresponds to a value that you want to validate or sanitize.</li>
                    <li><strong>definition</strong>: (Optional) An associative array that defines the filters to apply to each field in the input array. The key in this array corresponds to the key in the input array, and the value defines the filter to apply.</li>
                    <li><strong>add_empty</strong>: (Optional) A boolean value (true or false). If set to <span className="background-grey">true</span>, the function will add missing keys in the output array and set their value to <span className="background-grey">null</span>. If <span className="background-grey">false</span>, missing keys will not be included in the result.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">filter_var_array()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Simulated form data</p>
                        <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(</p>
                        <p class="ml-60">        <span class="color-green">"name"</span> => <span class="color-green">"John Doe"</span>,</p>
                        <p class="ml-60">        <span class="color-green">"age"</span> => <span class="color-green">"28"</span>,</p>
                        <p class="ml-60">        <span class="color-green">"email"</span> => <span class="color-green">"john.doe@example.com"</span></p>
                        <p class="ml-30">    );</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Define the filters for each field</p>
                        <p class="ml-30">    <span class="color-pink">$filters</span> = <span class="color-red">array</span>(</p>
                        <p class="ml-60">        <span class="color-green">"name"</span> => FILTER_SANITIZE_STRING,</p>
                        <p class="ml-60">        <span class="color-green">"age"</span> => <span class="color-red">array</span>(</p>
                        <p class="ml-90">            <span class="color-green">"filter"</span> => FILTER_VALIDATE_INT,</p>
                        <p class="ml-90">            <span class="color-green">"options"</span> => <span class="color-red">array</span>(</p>
                        <p class="ml-120">                <span class="color-green">"min_range"</span> => <span class="color-pink">18</span>,</p>
                        <p class="ml-120">                <span class="color-green">"max_range"</span> => <span class="color-pink">65</span></p>
                        <p class="ml-90">            )</p>
                        <p class="ml-60">        ),</p>
                        <p class="ml-60">        <span class="color-green">"email"</span> => FILTER_VALIDATE_EMAIL</p>
                        <p class="ml-30">   );</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Apply the filters</p>
                        <p class="ml-30">    <span class="color-pink">$result</span> = <span class="color-red">filter_var_array</span>(<span class="color-pink">$data</span>, <span class="color-pink">$filters</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">"name"</span> field is sanitized using <span className="background-grey">FILTER_SANITIZE_STRING</span>, which removes harmful characters.</li>
                    <li>The <span className="background-grey">"age"</span> field is validated as an integer between 18 and 65 using <span className="background-grey">FILTER_VALIDATE_INT</span> with custom options.</li>
                    <li>The <span className="background-grey">"email"</span> field is validated using <span className="background-grey">FILTER_VALIDATE_EMAIL</span>.</li>
                </ul>
                <p>If any validation fails, the corresponding field in the output will return false.</p>
            </div>
        </section>
    )
}