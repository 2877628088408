import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_file_exists";

export default function FileExists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP file_exists() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_file_put_contents',
            'next': '/php/func_unlink'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP file_exists() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">file_exists()</span> function in PHP is used to check whether a file or directory exists. This function is useful for ensuring that a file or directory is present before performing actions such as reading, writing, or deleting. It returns a boolean value—<span className="background-grey">true</span> if the file or directory exists, and <span className="background-grey">false</span> otherwise.
                </p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">file_exists</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$filename</strong> (required) is the path to the file or directory you want to check. This can be a relative or absolute path.</p>
                <p>It returns <span className="background-grey">true</span> if the file or directory exists and returns <span className="background-grey">false</span> if the file or directory does not exist.</p>


                <h5 className="mt-5 mb-3">Example 1: Checking if a File Exists</h5>
                <p>In this example, we'll check whether a file named <span className="background-grey">example.txt</span> exists in the current directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filename</span> = <span class="color-green">'example.txt'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-pink">$filename</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The file $filename exists."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The file $filename does not exist."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2: Checking if a Directory Exists</h5>
                <p>You can also use <span className="background-grey">file_exists()</span> to check if a directory exists.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-green">'my_directory'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-pink">$directory</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The directory $directory exists."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The directory $directory does not exist."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>




            </div>
        </section>
    )
}