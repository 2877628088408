import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/font/style";

export default function FontStyle() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Font Style in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/font/fallbacks',
            'next': '/css/font/size'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Font Style in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`font-style`</strong> property in CSS is used to define the style of the font, primarily focusing on whether the text should be rendered in a normal, italic, or oblique style.
                </p>
                <h5 className="mt-5 mb-3">Different Examples of Font Style</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    p.normal {</p>
                            <p class="ml-60">        <span class="color-green">font-style</span>: normal;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.italic {</p>
                            <p class="ml-60">        <span class="color-green">font-style</span>: italic;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.oblique {</p>
                            <p class="ml-60">        <span class="color-green">font-style</span>: oblique;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.inherit {</p>
                            <p class="ml-60">        <span class="color-green">font-style</span>: inherit;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"normal"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"italic"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"oblique"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"inherit"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.normal {
                                    font-style: normal;
                                }
                                p.italic {
                                    font-style: italic;
                                }
                                p.oblique {
                                    font-style: oblique;
                                }
                                p.inherit {
                                    font-style: inherit;
                                }
                            </style>
                            <p class="normal">This is a paragraph.</p>
                            <p class="italic">This is a paragraph.</p>
                            <p class="oblique">This is a paragraph.</p>
                            <p class="inherit">This is a paragraph.</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Font Weight</h5>
                <p>
                    The <strong>`font-weight`</strong> property in CSS is used to specify the thickness or boldness of the text. It allows you to control how bold or light the text appears, making it an essential tool for emphasizing text and creating visual hierarchy.
                </p>
                <h5 className="mt-5 mb-3">Different Examples of Font Weight</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    p.normalWeight {</p>
                            <p class="ml-60">        <span class="color-green">font-weight</span>: normal;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.bold {</p>
                            <p class="ml-60">        <span class="color-green">font-weight</span>: bold;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.bolder {</p>
                            <p class="ml-60">        <span class="color-green">font-weight</span>: bolder;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.lighter {</p>
                            <p class="ml-60">        <span class="color-green">font-weight</span>: lighter;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.numberValue {</p>
                            <p class="ml-60">        <span class="color-green">font-weight</span>: 400;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"normalWeight"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"bold"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"bolder"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"lighter"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"numberValue"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.normalWeight {
                                    font-weight: normal;
                                }
                                p.bold {
                                    font-weight: bold;
                                }
                                p.bolder {
                                    font-weight: bolder;
                                }
                                p.lighter {
                                    font-weight: lighter;
                                }
                                p.numberValue {
                                    font-weight: 400;
                                }
                            
                            </style>
                            <p class="normalWeight">This is a paragraph.</p>
                            <p class="bold">This is a paragraph.</p>
                            <p class="bolder">This is a paragraph.</p>
                            <p class="lighter">This is a paragraph.</p>
                            <p class="numberValue">This is a paragraph.</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Font Variant</h5>
                <p>
                    The <strong>`font-variant`</strong> property in CSS allows you to control the variation of the text, typically focusing on features like small caps and numeric styles.
                </p>
                <h5 className="mt-5 mb-3">Different Examples of Font Variant</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    p.normal {</p>
                            <p class="ml-60">        <span class="color-green">font-variant</span>: normal;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p.smallcaps {</p>
                            <p class="ml-60">        <span class="color-green">font-variant</span>: small-caps;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"normal"</span>&gt;This Is A Example Of Font Variant.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"smallcaps"</span>&gt;This Is A Example Of Font Variant.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.normal {
                                    font-variant: normal;
                                }
                                p.smallcaps {
                                    font-variant: small-caps;
                                }
                            </style>
                            <p class="normal">This Is A Example Of Font Variant.</p>
                            <p class="smallcaps">This Is A Example Of Font Variant.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}