import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getTrace";

export default function GetTrace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getTrace() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getPrevious',
            'next': '/php/func_getTraceAsString'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getTrace() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getTrace()</span> function in PHP's Exception class returns an array of backtrace information about where the exception was thrown. This information includes details such as the file, line number, and function calls leading up to the exception.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getTrace</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getTrace()</strong> returns an array of the exception's backtrace information.</li>
                </ul>
                <p>The <span className="background-grey">getTrace()</span> method provides a detailed trace of the execution path that led to the thrown exception. It is useful for debugging, as it shows which files and functions were involved in the error.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getTrace()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">firstFunction</span>() {</p>
                        <p class="ml-60">        <span class="color-red">secondFunction</span>();</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">secondFunction</span>() {</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"An error occurred"</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-red">firstFunction</span>();</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-red">print_r</span>(<span class="color-pink">$e</span>-><span class="color-red">getTrace</span>());</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [file] => /path/to/your/file.php</p>
                        <p class="ml-90">            [line] => <span class="color-pink">7</span></p>
                        <p class="ml-90">            [function] => secondFunction</p>
                        <p class="ml-90">            [args] => Array</p>
                        <p class="ml-120">                (</p>
                        <p class="ml-120">                )</p>
                        <p class="ml-60">        )</p>
                        <br />
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [file] => /path/to/your/file.php</p>
                        <p class="ml-90">            [line] => <span class="color-pink">3</span></p>
                        <p class="ml-90">            [function] => firstFunction</p>
                        <p class="ml-90">            [args] => Array</p>
                        <p class="ml-120">                (</p>
                        <p class="ml-120">                )</p>
                        <p class="ml-60">        )</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">firstFunction()</span> calls <span className="background-grey">secondFunction()</span>, which throws an exception.</li>
                    <li>
                        The <span className="background-grey">getTrace()</span> method shows the path of execution leading up to the exception.
                        <ul style={{ listStyle: 'disc' }}>
                            <li>It indicates that <span className="background-grey">secondFunction()</span> was called in <span className="background-grey">firstFunction()</span>, which led to the error.</li>
                            <li>The trace includes details like the file and line number where the error occurred.</li>
                        </ul>
                    </li>
                </ul>


            </div>
        </section>
    )
}