import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/font/size";

export default function FontSize() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Font Size in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/font/style',
            'next': '/css/font/google'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Font Size in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The  <strong>`font-size`</strong> property in CSS is used to control the size of the text on a webpage. It is one of the fundamental aspects of typography, allowing you to adjust how large or small the text appears, which can significantly impact the readability and visual hierarchy of your content.
                </p>
                <p>
                    You can set font size by multiple types of value
                </p>
            </div>
        </section>
    )
}