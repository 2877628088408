import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_closedir";

export default function CloseDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP closedir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_chroot',
            'next': '/php/func_dir'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP closedir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">closedir()</span> function in PHP is used to close a directory handle that was opened with <span className="background-grey">opendir()</span>. After reading the contents of a directory using functions like <span className="background-grey">readdir()</span>, it’s a good practice to close the directory handle with <span className="background-grey">closedir()</span> to free up resources.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">closedir</span>(dir_handle)</p>
                        `
                    }}></div>
                </div>
                <p>Where, dir_handle is the directory handle returned by <span className="background-grey">opendir()</span>. If omitted, the function will close the last directory handle opened by <span className="background-grey">opendir()</span>.</p>
                <p>This function does not return any value.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">closedir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Open a directory handle</p>
                        <p class="ml-30">    <span class="color-pink">$dir</span> = <span class="color-red">opendir</span>(<span class="color-green">"/path/to/directory"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$dir</span>) {</p>
                        <p class="ml-60 color-grey">        // Read the directory content</p>
                        <p class="ml-60">        <span class="color-blue">while</span> ((<span class="color-pink">$file</span> = <span class="color-red">readdir</span>(<span class="color-pink">$dir</span>)) !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Filename: "</span> . <span class="color-pink">$file</span> . <span class="color-green">"&#92;n"</span>;
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Close the directory handle</p>
                        <p class="ml-60">        <span class="color-red">closedir</span>(<span class="color-pink">$dir</span>);</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">opendir()</span> function is used to open the directory <span className="background-grey">/path/to/directory</span>.</li>
                    <li>The <span className="background-grey">readdir()</span> function is used in a <span className="background-grey">while</span> loop to read all files and directories inside the opened directory. The loop continues until <span className="background-grey">readdir()</span> returns <span className="background-grey">false</span>.</li>
                    <li>After reading all the contents, the <span className="background-grey">closedir()</span> function is called to close the directory handle. This releases system resources associated with the directory.</li>
                </ul>


            </div>
        </section>
    )
}