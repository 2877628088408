import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_error_reporting";

export default function ErrorReporting() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP error_reporting() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_error_log',
            'next': '/php/func_restore_error_handler'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP error_reporting() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">error_reporting()</span> function in PHP is used to define which types of errors should be reported during script execution. It allows developers to control the level of error reporting, which can be useful for debugging and ensuring that only relevant error messages are displayed or logged.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">error_reporting</span>(level);</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>level</strong> specifies the error reporting level. It can be set using predefined constants such as <span className="background-grey">E_ALL</span>, <span className="background-grey">E_ERROR</span>, <span className="background-grey">E_WARNING</span>, etc., or a combination of these constants. If omitted, the function returns the current error reporting level.</p>


                <h5 className="mt-5 mb-3">Error Reporting Levels</h5>
                <p>PHP provides several constants that represent different levels of errors. These constants can be used individually or combined using the bitwise OR (<span className="background-grey">|</span>) operator to customize error reporting.</p>
                <p>Some of the commonly used error levels include:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">E_ERROR</span>: Fatal run-time errors. These are the most severe errors, and execution is halted.</li>
                    <li><span className="background-grey">E_WARNING</span>: Run-time warnings (non-fatal errors). The script continues execution even if this type of error occurs.</li>
                    <li><span className="background-grey">E_NOTICE</span>: Notices (minor errors). These are often generated by things that may work but are technically incorrect, such as using an undefined variable.</li>
                    <li><span className="background-grey">E_PARSE</span>: Compile-time parse errors. These occur when the script has a syntax error.</li>
                    <li><span className="background-grey">E_STRICT</span>: Run-time notices about best coding practices and code interoperability.</li>
                    <li><span className="background-grey">E_ALL</span>: All errors and warnings, except for E_STRICT. (In newer versions of PHP, E_STRICT is included in E_ALL.)</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Reporting All Errors</h5>
                <p>To report all types of errors, you can use the <span className="background-grey">E_ALL</span> constant:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Set error reporting to report all errors</p>
                        <p class="ml-30">    <span class="color-red">error_reporting</span>(E_ALL);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Example that generates a notice</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$undefined_variable</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, an undefined variable is used (<span className="background-grey">$undefined_variable</span>), which generates an <span className="background-grey">E_NOTICE</span> error. Since <span className="background-grey">E_ALL</span> is set, the notice will be displayed.</p>


                <h5 className="mt-5 mb-3">Using <span className="background-grey">php.ini</span> to Set Error Reporting</h5>
                <p>In addition to using <span className="background-grey">error_reporting()</span> in your script, you can also set the error reporting level globally in the <span className="background-grey">php.ini</span> configuration file. For example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">error_reporting</span> = E_ALL & ~E_NOTICE</p>
                        `
                    }}></div>
                </div>
                <p>This setting reports all errors except notices, which is a common configuration in production environments where you may want to ignore minor issues like undefined variables.</p>

                

            </div>
        </section>
    )
}