import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_diff_key";

export default function ArrayDiffKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_diff_key() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_diff_assoc',
            'next': '/php/func_array_fill'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_diff_key() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_diff_key() function in PHP compares the keys of two or more arrays and returns an array containing the keys from the first array that are not present in any of the other arrays. It only compares keys, not values, making it useful when you need to find differences in keys between arrays.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_diff_key</span>(<span class="color-blue">array</span> <span class="color-pink">$array1</span>, <span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array1</strong>: The first array whose keys are to be compared.</li>
                    <li><strong>$arrays</strong>: One or more arrays whose keys will be compared against the first array.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"d"</span> => <span class="color-green">"durian"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_key</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [a] => apple</p>
                            <p class="ml-30">    [c] => cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The keys of <span className='background-grey'>$array1</span> are <span className='background-grey'>"a"</span>, <span className='background-grey'>"b"</span>, and <span className='background-grey'>"c"</span>.</li>
                    <li>The keys of <span className='background-grey'>$array2</span> are <span className='background-grey'>"b"</span> and <span className='background-grey'>"d"</span>.</li>
                    <li>Since <span className='background-grey'>"b"</span> exists in both arrays, it is removed from the result.</li>
                    <li>The result contains <span className='background-grey'>"a" =&gt; "apple"</span> and <span className='background-grey'>"c" =&gt; "cherry"</span>, as their keys do not exist in <span className='background-grey'>$array2</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Comparing Multiple Arrays</h5>
                <p>You can compare multiple arrays by passing additional arrays as arguments.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"x"</span> => <span class="color-green">"red"</span>, <span class="color-green">"y"</span> => <span class="color-green">"green"</span>, <span class="color-green">"z"</span> => <span class="color-green">"blue"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"x"</span> => <span class="color-green">"yellow"</span>];</p>
                            <p><span class="color-pink">$array3</span> = [<span class="color-green">"y"</span> => <span class="color-green">"pink"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_key</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>, <span class="color-pink">$array3</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [z] => blue</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The keys <span className='background-grey'>"x"</span> and <span className='background-grey'>"y"</span> exist in <span className='background-grey'>$array2</span> and <span className='background-grey'>$array3</span>, respectively, so they are excluded from the result.</li>
                    <li>The key <span className='background-grey'>"z"</span> exists only in <span className='background-grey'>$array1</span>, so it is returned.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Numeric Keys</h5>
                <p>The function works with numeric keys as well:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-pink">1</span> => <span class="color-green">"apple"</span>, <span class="color-pink">2</span> => <span class="color-green">"banana"</span>, <span class="color-pink">3</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-pink">2</span> => <span class="color-green">"banana"</span>, <span class="color-pink">4</span> => <span class="color-green">"durian"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_diff_key</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The key <span className='background-grey'>2</span> exists in both arrays, so it is removed from the result.</li>
                    <li>The keys <span className='background-grey'>1</span> and <span className='background-grey'>3</span> exist only in <span className='background-grey'>$array1</span>, so they are returned.</li>
                </ul>
            </div>
        </section>
    )
}