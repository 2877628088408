import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/symphony";

export default function Symphony() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Symfony | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks/laravel',
            'next': '/php/frameworks/codeigniter'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Symfony</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with Symfony, a robust PHP framework for building web applications, involves several steps. Symfony is known for its flexibility, modularity, and extensive set of components. Here’s a step-by-step guide to help you set up and start working with Symfony.
                </p>

                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Ensure your environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.2.5 or higher</li>
                    <li>Composer (dependency manager for PHP)</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>


                <h5 className='mt-4 mb-3'>Step 2: Install Composer</h5>
                <p>Composer is essential for managing Symfony dependencies. You can download and install Composer from its <Link to="https://getcomposer.org/">official website</Link>.</p>


                <h5 className='mt-4 mb-3'>Step 3: Install Symfony CLI</h5>
                <p>Symfony provides a command-line tool (Symfony CLI) to manage your projects easily. Install it globally using the following command:</p>
                <p>For Linux or macOS:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>curl -sS https://get.symfony.com/cli/installer | bash</p>
                        `
                    }}></div>
                </div>
                <p>For Windows, you can download the installer from the <Link to="https://symfony.com/">Symfony website</Link>.</p>



                <h5 className='mt-4 mb-3'>Step 4: Create a New Symfony Project</h5>
                <p>Use the Symfony CLI to create a new project. Open your terminal and run:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>symfony new project-name --full</p>
                        `
                    }}></div>
                </div>
                <p>This command creates a new Symfony project with all the required files and directories.</p>


                <h5 className='mt-4 mb-3'>Step 5: Navigate to Your Project Directory</h5>
                <p>Change into your project directory</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cd project-name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 6: Set Up Your Environment</h5>
                <p>Symfony uses an <span class="background-grey">.env</span> file for environment configuration. Open the <span class="background-grey">.env</span> file in your project and set your database connection parameters:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>DATABASE_URL=mysql://username:password@127.0.0.1:3306/db_name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 7: Install Database Driver</h5>
                <p>If you're using MySQL, install the database driver by running:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer require doctrine/doctrine-bundle</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 8: Create the Database</h5>
                <p>Create the database defined in your <span class="background-grey">.env</span> file using the Doctrine command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php bin/console doctrine:database:create</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 9: Create Your First Entity</h5>
                <p>Create a new entity (e.g., <span class="background-grey">Product</span>) using the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php bin/console make:entity Product</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 10: Run Migrations</h5>
                <p>After defining your entity, you can create a migration to set up the database table:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php bin/console make:migration</p>
                        `
                    }}></div>
                </div>
                <p>Then, execute the migration:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php bin/console doctrine:migrations:migrate</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 11: Start the Development Server</h5>
                <p>To start the built-in Symfony server, run:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>symfony serve</p>
                        `
                    }}></div>
                </div>
                <p>Your application will be accessible at <span class="background-grey">http://localhost:8000</span>.</p>


                <h5 className='mt-4 mb-3'>Step 12: Create Your First Route</h5>
                <p>Open the <span class="background-grey">config/routes/annotations.yaml</span> file and add a new route to your controller:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">App\Controller\DefaultController:</span></p>
                            <p><span class="color-pink">resource</span>: <span class="color-green">'../src/Controller/'</span></p>
                            <p><span class="color-pink">type</span>: <span class="color-green">annotation</span></p>
                        `
                    }}></div>
                </div>
                <p>Then, create a new controller file in <span class="background-grey">src/Controller/</span> (e.g., <span class="background-grey">DefaultController.php</span>):</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">namespace</span> <span class="color-red">App</span>&#92;<span class="color-red">Controller</span>;</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">Symfony</span>&#92;<span class="color-red">Bundle</span>&#92;<span class="color-red">FrameworkBundle</span>&#92;<span class="color-red">Controller</span>&#92;<span class="color-red">AbstractController</span>;</p>
                            <p><span class="color-blue">use</span> <span class="color-red">Symfony</span>&#92;<span class="color-red">Component</span>&#92;<span class="color-red">HttpFoundation</span>&#92;<span class="color-red">Response</span>;</p>
                            <p><span class="color-blue">use</span> <span class="color-red">Symfony</span>&#92;<span class="color-red">Component</span>&#92;<span class="color-red">Routing</span>&#92;<span class="color-red">Annotation</span>&#92;<span class="color-red">Route</span>;</p>
                            <br />
                            <p><span class="color-blue">class</span> <span class="color-yellow">DefaultController</span> <span class="color-blue">extends</span> <span class="color-yellow">AbstractController</span></p>
                            <p>{</p>
                            <p class="ml-30 color-grey">    /**</p>
                            <p class="ml-30 color-grey">     * @Route("/", name="homepage")</p>
                            <p class="ml-30 color-grey">     */</p>
                            <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">index</span>(): <span class="color-red">Response</span></p>
                            <p class="ml-30">    {</p>
                            <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>-><span class="color-red">render</span>(<span class="color-green">'index.html.twig'</span>, [<span class="color-green">'message'</span> => <span class="color-green">'Welcome to Symfony!'</span>]);</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 13: Create a View</h5>
                <p>Create a new Twig template in the <span class="background-grey">templates/</span> directory (e.g., <span class="background-grey">index.html.twig</span>):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">></span>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Welcome&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h1&gt;{{ message }}&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}