import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/file-uploads";

export default function FileUpload() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("File Uploads in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/writing-a-file',
            'next': '/php/php-with-mysql'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>File Uploads</h3>
            <div className='mt-4 mb-5'>
                <p>
                    File upload is a common task in web development, allowing users to upload files from their local machines to a server. PHP provides robust support for handling file uploads, making it easy to implement this functionality in your web applications.
                </p>

                <h5 className='mt-5 mb-3'><span class="background-grey">$_FILES</span> in PHP</h5>
                <p>
                    The <span class="background-grey">$_FILES</span> superglobal in PHP is an associative array that is automatically created when a file is uploaded via an HTML form. It contains information about the uploaded file, such as its name, type, size, and any error messages. This array is crucial for handling file uploads in PHP, as it allows you to access and manage the uploaded file.
                </p>
                <p>
                    When a file is uploaded, <span class="background-grey">$_FILES</span> is populated with the following information:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$_FILES</span>[<span class="color-green">'input_name'</span>][<span class="color-green">'name'</span>]     <span class="color-grey">// The original name of the file on the client's machine.</span></p>
                        <p><span class="color-pink">$_FILES</span>[<span class="color-green">'input_name'</span>][<span class="color-green">'type'</span>]     <span class="color-grey">// The MIME type of the uploaded file.</span></p>
                        <p><span class="color-pink">$_FILES</span>[<span class="color-green">'input_name'</span>][<span class="color-green">'tmp_name'</span>] <span class="color-grey">// The temporary filename of the file on the server.</span></p>
                        <p><span class="color-pink">$_FILES</span>[<span class="color-green">'input_name'</span>][<span class="color-green">'error'</span>]   <span class="color-grey"> // The error code associated with the file upload.</span></p>
                        <p><span class="color-pink">$_FILES</span>[<span class="color-green">'input_name'</span>][<span class="color-green">'size'</span>]     <span class="color-grey">// The size of the uploaded file in bytes.</span></p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>How File Upload Works in PHP</h5>
                <p>
                    When a user selects a file and submits the form, the file is sent to the server as part of the HTTP POST request. PHP handles this process behind the scenes, and the uploaded file is temporarily stored on the server until you decide what to do with it, such as moving it to a permanent directory.
                </p>

                <p>Let's create a simple form for upload a file -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"upload.php"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span> <span class="color-pink">enctype</span>=<span class="color-green">"multipart/form-data"</span>&gt;</p>
                            <p class="ml-30">    Select file to upload:</p>
                            <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"file"</span> <span class="color-pink">name</span>=<span class="color-green">"fileToUpload"</span> <span class="color-pink">id</span>=<span class="color-green">"fileToUpload"</span>&gt;</p>
                            <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">value</span>=<span class="color-green">"Upload File"</span> <span class="color-pink">name</span>=<span class="color-green">"submit"</span>&gt;</p>
                            <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Handling File Upload in PHP</h5>
                <p>
                    Once the user submits the form, the file is sent to the server, where the PHP script specified in the form's <span class="background-grey">action</span> attribute (<span class="background-grey">upload.php</span>) processes it.
                </p>
                <p>Here are the all steps, to upload a file in PHP.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <h6>Setting Up the Target Directory</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$target_dir</span> = <span class="color-green">"uploads/"</span>;</p>
                                    <p><span class="color-pink">$target_file</span> = <span class="color-pink">$target_dir</span> . <span class="color-red">basename</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"name"</span>]);</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">$target_dir</span>: Specifies the directory where the uploaded file will be saved. In this example, the directory is <span class="background-grey">uploads/</span>.</li>
                            <li><span class="background-grey">$target_file</span>: Combines the target directory with the filename to create the full path where the file will be stored.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <h6>Checking if the File is an Image</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$check</span> = <span class="color-red">getimagesize</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"tmp_name"</span>]);</p>
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$check</span> !== <span class="color-blue">false</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is an image - "</span> . <span class="color-pink">$check</span>[<span class="color-green">"mime"</span>] . ".";</p>
                                    <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">1</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is not an image."</span>;</p>
                                    <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">getimagesize()</span>: This function checks whether the uploaded file is actually an image. It returns <span class="background-grey">false</span> if the file is not an image.</li>
                            <li><span class="background-grey">$_FILES["fileToUpload"]["tmp_name"]</span>: Refers to the temporary file stored on the server during the upload process.</li>
                        </ul>
                    </li>
                    <li className='mt-4'>
                        <h6>Preventing File Overwrites</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-pink">$target_file</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Sorry, file already exists."</span>;</p>
                                    <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where <span class="background-grey">file_exists($target_file)</span> checks if a file with the same name already exists in the target directory. If it does, the script sets <span class="background-grey">$uploadOk</span> to 0 to prevent overwriting the existing file.</p>
                    </li>
                    <li className='mt-4'>
                        <h6>Limiting the File Size</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"size"</span>] > <span class="color-pink">500000</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Sorry, your file is too large."</span>;</p>
                                    <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where, <span class="background-grey">$_FILES["fileToUpload"]["size"]</span> checks the size of the uploaded file. In this example, files larger than 500KB are not allowed.</p>
                    </li>
                    <li className='mt-4'>
                        <h6>Allowing Specific File Formats</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$imageFileType</span> != <span class="color-green">"jpg"</span> && <span class="color-pink">$imageFileType</span> != <span class="color-green">"png"</span> && <span class="color-pink">$imageFileType</span> != <span class="color-green">"jpeg"</span> && <span class="color-pink">$imageFileType</span> != <span class="color-green">"gif"</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Sorry, only JPG, JPEG, PNG & GIF files are allowed."</span>;</p>
                                    <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where, <span class="background-grey">$imageFileType</span> retrieves the file extension and checks if it is one of the allowed formats (JPG, JPEG, PNG, GIF).</p>
                    </li>
                    <li className='mt-4'>
                        <h6>Moving the Uploaded File</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">move_uploaded_file</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"tmp_name"</span>], <span class="color-pink">$target_file</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The file "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-red">basename</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"name"</span>])) . <span class="color-green">" has been uploaded."</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Sorry, there was an error uploading your file."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where, <span class="background-grey">move_uploaded_file()</span> moves the uploaded file from its temporary location to the target directory. If successful, a success message is displayed; otherwise, an error message is shown.</p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Here is the full complete example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-pink">$target_dir</span> = <span class="color-green">"uploads/"</span>; <span class="color-grey">// Directory where the file will be saved</span></p>
                            <p class="ml-30">    <span class="color-pink">$target_file</span> = <span class="color-pink">$target_dir</span> . <span class="color-red">basename</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-pink">"name"</span>]); <span class="color-grey">// Full path of the uploaded file</span></p>
                            <p class="ml-30">    <span class="color-pink">$uploadOk</span> = <span class="color-pink">1</span>; <span class="color-grey">// Flag to check if upload should proceed</span></p>
                            <p class="ml-30">    <span class="color-pink">$imageFileType</span> = <span class="color-red">strtolower</span>(<span class="color-red">pathinfo</span>(<span class="color-pink">$target_file</span>, PATHINFO_EXTENSION)); <span class="color-grey">// Get the file extension</span></p>
                            <br />    
                            <p class="ml-30">    <span class="color-grey">// Check if the file is an actual image or a fake image</span></p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">isset</span>(<span class="color-pink">$_POST</span>[<span class="color-green">"submit"</span>])) {</p>
                            <p class="ml-60">        <span class="color-pink">$check</span> = <span class="color-red">getimagesize</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"tmp_name"</span>]);</p>
                            <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$check</span> !== <span class="color-blue">false</span>) {</p>
                            <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File is an image - "</span> . <span class="color-pink">$check</span>[<span class="color-green">"mime"</span>] . <span class="color-green">"."</span>;</p>
                            <p class="ml-90">            <span class="color-pink">$uploadOk</span> = <span class="color-pink">1</span>;</p>
                            <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                            <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File is not an image."</span>;</p>
                            <p class="ml-90">            <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                            <p class="ml-60">        }</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30">    <span class="color-grey">// Check if file already exists</span></p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-pink">$target_file</span>)) {</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Sorry, file already exists."</span>;</p>
                            <p class="ml-60">        <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30">    <span class="color-grey">// Check file size</span></p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"size"</span>] > <span class="color-pink">500000</span>) {</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Sorry, your file is too large."</span>;</p>
                            <p class="ml-60">        <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30">    <span class="color-grey">// Allow certain file formats</span></p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$imageFileType</span> != <span class="color-green">"jpg"</span> && <span class="color-pink">$imageFileType</span> != <span class="color-green">"png"</span> && <span class="color-pink">$imageFileType</span> != <span class="color-green">"jpeg"</span>
                                && <span class="color-pink">$imageFileType</span> != <span class="color-green">"gif"</span>) {</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Sorry, only JPG, JPEG, PNG & GIF files are allowed."</span>;</p>
                            <p class="ml-60">        <span class="color-pink">$uploadOk</span> = <span class="color-pink">0</span>;</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30">    <span class="color-grey">// Check if $uploadOk is set to 0 by an error</span></p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$uploadOk</span> == <span class="color-pink">0</span>) {</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Sorry, your file was not uploaded."</span>;</p>
                            <p class="ml-60">    <span class="color-grey">// if everything is ok, try to upload the file</span></p>
                            <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                            <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">move_uploaded_file</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"tmp_name"</span>], <span class="color-pink">$target_file</span>)) {</p>
                            <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"The file "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-red">basename</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">"fileToUpload"</span>][<span class="color-green">"name"</span>])) . <span class="color-green">" has been uploaded."</span>;</p>
                            <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                            <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Sorry, there was an error uploading your file."</span>;</p>
                            <p class="ml-60">        }</p>
                            <p class="ml-30">    }</p>
                            <p>?&gt;</p>
                        
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}