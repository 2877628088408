import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/get/post/methods";

export default function GetPostMethods() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("GET vs POST Methods in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/create/form',
            'next': '/php/form-handling'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>GET vs POST Methods</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">GET</span> and <span class="background-grey">POST</span> methods are two commonly used HTTP request methods in PHP for sending data from a client (like a web browser) to a server. They are used primarily in HTML forms to transfer form data to the server for processing.
                </p>
                <h5 className="mt-5 mb-3">GET Method</h5>
                <p>It sends data appended to the URL. It is suitable for non-sensitive data.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// process.php</p>
                            <p><span class="color-pink">$name</span> = <span class="color-pink">$_GET</span>[<span class="color-green">'name'</span>]; <span class="color-grey">// where 'name' is the input field name</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Hello, "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$name</span>);</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">POST Method</h5>
                <p>It sends data in the request body. It is suitable for sensitive data.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// process.php</p>
                            <p><span class="color-pink">$name</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'name'</span>]; <span class="color-grey">// where 'name' is the input field name</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Hello, "</span> . <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$name</span>);</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Difference between GET & POST Method</h5>
                <table className='table table-striped'>
                    <thead>
                        <th style={{width:'16%'}}></th>
                        <th style={{width:'47%'}}>GET</th>
                        <th style={{width:'47%'}}>POST</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Data Visibility</strong></td>
                            <td>
                                Here, data is visible in URL. Data is appended to the URL as query parameters. For example: http://example.com/form.php?name=John&age=30. Since data is visible in the URL, it’s less secure for sensitive information like passwords.
                            </td>
                            <td>
                                Data is sent in the body of the HTTP request, not in the URL. That's why more secure for sensitive data.
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Data Length</strong></td>
                            <td>
                                The length of the data is limited by the maximum URL length (about 2000 characters depending on the browser).
                            </td>
                            <td>
                                There is no restriction on data length (although server settings may impose a limit).
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Caching</strong></td>
                            <td>
                                Requests made with <span class="background-grey">GET</span> are often cached by browsers, which can improve performance for repeated requests.
                            </td>
                            <td>
                                Requests made with <span class="background-grey">POST</span> are not cached by default.
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Bookmarking</strong></td>
                            <td>
                                URLs with <span class="background-grey">GET</span> parameters can be bookmarked, allowing the user to return to the same page with the same parameters later.
                            </td>
                            <td>
                                Since data is not in the URL, the exact request cannot be bookmarked.
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Data Security</strong></td>
                            <td>
                                Because data is exposed in the URL, it’s less secure for transmitting sensitive information.
                            </td>
                            <td>
                                Data is transmitted in the request body, making it more secure for sensitive information like passwords and personal details.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}