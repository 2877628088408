import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_id";

export default function FilterId() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_id() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_has_var',
            'next': '/php/func_filter_input'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_id() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_id()</span> function in PHP is used to get the ID of a specified filter by its name. PHP has a set of built-in filters, each of which can be referred to by name or by an integer ID. The <span className="background-grey">filter_id()</span> function allows you to retrieve the numeric ID associated with a filter name, which can be useful when working with filter-related functions such as <span className="background-grey">filter_var()</span> or <span className="background-grey">filter_input()</span>. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_id</span>(filtername)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>filtername</strong> is the name of the filter as a string (e.g., "FILTER_VALIDATE_EMAIL"). It returns the <strong>filter ID</strong> (an integer) if the specified filter name exists and returns <span className="background-grey">false</span> if the filter name does not exist.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">filter_id()</span></h5>
                <p>Let's say you want to find the ID for the <span className="background-grey">FILTER_VALIDATE_EMAIL</span> filter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filterName</span> = <span class="color-green">"FILTER_VALIDATE_EMAIL"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$filterId</span> = <span class="color-red">filter_id</span>(<span class="color-pink">$filterName</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The ID for $filterName is: "</span> . <span class="color-pink">$filterId</span>;</p>
                        <p class="ml-30 color-grey">    // Output: The ID for FILTER_VALIDATE_EMAIL is: 274</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, the filter ID for <span className="background-grey">FILTER_VALIDATE_EMAIL</span> is returned as <span className="background-grey">274</span>, and it is printed out.</p>


                <h5 className="mt-5 mb-3">Common Filters and Their IDs</h5>
                <p>Here are a few common filters with their corresponding names and IDs:</p>

                <table className='table table-stripe my-table'>
                    <thead>
                        <th>Filter Name</th>
                        <th>Filter Id</th>
                    </thead>
                    <tr>
                        <td>FILTER_VALIDATE_EMAIL</td>
                        <td>274</td>
                    </tr>
                    <tr>
                        <td>FILTER_VALIDATE_INT</td>
                        <td>257</td>
                    </tr>
                    <tr>
                        <td>FILTER_VALIDATE_URL</td>
                        <td>273</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_STRING</td>
                        <td>513</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_EMAIL</td>
                        <td>517</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_URL</td>
                        <td>518</td>
                    </tr>
                    <tr>
                        <td>FILTER_SANITIZE_NUMBER_INT</td>
                        <td>519</td>
                    </tr>
                </table>

                <p>
                    The <span className="background-grey">filter_id()</span> function is useful when you need to work with PHP's built-in filters programmatically, especially in cases where you may need to retrieve the filter ID dynamically based on its name.
                </p>

            </div>
        </section>
    )
}