import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_trim";

export default function Trim() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP trim() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ucwords',
            'next': '/php/func_ltrim'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP trim() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The trim() function in PHP is used to remove whitespace or other predefined characters from the beginning and end of a string. It is helpful when you want to clean up user input or remove unnecessary spaces before processing a string. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">trim</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">string</span> <span class="color-pink">$characters</span> = <span class="color-green">" \t\n\r\0\x0B"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string from which whitespace or specified characters will be removed.</li>
                    <li><strong>$characters</strong>: (Optional) A list of characters to be removed from the start and end of the string. If not provided, the default characters to be removed are spaces, tabs (<span className="background-grey">\t</span>), newlines (<span className="background-grey">\n</span>), carriage returns (<span className="background-grey">\r</span>), null characters (<span className="background-grey">\0</span>), and vertical tabs (<span className="background-grey">\x0B</span>).</li>
                    <li><strong>Return Value</strong>: It returns a new string with the specified characters removed from both the beginning and end.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Trimming User Input</h5>
                <p>When processing form input, it's common to use <span className="background-grey">trim()</span> to remove any unnecessary spaces around the input</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"   JohnDoe   "</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$trimmedUsername</span> = <span class="color-red">trim</span>(<span class="color-pink">$username</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$trimmedUsername</span>; <span class="color-grey">// Outputs: "JohnDoe"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Trimming Whitespace Characters</h5>
                <p>The <span className="background-grey">trim()</span> function can handle not just spaces but also other whitespace characters like tabs and newlines.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"\n\t Hello, world! \t\n"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">trim</span>(<span class="color-pink">$text</span>); <span class="color-grey">// Outputs: "Hello, world!"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 3 : Removing Custom Characters</h5>
                <p>Suppose you have a string with specific characters you want to remove. You can pass those characters as the second argument to <span className="background-grey">trim()</span></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-green">"###www.example.com###"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$cleanedUrl</span> = <span class="color-red">trim</span>(<span class="color-pink">$url</span>, <span class="color-green">"#"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$cleanedUrl</span>; <span class="color-grey">// Outputs: "www.example.com"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}