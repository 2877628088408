import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/cakephp";

export default function Cakephp() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Cakephp | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks/yii',
            'next': '/php/frameworks/phalcon'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Cakephp</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with CakePHP, a powerful and easy-to-use PHP framework, is a great choice for building web applications quickly. CakePHP follows the MVC (Model-View-Controller) design pattern and emphasizes convention over configuration. Here’s a step-by-step guide to help you set up and start working with CakePHP.
                </p>

                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Ensure your environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.2 or higher</li>
                    <li>Composer (dependency manager for PHP)</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>

                <h5 className='mt-4 mb-3'>Step 2: Install Composer</h5>
                <p>Composer is essential for managing Cakephp dependencies. You can download and install Composer from its <Link to="https://getcomposer.org/">official website</Link>.</p>

                <h5 className='mt-4 mb-3'>Step 3: Install CakePHP</h5>
                <p>You can create a new CakePHP project using Composer. Open your terminal and run the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer create-project --prefer-dist cakephp/app project-name</p>
                        `
                    }}></div>
                </div>
                <p>This command will create a new CakePHP project in a directory named <span class="background-grey">project-name</span>.</p>

                <h5 className='mt-4 mb-3'>Step 4: Navigate to Your Project Directory</h5>
                <p>Change into your project directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cd project-name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 5: Configure Your Environment</h5>
                <p>Open the <span class="background-grey">.env</span> file (or create one based on <span class="background-grey">.env.default</span>) in your project root and set your database connection parameters:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>DATABASE_URL=mysql://username:password@localhost:3306/db_name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 6: Create the Database</h5>
                <p>Create a new database in your database server (e.g., using phpMyAdmin) that matches the name in your configuration.</p>

                <h5 className='mt-4 mb-3'>Step 7: Set File Permissions</h5>
                <p>Ensure that the <span class="background-grey">tmp</span> and <span class="background-grey">logs</span> directories are writable:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>chmod -R 777 tmp</p>
                            <p>chmod -R 777 logs</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 8: Run Migrations</h5>
                <p>CakePHP uses migrations to manage database schema changes. You can run the default migrations by executing:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>bin/cake migrations migrate</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 9: Start the Development Server</h5>
                <p>You can start the built-in CakePHP server with the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>bin/cake server</p>
                        `
                    }}></div>
                </div>
                <p>Your application will be accessible at <span class="background-grey">http://localhost:8765</span></p>


                <h5 className='mt-4 mb-3'>Step 10: Create Your First Controller</h5>
                <p>Create a new controller in the <span class="background-grey">src/Controller</span> directory. For example, create a file named <span class="background-grey">PagesController.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">namespace</span> <span class="color-red">App</span>\<span class="color-red">Controller</span>;</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">App</span>\<span class="color-red">Controller</span>\<span class="color-red">AppController</span>;</p>
                            <br />
                            <p><span class="color-blue">class</span> <span class="color-yellow">PagesController</span> <span class="color-blue">extends</span> <span class="color-yellow">AppController</span></p>
                            <p>{</p>
                            <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">display</span>(<span class="color-pink">...$page</span>)</p>
                            <p class="ml-30">    {</p>
                            <p class="ml-60">        <span class="color-pink">$this</span>-><span class="color-red">set</span>(<span class="color-red">compact</span>(<span class="color-green">'page'</span>));</p>
                            <p class="ml-60">        <span class="color-pink">$this</span>-><span class="color-red">viewBuilder</span>()-><span class="color-red">setLayout</span>(<span class="color-green">'default'</span>);</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 11: Create a View</h5>
                <p>Create a new view file in the <span class="background-grey">templates/Pages</span> directory named <span class="background-grey">home.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h1&gt;Welcome to CakePHP!&lt;/h1&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 12: Set Up Routing</h5>
                <p>By default, CakePHP routes are configured in <span class="background-grey">config/routes.php</span>. To set your homepage, you can add the following line:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$routes</span>-><span class="color-red">connect</span>(<span class="color-green">'/'</span>, [<span class="color-green">'controller'</span> => <span class="color-green">'Pages'</span>, <span class="color-green">'action'</span> => <span class="color-green">'display'</span>, <span class="color-green">'home'</span>]);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}