import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/details";

export default function Details() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <details> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;details&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;details&gt;</span> tag in HTML is used to create a collapsible section that the user can open and close. This tag is useful for showing additional information only when the user wants to see it, helping to keep the webpage cleaner and more organized.
                </p>

                <h5 className='mt-5 mb-3'>Structure of <span class="background-grey">&lt;details&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;details&gt;</span> element usually contains:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">&lt;summary&gt;</span>: An optional element that provides a summary or title for the content. Clicking on the summary toggles the display of the content inside <span class="background-grey">&lt;details&gt;</span>.</li>
                    <li><strong>Additional Content</strong>: Any content (text, images, links, etc.) that you want to hide until the section is expanded.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">details</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">summary</span>&gt;Click here to expand&lt;/<span class="color-blue">summary</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;This is the hidden content that will show when expanded.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p>&lt;/<span class="color-blue">details</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <p>Ok, let's see a simple example...</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">details</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">summary</span>&gt;More about HTML5&lt;/<span class="color-blue">summary</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;HTML5 is the latest version of the HyperText Markup Language.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;It includes new features like the &#96;&lt;video&gt;&#96;, &#96;&lt;audio&gt;&#96;, and &#96;&lt;canvas&gt;&#96; elements, which allow for embedding multimedia.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p>&lt;/<span class="color-blue">details</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>The <span class="background-grey">&lt;summary&gt;</span> element "More about HTML5" is always visible.</li>
                    <li>The additional content within <span class="background-grey">&lt;details&gt;</span> is hidden initially and will be displayed when the user clicks the summary.</li>
                </ul>

                <p>By default, <strong>state</strong> of  <span class="background-grey">&lt;details&gt;</span> is "open". To make it open by default, add the <span class="background-grey">open</span> attribute.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">details</span> open&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">summary</span>&gt;Open by default&lt;/<span class="color-blue">summary</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;This content is visible when the page loads.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p>&lt;/<span class="color-blue">details</span>&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}