import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/operators";

export default function Operators() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Operators in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/variables',
            'next': '/php/loop/control/structure'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Operators</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Operators in PHP are symbols that perform operations on variables and values. PHP supports a variety of operators, each serving different purposes. Here's an overview of the different types of operators in PHP:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5>Arithmetic Operators</h5>
                        <p>It performs mathematical operations on numbers.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">+</span> (Addition) : It adds two numbers.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$sum</span> = <span class="color-pink">5</span> + <span class="color-pink">3</span>;  <span class="color-grey">// $sum is 8</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">-</span> (Subtraction): It subtracts one number from another.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$difference</span> = <span class="color-pink">5</span> - <span class="color-pink">3</span>;  <span class="color-grey">// $difference is 2</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">*</span> (Multiplication): It multiplies two numbers.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$product</span> = <span class="color-pink">5</span> * <span class="color-pink">3</span>;  <span class="color-grey">// $product is 15</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">/</span> (Division): It divides one number by another.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$quotient</span> = <span class="color-pink">15</span> / <span class="color-pink">3</span>;  <span class="color-grey">// $quotient is 5</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">%</span> (Modulus): It returns the remainder of a division.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$remainder</span> = <span class="color-pink">5</span> % <span class="color-pink">3</span>;  <span class="color-grey">// $remainder is 2</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">**</span> (Exponentiation): Raises one value to the power of another.  
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$power</span> = <span class="color-pink">2</span> ** <span class="color-pink">3</span>;  <span class="color-grey">// $power is 8</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Assignment Operators</h5>
                        <p>This operator assign value to variables.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">=</span> (Assignment): Assigns the right operand's value to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;  <span class="color-grey">// $x is 5</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">+=</span> (Add and Assign): Adds the right operand to the left operand and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x</span> += <span class="color-pink">3</span>;  <span class="color-grey">// $x is now 8</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">-=</span> (Subtract and Assign): Subtracts the right operand from the left operand and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x</span> -= <span class="color-pink">3</span>;  <span class="color-grey">// $x is now 2</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">*=</span> (Multiply and Assign): Multiplies the left operand by the right operand and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x</span> *= <span class="color-pink">3</span>;  <span class="color-grey">// $x is now 15</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">/=</span> (Divide and Assign): Divides the left operand by the right operand and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">15</span>;</p>
                                            <p><span class="color-pink">$x</span> /= <span class="color-pink">3</span>;  <span class="color-grey">// $x is now 5</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">%=</span> (Modulus and Assign): Calculates the modulus and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x</span> %= <span class="color-pink">3</span>;  <span class="color-grey">// $x is now 2</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Comparison Operators</h5>
                        <p>This operator compare two values and return <span class="background-grey">true</span> or <span class="background-grey">false</span>.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">==</span> (Equal): Checks if two values are equal.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> == <span class="color-pink">5</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">===</span> (Identical): Checks if two values are equal and of the same type.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> === <span class="color-green">"5"</span>);  <span class="color-grey">// $result is false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">!=</span> or <span class="background-grey">&lt;&gt;</span> (Not Equal): Checks if two values are not equal.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> != <span class="color-pink">3</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">!==</span> (Not Identical): Checks if two values are not equal or not of the same type.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> !== <span class="color-green">"5"</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">&gt;</span> (Greater Than): Checks if the left operand is greater than the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> > <span class="color-pink">3</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">&lt;</span> (Less Than): Checks if the left operand is less than the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">3</span> < <span class="color-pink">5</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">&gt;=</span> (Greater Than or Equal To): Checks if the left operand is greater than or equal to the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">5</span> >= <span class="color-pink">5</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">&lt;=</span> (Less Than or Equal To): Checks if the left operand is less than or equal to the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">3</span> <= <span class="color-pink">5</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">&lt;=&gt;</span> (Spaceship Operator): Compares two values. Returns -1, 0, or 1 depending on whether the left operand is less than, equal to, or greater than the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = <span class="color-pink">5</span> <=> <span class="color-pink">3</span>;  <span class="color-grey">// $result is 1</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Logical Operators</h5>
                        <p>This operator combine multiple conditions and return <span class="background-grey">true</span> or <span class="background-grey">false</span>.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">&&</span> (Logical AND): Returns true if both operands are true.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-blue">true</span> && <span class="color-blue">false</span>);  <span class="color-grey">// $result is false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">||</span> (Logical OR): Returns true if at least one of the operands is true.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-blue">true</span> || <span class="color-blue">false</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">!</span> (Logical NOT): Inverts the boolean value of the operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = !<span class="color-blue">true</span>;  <span class="color-grey">// $result is false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">and</span> (Lower Precedence AND): Similar to && but with lower precedence.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-blue">true and false</span>);  <span class="color-grey">// $result is false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">or</span> (Lower Precedence OR): Similar to || but with lower precedence.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-blue">true or false</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">xor</span> (Logical XOR): Returns true if one operand is true and the other is false.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-blue">true xor false</span>);  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Increment/Decrement Operators</h5>
                        <p>It increase or decrease a variable's value by 1.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">++$var</span> (Pre-Increment): Increases $var by 1, then returns $var.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">++$x</span>;  <span class="color-grey">// $x is now 6</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">$var++</span> (Post-Increment): Returns $var, then increases $var by 1.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x++</span>;  <span class="color-grey">// $x is now 6</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">--$var</span> (Pre-Decrement): Decreases $var by 1, then returns $var.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">--$x</span>;  <span class="color-grey">// $x is now 4</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">$var--</span> (Post-Decrement): Returns $var, then decreases $var by 1.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$x</span> = <span class="color-pink">5</span>;</p>
                                            <p><span class="color-pink">$x--</span>;  <span class="color-grey">// $x is now 4</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>String Operators</h5>
                        <p>It performs operations on strings.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">.</span> (Concatenation): Combines two strings.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$text</span> = <span class="color-green">"Hello"</span> . <span class="color-green">" World!"</span>;  <span class="color-grey">// $text is "Hello World!"</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">.=</span> (Concatenation and Assign): Appends the right operand to the left operand and assigns the result to the left operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$text</span> = <span class="color-green">"Hello"</span>;</p>
                                            <p><span class="color-pink">$text</span> .= <span class="color-green">" World!"</span>;  <span class="color-grey">// $text is now "Hello World!"</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Array Operators</h5>
                        <p>It performs operations on arrays.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">+</span> (Union): Combines two arrays. If two arrays have the same keys, the elements of the first array are preserved.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$array1</span> = <span class="color-blue">array</span>(<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>);</p>
                                            <p><span class="color-pink">$array2</span> = <span class="color-blue">array</span>(<span class="color-green">"b"</span> => <span class="color-green">"berry"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>);</p>
                                            <p><span class="color-pink">$result</span> = <span class="color-pink">$array1</span> + <span class="color-pink">$array2</span>;  <span class="color-grey">// $result is array("a" => "apple", "b" => "banana", "c" => "cherry")</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">==</span> (Equality): Returns <span class="background-grey">true</span> if two arrays have the same key/value pairs.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">$array1</span> == <span class="color-pink">$array2</span>);  <span class="color-grey">// $result is true or false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">===</span> (Identity): Returns <span class="background-grey">true</span> if two arrays have the same key/value pairs in the same order and of the same types.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">$array1</span> === <span class="color-pink">$array2</span>);  <span class="color-grey">// $result is true or false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">!=</span> or <span class="background-grey">&lt;&gt;</span> (Inequality): Returns <span class="background-grey">true</span> if two arrays do not have the same key/value pairs.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">$array1</span> != <span class="color-pink">$array2</span>);  <span class="color-grey">// $result is true or false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <span class="background-grey">!==</span> (Non-identity): Returns <span class="background-grey">true</span> if two arrays do not have the same key/value pairs in the same order and of the same types.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$result</span> = (<span class="color-pink">$array1</span> !== <span class="color-pink">$array2</span>);  <span class="color-grey">// $result is true or false</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Type Operators</h5>
                        <p>It determine the type of a variable or if an object is an instance of a specific class.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">instanceof</span>: Checks if an object is an instance of a specific class.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">class</span> <span class="color-yellow">MyClass</span> {}</p>
                                            <p><span class="color-pink">$obj</span> = <span class="color-blue">new</span> <span class="color-red">MyClass</span>();</p>
                                            <p><span class="color-pink">$result</span> = <span class="color-pink">$obj</span> <span class="color-blue">instanceof</span> MyClass;  <span class="color-grey">// $result is true</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Execution Operators</h5>
                        <p>It executes shell commands.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">`command`</span> (Backticks): Executes a shell command.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$output</span> = &#96;ls&#96;;  <span class="color-grey">// Executes the &#96;ls&#96; command and stores the output in $output</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Error Control Operators</h5>
                        <p>It suppresses error messages.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">@</span>: Suppresses errors that would normally be output.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$file</span> = @<span class="color-pink">file</span>(<span class="color-green">'nonexistent_file.txt'</span>);  <span class="color-grey">// Suppresses the warning if the file doesn't exist</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Null Coalescing Operators</h5>
                        <p>This operator return the first non-null operand.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <span class="background-grey">??</span>: Returns the left operand if it is not <span class="background-grey">null</span>; otherwise, returns the right operand.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$username</span> = <span class="color-pink">$_GET</span>[<span class="color-green">'user'</span>] ?? <span class="color-green">'default_user'</span>;</p>
                                            <p class="color-grey">// If $_GET['user'] is set and not null, $username is $_GET['user']</p>
                                            <p class="color-grey">// Otherwise, $username is 'default_user'</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </section>
    )
}