import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_chmod";

export default function FtpChmod() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_chmod() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_size',
            'next': '/php/func_json_decode'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_chmod() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_chmod()</span> function in PHP is used to set the permissions (also known as mode) of a file on a remote FTP server. This function allows you to change the file permissions (like read, write, execute) for the owner, group, and others.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_chmod</span>(ftp_connection, mode, filename)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource, which is created using the <span className="background-grey">ftp_connect()</span> function. This represents an active connection to the remote FTP server.</li>

                    <li><strong>mode</strong> (Required): The new permissions to be set for the file. This is an integer that represents the permissions in octal form (e.g., 0644, 0755, etc.).</li>

                    <li><strong>filename</strong> (Required): The name and path of the file on the FTP server for which the permissions need to be changed.</li>
                </ul>

                <p>It returns the new permissions on success and returns false if the operation fails.</p>

                <h5 className="mt-5 mb-3">Permissions</h5>
                <p>File permissions are typically represented using a three-digit octal number:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The first digit represents the permissions for the owner.</li>
                    <li>The second digit represents the permissions for the group.</li>
                    <li>The third digit represents the permissions for others.</li>
                </ul>
                <p>Each digit is a sum of the following values:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>4: Read permission.</li>
                    <li>2: Write permission.</li>
                    <li>1: Execute permission.</li>
                </ul>
                <p>For example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>0644 means: Owner can read and write, group and others can only read.</li>
                    <li>0755 means: Owner can read, write, and execute, group and others can read and execute.</li>
                </ul>

                
                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_chmod()</span> Function</h5>
                <p>This is an example of changing permission of a file.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.&#92;n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // File whose permissions we want to change</p>
                        <p class="ml-60">        <span class="color-pink">$file</span> = <span class="color-green">"public_html/myfile.txt"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Set permissions to 0755 (owner: read, write, execute; group and others: read, execute)</p>
                        <p class="ml-60">        <span class="color-pink">$new_permissions</span> = <span class="color-red">ftp_chmod</span>(<span class="color-pink">$ftp_conn</span>, 0755, <span class="color-pink">$file</span>);</p>
                        <br />        
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$new_permissions</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Permissions changed successfully to: "</span> . <span class="color-red">decoct</span>(<span class="color-pink">$new_permissions</span>) . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to change permissions.&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close FTP connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server using <span className="background-grey">ftp_connect()</span> and log in with <span className="background-grey">ftp_login()</span>.</li>
                    <li>The permissions of the file <span className="background-grey">myfile.txt</span> are changed to <span className="background-grey">0755</span> using the <span className="background-grey">ftp_chmod()</span> function.</li>
                    <li>If the operation is successful, the new permissions are printed. If the operation fails, an error message is shown.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>Permissions changed successfully to: <span class="color-pink">755</span>.</p>
                        `
                    }}></div>
                </div>
    

            </div>
        </section>
    )
}