import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_push";

export default function ArrayPush() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_push() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_pop',
            'next': '/php/func_array_rand'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_push() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_push() function in PHP is used to add one or more elements to the end of an array. It treats the array as a stack (Last In, First Out - LIFO) and appends elements in the order they are provided. After using array_push(), the numeric keys in the array will be reindexed.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_push</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>, <span class="color-blue">mixed</span> <span class="color-pink">...$values</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array where new elements will be added.</li>
                    <li><strong>$values</strong>: One or more values to be added to the end of the array.</li>
                    <li><strong>Return Value</strong>: The function returns the new number of elements in the array after the elements have been added.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Adding a Single Element</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>];</p>
                            <p><span class="color-red">array_push</span>(<span class="color-pink">$array</span>, <span class="color-green">"cherry"</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>"cherry"</span> is added to the end of the <span className='background-grey'>$array</span>.</li>
                    <li>The array now contains three elements: <span className='background-grey'>"apple"</span>, <span className='background-grey'>"banana"</span>, and <span className='background-grey'>"cherry"</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Adding Multiple Elements</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>];</p>
                            <p><span class="color-red">array_push</span>(<span class="color-pink">$array</span>, <span class="color-green">"cherry"</span>, <span class="color-green">"date"</span>, <span class="color-green">"elderberry"</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => date</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => elderberry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Multiple elements (<span className='background-grey'>"cherry"</span>, <span className='background-grey'>"date"</span>, and <span className='background-grey'>"elderberry"</span>) are added to the end of the $array.</li>
                    <li>The array is expanded to include all new elements in the order they were added.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 3: Returning the Number of Elements</h5>
                <p><span className='background-grey'>array_push()</span> returns the total number of elements in the array after the new elements are added.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class='color-pink'>$array</span> = [<span class='color-green'>"apple"</span>, <span class='color-green'>"banana"</span>];</p>
                            <p><span class='color-pink'>$newCount</span> = <span class='color-red'>array_push</span>(<span class='color-pink'>$array</span>, <span class='color-green'>"cherry"</span>);</p>
                            <p><span class='color-blue'>echo</span> <span class='color-green'>"Total elements: $newCount"</span>; <span class='color-grey'>// Outputs: Total elements: 3</span></p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Total elements: <span class='color-pink'>3</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>After adding <span className='background-grey'>"cherry"</span> to the array, the total number of elements in the array is 3.</li>
                    <li>The return value of <span className='background-grey'>array_push()</span> is the new size of the array.</li>
                </ul>
            </div>
        </section>
    )
}