import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/progress";

export default function Progress() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <progress> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;progress&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;progress&gt;</span> tag in HTML is used to represent the completion progress of a task, such as a file download, form submission, or any process with a known duration. It visually indicates how much of the task is complete and can be styled to fit the design of your webpage.
                </p>
                <h5 className='mt-5 mb-3'>Structure of <span class="background-grey">&lt;progress&gt;</span> Tag</h5>
                <p>The <span class="background-grey">&lt;progress&gt;</span> element can take attributes to specify the completion status of the task:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">value</span>: Specifies the current progress (e.g., how much of the task is done).</li>
                    <li><span class="background-grey">max</span>: Specifies the total (e.g., the end value or full completion).</li>
                </ul>
                <p>If the <span class="background-grey">value</span> is not specified, <span class="background-grey">&lt;progress&gt;</span> will display an indeterminate state, which is useful when the task's duration is unknown.</p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;progress <span class="color-pink">value</span>=<span class="color-green">"30"</span> <span class="color-pink">max</span>=<span class="color-green">"100"</span>&gt;30%&lt;/progress&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    It's looks like -
                </p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <progress value="30" max="100">30%</progress>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Attributes of <span class="background-grey">&lt;progress&gt;</span></h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span class="background-grey">value</span>: The current progress (e.g., <span class="background-grey">30</span> for <span class="background-grey">30%</span> if max is <span class="background-grey">100</span>).</li>
                    <li><span class="background-grey">max</span>: The maximum value for the progress bar. If omitted, the default is <span class="background-grey">1</span>, meaning <span class="background-grey">value</span> should range from <span class="background-grey">0</span> to <span class="background-grey">1</span>.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Indeterminate State</h5>
                <p>If you omit the value attribute, the <span class="background-grey">&lt;progress&gt;</span> element will show an "indeterminate" state, which often appears as a looping animation:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;progress <span class="color-pink">max</span>=<span class="color-green">"100"</span>&gt;&lt;/progress&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Progress Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h2&gt;File Download Progress&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;progress <span class="color-pink">id</span>=<span class="color-green">"fileProgress"</span> <span class="color-pink">value</span>=<span class="color-green">"0"</span> <span class="color-pink">max</span>=<span class="color-green">"100"</span>&gt;&lt;/progress&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"updateProgress()"</span>&gt;Simulate Progress&lt;/button&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">updateProgress</span>() {</p>
                        <p class="ml-120">                <span class="color-blue">const</span> progressBar = document.<span class="color-red">getElementById</span>(<span class="color-green">"fileProgress"</span>);</p>
                        <p class="ml-120">                <span class="color-blue">if</span> (progressBar.value < <span class="color-pink">100</span>) {</p>
                        <p class="ml-150">                    progressBar.value += <span class="color-pink">10</span>; <span class="color-grey">// Increment progress by 10</span></p>
                        <p class="ml-120">                }</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;progress&gt;</span> element has <span class="background-grey">value="0"</span> and <span class="background-grey">max="100"</span>, so it starts empty.</li>
                    <li>Clicking "Simulate Progress" triggers the <span class="background-grey">updateProgress()</span> function, which increments the <span class="background-grey">value</span> by 10 each time, simulating progress.</li>
                </ul>

            </div>
        </section>
    )
}