import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_fill";

export default function ArrayDiffKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_fill() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_diff_key',
            'next': '/php/func_array_fill_keys'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_fill() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_fill() function in PHP is used to create an array and fill it with a specific value for a specified number of elements. You can define the starting index and the number of elements the array will contain. This function is helpful when you need to create an array with default or repeated values.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_fill</span>(<span class="color-blue">int</span> <span class="color-pink">$start_index</span>, <span class="color-blue">int</span> <span class="color-pink">$num</span>, <span class="color-blue">mixed</span> <span class="color-pink">$value</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$start_index</strong>: The index where the array starts. It can be a positive or negative integer. If it's negative, the starting index will count backward from the end of the array.</li>
                    <li><strong>$num</strong>: The number of elements to insert in the array. Must be a positive integer.</li>
                    <li><strong>$value</strong>: The value to fill the array with. This can be any data type (e.g., integer, string, boolean, array, object).</li>       
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$result</span> = <span class="color-red">array_fill</span>(<span class="color-pink">0</span>, <span class="color-pink">5</span>, <span class="color-green">"apple"</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p> 
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => apple</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>0</span> is the starting index of the array.</li>
                    <li><span className='background-grey'>5</span> is the number of elements to fill.</li>
                    <li><span className='background-grey'>"apple"</span> is the value that fills all the elements.</li>
                    <li>The result is an array with five elements, all set to <span className='background-grey'>"apple"</span>.</li>
                </ul>
            </div>
        </section>
    )
}