import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/introduction";

export default function PHPIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            //'previous': '/css/font/style',
            'next': '/php/setup/environment'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is PHP ?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <b>PHP (PHP Hypertext Preprocessor)</b> is a widely-used <b><i>server-side scripting language</i></b> designed for web development. It allows you to embed code within HTML to create dynamic web pages. PHP runs on the server, processes the code, and then sends the generated HTML to the user's web browser. This makes it possible to build web applications that can interact with users and databases effectively.
                </p>
                <h5 className="mt-5 mb-3">Why We Use PHP ?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        PHP is a server-side scripting language designed for web development. It runs on the server and generates HTML which is then sent to the client’s browser.
                    </li>
                    <li>
                        PHP code can be embedded directly within HTML code. This makes it easy to create dynamic web pages by mixing HTML and PHP.
                    </li>
                    <li>
                        PHP allows for the generation of dynamic content. For instance, it can generate different content based on user input or interactions.
                    </li>
                    <li>
                        PHP can interact with databases, commonly MySQL, to store and retrieve data. This makes it powerful for creating data-driven applications.
                    </li>
                    <li>
                        PHP is cross-platform and can run on various operating systems, including Windows, macOS, and Linux.
                    </li>
                    <li>
                        PHP is open-source and free to use. Its source code is available for modification and distribution.
                    </li>
                    <li>
                        There are several popular PHP frameworks such as Laravel, Symfony, and CodeIgniter that simplify and speed up the development process by providing reusable components and tools.
                    </li>
                    <li>
                        PHP offers a wide range of built-in functions for various tasks, including file manipulation, string operations, and more.
                    </li>
                    <li>
                        PHP provides mechanisms for error handling and debugging, including error reporting functions and custom error handlers.
                    </li>
                    <li>
                        PHP includes features for security, such as data sanitization and validation, but developers need to be vigilant to protect against common vulnerabilities like SQL injection and cross-site scripting (XSS).
                    </li>
                    <li>
                        PHP has a large and active community, which means there is ample documentation, forums, and resources available for learning and troubleshooting.
                    </li>
                    <li>
                        PHP works seamlessly with HTML, CSS, and JavaScript, making it a versatile choice for web development.
                    </li>
                    <li>
                        PHP provides session management capabilities, allowing developers to maintain state and track user sessions across different pages.
                    </li>
                    <li>
                        PHP supports object-oriented programming, allowing developers to use classes and objects to structure their code more effectively.
                    </li>
                    <li>
                        PHP can also be used for command-line scripting, allowing for tasks such as cron jobs and batch processing.
                    </li>
                    <li>
                        PHP is regularly updated with new features, improvements, and security patches, ensuring its continued relevance and performance.
                    </li>
                </ul>
                <h5 className="mt-5 mb-3">What's new in PHP 7 ?</h5>
                <p>
                    PHP 7 introduced several significant improvements and new features over PHP 5.x. Here are some of the key enhancements in PHP 7:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Performance Improvements</strong>: PHP 7 includes a new engine called the Zend Engine 3.0, which offers substantial performance improvements. This version is significantly faster than PHP 5.6, often resulting in a performance boost of around 2x to 3x.
                    </li>

                    <li>
                        <strong>Reduced Memory Consumption</strong>: PHP 7 has better memory management, leading to reduced memory usage compared to PHP 5.x. This makes applications more efficient and can help reduce hosting costs.
                    </li>

                    <li>
                        <strong>Scalar Type Declarations</strong>: PHP 7 introduces scalar type declarations for function parameters and return values. This allows developers to specify types like int, float, string, and bool, improving type safety.
                    </li>

                    <li>
                        <strong>Return Type Declarations</strong>: Functions and methods can now declare the type of value they return. This helps ensure that the correct type of value is returned and can prevent bugs.
                    </li>

                    <li>
                        <strong>Null Coalescing Operator (??)</strong>: This operator provides a shorthand way to check if a value is set and is not null. It's useful for providing default values. For example:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$value</span> = <span class="color-pink">$array</span>[<span class="color-green">'key'</span>] ?? <span class="color-green">'default'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li> 
                    <li>
                        <strong>Spaceship Operator (&lt;=&gt;)</strong>: The spaceship operator is a three-way comparison operator that returns -1, 0, or 1 depending on whether the left-hand operand is less than, equal to, or greater than the right-hand operand. It simplifies sorting and comparisons.
                    </li>

                    <li>
                        <strong>Anonymous Classes</strong>: PHP 7 supports anonymous classes (or anonymous objects), allowing for the creation of objects without needing to define a named class.
                    </li>

                    <li>
                        <strong>Throwable Interface</strong>: PHP 7 introduces a new Throwable interface, which is the base interface for both Exception and Error classes. This unifies exception and error handling, making it easier to catch and handle both types of errors.
                    </li>

                    <li>
                        <strong>Error Handling Improvements</strong>: PHP 7 introduces EngineException and Error classes to handle errors more consistently. This provides better control over error handling and allows for catching fatal errors as exceptions.
                    </li>

                    <li>
                        <strong>Uniform Variable Syntax</strong>: The syntax for accessing variables has been made more consistent, which simplifies variable references and reduces confusion.
                    </li>

                    <li>
                        <strong>New Operators and Functions</strong>: PHP 7 introduces new functions and operators, including:
                        <ul style={{listStyle:'disc'}}>
                            <li>count() for counting elements in an array or objects.</li>
                            <li>iterable type hint for accepting any array or object implementing Traversable.</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Deprecations and Removals</strong>: Some older and deprecated features have been removed or changed in PHP 7, including deprecated functions and extensions. This helps streamline the language and improve security.
                    </li>

                    <li>
                        <strong>Improved Unicode Support</strong>: PHP 7 includes better support for Unicode, allowing for more robust handling of international characters and strings.
                    </li>

                    <li>
                        <strong>Consistent 64-bit Support</strong>: PHP 7 provides consistent 64-bit support across all platforms, improving compatibility and performance on 64-bit systems.
                    </li>
                </ul>
            </div>
        </section>
    )
}