import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/video-element";

export default function VideoElement() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Video Element | Aspirant's Home");
        const urls = {
            'previous': '/html/input-attributes',
            'next': '/html/audio-element'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Video Element</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>&lt;video&gt;</span> element in HTML is used to embed video content on a webpage. It provides a simple way to display video files without needing third-party plugins like Flash. Here’s a breakdown of its key features and attributes:
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>Here’s a simple example of the <span className='background-grey'>&lt;video&gt;</span> element:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;video <span class="color-pink">width</span>=<span class="color-green">"640"</span> <span class="color-pink">height</span>=<span class="color-green">"360"</span> <span class="color-pink">controls</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.mp4"</span> <span class="color-pink">type</span>=<span class="color-green">"video/mp4"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"video/ogg"</span>&gt;</p>
                        <p class="ml-30">    Your browser does not support the video tag.</p>
                        <p>&lt;/video&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here is an output of above example -</p>
                <div className='codePalateBox mt-2 mb-4' style={{'background':'none'}}>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <video width="640" height="360" controls>
                            <source src="../movie.mp4" type="video/mp4">
                            <source src="movie.ogg" type="video/ogg">
                            Your browser does not support the video tag.
                        </video>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Attributes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><span className='background-grey'>src</span>: Specifies the URL of the video file. This is used in the <span className='background-grey'>&lt;source&gt;</span> elements.</li>

                    <li><span className='background-grey'>controls</span>: Adds video controls, like play, pause, and volume.</li>

                    <li><span className='background-grey'>autoplay</span>: Automatically starts playing the video when the page loads. (Note: it may be muted in some browsers.)</li>

                    <li><span className='background-grey'>loop</span>: Causes the video to restart when it reaches the end.</li>

                    <li><span className='background-grey'>muted</span>: Starts the video with the sound muted.</li>

                    <li><span className='background-grey'>poster</span>: Specifies an image to show while the video is loading or until the user plays it.</li>

                    <li><span className='background-grey'>preload</span>: Provides a hint to the browser about whether to load the video when the page loads. Options include:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className='background-grey'>"none"</span>: Don't preload the video.</li>
                            <li><span className='background-grey'>"metadata"</span>: Preload only the metadata (like duration and dimensions).</li>
                            <li><span className='background-grey'>"auto"</span>: Preload the whole video.</li>
                        </ul>
                    </li>
                    <li><span className='background-grey'>width and height</span>: Define the dimensions of the video player.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Source Elements</h5>
                <p>Using multiple &lt;source&gt; elements allows the browser to choose the best format to play. It helps ensure compatibility across different browsers.</p>

                <h5 className='mt-5 mb-3'>Fallback Content</h5>
                <p>If the browser doesn’t support the &lt;video&gt; element, any content between the opening and closing &lt;video&gt; tags (like the message "Your browser does not support the video tag.") will be displayed.</p>


                <h5 className='mt-5 mb-3'>Example with Attributes</h5>
                <p>Here’s a more detailed example with various attributes:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;video <span class="color-pink">width</span>=<span class="color-green">"640"</span> <span class="color-pink">height</span>=<span class="color-green">"360"</span> <span class="color-pink">controls autoplay loop muted poster</span>=<span class="color-green">"thumbnail.jpg"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.mp4"</span> <span class="color-pink">type</span>=<span class="color-green">"video/mp4"</span>&gt;</p>
                        <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"video/ogg"</span>&gt;</p>
                        <p class="ml-30">    Your browser does not support the video tag.</p>
                        <p>&lt;/video&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Here is an output of above example -</p>
                <div className='codePalateBox mt-2 mb-4' style={{'background':'none'}}>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <video width="640" height="360" controls autoplay loop muted poster="thumbnail.jpg">
                            <source src="../movie.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        `
                    }}></div>
                </div>

                <p>The &lt;video&gt; element is a powerful tool for adding multimedia to web pages, making it easy to integrate videos into your content while providing a user-friendly interface.</p>

            </div>
        </section>
    )
}