import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_change_key_case";

export default function ArrayChangeCase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_change_key_case() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/array',
            'next': '/php/func_array_chunk'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_change_key_case() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>array_change_key_case()</span> function in PHP is used to change the case of all keys in an array. You can convert the keys to either lowercase or uppercase using this function. It does not affect the values, only the keys. Syntax will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_change_key_case</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$case</span> = CASE_LOWER)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array whose keys you want to change.</li>
                    <li>
                        <strong>$case</strong>: (Optional) It can be either:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>CASE_LOWER</strong>: Convert all keys to lowercase.</li>
                            <li><strong>CASE_UPPER</strong>: Convert all keys to uppercase.</li>
                            <li>The default value is CASE_LOWER.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Converting Keys to Lowercase</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$person</span> = <span class="color-blue">array</span>(<span class="color-green">"Name"</span> => <span class="color-green">"Alice"</span>, <span class="color-green">"AGE"</span> => <span class="color-pink">25</span>, <span class="color-green">"Country"</span> => <span class="color-green">"USA"</span>);</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_change_key_case</span>(<span class="color-pink">$person</span>, CASE_LOWER);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-red">Array</p>
                            <p>(</p>
                            <p class="ml-30">    [name] => Alice</p>
                            <p class="ml-30">    [age] => <span class="color-pink">25</span></p>
                            <p class="ml-30">    [country] => USA</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, all the keys of the <span className='background-grey'>$person</span> array (<span className='background-grey'>"Name"</span>, <span className='background-grey'>"AGE"</span>, <span className='background-grey'>"Country"</span>) are converted to lowercase (<span className='background-grey'>"name"</span>, <span className='background-grey'>"age"</span>, <span className='background-grey'>"country"</span>).
                </p>


                <h5 className="mt-5 mb-3">Example 2: Converting Keys to Uppercase</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$person</span> = <span class="color-blue">array</span>(<span class="color-green">"Name"</span> => <span class="color-green">"Alice"</span>, <span class="color-green">"AGE"</span> => <span class="color-pink">25</span>, <span class="color-green">"Country"</span> => <span class="color-green">"USA"</span>);</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_change_key_case</span>(<span class="color-pink">$person</span>, CASE_UPPER);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-red">Array</p>
                            <p>(</p>
                            <p class="ml-30">    [NAME] => Alice</p>
                            <p class="ml-30">    [AGE] => <span class="color-pink">25</span></p>
                            <p class="ml-30">    [COUNTRY] => USA</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the keys of the array are converted to uppercase.</p>
            </div>
        </section>
    )
}