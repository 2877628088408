import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_tan";

export default function Tan() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP tan() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_cos',
            'next': '/php/func_deg2rad'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP tan() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">tan()</span> function in PHP is used to calculate the tangent of an angle. Like the <span className="background-grey">sin()</span> and <span className="background-grey">cos()</span> functions, the angle must be provided in radians. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">tan</span>(angle)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The angle in radians for which you want to compute the tangent.</p>
                <p>The tan() function returns the tangent of the specified angle. The return value can be any real number, including negative values.</p>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage of tan()</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">tan</span>(<span class="color-pink">0</span>);  <span class="color-grey">// Output: 0</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">tan(0)</span> calculates the tangent of <span className="background-grey">0</span> radians, which is <span className="background-grey">0</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Tangent of a Positive Angle</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">tan</span>(M_PI / <span class="color-pink">4</span>);  <span class="color-grey">// Output: 1</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">tan(M_PI / 4)</span> calculates the tangent of <span className="background-grey">45</span> degrees, which is <span className="background-grey">1</span> (since the sine and cosine of 45 degrees are equal).</p>

            </div>
        </section>
    )
}