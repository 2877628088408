import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_values";

export default function ArrayValues() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_values() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_unique',
            'next': '/php/func_ksort'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_values() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_values() function in PHP is used to return all the values of an array (both indexed and associative arrays) and reindexes them with numerical keys starting from 0. This is particularly useful when you need to extract only the values from an associative array or when you want to reset the keys of an array after filtering or performing operations that modify it.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_values</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array from which you want to extract the values.</li>
                    <li>Returns an array containing all the values from the input array, with new numeric keys starting from 0.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage with Associative Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$fruits</span> = [</p>
                        <p class="ml-30">    <span class="color-green">"a"</span> => <span class="color-green">"Apple"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"b"</span> => <span class="color-green">"Banana"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"c"</span> => <span class="color-green">"Cherry"</span></p>
                        <p>];</p>
                        <br />
                        <p><span class="color-pink">$values</span> = <span class="color-red">array_values</span>(<span class="color-pink">$fruits</span>);</p>
                        <br />
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$values</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => Apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => Banana</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => Cherry</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The <span className='background-grey'>array_values()</span> function extracts the values (<span className='background-grey'>Apple</span>, <span className='background-grey'>Banana</span>, <span className='background-grey'>Cherry</span>) from the associative array and reindexes them starting from 0, effectively discarding the keys (<span className='background-grey'>a</span>, <span className='background-grey'>b</span>, <span className='background-grey'>c</span>).</p>



                <h5 className="mt-5 mb-3">Example 2: Mixed Keys (Associative and Indexed)</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$mix</span> = [</p>
                        <p class="ml-30">    <span class="color-pink">0</span> => <span class="color-green">"Apple"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"color"</span> => <span class="color-green">"Red"</span>,</p>
                        <p class="ml-30">    <span class="color-pink">2</span> => <span class="color-green">"Banana"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"size"</span> => <span class="color-green">"Large"</span></p>
                        <p>];</p>
                        <br />
                        <p><span class="color-pink">$values</span> = <span class="color-red">array_values</span>(<span class="color-pink">$mix</span>);</p>
                        <br />
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$values</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => Apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => Red</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => Banana</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => Large</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, In this case, the array contains both numeric and string keys. <span className='background-grey'>array_values()</span> reindexes the values (<span className='background-grey'>Apple</span>, <span className='background-grey'>Red</span>, <span className='background-grey'>Banana</span>, <span className='background-grey'>Large</span>) into a new numeric index starting from <span className='background-grey'>0</span>, discarding the original keys (<span className='background-grey'>0</span>, <span className='background-grey'>color</span>, <span className='background-grey'>2</span>, <span className='background-grey'>size</span>).</p>
            </div>
        </section>
    )
}