import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_key_exists";

export default function ArrayKeyExists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_key_exists() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_flip',
            'next': '/php/func_array_keys'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_key_exists() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_key_exists() function in PHP checks if a specified key exists in an array. This function returns true if the key is found, and <span className='background-grey'>false</span> otherwise. It is useful when you want to verify the presence of a key in an array before performing any operations on the corresponding value.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_key_exists</span>(<span class="color-blue">mixed</span> <span class="color-pink">$key</span>, <span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$key</strong>: The key to check for in the array.</li>
                    <li><strong>$array</strong>: The array in which to search for the specified key.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"name"</span>=> <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">25</span>, <span class="color-green">"city"</span> => <span class="color-green">"New York"</span>];</p>
                            <br />
                            <p><span class="color-blue">if</span> (<span class="color-red">array_key_exists</span>(<span class="color-green">"age"</span>, <span class="color-pink">$array</span>)) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The key 'age' exists in the array."</span>;</p>
                            <p>} else {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The key 'age' does not exist in the array."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The key 'age' exists in the array.</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function checks if the key <span className='background-grey'>"age"</span> exists in the array.</li>
                    <li>Since <span className='background-grey'>"age"</span> is a key in the array, the output is The key <span className='background-grey'>'age'</span> exists in the array.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Key Not Found</h5>
                <p>If the specified key is not present in the array, the function will return <span className='background-grey'>false</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"name"</span> => <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">25</span>];</p>
                            <br />
                            <p><span class="color-blue">if</span> (<span class="color-red">array_key_exists</span>(<span class="color-green">"city"</span>, <span class="color-pink">$array</span>)) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The key 'city' exists in the array."</span>;</span>
                            <p>} <span class="color-blue">else</span> {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The key 'city' does not exist in the array."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The key 'city' does not exist in the array.</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function checks for the key <span className='background-grey'>"city"</span> in the array.</li>
                    <li>Since the array does not contain the key <span className='background-grey'>"city"</span>, the output is <span className='background-grey'>The key 'city' does not exist in the array</span>.</li>
                </ul>
            </div>
        </section>
    )
}