import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_error_log";

export default function ErrorLog() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP error_log() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_error_get_last',
            'next': '/php/func_error_reporting'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP error_log() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">error_log()</span> function in PHP is used to send error messages to various destinations, such as system logs, a file, or an email, depending on the configuration. This function is useful for debugging and logging purposes, especially in production environments where direct display of errors to users is not desirable.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">error_log</span>(message, message_type, destination, extra_headers);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">message</span>: (Required) The error message to be logged.</li>
                    <li>
                        <span className="background-grey">message_type</span>: (Optional) Specifies where to send the error message. Default is <span className="background-grey">0</span> (system log). Other types are:
                        <ul style={{listStyle: 'disc'}}>
                            <li><span className="background-grey">0</span>: Sends the message to the PHP system logger (error_log file or syslog).</li>
                            <li><span className="background-grey">1</span>: Sends the message via email.</li>
                            <li><span className="background-grey">3</span>: Sends the message to a file.</li>
                            <li><span className="background-grey">4</span>: Sends the message directly to SAPI (e.g., the web server's error log).</li>
                        </ul>
                    </li>
                    <li><span className="background-grey">destination</span>: (Optional) Used when message_type is <span className="background-grey">1</span> or <span className="background-grey">3</span>. Specifies the email address (for type <span className="background-grey">1</span>) or the file path (for type <span className="background-grey">3</span>).</li>
                    <li><span className="background-grey">extra_headers</span>: (Optional) Used when message_type is <span className="background-grey">1</span> (sending email). Specifies additional headers to include in the email.</li>
                </ul>

                
                <h5 className="mt-5 mb-3">Example 1: Logging an Error Message to System Log</h5>
                <p>The default behavior of <span className="background-grey">error_log()</span> is to send the error message to the system log, which can be a file specified in the php.ini configuration.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Log a message to the system log</p>
                        <p class="ml-30">    <span class="color-red">error_log</span>(<span class="color-green">"This is an error message logged to the system log."</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the message <span className="background-grey">"This is an error message logged to the system log."</span> will be sent to the PHP system logger. The location of the system log depends on the server configuration (often specified in <span className="background-grey">php.ini</span>).</p>


                <h5 className="mt-5 mb-3">Example 2: Logging an Error Message to a File</h5>
                <p>You can also log errors to a custom file by setting the <span className="background-grey">message_type</span> to <span className="background-grey">3</span> and specifying the file path in the destination parameter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Log an error message to a custom file</p>
                        <p class="ml-30">    <span class="color-red">error_log</span>(<span class="color-green">"This is an error message logged to a file."</span>, <span class="color-pink">3</span>, <span class="color-green">"/path/to/error.log"</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the error message will be appended to the specified file (<span className="background-grey">/path/to/error.log</span>). Make sure that the web server has write permissions to the file or directory where the log file is stored.</p>



            </div>
        </section>
    )
}