import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/nav";

export default function Nav() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <nav> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;nav&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;nav&gt;</span> tag in HTML is used to define a section of the page that contains navigational links. This element helps users and search engines understand where the main navigation is located on a webpage, making it a key part of accessible and well-structured page design.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;nav&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;nav&gt;</span> element is typically used for primary menus like the main site navigation, sidebars, or secondary menus.</li>
                    <li>It can contain various types of links, including links to other pages, different sections within the page, or external websites.</li>
                    <li>The <span class="background-grey">&lt;nav&gt;</span> element is a semantic tag, meaning it adds meaning and structure to your HTML, improving accessibility and SEO.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;nav&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Navigation links here --&gt;</p>
                        <p>&lt;/nav&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Styling the <span class="background-grey">&lt;nav&gt;</span> Element</h5>
                <p>The <span class="background-grey">&lt;nav&gt;</span> element does not have any default styling, so you can add custom CSS to style your navigation menu:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>nav ul {</p>
                            <p class="ml-30">    <span class="color-green">list-style-type</span>: none;</p>
                            <p class="ml-30">    <span class="color-green">display</span>: flex;</p>
                            <p class="ml-30">    <span class="color-green">gap</span>: <span class="color-pink">10px</span>;</p>
                            <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">0</span>;</p>
                            <p>}</p>
                            <br />
                            <p>nav ul li a {</p>
                            <p class="ml-30">    <span class="color-green">text-decoration</span>: none;</p>
                            <p class="ml-30">    <span class="color-green">color</span>: <span class="color-pink">#333</span>;</p>
                            <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">0.5em 1em</span>;</p>
                            <p class="ml-30">    <span class="color-green">border-radius</span>: <span class="color-pink">5px</span>;</p>
                            <p>}</p>
                            <br />
                            <p>nav ul li a:hover {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#ddd</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Complete Example with Styling</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Navigation Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            nav ul {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: none;</p>
                        <p class="ml-120">                <span class="color-green">display</span>: flex;</p>
                        <p class="ml-120">                <span class="color-green">gap</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">0</span>;</p>
                        <p class="ml-120">            }</p>
                        <br />        
                        <p class="ml-90">            nav ul li a {</p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: none;</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#333</span>;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">0.5em 1em</span>;</p>
                        <p class="ml-120">                <span class="color-green">border-radius</span>: <span class="color-pink">5px</span>;</p>
                        <p class="ml-120">            }</p>
                        <br />        
                        <p class="ml-90">            nav ul li a:hover {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#ddd</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;header&gt;</p>
                        <p class="ml-90">            &lt;h1&gt;My Website&lt;/h1&gt;</p>
                        <p class="ml-90">            &lt;nav&gt;</p>
                        <p class="ml-120">                &lt;ul&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#home"</span>&gt;Home&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#about"</span>&gt;About&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#services"</span>&gt;Services&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#contact"</span>&gt;Contact&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;/ul&gt;</p>
                        <p class="ml-90">            &lt;/nav&gt;</p>
                        <p class="ml-60">        &lt;/header&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Styled Navigation Example</title>
                                <style>
                                    nav.myExample ul {
                                        list-style-type: none;
                                        display: flex;
                                        gap: 10px;
                                        padding: 0;
                                    }
                                
                                    nav.myExample ul li a {
                                        text-decoration: none;
                                        color: #333;
                                        padding: 0.5em 1em;
                                        border-radius: 5px;
                                    }
                                
                                    nav.myExample ul li a:hover {
                                        background-color: #ddd;
                                    }
                                </style>
                            </head>
                            <body>
                            
                                <header>
                                    <h1>My Website</h1>
                                    <nav class="myExample">
                                        <ul>
                                            <li><a href="#home">Home</a></li>
                                            <li><a href="#about">About</a></li>
                                            <li><a href="#services">Services</a></li>
                                            <li><a href="#contact">Contact</a></li>
                                        </ul>
                                    </nav>
                                </header>
                            
                            </body>
                        </html>
                        
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">&lt;nav&gt;</span> tag improves the semantic structure of HTML, helping search engines and screen readers identify navigation. It is a simple but powerful tool for organizing navigation in HTML, enhancing both the user experience and the accessibility of your site.</p>


            </div>
        </section>
    )
}