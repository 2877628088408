import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/footer";

export default function Footer() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <footer> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;footer&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;footer&gt;</span> tag in HTML is used to define the footer section of a document or a section within it. It generally contains information about the enclosing section or the entire page, such as copyright information, contact details, legal disclaimers, links to terms of service, or any other closing content.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;footer&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;footer&gt;</span> element provides a semantic way to group content that usually appears at the bottom of a page or section.</li>
                    <li>It can be used within different parts of the document, not just the main page footer. For example, it can appear within an <span class="background-grey">&lt;article&gt;</span>, <span class="background-grey">&lt;section&gt;</span>, or <span class="background-grey">&lt;aside&gt;</span> to provide a specific section footer.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;footer&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Footer content goes here --&gt;</p>
                        <p>&lt;/footer&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Styling <span class="background-grey">&lt;footer&gt;</span> Elements</h5>
                <p>The <span class="background-grey">&lt;footer&gt;</span> element can be styled to stand out from the rest of the page content, often with a background color and padding to differentiate it visually.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>footer {</p>
                        <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#333</span>;</p>
                        <p class="ml-30">    <span class="color-green">color</span>: white;</p>
                        <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">15px</span>;</p>
                        <p class="ml-30">    <span class="color-green">text-align</span>: center;</p>
                        <p>}</p>
                        <p>footer a {</p>
                        <p class="ml-30">    <span class="color-green">color</span>: <span class="color-pink">#bbb</span>;</p>
                        <p class="ml-30">    <span class="color-green">text-decoration</span>: none;</p>
                        <p>}</p>
                        <p>footer a:hover {</p>
                        <p class="ml-30">    <span class="color-green">color</span>: <span class="color-pink">#fff</span>;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Footer Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            footer {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#333</span>;</p>
                        <p class="ml-120">                <span class="color-green">color</span>: white;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">15px</span>;</p>
                        <p class="ml-120">                <span class="color-green">text-align</span>: center;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            footer a {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#bbb</span>;</p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: none;</p>
                        <p class="ml-120">                <span class="color-green">margin</span>: <span class="color-pink">0 10px</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            footer a:hover {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#fff</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;header&gt;</p>
                        <p class="ml-90">            &lt;h1&gt;My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;/header&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;main&gt;</p>
                        <p class="ml-90">            &lt;article&gt;</p>
                        <p class="ml-120">                &lt;h2&gt;About Semantic HTML&lt;/h2&gt;</p>
                        <p class="ml-120">                &lt;p&gt;Semantic HTML improves web accessibility and helps search engines better understand page structure...&lt;/p&gt;</p>
                        <p class="ml-90">            &lt;/article&gt;</p>
                        <p class="ml-60">        &lt;/main&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;footer&gt;</p>
                        <p class="ml-90">            &lt;p&gt;&copy; 2024 My Website. All rights reserved.&lt;/p&gt;</p>
                        <p class="ml-90">            &lt;nav&gt;</p>
                        <p class="ml-120">                &lt;a <span class="color-pink">href</span>=<span class="color-green">"/about"</span>&gt;About Us&lt;/a&gt; | 
                                        &lt;a <span class="color-pink">href</span>=<span class="color-green">"/contact"</span>&gt;Contact Us&lt;/a&gt; | 
                                        &lt;a <span class="color-pink">href</span>=<span class="color-green">"/privacy"</span>&gt;Privacy Policy&lt;/a&gt;</p>
                        <p class="ml-90">            &lt;/nav&gt;</p>
                        <p class="ml-60">        &lt;/footer&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <title>Styled Footer Example</title>
                          <style>
                            footer {
                              background-color: #333;
                              color: white;
                              padding: 15px;
                              text-align: center;
                            }
                            footer a {
                              color: #bbb;
                              text-decoration: none;
                              margin: 0 10px;
                            }
                            footer a:hover {
                              color: #fff;
                            }
                          </style>
                        </head>
                        <body>
                        
                        <header>
                          <h1>My Website</h1>
                        </header>
                        
                        <main>
                          <article>
                            <h2>About Semantic HTML</h2>
                            <p>Semantic HTML improves web accessibility and helps search engines better understand page structure...</p>
                          </article>
                        </main>
                        
                        <footer>
                          <p>&copy; 2024 My Website. All rights reserved.</p>
                          <nav>
                            <a href="/about">About Us</a> | 
                            <a href="/contact">Contact Us</a> | 
                            <a href="/privacy">Privacy Policy</a>
                          </nav>
                        </footer>
                        
                        </body>
                        </html>
                        
                        `
                    }}></div>
                </div>

                <p>The <span class="background-grey">&lt;footer&gt;</span> tag is an essential part of HTML5 for organizing closing information on webpages, supporting clear structure and accessible content. Use <span class="background-grey">&lt;footer&gt;</span> to designate the end of content for the main page or specific sections.</p>


            </div>
        </section>
    )
}