import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_implode";

export default function Implode() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP implode() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_explode',
            'next': '/php/func_str_replace'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP implode() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The implode() function in PHP is used to <strong>join the elements of an array into a single string</strong>, with an optional separator placed between each element. It's the reverse of the explode() function, which splits a string into an array.
                </p>
                <p>
                    This function is particularly useful when you need to take an array of data and represent it as a string, such as when saving multiple values into a database field, creating URLs, or displaying a list. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">implode</span>(<span class="color-blue">string</span> <span class="color-pink">$separator</span>, <span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$separator</strong>: (Optional) A string to be inserted between each array element in the resulting string. If not provided, the elements are joined together without any separator.</li>
                    <li><strong>$array</strong>: The array of elements to be joined into a single string.</li>
                    <li><strong>Return Value</strong>: Returns a string formed by combining the elements of the array, separated by the provided separator.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1 : Creating CSV (Comma Separated Values) Strings</h5>
                <p>You can use <span className="background-grey">implode()</span> to convert an array into a CSV format.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-red">array</span>(<span class="color-green">"John"</span>, <span class="color-green">"Doe"</span>, <span class="color-green">"john@example.com"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$csv</span> = <span class="color-red">implode</span>(<span class="color-green">","</span>, <span class="color-pink">$data</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$csv</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>John,Doe,john@example.com</p>
                        `
                    }}></div>
                </div>


                <h5 className="mt-5 mb-3">Example 2 : Generating URLs with Query Parameters</h5>
                <p>You can join URL parameters together to create query strings:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$params</span> = <span class="color-red">array</span>(<span class="color-green">"id=123"</span>, <span class="color-green">"name=John"</span>, <span class="color-green">"age=25"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$queryString</span> = <span class="color-red">implode</span>(<span class="color-green">"&"</span>, <span class="color-pink">$params</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"https://example.com/profile?"</span> . <span class="color-pink">$queryString</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>https://example.com/profile?id=123&name=John&age=25</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 3 : Displaying Lists or Sentences</h5>
                <p>If you need to display a list of items as a sentence, <span className="background-grey">implode()</span> can help format it:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$colors</span> = <span class="color-red">array</span>(<span class="color-green">"red"</span>, <span class="color-green">"green"</span>, <span class="color-green">"blue"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$sentence</span> = <span class="color-red">implode</span>(<span class="color-green">" and "</span>, <span class="color-pink">$colors</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"My favorite colors are "</span><span class="color-pink"> . $sentence</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>My favorite colors are red and green and blue</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}