import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_pad";

export default function ArrayPad() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_pad() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_merge',
            'next': '/php/func_array_pop'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_pad() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_pad() function in PHP is used to pad an array to a specified length with a specified value. It adds elements to the array either at the end or at the beginning, depending on whether the length is positive or negative.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_pad</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$size</span>, <span class="color-blue">mixed</span> <span class="color-pink">$value</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array that will be padded.</li>
                    <li>
                        <strong>$size</strong>: The new size of the array. If the absolute value of <span className='background-grey'>$size</span> is larger than the number of elements in <span className='background-grey'>$array</span>, the array will be padded to match the given size.
                        <ul style={{ listStyle: 'disc' }}>
                            <li>If <span className='background-grey'>$size</span> is positive, padding is added to the end of the array.</li>
                            <li>If <span className='background-grey'>$size</span> is negative, padding is added to the beginning of the array.</li>
                        </ul>
                    </li>
                    <li><strong>$value</strong>: The value used to pad the array.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Padding</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_pad</span>(<span class="color-pink">$array</span>, <span class="color-pink">5</span>, <span class="color-green">"orange"</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => cherry</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => orange</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => orange</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The input array has 3 elements.</li>
                    <li>The array is padded with the value <span className='background-grey'>"orange"</span> until the array contains 5 elements.</li>
                    <li>The padding is added to the end because the <span className='background-grey'>$size</span> is positive.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Negative Padding (Padding at the Beginning)</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$result</span> = <span class="color-red">array_pad</span>(<span class="color-pink">$array</span>, <span class="color-pink">-5</span>, <span class="color-green">"orange"</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => orange</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => orange</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => apple</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">4</span>] => cherry</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Since <span className='background-grey'>$size</span> is negative (<span className='background-grey'>-5</span>), the array is padded from the beginning.</li>
                    <li>Two <span className='background-grey'>"orange"</span> elements are added to the start of the array to meet the length requirement of 5.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: No Padding if Array Already Has Enough Elements</h5>
                <p>If the specified size is less than or equal to the number of elements in the array, no padding will occur, and the original array is returned.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$result</span> = <span class="color-red">array_pad</span>(<span class="color-pink">$array</span>, <span class="color-pink">2</span>, <span class="color-green">"orange"</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => cherry</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Since the original array already has 3 elements, and the size requested is 2, no padding is added. The original array is returned unchanged.</p>
            </div>
        </section>
    )
}