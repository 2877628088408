import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_dir";

export default function Dir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP dir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_closedir',
            'next': '/php/func_getcwd'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP dir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">dir()</span> function in PHP is a powerful utility that allows you to interact with directories. It opens a directory and provides an object that can be used to read and manipulate the directory contents, offering an object-oriented alternative to the <span className="background-grey">opendir()</span>, <span className="background-grey">readdir()</span>, and <span className="background-grey">closedir()</span> functions.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">dir</span>(directory, context)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>directory</strong> (Required): The path to the directory you want to open. This must be a valid directory path.</li>

                    <li><strong>context</strong> (Optional): A context resource, which can be used to modify the behavior of the <span className="background-grey">dir()</span> function (e.g., to specify stream options). It is rarely used in common directory operations.</li>
                </ul>
                <p>It returns a directory object: This object contains three properties (<span className="background-grey">handle</span>, <span className="background-grey">path</span>, and <span className="background-grey">rewind</span>), and three methods (<span className="background-grey">read()</span>, <span className="background-grey">rewind()</span>, and <span className="background-grey">close()</span>) that can be used to manage the directory.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">dir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Open the directory</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-red">dir</span>(<span class="color-green">"/path/to/directory"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Path: "</span> . <span class="color-pink">$directory</span>->path . <span class="color-green">"&#92;n"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Loop through the directory contents</p>
                        <p class="ml-30">    <span class="color-blue">while</span> ((<span class="color-pink">$file</span> = <span class="color-pink">$directory</span>-><span class="color-red">read</span>()) !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Filename: "</span> . <span class="color-pink">$file</span> . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the directory handle</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span>-><span class="color-red">close</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">dir()</span> function is used to open the directory <span className="background-grey">/path/to/directory</span>. This returns a <span className="background-grey">Directory</span> object that can be used to interact with the directory.</li>
                    <li>The <span className="background-grey">read()</span> method of the <span className="background-grey">Directory</span> object reads the contents of the directory, one file or directory at a time. It continues reading until there are no more files, and <span className="background-grey">false</span> is returned.</li>
                    <li>After reading the directory, the <span className="background-grey">close()</span> method is called to close the directory handle, freeing up resources.</li>
                </ul>


            </div>
        </section>
    )
}