import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rename";

export default function Rename() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rename() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_copy',
            'next': '/php/func_is_file'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rename() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">rename()</span> function in PHP is used to rename a file or directory. It can also be used to move a file or directory to a new location by changing its path. This function is handy when you need to rename files or directories, or when you want to move them within the file system.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rename</span>(<span class="color-blue">string</span> <span class="color-pink">$oldname</span>, <span class="color-blue">string</span> <span class="color-pink">$newname</span>, resource <span class="color-pink">$context</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$oldname</strong> (required): The current name or path of the file or directory you want to rename.</li>
                    <li><strong>$newname</strong> (required): The new name or path to which you want to rename or move the file/directory.</li>
                    <li><strong>$context</strong> (optional): A context stream resource, which is rarely used.</li>
                </ul>
                <p>The function returns <span className="background-grey">true</span> on success and returns <span className="background-grey">false</span> if the operation fails.</p>


                <h5 className="mt-5 mb-3">Example : Renaming a File</h5>
                <p>This example shows how to rename a file named <span className="background-grey">oldfile.txt</span> to <span className="background-grey">newfile.txt</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$oldname</span> = <span class="color-green">'oldfile.txt'</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$newname</span> = <span class="color-green">'newfile.txt'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">rename</span>(<span class="color-pink">$oldname</span>, <span class="color-pink">$newname</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"File renamed successfully!"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to rename the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The <span className="background-grey">rename()</span> function in PHP is a simple yet powerful tool for renaming files or directories. It also enables you to move files within the same filesystem. Just make sure to handle cases where files already exist at the destination to avoid accidental overwrites, and ensure the proper file permissions to avoid errors.
                </p>


            </div>
        </section>
    )
}