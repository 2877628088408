import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_combine";

export default function ArrayColumn() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_combine() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_column',
            'next': '/php/func_array_count_values'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_combine() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_combine() function in PHP is used to create an associative array by combining two arrays—one for keys and one for values. The first array provides the keys, and the second array provides the corresponding values. This function is useful when you need to pair two sets of related data, such as labels and values, into a single associative array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_combine</span>(<span class="color-blue">array</span> <span class="color-pink">$keys</span>, <span class="color-blue">array</span> <span class="color-pink">$values</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$keys</strong>: An array of keys.</li>
                    <li><strong>$values</strong>: An array of values.</li>
                </ul>
                <p>Both arrays must have the same number of elements, otherwise, the function will return a warning.</p>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$keys</span> = [<span class="color-green">"name"</span>, <span class="color-green">"age"</span>, <span class="color-green">"city"</span>];</p>
                            <p><span class="color-pink">$values</span> = [<span class="color-green">"Alice"</span>, <span class="color-pink">25</span>, <span class="color-green">"New York"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_combine</span>(<span class="color-pink">$keys</span>, <span class="color-pink">$values</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [name] => Alice</p>
                            <p class="ml-30">    [age] => <span class="color-pink">25</span></p>
                            <p class="ml-30">    [city] => New York</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first array <span className='background-grey'>$keys</span> (<span className='background-grey'>["name", "age", "city"]</span>) provides the keys.</li>
                    <li>The second array <span className='background-grey'>$values</span> (<span className='background-grey'>["Alice", 25, "New York"]</span>) provides the corresponding values.</li>
                    <li>The result is an associative array where <span className='background-grey'>"name"</span> is paired with <span className='background-grey'>"Alice"</span>, <span className='background-grey'>"age"</span> is paired with <span className='background-grey'>25</span>, and <span className='background-grey'>"city"</span> is paired with <span className='background-grey'>"New York"</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Using Numeric Keys and Values</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$keys</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-pink">$values</span> = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_combine</span>(<span class="color-pink">$keys</span>, <span class="color-pink">$values</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => Apple</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => Banana</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => Cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The first array of numeric keys (<span className='background-grey'>[1, 2, 3]</span>) is combined with the second array of fruit names (<span className='background-grey'>["Apple", "Banana", "Cherry"]</span>), resulting in an associative array.</p>


                <h5 className="mt-5 mb-3">Example 3: Error Handling</h5>
                <p>If the two input arrays have different lengths, <span className='background-grey'>array_combine()</span> will return <span className='background-grey'>false</span> and trigger an error.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$keys</span> = [<span class="color-green">"name"</span>, <span class="color-green">"age"</span>];</p>
                            <p><span class="color-pink">$values</span> = [<span class="color-green">"Alice"</span>, <span class="color-pink">25</span>, <span class="color-green">"New York"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_combine</span>(<span class="color-pink">$keys</span>, <span class="color-pink">$values</span>);</p>
                            <br />
                            <p><span class="color-blue">if</span> (<span class="color-pink">$result</span> === <span class="color-blue">false</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Error: The arrays must have the same number of elements."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-red">Error: The arrays must have the same number of elements.</p>
                        `
                    }}></div>
                </div>
                <p>Since the arrays have different lengths (<span className='background-grey'>$keys</span> has 2 elements, <span className='background-grey'>$values</span> has 3), the function fails and returns <span className='background-grey'>false</span>.</p>
            </div>
        </section>
    )
}