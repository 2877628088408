import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getLine";

export default function GetLine() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getLine() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getMessage',
            'next': '/php/func_getPrevious'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getLine() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getLine()</span> function in the PHP <span className="background-grey">Exception</span> class is used to retrieve the line number in the script where the exception was thrown. This is helpful for debugging purposes, as it provides precise information about where the error occurred within the code.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getLine</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getLine()</strong> returns the line number where the exception was triggered.</li>
                </ul>
                <p>
                    The <span className="background-grey">getLine()</span> function is primarily used in debugging to pinpoint the exact location in the script that caused the exception. By combining this with other methods like <span className="background-grey">getMessage()</span> and <span className="background-grey">getFile()</span>, you can get detailed information about the error's source and nature.
                </p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getLine()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60 color-grey">        // Simulate an error by dividing by zero</p>
                        <p class="ml-60">        <span class="color-pink">$result</span> = <span class="color-pink">10</span> / <span class="color-pink">0</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>() . <span class="color-green">"&lt;br&gt;"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Line: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getLine</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Error</span>: Division by zero</p>
                            <p><span class="color-blue">Line</span>: <span class="color-pink">4</span></p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>An exception is triggered when dividing by zero.</li>
                    <li><span className="background-grey">getLine()</span> returns the line number (<span className="background-grey">4</span>), where the division by zero occurred.</li>
                </ul>


            </div>
        </section>
    )
}