import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/php-with-mysql";

export default function PhpMysql() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to MySQL in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/file-uploads',
            'next': '/php/crud-operation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Introduction to MySQL</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MySQL is a popular open-source relational database management system (RDBMS) that is widely used in web development to store and manage data. When working with PHP, you can interact with MySQL databases to perform various operations like creating tables, inserting data, retrieving data, updating records, and deleting data.
                </p>
                <h5 className='mt-5 mb-3'>Why Use MySQL with PHP?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Database Integration</strong>: MySQL is commonly used with PHP because of its speed, reliability, and ease of use. It allows you to create dynamic, data-driven websites where the content is stored in a database.</li>
                    <li><strong>Widespread Support</strong>: MySQL is supported by most web hosting providers, making it an accessible option for web developers.</li>
                    <li><strong>Scalability</strong>: MySQL can handle large databases efficiently, making it suitable for both small and large applications.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Connecting to MySQL Using PHP</h5>
                <p>
                    To interact with a MySQL database, you first need to establish a connection using PHP. PHP provides two main extensions to connect with MySQL:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>MySQLi (MySQL Improved Extension)</strong>: A more secure and feature-rich extension for interacting with MySQL databases.</li>
                    <li><strong>PDO (PHP Data Objects)</strong>: A database access layer providing a uniform method of access to multiple databases, including MySQL.</li>
                </ul>
                <p>
                    Lets see both ways with an example -
                </p>
                <h6 className='mt-3'>Example of Connecting to MySQL Using MySQLi:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-pink">$servername</span> = <span class="color-green">"localhost"</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"username"</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$password</span> = <span class="color-green">"password"</span>;</p>
                            <p class="ml-30">    <span class="color-pink">$dbname</span> = <span class="color-green">"database_name"</span>;</p>
                            <br />    
                            <p class="ml-30 color-grey">    // Create connection</p>
                            <p class="ml-30">    <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">mysqli</span>(<span class="color-pink">$servername</span>, <span class="color-pink">$username</span>, <span class="color-pink">$password</span>, <span class="color-pink">$dbname</span>);</p>
                            <br />    
                            <p class="ml-30 color-grey">    // Check connection</p>
                            <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$conn</span>->connect_error) {</p>
                            <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Connection failed: "</span> . <span class="color-pink">$conn</span>->connect_error);</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Connected successfully"</span>;</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">$servername</span>, <span class="background-grey">$username</span>, <span class="background-grey">$password</span>, <span class="background-grey">$dbname</span>: Variables storing the connection details.</li>
                    <li><span class="background-grey">new mysqli(...)</span>: Creates a new MySQLi connection.</li>
                    <li><span class="background-grey">$conn-&gt;connect_error</span>: Checks if the connection was successful.</li>
                </ul>

                <h6 className='mt-5'>Example of Connecting to MySQL Using PDO:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dsn</span> = <span class="color-green">"mysql:host=localhost;dbname=database_name"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$password</span> = <span class="color-green">"password"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-pink">$conn</span> = <span class="color-blue">new</span> <span class="color-red">PDO</span>(<span class="color-pink">$dsn</span>, <span class="color-pink">$username</span>, <span class="color-pink">$password</span>);</p>
                        <p class="ml-60">        <span class="color-pink">$conn</span>-><span class="color-red">setAttribute</span>(PDO::<span class="color-pink">ATTR_ERRMODE</span>, PDO::<span class="color-pink">ERRMODE_EXCEPTION</span>);</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected successfully"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span>(PDOException <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connection failed: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">$dsn</span>: Data Source Name, which includes the database type, host, and database name.</li>
                    <li><span class="background-grey">new PDO(...)</span>: Creates a new PDO connection.</li>
                    <li><span class="background-grey">setAttribute(...)</span>: Sets an attribute, such as error mode, for the PDO connection.</li>
                    <li><span class="background-grey">catch(PDOException $e)</span>: Catches any connection error and outputs the error message.</li>
                </ul>
            </div>
        </section>
    )
}