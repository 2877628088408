import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ucwords";

export default function UCWords() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ucwords() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ucfirst',
            'next': '/php/func_trim'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ucwords() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The ucwords() function in PHP is used to capitalize the first letter of each word in a string. This is particularly useful for formatting text, such as titles, names, or any content where proper capitalization of multiple words is necessary
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ucwords</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">string</span> <span class="color-pink">$delimiters</span> = <span class="color-green">" \t\r\n\f\v"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span>$string</span>: The input string where you want to capitalize the first letter of each word.</li>
                    <li><span>$delimiters</span>: (Optional) This is a string that specifies the characters that define the boundaries of words. By default, word boundaries are defined by space, tab, and other whitespace characters.</li>
                    <li><span>Return Value</span>: It returns a new string where the first letter of each word is capitalized.</li>
                </ul>

                <h5 className="mt-5 mb-3">Basic Example of Usage</h5>
                <p>Here is a basic example of using the ucfirst() function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"hello world from php!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$capitalizedText</span> = <span class="color-red">ucwords</span>(<span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$capitalizedText</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Hello World From Php!</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string <span className="background-grey">"hello world from php!"</span> is converted to <span className="background-grey">"Hello World From Php!"</span>. The first letter of each word has been capitalized.</p>
            </div>
        </section>
    )
}