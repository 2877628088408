import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/loop/control/structure";

export default function LoopControl() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("All Loop Control Structures in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/operators',
            'next': '/php/create/form'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Control Structures in PHP</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Control structures in PHP determine the flow of execution of the script. They allow you to make decisions, repeat code, and handle multiple possible outcomes. Here's an overview of the main control structures in PHP:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5><span class="background-grey">if</span> Statement</h5>
                        <p>Executes a block of code only if a specified condition is true. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (condition) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if condition is true</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$age</span> = <span class="color-pink">18</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$age</span> >= <span class="color-pink">18</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"You are an adult."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In the above example, The code inside the if block runs only if the condition ($age &gt;= 18) is true.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">if...else</span> Statement</h5>
                        <p>
                            It executes one block of code if a condition is true and another block if the condition is false. It has a basic syntax -
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (condition) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if condition is true</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if condition is false</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">$age</span> = <span class="color-pink">18</span>;</p>
                                <p><span class="color-blue">if</span> (<span class="color-pink">$age</span> >= <span class="color-pink">18</span>) {</p>
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"You are an adult."</span>;</p>
                                <p>}</p>
                                <p><span class="color-blue">else</span> {</p>
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"You are a minor."</span>;</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Here, if the condition ($age &gt;= 18) is false, the code inside the else block runs.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">if...elseif...else</span> Statement</h5>
                        <p>
                            It tests multiple conditions in sequence and executes the corresponding block of code for the first true condition. Syntax is -
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (condition1) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if condition1 is true</p>
                                    <p>} <span class="color-blue">elseif</span> (condition2) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if condition1 is false and condition2 is true</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed if both condition1 and condition2 are false</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$score</span> = <span class="color-pink">85</span>;</p>
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$score</span> >= <span class="color-pink">90</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</sppan> <span class="color-green">"Grade: A"</span>;</p>
                                    <p>} <span class="color-blue">elseif</span> (<span class="color-pink">$score</span> >= <span class="color-pink">80</span>) {</p>
                                    <p class="ml-30">   <span class="color-blue"> echo</span> <span class="color-green">"Grade: B"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Grade: C"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This example checks each condition in order. If $score &gt;= 90, it outputs "Grade: A". If not, it checks $score &gt;= 80, and so on.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">switch</span> Statement</h5>
                        <p>It is similar to <span class="background-grey">if...elseif...else</span>, but often more efficient and readable when you need to compare the same variable to different values. It has a basic syntax -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">switch</span> (expression) {</p>
                                    <p class="ml-30">    <span class="color-blue">case</span> value1:</p>
                                    <p class="ml-60 color-grey">        // Code to be executed if expression equals value1</p>
                                    <p class="ml-30">        <span class="color-blue">break</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">case</span> value2:</p>
                                    <p class="ml-60 color-grey">        // Code to be executed if expression equals value2</p>
                                    <p class="ml-30">        <span class="color-blue">break</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">default</span>:</p>
                                    <p class="ml-60 color-grey">        // Code to be executed if expression does not match any case</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$day</span> = <span class="color-green">"Tuesday"</span>;</p>
                                    <p><span class="color-blue">switch</span> (<span class="color-pink">$day</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Monday"</span>:</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Today is Monday."</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">break</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Tuesday"</span>:</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Today is Tuesday."</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">break</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">case</span> <span class="color-green">"Wednesday"</span>:</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Today is Wednesday."</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">break</span>;</p>
                                    <p class="ml-30">    <span class="color-blue">default</span>:</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Unknown day."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this example, the <span class="background-grey">switch</span> statement compares <span class="background-grey">$day</span> with each case. If <span class="background-grey">$day</span> matches "Tuesday", the corresponding block is executed, and the <span class="background-grey">break</span> statement stops further checks.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">while</span> Loop</h5>
                        <p>It repeats a block of code as long as a specified condition is true. Look at the syntax below -</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">while</span> (condition) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$i</span> = <span class="color-pink">1</span>;</p>
                                    <p><span class="color-blue">while</span> (<span class="color-pink">$i</span> <= <span class="color-pink">5</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The number is: $i &lt;br&gt;"</span>;</p>
                                    <p class="ml-30">    <span class="color-pink">$i</span>++;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            The loop continues running as long as <span class="background-grey">$i &lt;= 5</span>.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">do...while</span> Loop</h5>
                        <p>It is similar to the <span class="background-grey">while</span> loop, but it guarantees the block of code is executed at least once.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">do</span> {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed</p>
                                    <p>} <span class="color-blue">while</span> (condition);</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$i</span> = <span class="color-pink">1</span>;</p>
                                    <p><span class="color-blue">do</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The number is: $i <br>"</span>;</p>
                                    <p class="ml-60">    <span class="color-pink">$i</span>++;</p>
                                    <p>} <span class="color-blue">while</span> (<span class="color-pink">$i</span> <= <span class="color-pink">5</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this example, the loop executes the code block first, then checks the condition. If the condition is true, it repeats.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">for</span> Loop</h5>
                        <p>
                            It repeats a block of code a specified number of times.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">for</span> (initialization; condition; increment) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">for</span> (<span class="color-pink">$i</span> = <span class="color-pink">1</span>; <span class="color-pink">$i</span> <= <span class="color-pink">5</span>; <span class="color-pink">$i</span>++) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The number is: $i &lt;br&gt;"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            The loop runs from <span class="background-grey">$i = 1</span> to <span class="background-grey">$i = 5</span>, incrementing <span class="background-grey">$i</span> each time.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">foreach</span> Loop</h5>
                        <p>
                            This loop used to iterate over arrays, easily accessing each element. This is an basic syntax -
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">foreach</span> (<span class="color-pink">$array</span> <span class="color-blue">as</span> <span class="color-pink">$value</span>) {</p>
                                    <p class="ml-30 color-grey">    // Code to be executed</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$colors</span> = <span class="color-blue">array</span>(<span class="color-green">"red"</span>, <span class="color-green">"green"</span>, <span class="color-green">"blue"</span>);</p>
                                    <p><span class="color-blue">foreach</span> (<span class="color-pink">$colors</span> <span class="color-blue">as</span> <span class="color-pink">$color</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The color is: $color &lt;br&gt;"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>The loop iterates over each element in the <span class="background-grey">$colors</span> array.</p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">break</span> Statement</h5>
                        <p>It exits a loop or switch statement before it naturally concludes.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p class="color-blue">break;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">for</span> (<span class="color-pink">$i</span> = <span class="color-pink">1</span>; <span class="color-pink">$i</span> <= <span class="color-pink">10</span>; <span class="color-pink">$i</span>++) {</p>
                                    <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$i</span> == <span class="color-pink">5</span>) {</p>
                                    <p class="ml-60">        <span class="color-blue">break</span>;  <span class="color-grey">// Exit the loop when $i equals 5</span></p>
                                    <p class="ml-30">    }</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The number is: $i &lt;br&gt;"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>The loop stops execution when <span class="background-grey">$i</span> equals 5.</p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">continue</span> Statement</h5>
                        <p>It skips the current iteration of a loop and proceeds with the next iteration.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p class="color-blue">continue;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">for</span> (<span class="color-pink">$i</span> = <span class="color-pink">1</span>; <span class="color-pink">$i</span> <= <span class="color-pink">5</span>; <span class="color-pink">$i</span>++) {</p>
                                    <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$i</span> == <span class="color-pink">3</span>) {</p>
                                    <p class="ml-60">        <span class="color-blue">continue</span>;  <span class="color-grey">// Skip the iteration when $i equals 3</span></p>
                                    <p class="ml-30">    }</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The number is: $i &lt;br&gt;"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>The loop skips the iteration where <span class="background-grey">$i</span> equals 3 but continues with the next iterations.</p>
                    </li>
                    <li className='operator'>
                        <h5><span class="background-grey">return</span> Statement</h5>
                        <p>This statement ends the execution of a function and returns a value to the calling code.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">return</span> value;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Look at the below example for better understanding.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">function</span> <span class="color-red">sum</span>(<span class="color-pink">$a</span>, <span class="color-pink">$b</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$a</span> + <span class="color-pink">$b</span>;</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">echo</span> <span class="color-red">sum</span>(<span class="color-pink">2</span>,<span class="color-pink"> 3</span>);  <span class="color-grey">// Outputs: 5</span></p>
                                `
                            }}></div>
                        </div>
                        <p>In this example, the <span class="background-grey">sum</span> function returns the sum of <span class="background-grey">$a</span> and <span class="background-grey">$b</span>.</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}