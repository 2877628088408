import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_search";

export default function ArraySearch() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_search() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_reverse',
            'next': '/php/func_array_shift'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_search() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_search() function in PHP is used to search for a specific value within an array and returns the key of the first matching element. If the value is not found, it returns <span className='background-grey'>false</span>. You can also make the search strict, meaning it will check both the value and the type.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_search</span>(<span class="color-blue">mixed</span> <span class="color-pink">$needle</span>, <span class="color-blue">array</span> <span class="color-pink">$haystack</span>, <span class="color-blue">bool</span> <span class="color-pink">$strict</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$needle</strong>: This is the value you are looking for in the array.</li>
                    <li><strong>$haystack</strong>: This is the array where the function will search for the value.</li>
                    <li><strong>$strict</strong>: This optional parameter is used for strict comparison (<span className='background-grey'>===</span>). If it's set to <span className='background-grey'>true</span>, both the value and the type of the <span className='background-grey'>$needle</span> are checked.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Search</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$key</span> = <span class="color-red">array_search</span>(<span class="color-green">"banana"</span>, <span class="color-pink">$array</span>);</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$key</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-pink">1</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value <span className='background-grey'>"banana"</span> is found at index <span className='background-grey'>1</span> in the array.</li>
                    <li><span className='background-grey'>array_search()</span> returns the index/key where the value is found, in this case, <span className='background-grey'>1</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Value Not Found</h5>
                <p>If the value you're searching for is not found in the array, <span className='background-grey'>array_search()</span> returns <span className='background-grey'>false</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$key</span> = <span class="color-red">array_search</span>(<span class="color-green">"grape"</span>, <span class="color-pink">$array</span>);</p>
                            <p><span class="color-blue">if</span> (<span class="color-pink">$key</span> === <span class="color-blue">false</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Value not found."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Value not found.</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value <span className='background-grey'>"grape"</span> is not present in the array, so the function returns <span className='background-grey'>false</span>.</li>
                    <li>The <span className='background-grey'>if</span> condition checks if <span className='background-grey'>$key</span> is <span className='background-grey'>false</span>, indicating that the value was not found.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Associative Array Search</h5>
                <p>When working with associative arrays, <span className='background-grey'>array_search()</span> returns the key (not the index) where the value is found:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$key</span> = <span class="color-red">array_search</span>(<span class="color-green">"cherry"</span>, <span class="color-pink">$array</span>);</p>
                        <p><span class="color-blue">echo</span> <span class="color-pink">$key</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>c</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value <span className='background-grey'>"cherry"</span> is found in the array with the key <span className='background-grey'>"c"</span>.</li>
                    <li><span className='background-grey'>array_search()</span> returns <span className='background-grey'>"c"</span>, which is the key corresponding to <span className='background-grey'>"cherry"</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Searching in a Multidimensional Array</h5>
                <p><span className='background-grey'>array_search()</span> does not directly support searching within a multidimensional array. You need to iterate through the inner arrays or use functions like <span className='background-grey'>array_column()</span> to extract values for searching.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [</p>
                            <p class="ml-30">    [<span class="color-green">"id"</span> => <span class="color-pink">1</span>, <span class="color-green">"name"</span> => <span class="color-green">"apple"</span>],</p>
                            <p class="ml-30">    [<span class="color-green">"id"</span> => <span class="color-pink">2</span>, <span class="color-green">"name"</span> => <span class="color-green">"banana"</span>],</p>
                            <p class="ml-30">    [<span class="color-green">"id"</span> => <span class="color-pink">3</span>, <span class="color-green">"name"</span> => <span class="color-green">"cherry"</span>]</p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$key</span> = <span class="color-red">array_search</span>(<span class="color-pink">2</span>, <span class="color-red">array_column</span>(<span class="color-pink">$array</span>, <span class="color-green">"id"</span>));</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$key</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-pink">1</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We first use <span className='background-grey'>array_column()</span> to extract the <span className='background-grey'>"id"</span> column from the multidimensional array.</li>
                    <li>Then we search for the value <span className='background-grey'>2</span> in the resulting array, and the function returns <span className='background-grey'>1</span>, which is the index of the matching element.</li>
                </ul>
                
            </div>
        </section>
    )
}