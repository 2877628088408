import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/file-handling";

export default function FileHandling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("File Handling in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/statements',
            'next': '/php/reading-a-file'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>File Handling</h3>
            <div className='mt-4 mb-5'>
                <p>
                    File handling in PHP allows you to work with files—creating, reading, writing, and deleting them. It’s an essential part of web development when you need to store, retrieve, or manipulate data files on the server. PHP offers a rich set of functions to handle files effectively.
                </p>
                <h5 className="mt-5 mb-3">Opening a File</h5>
                <p>
                    To work with a file, the first step is to open it. The <span class="background-grey">fopen()</span> function is used to open a file, and it requires two parameters: the name of the file and the mode in which you want to open it.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"filename.txt"</span>, <span class="color-green">"mode"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>Where, Modes in <span class="background-grey">fopen()</span></p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">"r"</span>: Open for reading; starts at the beginning of the file.</li>
                    <li><span class="background-grey">"r+"</span>: Open for reading and writing; starts at the beginning of the file.</li>
                    <li><span class="background-grey">"w"</span>: Open for writing; truncates the file to zero length. If the file does not exist, it attempts to create it.</li>
                    <li><span class="background-grey">"w+"</span>: Open for reading and writing; truncates the file to zero length. If the file does not exist, it attempts to create it.</li>
                    <li><span class="background-grey">"a"</span>: Open for writing; the pointer is at the end of the file. If the file does not exist, it attempts to create it.</li>
                    <li><span class="background-grey">"a+"</span>: Open for reading and writing; the pointer is at the end of the file. If the file does not exist, it attempts to create it.</li>
                    <li><span class="background-grey">"x"</span>: Create and open for writing; if the file already exists, the fopen() call will fail.</li>
                    <li><span class="background-grey">"x+"</span>: Create and open for reading and writing; if the file already exists, the fopen() call will fail.</li>
                </ul>

                <h5 className="mt-5 mb-3">Closing a File</h5>
                <p>
                    After working with a file, it’s important to close it using the <span class="background-grey">fclose()</span> function. This helps free up resources.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">File Existence and Information</h5>
                <p>
                    Before working with a file, it’s often useful to check if the file exists and to retrieve some basic information about it.
                </p>
                <h6 className='mt-3 mb-3'>Checking If a File Exists</h6>
                <p>To check if a file exists, use the <span class="background-grey">file_exists()</span> function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-green">"example.txt"</span>)) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File exists."</span>;</p>
                            <p>} <span class="color-blue">else</span> {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File does not exist."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-3 mb-3'>Getting File Information</h6>
                <p>PHP provides functions to get various file attributes.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">filesize()</span>: Get the size of the file in bytes.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">echo</span> <span class="color-green">"File size: "</span> . <span class="color-red">filesize</span>(<span class="color-green">"example.txt"</span>) . <span class="color-green">" bytes"</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">filemtime()</span>: Get the last modification time of the file.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">echo</span> <span class="color-green">"Last modified: "</span> . <span class="color-red">date</span>(<span class="color-green">"F d Y H:i:s."</span>, <span class="color-red">filemtime</span>(<span class="color-green">"example.txt"</span>));</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">is_readable()</span>: Check if the file is readable.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">is_readable</span>(<span class="color-green">"example.txt"</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is readable."</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is not readable."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">is_writable()</span>: Check if the file is writable.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">is_writable</span>(<span class="color-green">"example.txt"</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is writable."</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File is not writable."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Deleting a File</h5>
                <p>To delete a file, use the <span class="background-grey">unlink()</span> function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-green">"example.txt"</span>)) {</p>
                            <p class="ml-30">    <span class="color-red">unlink</span>(<span class="color-green">"example.txt"</span>);</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File deleted."</span>;</p>
                            <p>} <span class="color-blue">else</span> {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"File does not exist."</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}