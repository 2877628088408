import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_filter";

export default function ArrayFilter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_filter() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_fill_keys',
            'next': '/php/func_array_flip'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_filter() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_filter() function in PHP filters elements of an array using a callback function. It creates a new array containing only the elements that pass the test defined by the callback function. If no callback function is provided, it removes any entries that are <span className='background-grey'>false</span> or equivalent to <span className='background-grey'>false</span>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_filter</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, ?<span class="color-blue">callable</span> <span class="color-pink">$callback</span> = <span class="color-blue">null</span>, <span class="color-blue">int</span> <span class="color-pink">$mode</span> = <span class="color-pink">0</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array to be filtered.</li>
                    <li><strong>$callback</strong>: A function that tests each element of the array. If the callback returns <span className='background-grey'>true</span>, the element is kept in the new array; otherwise, it is removed. If no callback is provided, the function removes any <span className='background-grey'>false</span>-like values from the array.</li>
                    <li>
                        <strong>$mode</strong>: Specifies what to pass to the callback function:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>ARRAY_FILTER_USE_KEY</span>: The callback function will receive the array keys instead of values.</li>
                            <li><span className='background-grey'>ARRAY_FILTER_USE_BOTH</span>: The callback function will receive both keys and values.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Filtering Based on Values</h5>
                <p>You can use <span className='background-grey'>array_filter()</span> to remove specific values from an array based on a custom condition.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_filter</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$value</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$value</span> % <span class="color-pink">2</span> == <span class="color-pink">0</span>;  <span class="color-grey">// Keep only even numbers</span></p>
                            <p>});</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">4</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The callback function filters out odd numbers and keeps only even numbers.</li>
                    <li>The result is an array with only the even numbers <span className='background-grey'>2</span> and <span className='background-grey'>4</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Removing Empty Values</h5>
                <p>You can use <span className='background-grey'>array_filter()</span> without a callback to remove empty or <span className='background-grey'>false</span>-like values from an array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">0</span>, <span class="color-pink">1</span>, <span class="color-blue">false</span>, <span class="color-pink">2</span>, <span class="color-green">''</span>, <span class="color-pink">3</span>, <span class="color-blue">null</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_filter</span>(<span class="color-pink">$array</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">5</span>] => <span class="color-pink">3</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function removes values that are equivalent to <span className='background-grey'>false</span>, including <span className='background-grey'>0</span>, <span className='background-grey'>false</span>, <span className='background-grey'>''</span>, and <span className='background-grey'>null</span>.</li>
                    <li>The resulting array contains only the non-empty values <span className='background-grey'>1</span>, <span className='background-grey'>2</span>, and <span className='background-grey'>3</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Filtering with Keys</h5>
                <p>You can specify that the callback should work with the keys instead of the values by using the <span className='background-grey'>ARRAY_FILTER_USE_KEY</span> mode.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-pink">1</span>, <span class="color-green">"b"</span> => <span class="color-pink">2</span>, <span class="color-green">"c"</span> => <span class="color-pink">3</span>, <span class="color-green">"d"</span> => <span class="color-pink">4</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_filter</span>(<span class="color-pink">$array</span>, <span class="color-blue">function</span>(<span class="color-pink">$key</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-red">in_array</span>(<span class="color-pink">$key</span>, [<span class="color-green">"b"</span>, <span class="color-green">"d"</span>]);  <span class="color-grey">// Keep only keys "b" and "d"</span></p>
                            <p>}, ARRAY_FILTER_USE_KEY);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [b] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [d] => <span class="color-pink">4</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The callback function filters the array based on keys and keeps only the elements with keys <span className='background-grey'>"b"</span> and <span className='background-grey'>"d"</span>.</li>
                    <li>The result is an array containing only those key-value pairs.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 4: Filtering Based on Both Keys and Values</h5>
                <p>Using the <span className='background-grey'>ARRAY_FILTER_USE_BOTH</span> mode, you can filter the array based on both keys and values.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-pink">1</span>, <span class="color-green">"b"</span> => <span class="color-pink">2</span>, <span class="color-green">"c"</span> => <span class="color-pink">3</span>, <span class="color-green">"d"</span> => <span class="color-pink">4</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_filter</span>(<span class="color-pink">$array</span>, <span class="color-red">function</span>(<span class="color-pink">$value</span>, <span class="color-pink">$key</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-pink">$value</span> % <span class="color-pink">2</span> == <span class="color-pink">0</span> && <span class="color-pink">$key</span> != <span class="color-green">"b"</span>;  <span class="color-grey">// Keep even values but exclude key "b"</span></p>
                            <p>}, ARRAY_FILTER_USE_BOTH);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [d] => <span class="color-pink">4</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The callback function checks both keys and values.</li>
                    <li>It keeps only even values (<span className='background-grey'>2</span> and <span className='background-grey'>4</span>) and removes any element with key <span className='background-grey'>"b"</span>, leaving only the key <span className='background-grey'>"d"</span>.</li>
                </ul>
            </div>
        </section>
    )
}