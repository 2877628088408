import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_put";

export default function FtpPut() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_put() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_close',
            'next': '/php/func_ftp_get'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_put() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_put()</span> function in PHP is used to upload a file from your local machine to a remote FTP server. It allows you to transfer files to the server's file system, making it an essential function for managing files through FTP.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_put</span>(ftp_connection, remote_file, local_file, mode, startpos)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource that is returned by <span className="background-grey">ftp_connect()</span>.</li>
                    <li><strong>remote_file</strong> (Required): The path to the destination file on the FTP server. This is where the file will be uploaded.</li>
                    <li><strong>local_file</strong> (Required): The path to the local file on your machine that you want to upload to the FTP server.</li>
                    <li>
                        <strong>mode</strong> (Required): The transfer mode. It can be one of the following:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>FTP_ASCII</strong>: For text files (ASCII mode).</li>
                            <li><strong>FTP_BINARY</strong>: For binary files (like images, videos, executables).</li>
                        </ul>
                    </li>
                    <li><strong>startpos</strong>(Optional): This is the position where the upload will start.It’s useful for resuming an interrupted upload by specifying the byte position.If not provided, it defaults to the beginning of the file.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_put()</span> Function</h5>
                <p>This is an example of uploading a file to an FTP server</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Local file to upload</p>
                        <p class="ml-30">    <span class="color-pink">$local_file</span> = <span class="color-green">"local_path/to/file.txt"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Remote file path on the server</p>
                        <p class="ml-30">    <span class="color-pink">$remote_file</span> = <span class="color-green">"server_path/to/file.txt"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Establish connection to FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected and logged in successfully.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Upload file</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_put</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$remote_file</span>, <span class="color-pink">$local_file</span>, FTP_ASCII)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File uploaded successfully to $remote_file.\n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File upload failed.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Connection</strong>: We establish a connection to the FTP server using <span className="background-grey">ftp_connect()</span>.</li>
                    <li><strong>Login</strong>: We log in to the FTP server using <span className="background-grey">ftp_login()</span>.</li>
                    <li><strong>Upload</strong>: We upload a local file (<span className="background-grey">local_path/to/file.txt</span>) to the server at the destination path (<span className="background-grey">server_path/to/file.txt</span>) using <span className="background-grey">ftp_put()</span>. We specify <span className="background-grey">FTP_ASCII</span> as the mode because it's a text file.</li>
                    <li><strong>Close</strong>: Finally, we close the FTP connection using <span className="background-grey">ftp_close()</span>.</li>
                </ul>


                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected and logged in successfully.</p>
                            <p>File uploaded successfully to server_path/to/file.txt.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}