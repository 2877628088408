import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_pi";

export default function PI() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP pi() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_fmod',
            'next': '/php/func_exp'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP pi() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The pi() function in PHP is used to return the value of π (pi), which is a mathematical constant representing the ratio of a circle's circumference to its diameter. The value of π is approximately 3.14159. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">pi</span>()</p>
                        `
                    }}></div>
                </div>
                <p>The <span className="background-grey">pi()</span> function returns the value of π as a floating-point number.</p>


                <h5 className="mt-5 mb-3">Example : Using π in Calculations</h5>
                <p>
                    You can use the value of π in various mathematical calculations. For instance, if you want to calculate the area of a circle, you can use the formula: <strong>Area</strong> = 𝜋 × 𝑟<sup>2</sup>
                </p>
                <p>where <strong>r</strong> is the radius of the circle.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$radius</span> = <span class="color-pink">5</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$area</span> = <span class="color-red">pi</span>() * <span class="color-red">pow</span>(<span class="color-pink">$radius</span>, <span class="color-pink">2</span>);  <span class="color-grey">// Area = π * r<sup>2</sup></span></p>
                        <p class="ml-30">   <span class="color-blue"> echo</span> <span class="color-green">"Area of the circle: "</span> . <span class="color-pink">$area</span>;  <span class="color-grey">// Output: Area of the circle: 78.53981633974483</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, we calculate the area of a circle with a radius of 5. The <span class="background-grey">pow()</span> function is used to square the radius.</p>

            </div>
        </section>
    )
}