import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_replace";

export default function ArrayReplace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_replace() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_reduce',
            'next': '/php/func_array_reverse'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_replace() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_replace() function in PHP is used to replace values in the first array with values from one or more other arrays. It works by taking elements from subsequent arrays and replacing the elements in the first array that have the same keys. If a key exists in both arrays, the value from the second array will overwrite the value in the first array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_replace</span>(<span class="color-blue">array</span> <span class="color-pink">$array1</span>, <span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array1</strong>: The array in which elements are replaced.</li>
                    <li><strong>...$arrays</strong>: One or more arrays from which the replacement values are taken.</li>
                    <li><strong>Return Value</strong>: The modified array after the replacement. If a key from the replacement array does not exist in the first array, it will be added to the result.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apricot"</span>, <span class="color-green">"c"</span> => <span class="color-green">"citrus"</span>, <span class="color-green">"d"</span> => <span class="color-green">"date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_replace</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [a] => apricot</p>
                        <p class="ml-30">    [b] => banana</p>
                        <p class="ml-30">    [c] => citrus</p>
                        <p class="ml-30">    [d] => date</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The values for keys <span className='background-grey'>"a"</span> and <span className='background-grey'>"c"</span> in <span className='background-grey'>$array1 </span>are replaced by the corresponding values from <span className='background-grey'>$array2</span> (<span className='background-grey'>"apricot"</span> and <span className='background-grey'>"citrus"</span>).</li>
                    <li>The key <span className='background-grey'>"d"</span> does not exist in <span className='background-grey'>$array1</span>, so it is added from <span className='background-grey'>$array2</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Multiple Replacement Arrays</h5>
                <p>You can use more than one replacement array, and each one is processed in the order they are provided.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"b"</span> => <span class="color-green">"blueberry"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                            <p><span class="color-pink">$array3</span> = [<span class="color-green">"c"</span> => <span class="color-green">"cranberry"</span>, <span class="color-green">"d"</span> => <span class="color-green">"date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_replace</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>, <span class="color-pink">$array3</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [a] => apple</p>
                            <p class="ml-30">    [b] => blueberry</p>
                            <p class="ml-30">    [c] => cranberry</p>
                            <p class="ml-30">    [d] => date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The value for key <span className='background-grey'>"b"</span> is replaced by <span className='background-grey'>"blueberry"</span> from <span className='background-grey'>$array2</span>.</li>
                    <li>The value for key <span className='background-grey'>"c"</span> is replaced twice: first by <span className='background-grey'>"cherry"</span> from <span className='background-grey'>$array2</span>, and then by <span className='background-grey'>"cranberry"</span> from <span className='background-grey'>$array3</span>.</li>
                    <li>Key <span className='background-grey'>"d"</span> is added from <span className='background-grey'>$array3</span> since it does not exist in <span className='background-grey'>$array1</span> or <span className='background-grey'>$array2</span>.</li>
                </ul>
            </div>
        </section>
    )
}