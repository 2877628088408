import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/yii";

export default function Yii() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Yii | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks/codeigniter',
            'next': '/php/frameworks/cakephp'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Yii</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with Yii, a high-performance PHP framework, is a great way to build modern web applications. Yii follows the MVC (Model-View-Controller) design pattern and is known for its speed and flexibility. Here’s a step-by-step guide to help you set up and start working with Yii.
                </p>

                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Ensure your environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.4 or higher</li>
                    <li>Composer (dependency manager for PHP)</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>

                <h5 className='mt-4 mb-3'>Step 2: Install Composer</h5>
                <p>Composer is essential for managing Yii dependencies. You can download and install Composer from its <Link to="https://getcomposer.org/">official website</Link>.</p>


                <h5 className='mt-4 mb-3'>Step 3: Install Yii</h5>
                <p>You can create a new Yii project using Composer. Open your terminal and run the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer create-project --prefer-dist yiisoft/yii2-app-basic project-name</p>
                        `
                    }}></div>
                </div>
                <p>This command will create a new Yii project in a directory named <span class="background-grey">project-name</span>.</p>


                <h5 className='mt-4 mb-3'>Step 4: Navigate to Your Project Directory</h5>
                <p>Change into your project directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cd project-name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 5: Configure Your Environment</h5>
                <p>Open the <span class="background-grey">.env</span> file (or create one based on <span class="background-grey">env.example</span>) and set your database connection parameters. If you're using MySQL, it might look like this:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>DB_DSN=mysql:host=localhost;dbname=your_database</p>
                            <p>DB_USER=your_username</p>
                            <p>DB_PASS=your_password</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 6: Set Up Database</h5>
                <p>Create a new database in your database server (e.g., using phpMyAdmin) that matches the name in your configuration.</p>


                <h5 className='mt-4 mb-3'>Step 7: Run Migrations</h5>
                <p>Yii comes with a built-in migration tool. To create the necessary tables, run:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php yii migrate</p>
                        `
                    }}></div>
                </div>
                <p>This command will create the default user table and other necessary tables.</p>



                <h5 className='mt-4 mb-3'>Step 8: Start the Development Server</h5>
                <p>You can start the built-in PHP server with the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php yii serve</p>
                        `
                    }}></div>
                </div>
                <p>Your application will be accessible at <span class="background-grey">http://localhost:8080</span></p>


                <h5 className='mt-4 mb-3'>Step 9: Create Your First Controller</h5>
                <p>Create a new controller in the <span class="background-grey">controllers</span> directory. For example, create a file named <span class="background-grey">SiteController.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">namespace</span> <span class="color-red">app</span>&#92;<span class="color-red">controllers</span>;</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">Yii</span>;</p>
                            <p><span class="color-blue">use</span> <span class="color-red">yii</span>&#92;<span class="color-red">web</span>&#92;<span class="color-red">Controller</span>;</p>
                            <br />
                            <p><span class="color-blue">class</span> <span class="color-yellow">SiteController</span> <span class="color-blue">extends</span> <span class="color-yellow">Controller</span></p>
                            <p>{</p>
                            <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">actionIndex</span>()</p>
                            <p class="ml-30">    {</p>
                            <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>-><span class="color-red">render</span>(<span class="color-green">'index'</span>, [<span class="color-green">'message'</span> => <span class="color-green">'Welcome to Yii!'</span>]);</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 10: Create a View</h5>
                <p>Create a new view file in the <span class="background-grey">views/site</span> directory named <span class="background-grey">index.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h1&gt;&lt;?= $message ?&gt;&lt;/h1&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 11: Set Up Routing</h5>
                <p>By default, Yii uses the <span class="background-grey">SiteController</span> as the main controller. You can configure routes in the <span class="background-grey">config/web.php</span> file. To set your homepage, ensure you have:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">'controller'</span> => <span class="color-green">'site'</span>,</p>
                            <p><span class="color-green">'action'</span> => <span class="color-green">'index'</span>,</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}