import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ucfirst";

export default function UCFirst() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ucfirst() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strtoupper',
            'next': '/php/func_ucwords'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ucfirst() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The ucfirst() function in PHP is used to convert the first character of a string to uppercase while leaving the rest of the string unchanged. It is commonly used for formatting strings, such as capitalizing the first letter of sentences or proper nouns.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ucfirst</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string where you want the first letter to be capitalized.</li>
                    <li><strong>Return Value</strong>: It returns a new string with the first letter capitalized, and all other characters remain the same.</li>
                </ul>

                <h5 className="mt-5 mb-3">Basic Example of Usage</h5>
                <p>Here is a basic example of using the ucfirst() function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"hello, world!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$capitalizedText</span> = <span class="color-red">ucfirst</span>(<span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$capitalizedText</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Hello, world!</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string <span className="background-grey">"hello, world!"</span> is transformed into <span className="background-grey">"Hello, world!"</span>. Only the first character (<span className="background-grey">'h'</span>) is capitalized, and the rest of the string remains as it was.</p>
            </div>
        </section>
    )
}