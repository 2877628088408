import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/oops/introduction";

export default function PHPOOPS() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Introduction to OOP in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/working-with-cookies',
            'next': '/php/oops/constructor/destructor'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Introduction to OOP</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Object-Oriented Programming (OOP) in PHP is a way to organize and structure your code using objects. Instead of writing code in a procedural way (step by step), OOP allows you to group related functions and data together in a more logical and reusable manner.
                </p>
                <h5 className='mt-5 mb-3'>What is OOP?</h5>
                <p>OOP is a programming approach that uses <strong>classes</strong> and <strong>objects</strong>. Here's a simple explanation:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Class</strong>: A class is like a blueprint or template. It defines the structure and behavior (data and functions) that the objects created from it will have. For example, if you were designing a blueprint for a car, the class would describe what the car is (its attributes, like color, model) and what it can do (its methods, like drive, stop).</li>

                    <li><strong>Object</strong>: An object is an instance of a class. When you create a car using the blueprint, that car is an object. Each object can have different attributes (like a red car or a blue car) but will follow the structure and behavior defined by the class.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Why Use OOP?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Modularity</strong>: Code is organized into classes, making it easier to manage and understand.</li>
                    <li><strong>Reusability</strong>: Once a class is defined, you can create multiple objects from it, reusing the same code.</li>
                    <li><strong>Scalability</strong>: OOP makes it easier to expand your codebase. You can add new features without changing existing code.</li>
                    <li><strong>Maintainability</strong>: Since the code is organized into classes, it's easier to locate and fix bugs.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic OOP Concepts in PHP</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Class</strong>
                        <p>A class is defined using the <span className='background-grey'>class</span> keyword. It contains <span className='background-grey'>properties</span> (variables) and <span className='background-grey'>methods</span> (functions).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">class</span> <span class="color-yellow">Car</span> {</p>
                                <p class="ml-30 color-grey">    // Properties</p>
                                <p class="ml-30">    <span class="color-blue">public</span> <span class="color-pink">$color</span>;</p>
                                <p class="ml-30">    <span class="color-blue">public</span> <span class="color-pink">$model</span>;</p>
                                <br />
                                <p class="ml-30 color-grey">    // Methods</p>
                                <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">drive</span>() {</p>
                                <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The car is driving."</span>;</p>
                                <p class="ml-30">    }</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Object</strong>
                        <p>You create an object from a class using the <span className='background-grey'>new</span> keyword.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$myCar</span> = <span class="color-blue">new</span> <span class="color-red">Car</span>();</p>
                                    <p><span class="color-pink">$myCar</span>->color = <span class="color-green">"red"</span>;</p>
                                    <p><span class="color-pink">$myCar</span>->model = <span class="color-green">"Toyota"</span>;</p>
                                    <p><span class="color-pink">$myCar</span>-><span class="color-red">drive</span>(); <span class="color-grey">// Outputs: The car is driving.</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Inheritance</strong>
                        <p>A class can inherit properties and methods from another class using the <span className='background-grey'>extends</span> keyword.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">ElectricCar</span> <span class="color-blue">extends</span> <span class="color-yellow">Car</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">chargeBattery</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"The battery is charging."</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$myElectricCar</span> = <span class="color-blue">new</span> <span class="color-red">ElectricCar</span>();</p>
                                    <p><span class="color-pink">$myElectricCar</span>-><span class="color-red">chargeBattery</span>(); <span class="color-grey">// Outputs: The battery is charging.</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Encapsulation</strong>
                        <p>Encapsulation is the practice of keeping properties and methods within a class private or protected, so they can't be accessed directly from outside the class.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">BankAccount</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">private</span> <span class="color-pink">$balance</span>;</p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">deposit</span>(<span class="color-pink">$amount</span>) {</p>
                                    <p class="ml-60">        <span class="color-pink">$this</span>->balance += <span class="color-pink">$amount</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <br />
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">getBalance</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">return</span> <span class="color-pink">$this</span>->balance;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$account</span> = <span class="color-blue">new</span> <span class="color-red">BankAccount</span>();</p>
                                    <p><span class="color-pink">$account</span>-><span class="color-red">deposit</span>(<span class="color-pink">100</span>);</p>
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$account</span>-><span class="color-red">getBalance</span>(); <span class="color-grey">// Outputs: 100</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Polymorphism</strong>
                        <p>Polymorphism allows objects of different classes to be treated as objects of a common parent class, and it allows methods to have the same name but behave differently based on the object.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Animal</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">makeSound</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Some generic animal sound"</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Dog</span> <span class="color-blue">extends</span> <span class="color-yellow">Animal</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">makeSound</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Bark"</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">class</span> <span class="color-yellow">Cat</span> <span class="color-blue">extends</span> <span class="color-yellow">Animal</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">makeSound</span>() {</p>
                                    <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Meow"</span>;</p>
                                    <p class="ml-30">    }</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-pink">$dog</span> = <span class="color-blue">new</span> <span class="color-red">Dog</span>();</p>
                                    <p><span class="color-pink">$cat</span> = <span class="color-blue">new</span> <span class="color-red">Cat</span>();</p>
                                    <br />
                                    <p><span class="color-pink">$dog</span>-><span class="color-red">makeSound</span>(); <span class="color-grey">// Outputs: Bark</span></p>
                                    <p><span class="color-pink">$cat</span>-><span class="color-red">makeSound</span>(); <span class="color-grey">// Outputs: Meow</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}