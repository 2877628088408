import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/working-with-cookies";

export default function PHPCookies() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Working with Cookies in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/working-with-session',
            'next': '/php/oops/introduction'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Working with Cookies</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Cookies in PHP are small pieces of data that a web server sends to a user's web browser. The browser stores these cookies and sends them back to the server with each subsequent request to the same website. Cookies are often used to store user preferences, session information, or tracking data across multiple pages or visits to a website.
                </p>
                <h5 className='mt-5 mb-3'>How Cookie Work</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Creating (Setting) a Cookie</strong>: The server sends a cookie to the user's browser, which stores it. The cookie contains a name, value, and optionally other information like expiration date, path, domain, and security flags.</li>

                    <li><strong>Storing a Cookie</strong>: The browser saves the cookie and automatically sends it back to the server with each request to the same domain.</li>

                    <li><strong>Accessing (Reading) a Cookie</strong>: The server can access the data stored in the cookie on subsequent requests.</li>

                    <li><strong>Deleting a Cookie</strong>: A cookie can be deleted by setting its expiration date in the past.</li>
                </ul>


                <h5 className='mt-5 mb-3'>Basic Cookie Functions</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Setting a Cookie</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">setcookie</span>(<span class="color-green">"name"</span>, <span class="color-green">"value"</span>, <span class="color-red">time</span>() + (<span class="color-pink">86400</span> * <span class="color-pink">30</span>), <span class="color-green">"/"</span>); <span class="color-grey">// 86400 = 1 day</span></p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul className='mb-4' style={{ listStyle: 'disc' }}>
                            <li><strong>"name"</strong>: The name of the cookie.</li>
                            <li><strong>"value"</strong>: The value stored in the cookie.</li>
                            <li>time() + (86400 * 30): The expiration time (in this case, 30 days from now).</li>
                            <li><strong>"/"</strong>: The path on the server where the cookie is available. "/" means it's available across the entire domain.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Reading a Cookie</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span>(<span class="color-red">isset</span>(<span class="color-pink">$_COOKIE</span>[<span class="color-green">"name"</span>])) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Cookie value: "</span> . <span class="color-pink">$_COOKIE</span>[<span class="color-green">"name"</span>];</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Where, <span class="background-grey">$_COOKIE["name"]</span> accesses the value of the cookie named "name".</p>
                    </li>
                    <li>
                        <strong>Deleting a Cookie</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">setcookie</span>(<span class="color-green">"name"</span>, <span class="color-green">""</span>, <span class="color-red">time</span>() - <span class="color-pink">3600</span>, <span class="color-green">"/"</span>); <span class="color-grey">// Setting expiration in the past deletes the cookie</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This code deletes the cookie by setting its expiration time to a past date.</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}