import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/web-storage";

export default function WebStorage() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Web Storage | Aspirant's Home");
        const urls = {
            'previous': '/html/drag-drop',
            'next': '/html/events'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Web Storage</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Web Storage</strong> in HTML allows websites to store data on the client-side (in the user's browser), making it possible to save information locally without sending it to the server each time. Web Storage is implemented through the <strong>localStorage</strong> and <strong>sessionStorage</strong> APIs, which provide simple key-value storage that can be accessed using JavaScript.
                </p>

                <h5 className='mt-5 mb-3'>Why Use Web Storage?</h5>
                <p>
                    Web Storage is useful for storing user preferences, session data, and other information that should persist between page loads or browser sessions. It offers an alternative to cookies with more storage space, better security, and faster access.
                </p>

                <h5 className='mt-5 mb-3'>Types of Web Storage</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>localStorage</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Stores data with no expiration date.</li>
                            <li>Data persists even when the browser is closed and reopened.</li>
                            <li>Useful for saving long-term data, like user settings or app state.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>sessionStorage</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Stores data only for the duration of the page session.</li>
                            <li>Data is cleared when the page session ends, such as when the browser tab is closed.</li>
                            <li>Useful for temporary data, like keeping track of a user's activity on a specific page.</li>
                        </ul>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>The localStorage Object</h5>
                <p>The <strong>localStorage</strong> object stores the data with no expiration date. The data will not be deleted when the browser is closed, and will be available the next day, week, or year.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// Set data</p>
                            <p>localStorage.<span class="color-red">setItem</span>(<span class="color-green">"key"</span>, <span class="color-green">"value"</span>);</p>
                            <br />
                            <p class="color-grey">// Get data</p>
                            <p>localStorage.<span class="color-red">getItem</span>(<span class="color-green">"key"</span>);</p>
                            <br />
                            <p class="color-grey">// Remove data</p>
                            <p>localStorage.<span class="color-red">removeItem</span>(<span class="color-green">"key"</span>);</p>
                            <br />
                            <p class="color-grey">// Clear all data</p>
                            <p>localStorage.<span class="color-red">clear</span>();</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>The sessionStorage Object</h5>
                <p>The <strong>sessionStorage</strong> object is equal to the localStorage object, except that it stores the data for only one session. The data is deleted when the user closes the specific browser tab.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Set data</p>
                        <p>sessionStorage.<span class="color-red">setItem</span>(<span class="color-green">"key"</span>, <span class="color-green">"value"</span>);</p>
                        <br />
                        <p class="color-grey">// Get data</p>
                        <p>sessionStorage.<span class="color-red">getItem</span>(<span class="color-green">"key"</span>);</p>
                        <br />
                        <p class="color-grey">// Remove data</p>
                        <p>sessionStorage.<span class="color-red">removeItem</span>(<span class="color-green">"key"</span>);</p>
                        <br />
                        <p class="color-grey">// Clear all data</p>
                        <p>sessionStorage.<span class="color-red">clear</span>();</p>
                        `
                    }}></div>
                </div>

                <p>
                    HTML Web Storage is a secure, efficient way to save client-side data that can improve user experience and application performance. By using <strong>localStorage</strong> for long-term storage and <strong>sessionStorage</strong> for temporary data, developers can create responsive web applications that remember user information without needing to rely on the server for every interaction.
                </p>

            </div>
        </section>
    )
}