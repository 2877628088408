import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_deg2rad";

export default function Deg2Rad() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP deg2rad() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_tan',
            'next': '/php/func_rad2deg'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP deg2rad() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">deg2rad()</span> function in PHP is used to convert an angle from degrees to radians. This conversion is important in various mathematical calculations, especially when working with trigonometric functions, which expect angles to be in radians. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">deg2rad</span>(degrees)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>degrees</strong> is the angle that you want to convert to radians.</p>
                <p>The <span className="background-grey">deg2rad()</span> function returns the angle in radians as a floating-point number.</p>


                <h5 className="mt-5 mb-3">Example : Converting Different Angles</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">deg2rad</span>(<span class="color-pink">0</span>);    <span class="color-grey">// Output: 0</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">deg2rad</span>(<span class="color-pink">90</span>);   <span class="color-grey">// Output: 1.5707963267949</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">deg2rad</span>(<span class="color-pink">360</span>);  <span class="color-grey">// Output: 6.2831853071796</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, we convert <strong>0</strong> degrees, <strong>90</strong> degrees, and <strong>360</strong> degrees to radians. The outputs are <strong>0</strong>, <strong>𝜋/2</strong>(approximately 1.5708), and <strong>2π</strong> (approximately 6.2832) respectively.</p>


            </div>
        </section>
    )
}