import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/svg";

export default function SVG() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - SVG | Aspirant's Home");
        const urls = {
            'previous': '/html/embed-multimedia',
            'next': '/html/canvas'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - SVG</h3>
            <div className='mt-4 mb-5'>
                <p>
                    SVG (Scalable Vector Graphics) is an XML-based format for vector graphics that allows for the creation of two-dimensional graphics directly in HTML. SVG graphics are scalable, meaning they can be resized without losing quality, making them ideal for web use. Here’s a detailed overview of SVG in HTML:
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>To embed an SVG graphic in HTML, you can use the &lt;svg&gt; element. Here’s a simple example:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;svg <span class="color-pink">width</span>=<span class="color-green">"100"</span> <span class="color-pink">height</span>=<span class="color-green">"100"</span>&gt;</p>
                        <p class="ml-30">    &lt;circle <span class="color-pink">cx</span>=<span class="color-green">"50"</span> <span class="color-pink">cy</span>=<span class="color-green">"50"</span> <span class="color-pink">r</span>=<span class="color-green">"40"</span> <span class="color-pink">fill</span>=<span class="color-green">"blue"</span> /&gt;</p>
                        <p>&lt;/svg&gt;</p>
                        `
                    }}></div>
                </div>
                <p>It's look like below -</p>
                <div className='outputPalateBox mt-2 mb-4 borderNone'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <svg width="100" height="100">
                            <circle cx="50" cy="50" r="40" fill="blue" />
                        </svg>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Key Components</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mb-4'>
                        <span className='background-grey'>&lt;svg&gt;</span> <strong>Element</strong>: This is the container for all SVG graphics. You can specify the width and height directly on this element.
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>width</span> and <span className='background-grey'>height</span>: Define the dimensions of the SVG canvas.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <strong>Shapes</strong>: Inside the &lt;svg&gt; element, you can define various shapes and graphics, such as:
                        <ul style={{ listStyle: 'disc' }}>
                            <li className='mb-4'>
                                <span className='background-grey'>&lt;circle&gt;</span>: Defines a circle.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;circle <span class="color-pink">cx</span>=<span class="color-green">"50"</span> <span class="color-pink">cy</span>=<span class="color-green">"50"</span> <span class="color-pink">r</span>=<span class="color-green">"40"</span> <span class="color-pink">fill</span>=<span class="color-green">"red"</span> /&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>cx</span> and <span className='background-grey'>cy</span>: Define the center of the circle.</li>
                                    <li><span className='background-grey'>r</span>: Defines the radius of the circle.</li>
                                    <li><span className='background-grey'>fill</span>: Specifies the fill color of the circle.</li>
                                </ul>
                            </li>
                            <li className='mb-4'>
                                <span className='background-grey'>&lt;rect&gt;</span>: Defines a rectangle.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;rect <span class="color-pink">x</span>=<span class="color-green">"10"</span> <span class="color-pink">y</span>=<span class="color-green">"10"</span> <span class="color-pink">width</span>=<span class="color-green">"80"</span> <span class="color-pink">height</span>=<span class="color-green">"80"</span> <span class="color-pink">fill</span>=<span class="color-green">"green"</span> /&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>x</span> and <span className='background-grey'>y</span>: Define the position of the rectangle.</li>
                                    <li><span className='background-grey'>width</span> and <span className='background-grey'>height</span>: Define the dimensions of the rectangle.</li>
                                </ul>
                            </li>
                            <li className='mb-4'>
                                <span className='background-grey'>&lt;line&gt;</span>: Defines a straight line.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;line <span class="color-pink">x1</span>=<span class="color-green">"0"</span> <span class="color-pink">y1</span>=<span class="color-green">"0"</span> <span class="color-pink">x2</span>=<span class="color-green">"100"</span> <span class="color-pink">y2</span>=<span class="color-green">"100"</span> <span class="color-pink">stroke</span>=<span class="color-green">"black"</span> /&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>x1</span>, <span className='background-grey'>y1</span>, <span className='background-grey'>x2</span>, and <span className='background-grey'>y2</span>: Define the start and end points of the line.</li>
                                    <li><span className='background-grey'>stroke</span>: Specifies the color of the line.</li>
                                </ul>
                            </li>
                            <li className='mb-4'>
                                <span className='background-grey'>&lt;polygon&gt;</span>: Defines a shape with multiple sides.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;polygon <span class="color-pink">points</span>=<span class="color-green">"50,15 100,100 0,100"</span> <span class="color-pink">fill</span>=<span class="color-green">"purple"</span> /&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>points</span>: Defines the vertices of the polygon.</li>
                                </ul>
                            </li>
                            <li className='mb-4'>
                                <span className='background-grey'>&lt;path&gt;</span>: Defines a complex shape.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;path <span class="color-pink">d</span>=<span class="color-green">"M10 10 H 90 V 90 H 10 L 10 10"</span> <span class="color-pink">fill</span>=<span class="color-green">"none"</span> <span class="color-pink">stroke</span>=<span class="color-green">"black"</span> /&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <ul style={{ listStyle: 'disc' }}>
                                    <li><span className='background-grey'>d</span>: Contains the commands for drawing the shape.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <strong>Text</strong>: You can also add text to your SVG.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;text <span class="color-pink">x</span>=<span class="color-green">"50"</span> <span class="color-pink">y</span>=<span class="color-green">"50"</span> <span class="color-pink">font-family</span>=<span class="color-green">"Verdana"</span> <span class="color-pink">font-size</span>=<span class="color-green">"20"</span> <span class="color-pink">fill</span>=<span class="color-green">"black"</span>&gt;Hello SVG&lt;/text&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong>Styles</strong>: You can style SVG elements using CSS. For example:
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    .<span class="color-pink">circle</span> {</p>
                                <p class="ml-60">        fill: orange;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;circle <span class="color-pink">class</span>=<span class="color-green">"circle"</span> <span class="color-pink">cx</span>=<span class="color-green">"50"</span> <span class="color-pink">cy</span>=<span class="color-green">"50"</span> <span class="color-pink">r</span>=<span class="color-green">"40"</span> /&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p>SVG is a powerful tool for creating graphics directly in HTML. Its scalability, interactivity, and support for animation make it an excellent choice for web developers looking to enhance the visual appeal and user experience of their websites.</p>


            </div>
        </section>
    )
}