import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/oops/constructor/destructor";

export default function ContructorDestructor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Constructor and Destructor in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/oops/introduction',
            'next': '/php/oops/access-modifier'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <div className='mt-4 mb-5'>
                <h5 className='mt-5 mb-3'>Constructor in PHP</h5>
                <p>
                    A <strong>constructor</strong> is a special method in a class that is automatically called when an object of that class is created. It is used to initialize properties of the object and to perform any setup tasks that are needed when the object is first created.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The constructor method is named <span className='background-grey'>__construct()</span>.</li>
                    <li>It is automatically invoked when an object is instantiated.</li>
                    <li>It can take parameters to initialize object properties.</li>
                    <li>Every class can have only one constructor.</li>
                    <li>It is optional; if you don't define a constructor, PHP will not perform any special action upon object creation.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example of Constructor in PHP</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">class</span> <span class="color-yellow">Car</span> {</p>
                        <p class="ml-60">        <span class="color-blue">public</span> <span class="color-pink">$make</span>;</p>
                        <p class="ml-60">        <span class="color-blue">public</span> <span class="color-pink">$model</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Constructor method</p>
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">__construct</span>(<span class="color-pink">$make</span>, <span class="color-pink">$model</span>) {</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->make = <span class="color-pink">$make</span>;</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->model = <span class="color-pink">$model</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">getDetails</span>() {</p>
                        <p class="ml-90">            <span class="color-blue">return</span> <span class="color-green">"This car is a "</span> . <span class="color-pink">$this</span>->make . <span class="color-green">" "</span> . <span class="color-pink">$this</span>->model . <span class="color-green">"."</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Creating an object of the Car class</p>
                        <p class="ml-30">    <span class="color-pink">$myCar</span> = <span class="color-blue">new</span> <span class="color-red">Car</span>(<span class="color-green">"Toyota"</span>, <span class="color-green">"Corolla"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Displaying the car details</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$myCar</span>-><span class="color-red">getDetails</span>();</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>__construct($make, $model)</span> method is called automatically when <span className='background-grey'>new Car("Toyota", "Corolla")</span> is executed.</li>
                    <li>The constructor initializes the <span className='background-grey'>$make</span> and <span className='background-grey'>$model</span> properties of the object with the values provided when creating the object.</li>
                    <li>The <span className='background-grey'>getDetails()</span> method then returns the details of the car, using the properties initialized by the constructor.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Destructor in PHP</h5>
                <p>
                    A <strong>destructor</strong> is a special method that is automatically called when an object is destroyed or when the script ends. It is used to clean up any resources or perform any final tasks before the object is removed from memory.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The destructor method is named <span className='background-grey'>__destruct()</span>.</li>
                    <li>It is automatically invoked when an object is destroyed, which usually happens at the end of a script or when there are no more references to the object.</li>
                    <li>It is often used for cleanup activities, like closing a database connection or releasing resources.</li>
                    <li>Like constructors, destructors are optional.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Example of Constructor in PHP</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">class</span> <span class="color-yellow">FileHandler</span> {</p>
                        <p class="ml-60">        <span class="color-blue">private</span> <span class="color-pink">$file</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Constructor method</p>
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">__construct</span>(<span class="color-pink">$filename</span>) {</p>
                        <p class="ml-90">            <span class="color-pink">$this</span>->file = <span class="color-red">fopen</span>(<span class="color-pink">$filename</span>, <span class="color-green">"w"</span>);</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File opened.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">writeToFile</span>(<span class="color-pink">$content</span>) {</p>
                        <p class="ml-90">            <span class="color-red">fwrite</span>(<span class="color-pink">$this</span>->file, <span class="color-pink">$content</span>);</p>
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Destructor method</p>
                        <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">__destruct</span>() {</p>
                        <p class="ml-90">            <span class="color-red">fclose</span>(<span class="color-pink">$this</span>->file);</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File closed.\n"</span>;</p>
                        <p class="ml-60">       }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Creating an object of the FileHandler class</p>
                        <p class="ml-30">    <span class="color-pink">$fileHandler</span> = <span class="color-blue">new</span> <span class="color-red">FileHandler</span>(<span class="color-green">"example.txt"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$fileHandler</span>-><span class="color-red">writeToFile</span>(<span class="color-green">"Hello, World!"</span>);</p>
                            
                        <p class="ml-30 color-grey">// The file will be closed automatically when the script ends or when the object is destroyed.</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>__construct($filename)</span> method opens a file for writing when the <span className='background-grey'>FileHandler</span> object is created.</li>
                    <li>The <span className='background-grey'>writeToFile($content)</span> method writes data to the file.</li>
                    <li>The <span className='background-grey'>__destruct()</span> method is called automatically when the object is destroyed (in this case, at the end of the script), and it closes the file.</li>
                </ul>
            </div>
        </section>
    )
}