import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_reverse";

export default function ArrayReverse() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_reverse() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_replace',
            'next': '/php/func_array_search'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_reverse() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_reverse() function in PHP is used to return an array with its elements in reverse order. It does not modify the original array but instead returns a new array with the elements reversed. You can also choose to preserve the keys of the array when reversing.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_reverse</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">bool</span> <span class="color-pink">$preserve_keys</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array that you want to reverse.</li>
                    <li><strong>$preserve_keys</strong>: (Optional) A boolean parameter. If set to <span className='background-grey'>true</span>, the original keys are preserved in the reversed array. The default value is <span className='background-grey'>false</span>, meaning the keys will be re-indexed.</li>
                    <li><strong>Return Value</strong>: The reversed array.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Reversing a Simple Indexed Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>];</p>
                            <p><span class="color-pink">$reversedArray</span> = <span class="color-red">array_reverse</span>(<span class="color-pink">$array</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$reversedArray</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">5</span></p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">4</span></p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">3</span></p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">2</span></p>
                        <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">1</span></p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    The elements of the input array <span className='background-grey'>[1, 2, 3, 4, 5]</span> are reversed, and the resulting array is <span className='background-grey'>[5, 4, 3, 2, 1]</span>.
                    The keys are re-indexed starting from <span className='background-grey'>0</span>.
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Reversing an Associative Array</h5>
                <p>When reversing an associative array, the keys are also reversed unless you set <span className='background-grey'>preserve_keys</span> to <span className='background-grey'>true</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$reversedArray</span> = <span class="color-red">array_reverse</span>(<span class="color-pink">$array</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$reversedArray</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => cherry</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => apple</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The values are reversed, but the keys are not preserved. The resulting array has keys <span className='background-grey'>[0, 1, 2]</span> and the corresponding values are reversed.</p>

                <h5 className="mt-5 mb-3">Example 3: Reversing an Associative Array and Preserving Keys</h5>
                <p>To keep the original keys when reversing an associative array, set <span className='background-grey'>preserve_keys</span> to <span className='background-grey'>true</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-green">"apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"banana"</span>, <span class="color-green">"c"</span> => <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$reversedArray</span> = <span class="color-red">array_reverse</span>(<span class="color-pink">$array</span>, <span class="color-blue">true</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$reversedArray</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [c] => cherry</p>
                        <p class="ml-30">    [b] => banana</p>
                        <p class="ml-30">    [a] => apple</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    The array is reversed, but the original keys (<span className='background-grey'>"a"</span>, <span className='background-grey'>"b"</span>, <span className='background-grey'>"c"</span>) are preserved.
                    The order of the values is reversed, but the key-value pair structure remains intact.
                </ul>
            </div>
        </section>
    )
}