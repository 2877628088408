import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_close";

export default function FtpClose() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_close() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_login',
            'next': '/php/func_ftp_put'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_close() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_close()</span> function in PHP is used to close an active connection to an FTP server. After completing your FTP operations (such as file transfers or directory listings), it’s important to call <span className="background-grey">ftp_close()</span> to properly terminate the connection and free up system resources.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_close</span>(ftp_connection)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>ftp_connection</strong> is the FTP connection resource that is returned by <span className="background-grey">ftp_connect()</span>. This resource is used to identify the connection that needs to be closed.</p>

                <p>It returns true on success and returns false on failure.</p>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_close()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey"    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-blue">"Connected and logged in successfully.\n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Could not log in.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the FTP connection</p>
                        <p class="ml-30">    <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Connection closed."</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We establish a connection to the FTP server using <span className="background-grey">ftp_connect()</span>.</li>
                    <li>We log in using <span className="background-grey">ftp_login()</span>.</li>
                    <li>After the operations, we use <span className="background-grey">ftp_close()</span> to close the FTP connection.</li>
                </ul>


            </div>
        </section>
    )
}