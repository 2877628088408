import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/script";

export default function Script() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <script> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;script&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;script&gt;</span> tag in HTML is used to embed or reference JavaScript code within an HTML document. JavaScript, which is the primary language for web interactivity, uses the <span class="background-grey">&lt;script&gt;</span> tag to add dynamic functionality, such as form validation, animations, and event handling.
                </p>
                        
                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;script&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>JavaScript code can be directly placed within the <span class="background-grey">&lt;script&gt;</span> tag.</li>
                    <li>JavaScript files can be linked by using the src attribute.</li>
                    <li>The <span class="background-grey">&lt;script&gt;</span> tag can be placed in the <span class="background-grey">&lt;head&gt;</span>, <span class="background-grey">&lt;body&gt;</span>, or before the closing <span class="background-grey">&lt;/body&gt;</span> tag for better performance.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;script&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- JavaScript code goes here --&gt;</p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Including External JavaScript Files</h5>
                <p>The <span class="background-grey">src</span> attribute is used to include an external JavaScript file.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span>&gt;&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>
                        
                <h5 className='mt-5 mb-3'>Examples of <span class="background-grey">&lt;script&gt;</span> Tag Usage</h5>
                <p>Let's see some usage of <span class="background-grey">&lt;script&gt;</span> Tag</p>
                <h6>Example 1: Inline JavaScript</h6>
                <p>An inline <span class="background-grey">&lt;script&gt;</span> tag can be placed within an HTML file to define a simple JavaScript function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Inline Script Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Click the button to see a message&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"showMessage()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">showMessage</span>() {</p>
                        <p class="ml-120">                <span class="color-red">alert</span>(<span class="color-green">"Hello! You clicked the button."</span>);</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>Example 2: External JavaScript File</h6>
                <p>Using an external JavaScript file keeps the code organized and reusable.</p>
                <small>HTML File</small>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;External Script Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span> <span class="color-pink">defer</span>&gt;&lt;/script&gt; <span class="color-grey">&lt;!-- External JavaScript file --&gt;</span></p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;External Script Example&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"showMessage()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <small>External JavaScript File (script.js)</small>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">showMessage</span>() {</p>
                        <p class="ml-30">    <span class="color-red">alert</span>(<span class="color-green">"Hello! This message comes from an external JavaScript file."</span>);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'><span class="background-grey">async</span> and <span class="background-grey">defer</span> Attributes</h5>
                <p>These attributes are useful for optimizing page load times:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <span class="background-grey">async</span>: When used, the script is downloaded and executed as soon as it's available, without waiting for HTML parsing to complete.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span> <span class="color-pink">async</span>&gt;&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">defer</span>: This attribute delays the script's execution until the HTML is fully parsed, making it suitable for scripts that depend on the structure of the HTML document.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span> <span class="color-pink">defer</span>&gt;&lt;/script&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>





            </div>
        </section>
    )
}