import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/canvas";

export default function Canvas() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Canvas | Aspirant's Home");
        const urls = {
            'previous': '/html/svg',
            'next': '/html/geolocation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Canvas</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>&lt;canvas&gt;</span> element in HTML is a powerful feature that allows you to draw graphics on the fly using JavaScript. It provides a bitmap-based drawing surface for rendering 2D shapes, images, and animations. The &lt;canvas&gt; element is particularly useful for game graphics, data visualization, and dynamic graphics. Here’s a detailed overview:
                </p>

                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>To use the <span className='background-grey'>&lt;canvas&gt;</span> element, you need to define it in your HTML:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;canvas <span class="color-pink">id</span>=<span class="color-green">"myCanvas"</span> <span class="color-pink">width</span>=<span class="color-green">"400"</span> <span class="color-pink">height</span>=<span class="color-green">"200"</span>&gt;&lt;/canvas&gt;</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>id</span>: An identifier for the canvas element, which you can use to access it via JavaScript.</li>
                    <li><span className='background-grey'>width</span> <strong>and</strong> <span className='background-grey'>height</span>: Specify the dimensions of the canvas. If not set, the default size is 300 pixels wide and 150 pixels tall.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Drawing on the Canvas</h5>
                <p>You can draw on the canvas using JavaScript. To do this, you first need to get a reference to the canvas element and its drawing context.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;canvas <span class="color-pink">id</span>=<span class="color-green">"myCanvas"</span> <span class="color-pink">width</span>=<span class="color-green">"400"</span> <span class="color-pink">height</span>=<span class="color-green">"200"</span>&gt;&lt;/canvas&gt;</p>
                            <br />
                            <p>&lt;script&gt;</p>
                            <p class="ml-30">    <span class="color-blue">const</span> canvas = document.<span class="color-red">getElementById</span>(<span class="color-green">"myCanvas"</span>);</p>
                            <p class="ml-30">    <span class="color-blue">const</span> ctx = canvas.<span class="color-red">getContext</span>(<span class="color-green">"2d"</span>); <span class="color-grey">// Get the 2D drawing context</span></p>
                            <br />    
                            <p class="ml-30 color-grey">    // Set fill color</p>
                            <p class="ml-30">    ctx.fillStyle = <span class="color-green">"blue"</span>;</p>
                            <p class="ml-30 color-grey">    // Draw a filled rectangle</p>
                            <p class="ml-30">    ctx.<span class="color-red">fillRect</span>(<span class="color-pink">50</span>, <span class="color-pink">50</span>, <span class="color-pink">150</span>, <span class="color-pink">100</span>); <span class="color-grey">// x, y, width, height</span></p>
                            <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Common Drawing Methods</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Drawing Shapes</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Rectangles:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>ctx.<span class="color-red">fillRect</span>(x, y, width, height); <span class="color-grey">// Draw filled rectangle</span></p>
                                            <p>ctx.<span class="color-red">strokeRect</span>(x, y, width, height); <span class="color-grey">// Draw rectangle outline</span></p>
                                            <p>ctx.<span class="color-red">clearRect</span>(x, y, width, height); <span class="color-grey">// Clear a rectangle area</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Circles</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>ctx.<span class="color-red">beginPath</span>(); <span class="color-grey">// Start a new path</span></p>
                                        <p>ctx.<span class="color-red">arc</span>(x, y, radius, startAngle, endAngle, anticlockwise);</p>
                                        <p>ctx.<span class="color-red">fill</span>(); <span class="color-grey">// Fill the circle</span></p>
                                        <p>ctx.<span class="color-red">stroke</span>(); <span class="color-grey">// Outline the circle</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Lines</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>ctx.<span class="color-red">moveTo</span>(x1, y1); <span class="color-grey">// Move the starting point</span></p>
                                            <p>ctx.<span class="color-red">lineTo</span>(x2, y2); <span class="color-grey">// Draw line to the endpoint</span></p>
                                            <p>ctx.<span class="color-red">stroke</span>(); <span class="color-grey">// Render the line</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Drawing Text</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>ctx.font = <span class="color-green">"20px Arial"</span>; <span class="color-grey">// Set font size and style</span></p>
                                <p>ctx.<span class="color-red">fillText</span>(<span class="color-green">"Hello Canvas"</span>, x, y); <span class="color-grey">// Draw filled text</span></p>
                                <p>ctx.<span class="color-red">strokeText</span>(<span class="color-green">"Hello Canvas"</span>, x, y); <span class="color-grey">// Draw outlined text</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Drawing Images</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">const</span> img = <span class="color-blue">new</span> <span class="color-red">Image</span>();</p>
                                    <p>img.src = <span class="color-green">'image.png'</span>; <span class="color-grey">// Image source</span></p>
                                    <p>img.onload = <span class="color-red">function</span>() {</p>
                                    <p class="ml-30">    ctx.<span class="color-red">drawImage</span>(img, x, y, width, height); <span class="color-grey">// Draw image on canvas</span></p>
                                    <p>};</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Animation</h5>
                <p>You can create animations on the canvas by using JavaScript’s <span className='background-grey'>requestAnimationFrame()</span> method to continuously redraw the canvas.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;canvas <span class="color-pink">id</span>=<span class="color-green">"myCanvas"</span> <span class="color-pink">width</span>=<span class="color-green">"400"</span> <span class="color-pink">height</span>=<span class="color-green">"200"</span>&gt;&lt;/canvas&gt;</p>
                        <br />
                        <p>&lt;script&gt;</p>
                        <p class="ml-30">    <span class="color-blue">const</span> canvas = document.<span class="color-red">getElementById</span>(<span class="color-green">"myCanvas"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">const</span> ctx = canvas.<span class="color-red">getContext</span>(<span class="color-green">"2d"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">let</span> x = <span class="color-pink">0</span>; <span class="color-grey">// Initial position</span></p>
                        <br />
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">draw</span>() {</p>
                        <p class="ml-60">        ctx.<span class="color-red">clearRect</span>(<span class="color-pink">0</span>, <span class="color-pink">0</span>, canvas.width, canvas.height); <span class="color-grey">// Clear canvas</span></p>
                        <p class="ml-60">        ctx.fillStyle = <span class="color-green">"blue"</span>;</p>
                        <p class="ml-60">        ctx.<span class="color-red">fillRect</span>(x, <span class="color-pink">50</span>, <span class="color-pink">50</span>, <span class="color-pink">50</span>); <span class="color-grey">// Draw rectangle</span></p>
                        <p class="ml-60">        x += <span class="color-pink">2</span>; <span class="color-grey">// Move rectangle</span></p>
                        <p class="ml-60">        <span class="color-blue">if</span> (x > canvas.width) x = <span class="color-pink">0</span>; <span class="color-grey">// Reset position</span></p>
                        <p class="ml-60">        <span class="color-red">requestAnimationFrame</span>(draw); <span class="color-grey">// Call draw again for animation</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    <span class="color-red">draw</span>(); <span class="color-grey">// Start the animation</span></p>
                        <p>&lt;/script&gt;</p>
                        `
                    }}></div>
                </div>

                <p>The <span className='background-grey'>&lt;canvas&gt;</span> element in HTML is a powerful tool for drawing and animating graphics directly in the browser using JavaScript. It is highly flexible, allowing for the creation of various visual content, including shapes, text, and images, making it ideal for applications like games, interactive visualizations, and custom graphics.</p>

            </div>
        </section>
    )
}