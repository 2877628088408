import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/laravel";

export default function Laravel() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Laravel | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks',
            'next': '/php/frameworks/symphony'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Laravel</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with Laravel, one of the most popular PHP frameworks, is an excellent choice for building modern web applications. Laravel provides a clean syntax, powerful features, and a strong community. Here’s a step-by-step guide to help you set up and start working with Laravel.
                </p>
                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Before you begin, ensure that your development environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.4 or higher</li>
                    <li>Composer (a dependency manager for PHP)</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>

                <h5 className='mt-4 mb-3'>Step 2: Install Composer</h5>
                <p>Composer is essential for managing Laravel dependencies. You can download and install Composer from its <Link to="https://getcomposer.org/">official website</Link>.</p>

                <h5 className='mt-4 mb-3'>Step 3: Install Laravel</h5>
                <p>Once Composer is set up, you can install Laravel using the command line. Open your terminal and run the following command:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer global require laravel/installer</p>
                        `
                    }}></div>
                </div>
                <p>Make sure the Composer's global bin directory is in your system's PATH.</p>

                <h5 className='mt-4 mb-3'>Step 4: Create a New Laravel Project</h5>
                <p>To create a new Laravel project, use the following command in your terminal:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>laravel new project-name</p>
                        `
                    }}></div>
                </div>
                <p>Alternatively, you can use Composer directly:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer create-project --prefer-dist laravel/laravel project-name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 5: Set Up Environment Configuration</h5>
                <p>Navigate to your project directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cd project-name</p>
                        `
                    }}></div>
                </div>
                <p>Copy the example environment configuration file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cp .env.example .env</p>
                        `
                    }}></div>
                </div>
                <p>Open the .env file and configure your database settings and other environment variables:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>DB_CONNECTION=mysql</p>
                            <p>DB_HOST=127.0.0.1</p>
                            <p>DB_PORT=3306</p>
                            <p>DB_DATABASE=your_database</p>
                            <p>DB_USERNAME=your_username</p>
                            <p>DB_PASSWORD=your_password</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 6: Generate Application Key</h5>
                <p>Laravel requires an application key, which is a random string used for encryption. Generate this key by running:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php artisan key:generate</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 7: Run Migrations</h5>
                <p>If you have set up your database and want to create the default tables (like users), you can run the migrations:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php artisan migrate</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 8: Start the Development Server</h5>
                <p>Laravel includes a built-in development server, making it easy to test your application. Start it by running:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php artisan serve</p>
                        `
                    }}></div>
                </div>
                <p>Your application will be accessible at <span class="background-grey">http://localhost:8000</span>.</p>

                <h5 className='mt-4 mb-3'>Step 9: Create Your First Route</h5>
                <p>Open the <span class="background-grey">routes/web.php</span> file and add a new route:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Route</span>::<span class="color-red">get</span>(<span class="color-green">'/'</span>, <span class="color-blue">function</span> () {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-red">view</span>(<span class="color-green">'welcome'</span>);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 10: Create a View</h5>
                <p>Create a new Blade view file in the <span class="background-grey">resources/views</span> directory. For example, create <span class="background-grey">home.blade.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE </span><span class="color-blue">html</span><span class="color-grey">&gt;</span>
                            <p>&lt;html&gt;</p>
                            <p>&lt;head&gt;</p>
                            <p class="ml-30">    &lt;title&gt;Welcome&lt;/title&gt;</p>
                            <p>&lt;/head&gt;</p>
                            <p>&lt;body&gt;</p>
                            <p class="ml-30">    &lt;h1&gt;Welcome to Laravel!&lt;/h1&gt;</p>
                            <p>&lt;/body&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Update your route to use this new view</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Route</span>::<span class="color-red">get</span>(<span class="color-green">'/'</span>, <span class="color-blue">function</span> () {</p>
                            <p class="ml-30">    <span class="color-blue">return</span> <span class="color-red">view</span>(<span class="color-green">'home'</span>);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>

                <div className='mt-5 featureClass'>
                    <p><strong>Official Documentation</strong>: The <Link to="https://laravel.com/docs/11.x">Laravel documentation</Link> is an excellent resource for in-depth information on all features.</p>
                </div>

            </div>
        </section>
    )
}