import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_mt_rand";

export default function MtRand() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP mt_rand() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rand',
            'next': '/php/func_fmod'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP mt_rand() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The mt_rand() function in PHP is used to generate a random integer using the Mersenne Twister algorithm, which is faster and produces better random numbers than the traditional rand() function. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">mt_rand</span>()</p>
                            <p><span class="color-red">mt_rand</span>(min, max)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>min</strong>: (Optional) The lower bound of the range from which the random number is generated. If not specified, the default is <span className="background-grey">0</span>.</li>
                    <li><strong>max</strong>: (Optional) The upper bound of the range. If not specified, the default is <span className="background-grey">PHP_INT_MAX</span>.</li>
                </ul>
                <p>The <span className="background-grey">mt_rand()</span> function returns a random integer within the specified range. If no range is specified, it returns a random number between <span className="background-grey">0</span> and <span className="background-grey">PHP_INT_MAX</span>.</p>


                <h5 className="mt-5 mb-3">Example 1: Generating a Random Number Without Arguments</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">mt_rand</span>();  <span class="color-grey">// Output: Random number like 832123456</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">mt_rand()</span> generates a random number between <span className="background-grey">0</span> and <span className="background-grey">PHP_INT_MAX</span>. The result will vary every time you run it.</p>


                <h5 className="mt-5 mb-3">Example 2: Generating a Random Number Within a Range</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">mt_rand</span>(<span class="color-pink">1</span>, <span class="color-pink">10</span>);  <span class="color-grey">// Output: Random number between 1 and 10</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">mt_rand(1, 10)</span> generates a random number between <span className="background-grey">1</span> and <span className="background-grey">10</span>. Each time you run this code, the result will be a different number within this range.</p>


            </div>
        </section>
    )
}