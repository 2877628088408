import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rewinddir";

export default function RewindDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rewinddir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_readdir',
            'next': '/php/func_scandir'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rewinddir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">rewinddir()</span> function in PHP is used to reset the position of the directory stream (i.e., the directory handle opened by <span className="background-grey">opendir()</span>) back to the beginning. After calling <span className="background-grey">rewinddir()</span>, subsequent calls to <span className="background-grey">readdir()</span> will start reading from the first entry in the directory again.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rewinddir</span>(dir_handle)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>dir_handle</strong> is the directory handle resource returned by the <span className="background-grey">opendir()</span> function. If omitted, it will rewind the last opened directory by <span className="background-grey">opendir()</span>.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">rewinddir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dir</span> = <span class="color-green">"/path/to/directory"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Open the directory</p>
                        <p class="ml-30">    <span class="color-pink">$handle</span> = <span class="color-red">opendir</span>(<span class="color-pink">$dir</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Read the first few files</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"First read:&#92;n"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>) . <span class="color-green">"&#92;n"</span>;  <span class="color-grey">// First file or directory</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>) . <span class="color-green">"&#92;n"</span>;  <span class="color-grey">// Second file or directory</span></p>
                        <br />    
                        <p class="ml-30 color-grey">    // Rewind to the beginning of the directory</p>
                        <p class="ml-30">    <span class="color-red">rewinddir</span>(<span class="color-pink">$handle</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Read the files again from the start</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"After rewind:&#92;n"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>) . <span class="color-green">"&#92;n"</span>;  <span class="color-grey">// First file or directory (again)</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>) . <span class="color-green">"&#92;n"</span>;  <span class="color-grey">// Second file or directory (again)</span></p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">closedir</span>(<span class="color-pink">$handle</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The directory is opened using <span className="background-grey">opendir()</span>, and the directory handle is stored in <span className="background-grey">$handle</span>.</li>
                    <li><span className="background-grey">readdir()</span> is called twice to read the first two entries in the directory.</li>
                    <li><span className="background-grey">rewinddir()</span> resets the directory pointer back to the start.</li>
                    <li>After rewinding, calling <span className="background-grey">readdir()</span> again will return the same entries that were initially read.</li>
                </ul>


            </div>
        </section>
    )
}