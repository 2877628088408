import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_str_replace";

export default function StrReplace() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP str_replace() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_implode',
            'next': '/php/func_substr'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP str_replace() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">str_replace()</span> function in PHP is used to <strong>replace all occurrences of a substring within a string with a new substring</strong>. It allows you to search for specific words or characters within a string and replace them with another value. This function works with both strings and arrays, making it versatile for various use cases. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">str_replace</span>(<span class="color-blue">mixed</span> <span class="color-pink">$search</span>, <span class="color-blue">mixed</span> <span class="color-pink">$replace</span>, <span class="color-blue">mixed</span> <span class="color-pink">$subject</span>, <span class="color-blue">int</span> &<span class="color-pink">$count</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$search</strong>: The substring (or an array of substrings) to search for.</li>
                    <li><strong>$replace</strong>: The substring (or an array of substrings) to replace the search substring with.</li>
                    <li><strong>$subject</strong>: The string (or an array of strings) in which the search and replacement should occur.</li>
                    <li><strong>$count</strong> (optional): If provided, this variable will be filled with the number of replacements made.</li>
                    <li><strong>Return Value</strong>: It returns the modified string (or array of strings) after performing the replacements.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Replace a Word in a String</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"Hello world!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$replacedString</span> = <span class="color-red">str_replace</span>(<span class="color-green">"world"</span>, <span class="color-green">"PHP"</span>, <span class="color-pink">$string</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$replacedString</span>; <span class="color-grey">// Output : Hello PHP!</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Replace Multiple Words</h5>
                <p>You can replace multiple words or characters by passing arrays to both the <span className="background-grey">$search</span> and <span className="background-grey">$replace</span> parameters. For each element in the <span className="background-grey">$search</span> array, the corresponding element in the <span className="background-grey">$replace</span> array is used.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"The sky is blue and the grass is green."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$search</span> = <span class="color-red">array</span>(<span class="color-green">"blue"</span>, <span class="color-green">"green"</span>);</p>
                        <p class="ml-30">    <span class="color-pink">$replace</span> = <span class="color-red">array</span>(<span class="color-green">"red"</span>, <span class="color-green">"yellow"</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$newString</span> = <span class="color-red">str_replace</span>(<span class="color-pink">$search</span>, <span class="color-pink">$replace</span>, <span class="color-pink">$string</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$newString</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The sky is red and the grass is yellow.</p>
                        `
                    }}></div>
                </div>
                <p>In this example, both <span className="background-grey">"blue"</span> and <span className="background-grey">"green"</span> are replaced with <span className="background-grey">"red"</span> and <span className="background-grey">"yellow"</span>, respectively.</p>


                <h5 className="mt-5 mb-3">Counting Replacements with the <span className="background-grey">$count</span> Parameter</h5>
                <p>You can use the optional <span className="background-grey">$count</span> parameter to find out how many replacements were made by the function.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$string</span> = <span class="color-green">"apple, banana, apple, orange"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$replacedString</span> = <span class="color-red">str_replace</span>(<span class="color-green">"apple"</span>, <span class="color-green">"kiwi"</span>, <span class="color-green">$string</span>, <span class="color-pink">$count</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$replacedString</span> . <span class="color-green">"\n"</span>;</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Replacements made: "</span> . <span class="color-pink">$count</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>kiwi, banana, kiwi, orange</p>
                            <p>Replacements made: 2</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the function replaces two occurrences of the word <span className="background-grey">"apple"</span> with <span className="background-grey">"kiwi"</span>, and the <span className="background-grey">$count</span> variable holds the value <span className="background-grey">2</span>.</p>

            </div>
        </section>
    )
}