import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/oops/static-methods-properties";

export default function StaticMethod() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Static Methods and Properties in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/oops/access-modifier',
            'next': '/php/error/handling'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Static Methods and Properties</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In PHP Object-Oriented Programming (OOP), static methods and properties are used to define functionality and data that belong to the class itself, rather than to any particular instance of the class. This means you can access static methods and properties without creating an object of the class.
                </p>

                <h5 className="mt-5 mb-3">Static Properties</h5>
                <p>
                    A static property is a property that belongs to the class itself rather than to instances of the class. This means that the property is shared among all instances of the class, and it can be accessed directly using the class name.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Declared with the <span className='background-grey'>static</span> keyword.</li>
                    <li>Accessed using the <span className='background-grey'>ClassName::$propertyName</span> syntax.</li>
                    <li>Shared among all instances of the class (i.e., a change to a static property affects all instances).</li>
                    <li>Useful for values that should be consistent across all instances, such as a counter or configuration settings.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-blue">class</span> <span class="color-yellow">Counter</span> {</p>
                            <p class="ml-60">        <span class="color-blue">public</span> <span class="color-yellow">static</span> <span class="color-pink">$count</span> = <span class="color-pink">0</span>;</p>
                            <br />    
                            <p class="ml-60">        <span class="color-blue">public function</span> <span class="color-red">__construct</span>() {</p>
                            <p class="ml-90">            <span class="color-yellow">self</span>::<span class="color-pink">$count</span>++;</p>
                            <p class="ml-60">        }</p>
                            <br />    
                            <p class="ml-60">        <span class="color-blue">public </span> <span class="color-yellow">static</span> <span class="color-blue">function</span> <span class="color-red">getCount</span>() {</p>
                            <p class="ml-90">            <span class="color-blue">return</span> <span class="color-yellow">self</span>::<span class="color-pink">$count</span>;</p>
                            <p class="ml-60">        }</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30 color-grey">    // Incrementing the counter by creating new objects</p>
                            <p class="ml-30">    <span class="color-pink">$counter1</span> = <span class="color-blue">new</span> <span class="color-red">Counter</span>();</p>
                            <p class="ml-30">    <span class="color-pink">$counter2</span> = <span class="color-blue">new</span> <span class="color-red">Counter</span>();</p>
                            <p class="ml-30">    <span class="color-pink">$counter3</span> = <span class="color-blue">new</span> <span class="color-red">Counter</span>();</p>
                            <br />    
                            <p class="ml-30 color-grey">    // Accessing the static property</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"The counter value is: "</span> . <span class="color-red">Counter</span>::<span class="color-red">getCount</span>(); <span class="color-grey">// Output: The counter value is: </span></p>
                            ?>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>public static $count = 0;</span>: This line declares a static property <span className='background-grey'>$count</span> that belongs to the <span className='background-grey'>Counter</span> class. It is initialized to 0.</li>
                    <li><span className='background-grey'>self::$count++;</span>: This increments the <span className='background-grey'>$count</span> property every time a new object of the <span className='background-grey'>Counter</span> class is created.</li>
                    <li><span className='background-grey'>Counter::getCount();</span>: This static method returns the current value of the static property <span className='background-grey'>$count</span>. The value is accessed directly through the class name using <span className='background-grey'>self::</span> within the class and <span className='background-grey'>ClassName::</span> outside the class.</li>
                </ul>

                <h5 className="mt-5 mb-3">Static Methods</h5>
                <p>
                    A static method is a method that belongs to the class itself rather than to instances of the class. It can be called directly using the class name without creating an instance of the class.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Declared with the <span className='background-grey'>static</span> keyword.</li>
                    <li>Accessed using the <span className='background-grey'>ClassName::methodName()</span> syntax.</li>
                    <li>Cannot access non-static properties or methods directly (since they belong to instances, not the class itself).</li>
                    <li>Useful for utility functions or operations that don't require any object data.</li>
                </ul>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p class="ml-30">    <span class="color-blue">class</span> <span class="color-yellow">MathOperations</span> {</p>
                            <p class="ml-60">        <span class="color-blue">public</span> <span class="color-yellow">static</span> <span class="color-blue">function</span> <span class="color-red">add</span>(<span class="color-pink">$a</span>, <span class="color-pink">$b</span>) {</p>
                            <p class="ml-90">            <span class="color-blue">return</span> <span class="color-pink">$a</span> + <span class="color-pink">$b</span>;</p>
                            <p class="ml-60">        }</p>
                            <br />    
                            <p class="ml-60">        <span class="color-blue">public</span> <span class="color-yellow">static</span> <span class="color-blue">function</span> <span class="color-red">multiply</span>(<span class="color-pink">$a</span>, <span class="color-pink">$b</span>) {</p>
                            <p class="ml-90">            <span class="color-blue">return</span> <span class="color-pink">$a</span> * <span class="color-pink">$b</span>;</p>
                            <p class="ml-60">        }</p>
                            <p class="ml-30">    }</p>
                            <br />    
                            <p class="ml-30 color-grey">    // Calling static methods without creating an object</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Addition: "</span> . <span class="color-red">MathOperations</span>::<span class="color-red">add</span>(<span class="color-pink">5</span>, <span class="color-pink">10</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: Addition: 15</span></p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Multiplication: "</span> . <span class="color-red">MathOperations</span>::<span class="color-red">multiply</span>(<span class="color-pink">5</span>, <span class="color-pink">10</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: Multiplication: 50</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>public static function add($a, $b)</span>: This is a static method that takes two parameters and returns their sum.</li>
                    <li><span className='background-grey'>MathOperations::add(5, 10);</span>: The static method <span className='background-grey'>add()</span> is called directly using the class name, without creating an instance of the <span className='background-grey'>MathOperations</span> class.</li>
                </ul>
            </div>
        </section>
    )
}