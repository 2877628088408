import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_connect";

export default function FtpConnect() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_connect() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_var_array',
            'next': '/php/func_ftp_login'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_connect() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_connect()</span> function in PHP is used to establish a connection to an FTP server. This is the first step in interacting with a remote FTP server for tasks like uploading, downloading, or managing files. The function returns an FTP stream on success, which can then be used with other FTP functions such as logging in, listing files, or transferring data. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_connect</span>(server, port, timeout)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>server</strong> (Required): The FTP server address, either as a domain name (e.g., <span className="background-grey">"ftp.example.com"</span>) or an IP address (e.g., <span className="background-grey">"192.168.0.1"</span>).</li>
                    <li><strong>port</strong> (Optional): The port to connect to on the FTP server. By default, FTP uses port 21.</li>
                    <li><strong>timeout</strong> (Optional): The timeout in seconds for all network operations. If not specified, the default is 90 seconds.</li>
                </ul>

                <p> It returns an FTP stream (a resource) on success which can be used in subsequent FTP functions and on failure it returns false if the connection to the FTP server fails.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_connect()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Establish connection</p>
                        <p class="ml-30">    <span class="color-pink">$conn_id</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Check if connection is successful</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (!<span class="color-pink">$conn_id</span>) {</p>
                        <p class="ml-60">       <span class="color-red"> die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Connected to $ftp_server successfully."</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the connection when done</p>
                        <p class="ml-30">    <span class="color-red">ftp_close</span>(<span class="color-pink">$conn_id</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>A connection to the FTP server <span className="background-grey">ftp.example.com</span> is attempted using the <span className="background-grey">ftp_connect()</span> function.</li>
                    <li>If the connection fails, an error message is displayed using <span className="background-grey">die()</span>.</li>
                    <li>If successful, a success message is printed, and the connection is later closed with <span className="background-grey">ftp_close()</span>.</li>
                </ul>


                <h5 className="mt-5 mb-3">Specifying Port and Timeout</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_port</span> = <span class="color-pink">21</span>;  <span class="color-grey">// Default FTP port</span></p>
                        <p class="ml-30">    <span class="color-pink">$timeout</span> = <span class="color-pink">120</span>;  <span class="color-grey">// 2-minute timeout</span></p>
                        <br />    
                        <p class="ml-30 color-grey">    // Establish connection with specified port and timeout</p>
                        <p class="ml-30">    <span class="color-pink">$conn_id</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>, <span class="color-pink">$ftp_port</span>, <span class="color-pink">$timeout</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (!<span class="color-pink">$conn_id</span>) {</p>
                        <p class="ml-60">        <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server on port $ftp_port."</span>);</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Connected to $ftp_server successfully with a timeout of $timeout seconds."</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Close the connection when done</p>
                        <p class="ml-30">    <span class="color-red">ftp_close</span>(<span class="color-pink">$conn_id</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, The connection is made to the FTP server <span className="background-grey">ftp.example.com</span> on port <span className="background-grey">21</span> with a timeout of 120 seconds.</p>

            </div>
        </section>
    )
}