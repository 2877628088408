import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/aside";

export default function Aside() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <aside> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;aside&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;aside&gt;</span> tag in HTML is used to define content that is indirectly related to the main content, typically acting as a sidebar or supplementary information. The <span class="background-grey">&lt;aside&gt;</span> element is intended to hold information that can enhance or provide context to the primary content, but it’s not essential to understanding the main text. Examples include sidebars, quotes, links to related articles, or any extra information that complements the primary content.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;aside&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;aside&gt;</span> element is often used to display content like advertisements, related links, quotes, side notes, or other elements that support the main content.</li>
                    <li>Content within <span class="background-grey">&lt;aside&gt;</span> can appear alongside the main content or in a designated sidebar area.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;aside&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Supplementary content goes here --&gt;</p>
                        <p>&lt;/aside&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Aside Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;article&gt;</p>
                        <p class="ml-90">            &lt;h1&gt;Understanding Semantic HTML&lt;/h1&gt;</p>
                        <p class="ml-90">            &lt;p&gt;Semantic HTML elements help convey the meaning of content structure to both users and search engines...&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/article&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;aside&gt;</p>
                        <p class="ml-90">            &lt;h2&gt;Related Articles&lt;/h2&gt;</p>
                        <p class="ml-90">            &lt;ul&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#intro"</span>&gt;Introduction to HTML5&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#elements"</span>&gt;Understanding HTML Elements&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#css"</span>&gt;CSS Basics&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;/aside&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Aside Example</title>
                            </head>
                            <body>
                                <article>
                                    <h1>Understanding Semantic HTML</h1>
                                    <p>Semantic HTML elements help convey the meaning of content structure to both users and search engines...</p>
                                </article>
                                <aside>
                                    <h2>Related Articles</h2>
                                    <ul>
                                        <li><a href="#intro">Introduction to HTML5</a></li>
                                        <li><a href="#elements">Understanding HTML Elements</a></li>
                                        <li><a href="#css">CSS Basics</a></li>
                                    </ul>
                                </aside>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p>In this example-</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;aside&gt;</span> element contains related articles, offering links that provide additional context or information relevant to the main article on "Understanding Semantic HTML."</li>
                    <li>This is a classic use of <span class="background-grey">&lt;aside&gt;</span>, where it offers supplemental content that complements the main article.</li>
                </ul>
                        


            </div>
        </section>
    )
}