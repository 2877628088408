import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_number_format";

export default function NumberFormat() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP number_format() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strrev',
            'next': '/php/func_strcmp'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP number_format() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The number_format() function in PHP is used to format a number with grouped thousands and a specified number of decimal places. It’s particularly useful for displaying numbers in a user-friendly format, such as financial figures, percentages, or any numeric data where readability is important. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">number_format</span>(<span class="color-blue">float</span> <span class="color-pink">$number</span>, <span class="color-blue">int</span> <span class="color-pink">$decimals</span> = <span class="color-pink">0</span>, <span class="color-blue">string</span> <span class="color-pink">$dec_point</span> = <span class="color-green">"."</span>, <span class="color-blue">string</span> <span class="color-pink">$thousands_sep</span> = <span class="color-green">","</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$number</strong>: The number you want to format.</li>
                    <li><strong>$decimals</strong> (optional): The number of decimal points to display. Default is <span className="background-grey">0</span>.</li>
                    <li><strong>$dec_point</strong> (optional): The character to use as the decimal point. Default is <span className="background-grey">.</span> (period).</li>
                    <li><strong>$thousands_sep</strong> (optional): The character to use as the thousands separator. Default is <span className="background-grey">,</span> (comma).</li>
                    <li><strong>Return Value</strong>: Returns the formatted number as a string.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Formatting with Different Decimal Places</h5>
                <p>You can specify how many decimal places you want to display. If you want to round the number to a certain number of decimal places, you can set the <span className="background-grey">decimals</span> parameter.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$number</span> = <span class="color-pink">1234.5678</span>;</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">number_format</span>(<span class="color-pink">$number</span>, <span class="color-pink">0</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: 1,235</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">number_format</span>(<span class="color-pink">$number</span>, <span class="color-pink">1</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: 1,234.6</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">number_format</span>(<span class="color-pink">$number</span>, <span class="color-pink">2</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: 1,234.57</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">number_format</span>(<span class="color-pink">$number</span>, <span class="color-pink">3</span>) . <span class="color-green">"\n"</span>; <span class="color-grey">// Output: 1,234.568</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}