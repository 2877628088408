import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getFile";

export default function GetFile() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getFile() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getCode',
            'next': '/php/func_getMessage'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getFile() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getFile()</span> function in the PHP <span className="background-grey">Exception</span> class is used to retrieve the file name in which the exception was thrown. This function is useful for debugging and error-handling because it tells you the specific location in the code where the exception occurred.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getFile</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getFile()</strong> returns the full path to the file where the exception was thrown..</li>
                </ul>

                <p>The <span className="background-grey">getFile()</span> method provides a way to pinpoint the exact file in which an error or exception was triggered, which is particularly helpful when working with large applications that span multiple files.</p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getFile()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60 color-grey">        // Intentionally throwing an exception</p>
                        <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"Something went wrong"</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error occurred in file: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getFile</span>() . <span class="color-green">"&lt;br&gt;"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Error occurred in file: /path/to/your/file.php</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">throw new Exception()</span> triggers an exception.</li>
                    <li>In the <span className="background-grey">catch</span> block, <span className="background-grey">getFile()</span> retrieves the name of the file where the exception was thrown, including its full path.</li>
                </ul>


            </div>
        </section>
    )
}