import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/summary";

export default function Summary() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <summary> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;summary&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;summary&gt;</span> tag in HTML is used as a clickable heading for the <span class="background-grey">&lt;details&gt;</span> element. It provides a summary or title for the collapsible content created by <span class="background-grey">&lt;details&gt;</span>. When you click on the <span class="background-grey">&lt;summary&gt;</span>, it toggles the visibility of the content inside the <span class="background-grey">&lt;details&gt;</span> element.
                </p>
                <h5 className='mt-5 mb-3'>Structure of <span class="background-grey">&lt;summary&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;summary&gt;</span> element is placed directly inside the <span class="background-grey">&lt;details&gt;</span> element.</li>
                    <li>It acts as a clickable title for the content within <span class="background-grey">&lt;details&gt;</span>, making it user-friendly and easy to expand or collapse content.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">details</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">summary</span>&gt;Title or clickable text goes here&lt;/<span class="color-blue">summary</span>&gt;</p>
                        <p class="ml-30">    &lt;<span class="color-blue">p</span>&gt;Hidden content that will show when expanded.&lt;/<span class="color-blue">p</span>&gt;</p>
                        <p>&lt;/<span class="color-blue">details</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Features of <span class="background-grey">&lt;summary&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The text inside <span class="background-grey">&lt;summary&gt;</span> is always visible and acts as a trigger to show or hide the content.</li>
                    <li>You can style <span class="background-grey">&lt;summary&gt;</span> text with CSS, although it has some browser-default styling.</li>
                    <li>The <span class="background-grey">&lt;summary&gt;</span> tag provides an interactive element without requiring JavaScript to show or hide content.</li>
                    <li>The <span class="background-grey">&lt;summary&gt;</span> tag enhances accessibility by providing a clearly defined clickable area, making it user-friendly for both keyboard and screen reader users.</li>
                    <li>The <span class="background-grey">&lt;summary&gt;</span> tag is supported in all modern browsers. For older browsers, however, you may want to test compatibility.</li>
                </ul>


            </div>
        </section>
    )
}