import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_keys";

export default function ArrayKeys() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_keys() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_key_exists',
            'next': '/php/func_array_map'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_keys() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_keys() function in PHP is used to return all the keys (or a subset of keys) from an array. This function can be helpful when you need to extract just the keys from an array, either for manipulation or to loop over them independently of the values.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_keys</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">mixed</span> <span class="color-pink">$value</span> = <span class="color-blue">null</span>, <span class="color-blue">bool</span> <span class="color-pink">$strict</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array from which to extract the keys.</li>
                    <li><strong>$value</strong> (optional): If specified, the function returns only the keys for the given value.</li>
                    <li><strong>$strict</strong> (optional): If set to <span className='background-grey'>true</span>, the function will use strict comparison (<span className='background-grey'>===</span>) to check for values.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"name"</span> => <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">25</span>, <span class="color-green">"city"</span> => <span class="color-green">"New York"</span>];</p>
                        <p><span class="color-pink">$keys</span> = <span class="color-red">array_keys</span>(<span class="color-pink">$array</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$keys</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => name</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => age</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => city</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The <span className='background-grey'>array_keys()</span> function returns all the keys from the associative array: <span className='background-grey'>"name"</span>, <span className='background-grey'>"age"</span>, and <span className='background-grey'>"city"</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Filtering by Value</h5>
                <p>You can pass a specific value to the <span className='background-grey'>array_keys()</span> function to return only the keys that match that value.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"name"</span> => <span class="color-green">"John"</span>, <span class="color-green">"age"</span> => <span class="color-pink">25</span>, <span class="color-green">"city"</span> => <span class="color-green">"New York"</span>, <span class="color-green">"country"</span> => <span class="color-green">"USA"</span>, <span class="color-green">"city"</span> => <span class="color-green">"Boston"</span>];</p>
                            <p><span class="color-pink">$keys</span> = <span class="color-red">array_keys</span>(<span class="color-pink">$array</span>, <span class="color-green">"New York"</span>);</p>
                            <p><span class="color-red">print_r</span>($keys);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => city</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function searches for the value <span className='background-grey'>"New York"</span> and returns the corresponding key, which is <span className='background-grey'>"city"</span>.</li>
                    <li>Since the value <span className='background-grey'>"New York"</span> is associated with the key <span className='background-grey'>"city"</span>, the key <span className='background-grey'>"city"</span> is returned.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Strict Comparison with ===</h5>
                <p>When using the optional <span className='background-grey'>strict</span> parameter, <span className='background-grey'>array_keys()</span> will perform a strict comparison using the <span className='background-grey'>===</span> operator, which means both the value and the data type must match.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-green">"1"</span>, <span class="color-green">"b"</span> => <span class="color-pink">1</span>, <span class="color-green">"c"</span> => <span class="color-pink">1</span>];</p>
                            <p><span class="color-pink">$keys</span> = <span class="color-red">array_keys</span>(<span class="color-pink">$array</span>, <span class="color-pink">1</span>, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$keys</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => b</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => c</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function searches for the value <span className='background-grey'>1</span> using strict comparison.</li>
                    <li>The key <span className='background-grey'>"a"</span> is not included in the result because its value is the string <span className='background-grey'>"1"</span>, not the integer <span className='background-grey'>1</span>. The keys <span className='background-grey'>"b"</span> and <span className='background-grey'>"c"</span>, which have the value <span className='background-grey'>1</span> as an integer, are returned.</li>
                </ul>

            </div>
        </section>
    )
}