import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_filter_var";

export default function FilterVar() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP filter_var() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_filter_list',
            'next': '/php/func_filter_var_array'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP filter_var() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">filter_var()</span> function in PHP is used to filter and validate data. It can be used to sanitize or validate a variable, such as checking if a string is a valid email address, a URL, an integer, or applying custom filters to clean input data. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">filter_var</span>(variable, filter, options)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>variable</strong>: (Required) The data to be filtered. This can be a string, integer, float, etc.</li>
                    <li><strong>filter</strong>: (Optional) The ID of the filter to apply. The default filter is FILTER_DEFAULT, which performs no filtering.</li>
                    <li><strong>options</strong>: (Optional) An array or a single option that sets the behavior of the filter. Options can be flags or filter options, such as defining ranges for validation.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">filter_var()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$email</span> = <span class="color-green">"test@example.com"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Validate if it's a valid email address</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">filter_var</span>(<span class="color-pink">$email</span>, FILTER_VALIDATE_EMAIL)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Valid email address."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Invalid email address."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">FILTER_VALIDATE_EMAIL</span> filter checks if the given <span className="background-grey">$email</span> is valid.</li>
                    <li>The function returns the filtered data if valid, or <span className="background-grey">false</span> if it's invalid.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example of Sanitizing a URL</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$url</span> = <span class="color-green">"https://www.example.com?search=<script>alert('XSS')</script>"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Sanitize the URL</p>
                        <p class="ml-30">    <span class="color-pink">$clean_url</span> = <span class="color-red">filter_var</span>(<span class="color-pink">$url</span>, FILTER_SANITIZE_URL);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$clean_url</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">FILTER_SANITIZE_URL</span> filter removes any illegal characters from the URL, preventing cross-site scripting (XSS) attacks.</li>
                    <li>The function returns the cleaned version of the URL.</li>
                </ul>

            </div>
        </section>
    )
}