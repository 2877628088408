import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_opendir";

export default function OpenDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP opendir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getcwd',
            'next': '/php/func_readdir'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP opendir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">opendir()</span> function in PHP is used to open a directory for reading. Once a directory is opened, you can use other directory functions like <span className="background-grey">readdir()</span> to read the contents of that directory, and <span className="background-grey">closedir()</span> to close the directory when you're done. It essentially prepares the directory so that you can loop through the files or subdirectories inside it.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">opendir</span>(directory, context)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>directory</strong> (Required): This is the path to the directory that you want to open.</li>

                    <li><strong>context</strong> (Optional): A context resource that modifies the behavior of the <span className="background-grey">opendir()</span> function (usually used for stream handling, rarely needed in simple directory operations).</li>
                </ul>

                <p>On success, it returns a directory handle resource, which is used by other directory functions like <span className="background-grey">readdir()</span> or <span className="background-grey">closedir()</span>. If it fails (e.g., if the directory doesn't exist), it returns false.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">opendir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dir</span> = <span class="color-green">"/path/to/directory"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Open the directory</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$handle</span> = <span class="color-red">opendir</span>(<span class="color-pink">$dir</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Directory opened successfully!&#92;n"</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Read directory contents</p>
                        <p class="ml-60">        <span class="color-blue">while</span> ((<span class="color-pink">$file</span> = <span class="color-red">readdir</span>(<span class="color-pink">$handle</span>)) !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File: "</span> . <span class="color-pink">$file</span> . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Close the directory</p>
                        <p class="ml-60">        <span class="color-red">closedir</span>(<span class="color-pink">$handle</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to open directory.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">opendir()</span> function tries to open the directory <span className="background-grey">/path/to/directory</span>. If successful, it returns a directory handle.</li>
                    <li>The <span className="background-grey">readdir()</span> function is used inside a while loop to read the contents of the directory, one file or directory at a time. It continues until <span className="background-grey">readdir()</span> returns false, indicating there are no more files.</li>
                    <li>After reading the directory contents, <span className="background-grey">closedir()</span> is called to close the directory handle and free up resources.</li>
                </ul>



            </div>
        </section>
    )
}