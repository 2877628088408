import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_error_get_last";

export default function ErrorGetLast() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP error_get_last() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_debug_print_backtrace',
            'next': '/php/func_error_log'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP error_get_last() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">error_get_last()</span> function in PHP retrieves the last error that occurred during script execution. It is commonly used to catch errors that have occurred, especially in cases where traditional error handling mechanisms (such as <span className="background-grey">try/catch</span> or custom error handlers) are not sufficient or appropriate.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">error_get_last</span>()</p>
                        `
                    }}></div>
                </div>
                <p><strong>If an error has occurred</strong>, it returns an associative array describing the last error. <strong>If no error has occurred</strong>, it returns null.</p>

                <p>The array returned by <span className="background-grey">error_get_last()</span> contains the following keys:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">type</span>: The error type (such as E_ERROR, E_WARNING, etc.).</li>
                    <li><span className="background-grey">message</span>: The error message describing what went wrong.</li>
                    <li><span className="background-grey">file</span>: The file in which the error occurred.</li>
                    <li><span className="background-grey">line</span>: The line number where the error occurred.</li>
                </ul>


                <h5 className="mt-5 mb-3">Basic Usage of <span className="background-grey">error_get_last()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Attempt to use an undefined function, which causes an error</p>
                        <p class="ml-30">    <span class="color-red">undefined_function</span>();</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Retrieve the last error</p>
                        <p class="ml-30">    <span class="color-pink">$error</span> = <span class="color-red">error_get_last</span>();</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$error</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error Type: "</span> . <span class="color-pink">$error</span>[<span class="color-green">'type'</span>] . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error Message: "</span> . <span class="color-pink">$error</span>[<span class="color-green">'message'</span>] . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error File: "</span> . <span class="color-pink">$error</span>[<span class="color-green">'file'</span>] . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error Line: "</span> . <span class="color-pink">$error</span>[<span class="color-green">'line'</span>] . <span class="color-green">"&#92;n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"No errors occurred.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Error Type: <span class="color-pink">1</span></p>
                        <p>Error Message: Uncaught Error: Call to undefined function <span class="color-red">undefined_function</span>()</p>
                        <p>Error File: /path/to/file.php</p>
                        <p>Error Line: <span class="color-pink">2</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The script attempts to call an undefined function (<span className="background-grey">undefined_function()</span>), which triggers an error.</li>
                    <li>After the error occurs, <span className="background-grey">error_get_last()</span> retrieves the last error and prints its details (type, message, file, and line number).</li>
                </ul>



            </div>
        </section>
    )
}