import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/drag-drop";

export default function DragDrop() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Drag/Drop | Aspirant's Home");
        const urls = {
            'previous': '/html/geolocation',
            'next': '/html/web-storage'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Drag/Drop</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>Drag and Drop API</strong> in HTML provides a way to create interactive elements that can be dragged and dropped on a web page. It uses JavaScript and a set of events that are triggered during a drag-and-drop interaction. Here’s a clear step-by-step explanation of how it works and how to implement it.
                </p>

                <h5 className='mt-5 mb-3'>Make an Element Draggable</h5>
                <p>To make an element draggable, set the <span class="color-red">draggable</span> attribute to true:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;img <span class="color-pink">draggable</span>=<span class="color-green">"true"</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>What to Drag - ondragstart and setData()</h5>
                <p>Then, specify what should happen when the element is dragged.</p>

                <p>In the example above, the <span class="color-red">ondragstart</span> attribute calls a function, drag(event), that specifies what data to be dragged.</p>

                <p>The <span class="color-red">dataTransfer.setData()</span> method sets the data type and the value of the dragged data:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">drag</span>(ev) {</p>
                        <p class="ml-30">    ev.dataTransfer.<span class="color-red">setData</span>(<span class="color-green">"text"</span>, ev.target.id);</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <p>In this case, the data type is "text" and the value is the id of the draggable element ("drag1").</p>


                <h5 className='mt-5 mb-3'>Where to Drop - ondragover</h5>
                <p>The <span class="color-red">ondragover</span> event specifies where the dragged data can be dropped.</p>

                <p>By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element.</p>

                <p>This is done by calling the <span class="color-red">event.preventDefault()</span> method for the ondragover event:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>event.<span class="color-red">preventDefault</span>()</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Do the Drop - ondrop</h5>
                <p>When the dragged data is dropped, a drop event occurs.</p>

                <p>In the example above, the ondrop attribute calls a function, drop(event):</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">function</span> <span class="color-red">drop</span>(ev) {</p>
                        <p class="ml-30">    ev.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-30">    <span class="color-blue">var</span> data = ev.dataTransfer.<span class="color-red">getData</span>(<span class="color-green">"text"</span>);</p>
                        <p class="ml-30">    ev.target.<span class="color-red">appendChild</span>(document.<span class="color-red">getElementById</span>(data));</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-5 mb-3'>Let's Make An Full Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">HTML</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            #parentDiv {</p>
                        <p class="ml-120">                width: <span class="color-pink">350px</span>;</p>
                        <p class="ml-120">                height: <span class="color-pink">70px</span>;</p>
                        <p class="ml-120">                padding: <span class="color-pink">10px;</span></p>
                        <p class="ml-120">                border: <span class="color-pink">1px</span> solid <span class="color-pink">#aaaaaa</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">allowDropImage</span>(ev) {</p>
                        <p class="ml-120">                ev.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-90">            }</p>
                        <br />            
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">dragImage</span>(ev) {</p>
                        <p class="ml-120">                ev.dataTransfer.<span class="color-pink">setData</span>(<span class="color-green">"content"</span>, ev.target.id);</p>
                        <p class="ml-90">            }</p>
                        <br />            
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">dropElement</span>(ev) {</p>
                        <p class="ml-120">                ev.<span class="color-red">preventDefault</span>();</p>
                        <p class="ml-120">                <span class="color-blue">var</span> data = ev.dataTransfer.<span class="color-red">getData</span>(<span class="color-green">"content"</span>);</p>
                        <p class="ml-120">                ev.target.<span class="color-red">appendChild</span>(document.<span class="color-red">getElementById</span>(data));</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;p&gt;Drag the W3Schools image into the rectangle:&lt;/p&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;div <span class="color-pink">id</span>=<span class="color-green">"parentDiv"</span> <span class="color-pink">ondrop</span>=<span class="color-green">"dropElement(event)"</span> <span class="color-pink">ondragover</span>=<span class="color-green">"allowDropImage(event)"</span>&gt;&lt;/div></p>
                        <p class="ml-60">        &lt;br&gt;</p>
                        <p class="ml-60">        &lt;img <span class="color-pink">id</span>=<span class="color-pink">"imageElement"</span> <span class="color-pink">src</span>=<span class="color-green">"your_image_path"</span> <span class="color-pink">draggable</span>=<span class="color-green">"true"</span> <span class="color-pink">ondragstart</span>=<span class="color-green">"dragImage(event)"</span> <span class="color-pink">width</span>=<span class="color-green">"336"</span> <span class="color-pink">height</span>=<span class="color-green">"69"</span>&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}