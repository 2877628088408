import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_str_repeat";

export default function StrRepeat() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP str_repeat() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_addslashes',
            'next': '/php/func_wordwrap'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP str_repeat() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The str_repeat() function in PHP is used to repeat a string a specified number of times. This function is useful for generating repeated patterns or duplicating text in various scenarios. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">str_repeat</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$multiplier</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string that you want to repeat.</li>
                    <li><strong>$multiplier</strong>: The number of times you want to repeat the string.</li>
                </ul>
                <p>The function returns a new string consisting of the original string repeated the specified number of times. If the multiplier is zero or negative, an empty string is returned.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello "</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$repeated</span> = <span class="color-red">str_repeat</span>(<span class="color-pink">$text</span>, <span class="color-pink">3</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$repeated</span>; <span class="color-grey">// Output: Hello Hello Hello</span> </p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string <span className="background-grey">"Hello "</span> is repeated three times, resulting in <span className="background-grey">"Hello Hello Hello "</span>.</p>


            </div>
        </section>
    )
}