import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/noscript";

export default function NoScript() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <noscript> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;noscript&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;noscript&gt;</span> tag in HTML is used to provide alternate content for users who either have JavaScript disabled in their browsers or whose browsers do not support JavaScript. The content within <span class="background-grey">&lt;noscript&gt;</span> will only be displayed if JavaScript is not enabled, making it a useful tool for enhancing accessibility and improving user experience when JavaScript is unavailable.
                </p>
                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;noscript&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">&lt;noscript&gt;</span> is used to offer a fallback for JavaScript-dependent functionality.</li>
                    <li>Though often placed alongside <span class="background-grey">&lt;script&gt;</span> tags, <span class="background-grey">&lt;noscript&gt;</span> can also stand alone.</li>
                    <li>It allows you to notify users or provide alternative links, forms, or other elements when JavaScript isn't available.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;noscript&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Content to show if JavaScript is disabled --&gt;</p>
                        <p>&lt;/noscript&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;noscript&gt;</span></h5>
                <h6>Example 1: Displaying a Message</h6>
                <p>A simple message informing users about JavaScript dependency.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Noscript Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;noscript&gt;</p>
                        <p class="ml-90">            &lt;p <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;JavaScript is disabled in your browser. Some features may not be available.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/noscript&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90 color-grey"> // JavaScript code</p>
                        <p class="ml-90">            document.<span class="color-red">write</span>(<span class="color-green">"JavaScript is enabled and working!"</span>);</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, if JavaScript is disabled, the message in the <span class="background-grey">&lt;noscript&gt;</span> tag will appear. If JavaScript is enabled, only the "JavaScript is enabled and working!" message is displayed.</p>

                <h6>Example 2: Providing an Alternate Link</h6>
                <p>For sites that rely on JavaScript for navigation or content loading, the <span class="background-grey">&lt;noscript&gt;</span> tag can provide alternative links.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Noscript Example with Link&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Product Information&lt;/h1&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;noscript&gt;</p>
                        <p class="ml-90">            &lt;p&gt;JavaScript is required to view our interactive catalog. Please enable JavaScript, or visit our &lt;a <span class="color-pink">href</span>=<span class="color-green">"catalog.html"</span>&gt;text-based catalog&lt;/a&gt; instead.</p>
                        <p class="ml-60">        &lt;/noscript&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90 color-grey">            // Code to load interactive catalog</p>
                        <p class="ml-90">            document.<span class="color-red">write</span>(<span class="color-green">"&lt;p&gt;Loading interactive catalog...&lt;/p&gt;"</span>);</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this case, if JavaScript is not enabled, users will see a link to a text-based catalog as an alternative to the interactive catalog.</p>


                <p>The <span class="background-grey">&lt;noscript&gt;</span> tag enhances the robustness of web applications by ensuring users can still access essential features or instructions if JavaScript isn't available or enabled.</p>
                        


            </div>
        </section>
    )
}