import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_cos";

export default function Cos() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP cos() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_sin',
            'next': '/php/func_tan'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP cos() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">cos()</span> function in PHP is used to calculate the cosine of an angle. Like the <span className="background-grey">sin()</span> function, the angle must be provided in radians. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">cos</span>(angle)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The angle in radians for which you want to compute the cosine.</p>
                <p>The cos() function returns the cosine of the specified angle. The return value is a floating-point number between -1 and 1.</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage of cos()</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cos</span>(<span class="color-pink">0</span>);  <span class="color-grey">// Output: 1</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">cos(0)</span> calculates the cosine of 0 radians, which is <span className="background-grey">1</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Cosine of a Positive Angle</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">cos</span>(M_PI / <span class="color-pink">2</span>);  <span class="color-grey">// Output: 0</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">cos(M_PI / 2)</span> calculates the cosine of 90 degrees, which is <span className="background-grey">0</span>.</p>

            </div>
        </section>
    )
}