import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_krsort";

export default function Krsort() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP krsort() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ksort',
            'next': '/php/func_asort'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP krsort() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>krsort()</span> function in PHP sorts an associative array by its <strong>keys</strong> in <strong>descending order</strong>. Like <span className='background-grey'>ksort()</span>, it maintains the association between keys and values but reverses the order of the keys.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">krsort</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$flags</span> = SORT_REGULAR)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The associative array to be sorted.</li>
                    <li>
                        <strong>$flags</strong>: (Optional) A sorting type flag that modifies the behavior of how the keys are sorted. Possible values:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>SORT_REGULAR</span> (default): Compare items normally (without changing their types).</li>
                            <li><span className='background-grey'>SORT_NUMERIC</span>: Compare items numerically.</li>
                            <li><span className='background-grey'>SORT_STRING</span>: Compare items as strings.</li>
                            <li><span className='background-grey'>SORT_LOCALE_STRING</span>: Compare items as strings based on the current locale.</li>
                        </ul>
                    </li>
                </ul>
                <p>It returns <span className='background-grey'>true</span> on success, <span className='background-grey'>false</span> on failure.</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [</p>
                            <p class="ml-30">    <span class="color-green">"c"</span> => <span class="color-green">"Orange"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"a"</span> => <span class="color-green">"Apple"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"b"</span> => <span class="color-green">"Banana"</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-red">krsort</span>(<span class="color-pink">$fruits</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$fruits</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [c] => Orange</p>
                            <p class="ml-30">    [b] => Banana</p>
                            <p class="ml-30">    [a] => Apple</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The function sorts the array by its keys (<span className='background-grey'>c</span>, <span className='background-grey'>b</span>, <span className='background-grey'>a</span>) in descending order.</p>


                <h5 className="mt-5 mb-3">Example 2: Sorting Numeric Keys</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [</p>
                            <p class="ml-30">    <span class="color-pink">3</span> => <span class="color-green">"Three"</span>,</p>
                            <p class="ml-30">    <span class="color-pink">1</span> => <span class="color-green">"One"</span>,</p>
                            <p class="ml-30">    <span class="color-pink">2</span> => <span class="color-green">"Two"</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-red">krsort</span>(<span class="color-pink">$numbers</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$numbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => Three</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => Two</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => One</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The array is sorted by its numeric keys (<span className='background-grey'>3</span>, <span className='background-grey'>2</span>, <span className='background-grey'>1</span>).</p>


                <h5 className="mt-5 mb-3">Example 3: Using <span className='background-grey'>SORT_STRING</span> Flag</h5>
                <p>You can use the <span className='background-grey'>SORT_STRING</span> flag to force keys to be compared as strings.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$numbers</span> = [</p>
                        <p class="ml-30">    <span class="color-green">"20"</span> => <span class="color-green">"Twenty"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"10"</span> => <span class="color-green">"Ten"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"2"</span>  => <span class="color-green">"Two"</span></p>
                        <p>];</p>
                        <br />
                        <p><span class="color-red">krsort</span>(<span class="color-pink">$numbers</span>, SORT_STRING);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$numbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">20</span>] => Twenty</p>
                        <p class="ml-30">    [<span class="color-pink">10</span>] => Ten</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => Two</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The keys <span className='background-grey'>"20"</span>, <span className='background-grey'>"10"</span>, and <span className='background-grey'>"2"</span> are compared as strings and sorted in reverse alphabetical order.</p>


                <h5 className="mt-5 mb-3">Example 4: Using <span className='background-grey'>SORT_NUMERIC</span> Flag</h5>
                <p>To sort keys numerically in descending order, use the <span className='background-grey'>SORT_NUMERIC</span> flag.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$numbers</span> = [</p>
                        <p class="ml-30">    <span class="color-green">"20"</span>=> <span class="color-green">"Twenty"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"10"</span> => <span class="color-green">"Ten"</span>,</p>
                        <p class="ml-30">    <span class="color-green">"2"</span>  => <span class="color-green">"Two"</span></p>
                        <p>];</p>
                        <br />
                        <p><span class="color-red">krsort</span>(<span class="color-pink">$numbers</span>, SORT_NUMERIC);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$numbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">20</span>] => Twenty</p>
                        <p class="ml-30">    [<span class="color-pink">10</span>] => Ten</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => Two</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The numeric keys are compared numerically, and they are sorted in descending order as <span className='background-grey'>20</span>, <span className='background-grey'>10</span>, and <span className='background-grey'>2</span>.</p>
            </div>
        </section>
    )
}