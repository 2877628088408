import './App.css';
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Nodejs from "./pages/Nodejs/Index";
import MongoDB from "./pages/MongoDB/Index";
import ReactJS from "./pages/ReactJS/Index";
import HTML from "./pages/HTML/Index";
import Angular from "./pages/Angular/Index";
import Javascript from "./pages/Javascript/Index";
import CSS from "./pages/CSS/Index";
import Mysql from "./pages/Mysql/Index";
import PHP from "./pages/PHP/Index";
import Python from "./pages/Python/Index";
import JQuery from "./pages/JQuery/Index";
import NextJs from "./pages/NextJs/Index";
import Blockchain from "./pages/Blockchain/Index";
import MachineLearning from "./pages/MachineLearning/Index";
import GenericNotFound from './GenericNotFound';
import { MenuContext, TitleContext, PathContext } from "./Context";
import { Helmet } from 'react-helmet';
import Privacy from './Privacy';
import AboutUs from './AboutUs';
import TermService from './TermService';

export default function App() {

    const [isMenu, setIsMenu] = useState(false);
    const [pathName, setPathName] = useState();
    const [urls, setURLs] = useState({});
    const [title, setTitle] = useState("Aspirant's Home");
    const [keywords, setPageKeywords] = useState("");
    const [description, setDescription] = useState("");
    const setMenuOpen = (status) => {
        setIsMenu(status);
    };

    const setPageTitle = (title) => {
        setTitle(title)
    }

    const setKeyWords = (keywords) => {
        setPageKeywords(keywords)
    }

    const setPageDescription = (description) => {
        setDescription(description)
    }

    const setPathNameFn = (path) => {
        setPathName(path);
    }

    const setPreviousNext = (urls) => {
        setURLs(urls);
    }

    const codePalate = document.querySelectorAll('.codePalate'); 
    for (let i of codePalate) {
        i.addEventListener('click', function (e) {
            const elements = document.getElementsByClassName('onetime');
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
            navigator.clipboard.writeText(e.currentTarget.innerText);
            var span = document.createElement('span');
            span.setAttribute('class', 'float-end onetime text-white');
            span.innerHTML = ' copied';
            e.currentTarget.parentNode.prepend(span);
        })
        i.addEventListener('mouseover', function (e) {
            if (e.currentTarget.parentNode.getAttribute('class') != 'onetimecopy')
            {
                var abbr = document.createElement('abbr');
                abbr.setAttribute('class', 'onetimecopy');
                abbr.setAttribute('title', 'Click to Copy');
                e.currentTarget.parentNode.replaceChild(abbr, e.currentTarget);
                abbr.appendChild(e.currentTarget);
            }
        })
    }

    window.addEventListener('contextmenu', function (e) {
        //e.preventDefault();
    }, false);

    return (
        <MenuContext.Provider value={{ isMenu: isMenu, setMenuOpen: setMenuOpen }}>
            <TitleContext.Provider value={{ setPageTitle: setPageTitle, setKeyWords: setKeyWords, setPageDescription: setPageDescription }}>
                <PathContext.Provider value={{ pathName : pathName, setPathName: setPathNameFn, setPreviousNext: setPreviousNext, urls : urls}}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="keywords" content={keywords}></meta>
                        <meta name="description" content={description} />
                    </Helmet>
                
                    <BrowserRouter>
                        <Routes>
                            <Route index path="/" element={<Home />}>
                            </Route>
                            <Route path="/node-js" element={<Nodejs.NodeJsLayout />}>
                                {
                                    Nodejs.Routes.map((route) => {
                                        const NodejsComponent = Nodejs[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<NodejsComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/mongo-db" element={<MongoDB.MongoDBLayout />}>
                                {
                                    MongoDB.Routes.map((route) => {
                                        const MongoDBComponent = MongoDB[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<MongoDBComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/react-js" element={<ReactJS.ReactJSLayout />} errorElement={<GenericNotFound />}>
                                {
                                    ReactJS.Routes.map((route) => {
                                        const ReactJSComponent = ReactJS[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<ReactJSComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/html" element={<HTML.HTMLLayout />}>
                                {
                                    HTML.Routes.map((route) => {
                                        const HTMLComponent = HTML[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<HTMLComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/angular" element={<Angular.AngularLayout />}>
                                {
                                    Angular.Routes.map((route) => {
                                        const AngularComponent = Angular[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<AngularComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/javascript" element={<Javascript.JavascriptLayout />}>
                                {
                                    Javascript.Routes.map((route) => {
                                        const JavascriptComponent = Javascript[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<JavascriptComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/css" element={<CSS.CSSLayout />}>
                                {
                                    CSS.Routes.map((route) => {
                                        const CSSComponent = CSS[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<CSSComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/mysql" element={<Mysql.MysqlLayout />}>
                                {
                                    Mysql.Routes.map((route) => {
                                        const MysqlComponent = Mysql[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<MysqlComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/php" element={<PHP.PHPLayout />}>
                                {
                                    PHP.Routes.map((route) => {
                                        const PHPComponent = PHP[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<PHPComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/python" element={<Python.PythonLayout />}>
                                {
                                    Python.Routes.map((route) => {
                                        const PythonComponent = Python[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<PythonComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/jquery" element={<JQuery.JQueryLayout />}>
                                {
                                    JQuery.Routes.map((route) => {
                                        const JQueryComponent = JQuery[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<JQueryComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/next-js" element={<NextJs.NextJsLayout />}>
                                {
                                    NextJs.Routes.map((route) => {
                                        const NextJsComponent = NextJs[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<NextJsComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/blockchain" element={<Blockchain.BlockchainLayout />}>
                                {
                                    Blockchain.Routes.map((route) => {
                                        const BlockchainComponent = Blockchain[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<BlockchainComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path="/machine-learning" element={<MachineLearning.MachineLearningLayout />}>
                                {
                                    MachineLearning.Routes.map((route) => {
                                        const MachineLearningComponent = MachineLearning[route.component];
                                        return (
                                            <Route
                                                key={route.path}
                                                path={`${route.path}`}
                                                element={<MachineLearningComponent />}
                                            />
                                        );
                                    })
                                }
                            </Route>
                            <Route path='*' element={<GenericNotFound />} />
                            <Route path="/privacy-policy" element={<Privacy />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/terms-of-service" element={<TermService />} />
                        </Routes>
                    </BrowserRouter>
                </PathContext.Provider>
            </TitleContext.Provider>
        </MenuContext.Provider>
    );
}