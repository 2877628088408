import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/statements";

export default function PHPFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Include and Require Statements in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/built-in/functions',
            'next': '/php/file-handling'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Include and Require Statements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In PHP, <span class="background-grey">include</span> and <span class="background-grey">require</span> statements are used to include files within another PHP script. This is particularly useful for code reusability, making your code modular, and organizing your code better. Both statements allow you to insert the content of one PHP file into another before the server executes it.
                </p>

                <h5 className="mt-5 mb-3">Syntax of <span class="background-grey">include</span> and <span class="background-grey">require</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">include</span>: This statement includes the specified file during the execution of the script. If the file is not found, a warning is issued, but the script continues to execute.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">include</span> <span class="color-green">'header.php'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">require</span>: This statement also includes the specified file, but if the file is not found, a fatal error occurs, and the script stops execution.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">require</span> <span class="color-green">'header.php'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className="mt-5 mb-3">Difference between <span class="background-grey">include</span> and <span class="background-grey">require</span></h5>
                <p>The main difference between <span class="background-grey">include</span> and <span class="background-grey">require</span> is in how they handle errors:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">include</span>: If the file to be included is not found, PHP will emit a warning (E_WARNING), but the script will continue to execute.
                    </li>
                    <li>
                        <span class="background-grey">require</span>: If the file to be included is not found, PHP will emit a fatal error (E_COMPILE_ERROR), and the script will stop executing.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">When to Use <span class="background-grey">include</span> vs <span class="background-grey">require</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Use <span class="background-grey">require</span> when the file is essential for the application to run. For example, a configuration file that sets up database connections or essential functions.</li>

                    <li>Use <span class="background-grey">include</span> when the file is not critical to the application’s operation. For example, a footer file or sidebar that is not crucial to the page's functionality.</li>
                </ul>

                <h5 className="mt-5 mb-3">Using <span class="background-grey">include_once</span> and <span class="background-grey">require_once</span></h5>
                <p>
                    Sometimes, you might want to make sure that a file is included only once, even if you accidentally include it multiple times. This is where <span class="background-grey">include_once</span> and <span class="background-grey">require_once</span> come into play.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">include_once</span>: Works like <span class="background-grey">include</span>, but ensures that the file is included only once.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">include_once</span> <span class="color-green">'header.php'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">require_once</span>: Works like <span class="background-grey">require</span>, but ensures that the file is included only once.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">require_once</span> <span class="color-green">'header.php'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>
                    These functions are useful in cases where you might include the same file multiple times, such as when working with large projects where different files might include the same configuration file or utility functions.
                </p>
            </div>
        </section>
    )
}