import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_asort";

export default function Asort() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP asort() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_krsort',
            'next': '/php/func_arsort'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP asort() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The asort() function in PHP is used to sort an <strong>associative array</strong> in <strong>ascending order</strong> based on its <strong>values</strong>, while maintaining the key-value relationships. This means that the keys stay associated with their corresponding values even after sorting.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">asort</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$flags</span> = SORT_REGULAR)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The associative array to be sorted.</li>
                    <li>
                        <strong>$flags</strong>: (Optional) A sorting type flag that modifies the behavior of how the keys are sorted. Possible values:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>SORT_REGULAR</span> (default): Compare items normally (without changing their types).</li>
                            <li><span className='background-grey'>SORT_NUMERIC</span>: Compare items numerically.</li>
                            <li><span className='background-grey'>SORT_STRING</span>: Compare items as strings.</li>
                            <li><span className='background-grey'>SORT_LOCALE_STRING</span>: Compare items as strings based on the current locale.</li>
                            <li><span className='background-grey'>SORT_NATURAL</span>: Compare items as strings using "natural ordering" (like a human would).</li>
                            <li><span className='background-grey'>SORT_FLAG_CASE</span>: Can be combined with <span className='background-grey'>SORT_STRING</span> or <span className='background-grey'>SORT_NATURAL</span> to sort in case-insensitive mode.</li>
                        </ul>
                    </li>
                </ul>
                <p>It returns <span className='background-grey'>true</span> on success, <span className='background-grey'>false</span> on failure.</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [</p>
                            <p class="ml-30">    <span class="color-green">"a"</span> => <span class="color-green">"Orange"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"b"</span> => <span class="color-green">"Banana"</span>,</p>
                            <p class="ml-30">    <span class="color-green">"c"</span> => <span class="color-green">"Apple"</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-red">asort</span>(<span class="color-pink">$fruits</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$fruits</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [c] => Apple</p>
                            <p class="ml-30">    [b] => Banana</p>
                            <p class="ml-30">    [a] => Orange</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The array is sorted in ascending order based on its values (<span className='background-grey'>Apple</span>, <span className='background-grey'>Banana</span>, <span className='background-grey'>Orange</span>), and the keys (<span className='background-grey'>a</span>, <span className='background-grey'>b</span>, <span className='background-grey'>c</span>) remain associated with their original values.</p>


                <h5 className="mt-5 mb-3">Example 2: Sorting Numeric Values</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$numbers</span> = [</p>
                        <p class="ml-30">    <span class="color-green">"first"</span> => <span class="color-pink">42</span>,</p>
                        <p class="ml-30">    <span class="color-green">"second"</span> => <span class="color-pink">100</span>,</p>
                        <p class="ml-30">    <span class="color-green">"third"</span> => <span class="color-pink">23</span></p>
                        <p>];</p>
                        <br />
                        <p><span class="color-red">asort</span>(<span class="color-pink">$numbers</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$numbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [third] => <span class="color-pink">23</span></p>
                        <p class="ml-30">    [first] => <span class="color-pink">42</span></p>
                        <p class="ml-30">    [second] => <span class="color-pink">100</span></p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The array is sorted in ascending order based on the numeric values (<span className='background-grey'>23</span>, <span className='background-grey'>42</span>, <span className='background-grey'>100</span>), with the keys (<span className='background-grey'>third</span>, <span className='background-grey'>first</span>, <span className='background-grey'>second</span>) still associated with their values.</p>


                <h5 className="mt-5 mb-3">Example 3: Sorting with <span className='background-grey'>SORT_STRING</span> Flag</h5>
                <p>You can use the <span className='background-grey'>SORT_STRING</span> flag to force values to be compared as strings, even if they are numeric.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [</p>
                            <p class="ml-30">    <span class="color-green">"first"</span> => <span class="color-pink">100</span>,</p>
                            <p class="ml-30">    <span class="color-green">"second"</span> => <span class="color-pink">25</span>,</p>
                            <p class="ml-30">    <span class="color-green">"third"</span> => <span class="color-pink">50</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-red">asort</span>(<span class="color-pink">$numbers</span>, SORT_STRING);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$numbers</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [first] => <span class="color-pink">100</span></p>
                        <p class="ml-30">    [third] => <span class="color-pink">50</span></p>
                        <p class="ml-30">    [second] => <span class="color-pink">25</span></p>
                        )
                        `
                    }}></div>
                </div>
                <p>Where, The values are treated as strings and sorted lexicographically (based on the first character). Since "100" starts with <span className='background-grey'>1</span>, it is treated as less than "50" (which starts with <span className='background-grey'>5</span>), and so on.</p>

            </div>
        </section>
    )
}