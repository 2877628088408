import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/phalcon";

export default function Phalcon() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Phalcon | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks/cakephp'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Phalcon</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with Phalcon, a high-performance PHP framework delivered as a C extension, can be a great way to build efficient web applications. Phalcon is known for its speed and low resource consumption. Here’s a step-by-step guide to help you set up and start working with Phalcon.
                </p>

                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Ensure your environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.2 or higher</li>
                    <li>Phalcon extension installed</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>

                <h5 className='mt-4 mb-3'>Step 2: Install Phalcon</h5>
                <h6>For Linux</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Install Dependencies</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>sudo apt-get install gcc make pkg-config libpcre3-dev libssl-dev</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Install Phalcon</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>git <span class="color-yellow">clone</span> https://github.com/phalcon/cphalcon.git</p>
                                    <p><span class="color-yellow">cd</span> cphalcon/build</p>
                                    <p>./install</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h6>For Windows</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Download the appropriate DLL from the <Link to="https://github.com/phalcon/cphalcon/releases">Phalcon releases page</Link>.</li>
                    <li>Place the DLL in your PHP extension directory (usually ext).</li>
                    <li>Add the following line to your php.ini file
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">extension</span>=phalcon.dll</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h6>For macOS with Homebrew</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>brew install phalcon</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 3: Install Composer</h5>
                <p>Composer is essential for managing Phalcon dependencies. You can download and install Composer from its <Link to="https://getcomposer.org/">official website</Link>.</p>

                <h5 className='mt-4 mb-3'>Step 4: Create a New Phalcon Project</h5>
                <p>You can create a new Phalcon project using the Phalcon DevTools. First, install Phalcon DevTools:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>composer global require phalcon/devtools</p>
                        `
                    }}></div>
                </div>
                <p>Make sure the Composer global bin directory is in your system’s PATH.</p>


                <h5 className='mt-4 mb-3'>Step 5: Create a Project</h5>
                <p>Use the Phalcon DevTools to create a new project. Open your terminal and run:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>phalcon project project-name</p>
                        `
                    }}></div>
                </div>
                <p>This will create a new directory called <span class="background-grey">project-name</span> with the basic structure of a Phalcon application.</p>


                <h5 className='mt-4 mb-3'>Step 6: Navigate to Your Project Directory</h5>
                <p>Change into your project directory:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-yellow">cd</span> project-name</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 7: Configure the Application</h5>
                <p>Open the <span class="background-grey">app/config/config.php</span> file and configure your database connection parameters:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">return new</span> \<span class="color-red">Phalcon</span>\<span class="color-red">Config</span>([</p>
                            <p class="ml-30">    <span class="color-green">'database'</span> => [</p>
                            <p class="ml-60">        <span class="color-green">'adapter'</span> => <span class="color-green">'Mysql'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'host'</span> => <span class="color-green">'localhost'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'username'</span> => <span class="color-green">'your_username'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'password'</span> => <span class="color-green">'your_password'</span>,</p>
                            <p class="ml-60">        <span class="color-green">'dbname'</span> => <span class="color-green">'your_database'</span>,</p>
                            <p class="ml-30">    ],</p>
                            <p>]);</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 8: Set Up Your Database</h5>
                <p>Create a new database in your database server (e.g., using phpMyAdmin) that matches the name in your configuration.</p>

                <h5 className='mt-4 mb-3'>Step 9: Create a Controller</h5>
                <p>Create a new controller in the <span class="background-grey">app/controllers</span> directory. For example, create a file named <span class="background-grey">IndexController.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <br />
                            <p><span class="color-blue">use</span> <span class="color-red">Phalcon</span>\<span class="color-red">Mvc</span>\<span class="color-red">Controller</span>;</p>
                            <br />
                            <p><span class="color-blue">class</span> <span class="color-yellow">IndexController</span> <span class="color-blue">extends</span> <span class="color-yellow">Controller</span></p>
                            <p>{</p>
                            <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">indexAction</span>()</p>
                            <p class="ml-30">    {</p>
                            <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Welcome to Phalcon!"</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 10: Set Up Routing</h5>
                <p>Open the <span class="background-grey">app/config/routes.php</span> file to set up your routes. By default, it routes to the <span class="background-grey">IndexController</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">use</span> <span class="color-red">Phalcon</span>\<span class="color-red">Mvc</span>\<span class="color-red">Router</span>;</p>
                            <br />
                            <p><span class="color-pink">$router</span> = <span class="color-blue">new</span> <span class="color-red">Router</span>();</p>
                            <p><span class="color-pink">$router</span>-><span class="color-red">add</span>(<span class="color-green">'/'</span>, [</p>
                            <p class="ml-30">    <span class="color-green">'controller'</span> => <span class="color-green">'index'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'action'</span> => <span class="color-green">'index'</span></p>
                            <p>]);</p>
                            <p><span class="color-blue">return</span> <span class="color-pink">$router</span>;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 11: Start the Development Server</h5>
                <p>You can use the built-in PHP server to test your application:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>php -S localhost:8000 -t public</p>
                        `
                    }}></div>
                </div>
                <p>Your application will be accessible at <span class="background-grey">http://localhost:8000</span></p>

            </div>
        </section>
    )
}