import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_copy";

export default function Copy() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP copy() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_unlink',
            'next': '/php/func_rename'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP copy() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">copy()</span> function in PHP is used to copy a file from one location to another. It takes the source file and creates a copy of it in the destination path. This function is helpful when you need to duplicate files, such as when creating backups or moving files to different directories.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">copy</span>(<span class="color-blue">string</span> <span class="color-pink">$source</span>, <span class="color-blue">string</span> <span class="color-pink">$destination</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$source</strong> (required): The path to the file you want to copy.</li>
                    <li><strong>$destination</strong> (required): The path where you want the copy of the file to be placed.</li>
                </ul>
                <p>The function returns <span className="background-grey">true</span> if the file was successfully copied and returns <span className="background-grey">false</span> if the copy operation failed</p>


                <h5 className="mt-5 mb-3">Example 1: Copying a File</h5>
                <p>In this example, we will copy a file called <span className="background-grey">source.txt</span> to a new file called <span className="background-grey">copy.txt</span> in the same directory.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$source</span> = <span class="color-green">'source.txt'</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$destination</span> = <span class="color-green">'copy.txt'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">copy</span>(<span class="color-pink">$source</span>, <span class="color-pink">$destination</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"File copied successfully!"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to copy the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>


                <h5 className="mt-5 mb-3">Example 2: Copying to Another Directory</h5>
                <p>This example demonstrates copying a file from one directory to another.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$source</span> = <span class="color-green">'uploads/image.jpg'</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$destination</span> = <span class="color-green">'backup/image_backup.jpg'</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">copy</span>(<span class="color-pink">$source</span>, <span class="color-pink">$destination</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"File copied to backup directory."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to copy the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The <span className="background-grey">copy()</span> function in PHP provides a simple way to duplicate files, allowing you to copy a file from one location to another. It's a useful function for handling file backups, duplicating user uploads, or copying files between directories. Just ensure you handle permissions and overwriting properly to avoid errors or accidental data loss.
                </p>


            </div>
        </section>
    )
}