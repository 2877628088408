import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_chmod";

export default function Chmod() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP chmod() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_scandir',
            'next': '/php/func_filter_has_var'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP chmod() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">chmod()</span> function in PHP is used to change the permissions of a file or directory. It allows you to set the read, write, and execute permissions for the owner, group, and others.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">chmod</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>, <span class="color-blue">int</span> <span class="color-pink">$permissions</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$filename</strong> (required): The path to the file or directory for which you want to change permissions.</li>
                    <li><strong>$permissions</strong> (required): The new permissions, provided as an octal value (e.g., 0755, 0644). These values represent different levels of access for the file or directory.</li>
                </ul>
                <p>It returns <span className="background-grey">true</span> if the operation was successful. If the operation failed (e.g., due to invalid file path or insufficient permissions) returns <span className="background-grey">false</span>.</p>


                <h5 className="mt-5 mb-3">Permissions</h5>
                <p>File permissions are typically represented using a three-digit octal number:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The first digit represents the permissions for the owner.</li>
                    <li>The second digit represents the permissions for the group.</li>
                    <li>The third digit represents the permissions for others.</li>
                </ul>
                <p>Each digit is a sum of the following values:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>4</strong>: Read permission.</li>
                    <li><strong>2</strong>: Write permission.</li>
                    <li><strong>1</strong>: Execute permission.</li>
                </ul>
                <p>For example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>0644</strong> means: Owner can read and write, group and others can only read.</li>
                    <li><strong>0755</strong> means: Owner can read, write, and execute, group and others can read and execute.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage </h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$file</span> = <span class="color-green">'example.txt'</span>;</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">chmod</span>(<span class="color-pink">$file</span>, <span class="color-pink">0755</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Permissions changed successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to change permissions."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the permissions of <span className="background-grey">example.txt</span> are changed to <span className="background-grey">0755</span>, giving the owner read, write, and execute permissions, and giving the group and others read and execute permissions.</p>



            </div>
        </section>
    )
}