import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/events";

export default function HTMLEvents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Events | Aspirant's Home");
        const urls = {
            'previous': '/html/web-storage',
            'next': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Events</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML Events are actions or occurrences that happen in the browser while a user interacts with a web page. Like <strong>clicking a button</strong>, <strong>loading a page</strong>, <strong>hovering over an element</strong>, and more. Events allow HTML elements to "react" to user actions, enabling interactivity on websites by triggering JavaScript functions or other responses.
                </p>
                <h5 className='mt-5 mb-3'>Types of HTML Events</h5>
                <p>HTML events can be categorized based on the type of interaction, such as mouse events, keyboard events, form events, and document events.</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li className='mb-4'>
                        <h6>Mouse Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">onclick</span> : Triggered when an element is clicked.</li>
                            <li><span class="background-grey">ondblclick</span> : Triggered when an element is double-clicked.</li>
                            <li><span class="background-grey">onmousedown</span> : Triggered when a mouse button is pressed down over an element.</li>
                            <li><span class="background-grey">onmouseup</span> : Triggered when a mouse button is released over an element.</li>
                            <li><span class="background-grey">onmouseover</span> : Triggered when the mouse pointer moves over an element.</li>
                            <li><span class="background-grey">onmouseout</span> : Triggered when the mouse pointer moves out of an element.</li>
                            <li><span class="background-grey">onmousemove</span> : Triggered when the mouse pointer moves within an element.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Keyboard Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">onkeydown</span> : Triggered when a key is pressed down.</li>
                            <li><span class="background-grey">onkeypress</span> : Triggered when a key is pressed (deprecated; onkeydown and onkeyup are recommended).</li>
                            <li><span class="background-grey">onkeyup</span> : Triggered when a key is released.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Form Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">onsubmit</span> : Triggered when a form is submitted.</li>
                            <li><span class="background-grey">onchange</span> : Triggered when the value of an input, select, or textarea changes.</li>
                            <li><span class="background-grey">onfocus</span> : Triggered when an element (like an input) gains focus.</li>
                            <li><span class="background-grey">onblur</span> : Triggered when an element loses focus.</li>
                            <li><span class="background-grey">oninput</span> : Triggered when a user inputs text in an input or textarea.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Window and Document Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">onload</span> : Triggered when the page or an image has fully loaded.</li>
                            <li><span class="background-grey">onunload</span> : Triggered when the page is closed or navigated away from.</li>
                            <li><span class="background-grey">onresize</span> : Triggered when the browser window is resized.</li>
                            <li><span class="background-grey">onscroll</span> : Triggered when the user scrolls on an element.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Clipboard Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">oncopy</span> : Triggered when content is copied.</li>
                            <li><span class="background-grey">oncut</span> : Triggered when content is cut.</li>
                            <li><span class="background-grey">onpaste</span> : Triggered when content is pasted.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Media Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">onplay</span> : Triggered when media (audio/video) starts playing.</li>
                            <li><span class="background-grey">onpause</span> : Triggered when media is paused.</li>
                            <li><span class="background-grey">onvolumechange</span> : Triggered when the volume of the media is changed.</li>
                            <li><span class="background-grey">onended</span> : Triggered when media playback reaches the end</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Drag Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">ondrag</span> : Triggered when an element is being dragged.</li>
                            <li><span class="background-grey">ondrop</span> : Triggered when the dragged element is dropped on a target.</li>
                            <li><span class="background-grey">ondragover</span> : Triggered when an element is dragged over a valid drop target.</li>
                        </ul>
                    </li>
                    <li className='mb-4'>
                        <h6>Touch Events</h6>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span class="background-grey">ontouchstart</span> : Triggered when a touch event starts.</li>
                            <li><span class="background-grey">ontouchmove</span> : Triggered when the touch moves across the screen.</li>
                            <li><span class="background-grey">ontouchend</span> : Triggered when the touch is lifted from the screen.</li>
                        </ul>
                    </li>
                </ul>




            </div>
        </section>
    )
}