import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_strcasecmp";

export default function StrCaseCmp() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP strcasecmp() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_strcmp',
            'next': '/php/func_htmlspecialchars'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP strcasecmp() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The strcasecmp() function in PHP is used to compare two strings in a case-insensitive manner. This means that the comparison does not consider whether letters are uppercase or lowercase, allowing for a more flexible string comparison. It’s particularly useful in situations where the case of the strings should not affect the comparison result. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">strcmp</span>(<span class="color-blue">string</span> <span class="color-pink">$str1</span>, <span class="color-blue">string</span> <span class="color-pink">$str2</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$str1</strong>: The first string to compare.</li>
                    <li><strong>$str2</strong>: The second string to compare.</li>
                    <li>
                    <strong>Return Value</strong>: The function returns:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Less than 0</strong>: If <span className="background-grey">$str1</span> is less than <span className="background-grey">$str2</span>.</li>
                            <li><strong>0</strong>: If <span className="background-grey">$str1</span> is equal to <span className="background-grey">$str2</span>.</li>
                            <li><strong>Greater than 0</strong>: If <span className="background-grey">$str1</span> is greater than <span className="background-grey">$str2</span>.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$str1</span> = <span class="color-green">"hello"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$str2</span> = <span class="color-green">"Hello"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$result</span> = <span class="color-red">strcasecmp</span>(<span class="color-pink">$str1</span>, <span class="color-pink">$str2</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$result</span> < <span class="color-pink">0</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$str1 is less than $str2\n"</span>; <span class="color-grey">// Output: hello is less than Hello</span></p>
                        <p class="ml-30">    } <span class="color-blue">elseif</span> (<span class="color-pink">$result</span> > <span class="color-pink">0</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$str1 is greater than $str2\n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"$str1 is equal to $str2\n"</span>; <span class="color-grey">// Output: hello is equal to Hello</span></p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}