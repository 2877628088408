import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_exception";

export default function Exception() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Exception() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_trigger_error',
            'next': '/php/func_getCode'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <div className='mt-4 mb-5'>
                <h5 className="mt-5 mb-3">What is an Exception?</h5>
                <p>
                    An <strong>exception</strong> is an error that occurs during the execution of a program. When an exception occurs, it can be "thrown," and if it is not caught, it will result in a fatal error. Using exceptions provides an alternative to traditional error handling, making your code cleaner and more manageable.
                </p>

                <h5 className="mt-5 mb-3">Throwing and Catching Exceptions</h5>
                <p>You "throw" an exception when an error occurs, and you "catch" it using a <span className="background-grey">try-catch</span> block. If an exception is thrown, PHP looks for a matching <span className="background-grey">catch</span> block to handle the exception.</p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">try</span> {</p>
                        <p class="ml-30 color-grey">    // Code that may throw an exception</p>
                        <p>} <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-30 color-grey">    // Code that handles the exception</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example: Throwing and Catching an Exception</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60 color-grey">        // Simulate an error by throwing an exception</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (!<span class="color-red">file_exists</span>(<span class="color-green">"somefile.txt"</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"File not found."</span>);</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60 color-grey">        // Handle the exception</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>();</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Error: File not found.</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Inside the <span className="background-grey">try</span> block, we check if a file exists. If it doesn’t, an exception is thrown using the <span className="background-grey">throw</span> keyword.</li>
                    <li>The <span className="background-grey">catch</span> block catches the exception and displays the error message using <span className="background-grey">$e-&gt;getMessage()</span>.</li>
                </ul>


            </div>
        </section>
    )
}