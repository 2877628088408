import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_scandir";

export default function ScanDir() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP scandir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rewinddir',
            'next': '/php/func_debug_backtrace'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP scandir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">scandir()</span> function in PHP is used to list all the files and directories within a specified directory. It returns an array containing the names of files and directories, including the special entries <span className="background-grey">.</span> (current directory) and <span className="background-grey">..</span> (parent directory).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">scandir</span>(<span class="color-blue">string</span> <span class="color-pink">$directory</span>, <span class="color-blue">int</span> <span class="color-pink">$sorting_order</span> = SCANDIR_SORT_ASCENDING, resource|null <span class="color-pink">$context</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>directory</strong>: The path to the directory you want to scan.</li>
                    <li>
                        <strong>sorting_order</strong> (Optional): The order in which the files and directories are returned. The possible values are:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className="background-grey">SCANDIR_SORT_ASCENDING</span> (default): Returns files in alphabetical order (A to Z).</li>
                            <li><span className="background-grey">SCANDIR_SORT_DESCENDING</span>: Returns files in reverse alphabetical order (Z to A).</li>
                            <li><span className="background-grey">SCANDIR_SORT_NONE</span>: No sorting; returns the files as they appear in the directory.</li>
                        </ul>
                    </li>
                    <li><strong>context</strong> (Optional): A context resource, typically created using stream_context_create(). This is rarely used.</li>
                </ul>

                <p>It returns an array of filenames on success. and it returns false on failure, such as if the directory doesn't exist or is not readable.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">scandir()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-green">"/path/to/directory"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$files</span> = <span class="color-red">scandir</span>(<span class="color-pink">$directory</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Output the files and directories</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$files</span>);</p>
                        <p class="ml-30">?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => .</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => ..</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => file1.txt</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => file2.txt</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => folder1</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example: Sorting in Descending Order</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$directory</span> = <span class="color-green">"/path/to/directory"</span>;
                        <p class="ml-30">    <span class="color-pink">$files</span> = <span class="color-red">scandir</span>(<span class="color-pink">$directory</span>, SCANDIR_SORT_DESCENDING);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$files</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => folder1</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => file2.txt</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => file1.txt</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => ..</p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => .</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}