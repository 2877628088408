import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_exp";

export default function Exp() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP exp() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_pi',
            'next': '/php/func_log'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP exp() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The exp() function in PHP is used to calculate the value of 𝑒 raised to the power of a given number. The constant 𝑒 is approximately equal to 2.71828 and is the base of the natural logarithm.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">exp</span>(number)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>number</strong> is the exponent to which 𝑒 is raised. It can be any floating-point number.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">exp</span>(<span class="color-pink">2</span>);  <span class="color-grey">// Output: 7.3890560989307</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">exp(2)</span> calculates 𝑒<sup>2</sup>, which is approximately 7.38906.</p>
            </div>
        </section>
    )
}