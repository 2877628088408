import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_size";

export default function FtpSize() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_size() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_rename',
            'next': '/php/func_ftp_chmod'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_size() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_size()</span> function in PHP is used to get the size of a file on a remote FTP server. It returns the size of the specified file in bytes, or <span className="background-grey">-1</span> if an error occurs or if the file does not exist.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_size</span>(ftp_connection, file)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource, which is created by the ftp_connect() function. This represents the active connection to the remote FTP server.</li>
                    <li><strong>file</strong> (Required): The path and name of the file on the FTP server whose size you want to retrieve.</li>
                </ul>

                <p>It returns the file size in bytes on success. and returns -1 if an error occurs or if the file does not exist.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_size()</span> Function</h5>
                <p>This is an example of getting the size of a file.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.&#92;n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // File whose size we want to retrieve</p>
                        <p class="ml-60">        <span class="color-pink">$file</span> = <span class="color-green">"public_html/test_file.txt"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Get file size</p>
                        <p class="ml-60">        <span class="color-pink">$file_size</span> = <span class="color-red">ftp_size</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$file</span>);</p>
                        <br />        
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$file_size</span> != <span class="color-pink">-1</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"The file size is: $file_size bytes.&#92;n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to retrieve file size or file does not exist.&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close FTP connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server using <span className="background-grey">ftp_connect()</span> and log in with <span className="background-grey">ftp_login()</span>.</li>
                    <li>The size of the file <span className="background-grey">test_file.txt</span> is retrieved using <span className="background-grey">ftp_size()</span>.</li>
                    <li>If the file exists, its size in bytes is printed. Otherwise, a failure message is displayed.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>The file size is: <span class="color-pink">1234</span> bytes.</p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}