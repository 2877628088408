import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_str_split";

export default function StrSplit() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP str_split() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_str_pad',
            'next': '/php/xml-parser'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP str_split() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The str_split() function in PHP is used to split a string into an array of smaller strings, each of a specified length. This function is particularly useful when you need to break down a larger string into manageable parts or when you want to process individual characters or substrings separately. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">str_split</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$length</span> = <span class="color-pink">1</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string that you want to split.</li>
                    <li><strong>$length</strong>: The length of each segment. The default value is <span className="background-grey">1</span>, which means the string will be split into individual characters.</li>
                </ul>
                <p>The function returns an array of strings. If the input string is empty, it returns an empty array.</p>


                <h5 className="mt-5 mb-3">Example 1 : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$splitArray</span> = <span class="color-red">str_split</span>(<span class="color-pink">$text</span>);</p>
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$splitArray</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>Array</p>
                        <p>(</p>
                        <p class="ml-30">    [0] => H</p>
                        <p class="ml-30">    [1] => e</p>
                        <p class="ml-30">    [2] => l</p>
                        <p class="ml-30">    [3] => l</p>
                        <p class="ml-30">    [4] => o</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string <span className="background-grey">"Hello"</span> is split into an array of its individual characters.</p>

            </div>
        </section>
    )
}