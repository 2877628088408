import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_php_scandir";

export default function ScanDirfn() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP scandir() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_rmdir',
            'next': '/php/func_chmod'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP scandir() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">scandir()</span> function in PHP is used to retrieve an array of files and directories inside a specified directory. It lists all files, including hidden files (those starting with a dot .). The function can also return the contents in both ascending and descending order.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">scandir</span>(<span class="color-blue">string</span> <span class="color-pink">$directory</span>, <span class="color-blue">int</span> <span class="color-pink">$sorting_order</span> = SCANDIR_SORT_ASCENDING, resource <span class="color-pink">$context</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$directory</strong> (required): The directory path that you want to scan.</li>
                    <li><strong>$sorting_order</strong> (optional): It determines the order in which the items are returned.
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">SCANDIR_SORT_ASCENDING</span> (default): Sorts the items in ascending order.</li>
                            <li><span className="background-grey">SCANDIR_SORT_DESCENDING</span>: Sorts the items in descending order.</li>
                            <li><span className="background-grey">SCANDIR_SORT_NONE</span>: No sorting is applied.</li>
                        </ul>
                    </li>
                    <li><strong>$context</strong> (optional): A stream context that can be used to modify the behavior of the function (e.g., for network file systems). It's rarely used in basic directory scanning.</li>
                </ul>
                <p>If the scan is successful, it <strong>returns an array</strong> containing the filenames and directories. If the function fails (e.g., if the directory doesn’t exist or you lack permissions), it returns <strong>false</strong>.</p>


                <h5 className="mt-5 mb-3">Example : Listing Files and Directories</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$dir</span> = <span class="color-green">'path/to/directory'</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$files</span> = <span class="color-red">scandir</span>(<span class="color-pink">$dir</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-red">print_r</span>(<span class="color-pink">$files</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => .</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => ..</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => file1.txt</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => file2.php</p>
                        <p class="ml-30">    [<span class="color-pink">4</span>] => subdir</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">.</span> represents the current directory.</li>
                    <li>The <span className="background-grey">..</span> represents the parent directory.</li>
                    <li>Files and subdirectories are listed afterward.</li>
                </ul>
                
                <p>The <span className="background-grey">scandir()</span> function is a simple and efficient way to retrieve the contents of a directory. Whether you’re listing files, checking if a folder is empty, or building a recursive directory scanning tool, <span className="background-grey">scandir()</span> is a fundamental function in PHP’s file system handling toolbox.</p>


            </div>
        </section>
    )
}