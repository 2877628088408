import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rad2deg";

export default function Rad2Deg() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rad2deg() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_deg2rad',
            'next': '/php/simple-xml'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rad2deg() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">rad2deg()</span> function in PHP is used to convert an angle from radians to degrees. This conversion is essential when working with angles in different mathematical contexts, especially when you need to interpret results that are more intuitively expressed in degrees. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rad2deg</span>(degrees)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>degrees</strong> is the angle that you want to convert to degrees.</p>
                <p>The <span className="background-grey">rad2deg()</span> function returns the angle in degrees as a floating-point number.</p>


                <h5 className="mt-5 mb-3">Example : Converting Different Angles</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$radians</span> = <span class="color-pink">3.1415926535898</span>; <span class="color-grey">// Approximately π</span></p>
                        <p class="ml-30">    <span class="color-pink">$degrees</span> = <span class="color-red">rad2deg</span>(<span class="color-pink">$radians</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$degrees</span>;  <span class="color-grey">// Output: 180</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <strong>π</strong> radians is converted to <strong>180</strong> degrees.</p>
            </div>
        </section>
    )
}