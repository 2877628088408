import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/frameworks/codeigniter";

export default function Codeigniter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP Framework - Codeigniter | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/frameworks/symphony',
            'next': '/php/frameworks/yii'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Getting Started with Codeigniter</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with CodeIgniter, a lightweight PHP framework, is straightforward and ideal for building web applications quickly. Here’s a step-by-step guide to help you set up and start working with CodeIgniter.
                </p>

                <h5 className='mt-4 mb-3'>Step 1: System Requirements</h5>
                <p>Ensure your environment meets the following requirements:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>PHP version 7.2 or higher</li>
                    <li>A web server (Apache, Nginx, etc.)</li>
                    <li>A database server (MySQL, PostgreSQL, SQLite, etc.)</li>
                </ul>

                <h5 className='mt-4 mb-3'>Step 2: Download CodeIgniter</h5>
                <p>You can download the latest version of CodeIgniter from the <Link to="https://codeigniter.com/">official CodeIgniter website</Link>. Alternatively, you can clone the repository from GitHub:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>git clone https://github.com/bcit-ci/CodeIgniter.git</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 3: Extract and Set Up the Project</h5>
                <p>If you downloaded the ZIP file, extract it to your web server's root directory (e.g., <span class="background-grey">htdocs</span> for XAMPP or <span class="background-grey">www</span> for WAMP).</p>


                <h5 className='mt-4 mb-3'>Step 4: Configure Base URL</h5>
                <p>Open the <span class="background-grey">application/config/config.php</span> file and set the <span class="background-grey">base_url</span>. This URL should point to your project:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$config</span>[<span class="color-green">'base_url'</span>] = <span class="color-green">'http://localhost/your_project_name/'</span>;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 5: Set Up Database Configuration</h5>
                <p>Open the <span class="background-grey">application/config/database.php</span> file and configure your database settings:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$db</span>[<span class="color-green">'default'</span>] = array(</p>
                            <p class="ml-30">    <span class="color-green">'dsn'</span>   => <span class="color-green">''</span>,</p>
                            <p class="ml-30">    <span class="color-green">'hostname'</span> => <span class="color-green">'localhost'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'username'</span> => <span class="color-green">'your_username'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'password'</span> => <span class="color-green">'your_password'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'database'</span> => <span class="color-green">'your_database_name'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'dbdriver'</span> => <span class="color-green">'mysqli'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'dbprefix'</span> => <span class="color-green">''</span>,</p>
                            <p class="ml-30">    <span class="color-green">'pconnect'</span> => <span class="color-blue">FALSE</span>,</p>
                            <p class="ml-30">    <span class="color-green">'db_debug'</span> => (ENVIRONMENT !== <span class="color-green">'production'</span>),</p>
                            <p class="ml-30">    <span class="color-green">'cache_on'</span> => <span class="color-blue">FALSE</span>,</p>
                            <p class="ml-30">    <span class="color-green">'cachedir'</span> => <span class="color-green">''</span>,</p>
                            <p class="ml-30">    <span class="color-green">'char_set'</span> => <span class="color-green">'utf8'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'dbcollat'</span> => <span class="color-green">'utf8_general_ci'</span>,</p>
                            <p class="ml-30">    <span class="color-green">'swap_pre'</span> => <span class="color-green">''</span>,</p>
                            <p class="ml-30">    <span class="color-green">'encrypt'</span> => <span class="color-blue">FALSE</span>,</p>
                            <p class="ml-30">    <span class="color-green">'compress'</span> => <span class="color-blue">FALSE</span>,</p>
                            <p class="ml-30">    <span class="color-green">'stricton'</span> => <span class="color-blue">FALSE</span>,</p>
                            <p class="ml-30">    <span class="color-green">'failover'</span> => <span class="color-red">array</span>(),</p>
                            <p class="ml-30">    <span class="color-green">'save_queries'</span> => <span class="color-blue">TRUE</span></p>
                            <p>);</p>
                        `
                    }}></div>
                </div>


                <h5 className='mt-4 mb-3'>Step 6: Create a Database</h5>
                <p>Create a new database in your database server (e.g., using phpMyAdmin) that matches the name in your configuration.</p>

                <h5 className='mt-4 mb-3'>Step 7: Create a Controller</h5>
                <p>Create a new controller in the <span class="background-grey">application/controllers</span> directory. For example, create a file named <span class="background-grey">Welcome.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-red">defined</span>(<span class="color-green">'BASEPATH'</span>) OR <span class="color-red">exit</span>(<span class="color-green">'No direct script access allowed'</span>);</p>
                            <br />
                            <p><span class="color-blue">class</span> <span class="color-yellow">Welcome</span> <span class="color-blue">extends</span> <span class="color-yellow">CI_Controller</span> {</p>
                            <p class="ml-30">    <span class="color-blue">public function</span> <span class="color-red">index</span>() {</p>
                            <p class="ml-60">        <span class="color-pink">$this</span>->load-><span class="color-red">view</span>(<span class="color-green">'welcome_message'</span>);</p>
                            <p class="ml-30">   }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 8: Create a View</h5>
                <p>Create a new view file in the <span class="background-grey">application/views</span> directory named <span class="background-grey">welcome_message.php</span>:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">></span>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Welcome to CodeIgniter&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h1&gt;Welcome to CodeIgniter!&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 9: Set Up Routing</h5>
                <p>By default, CodeIgniter uses the <span class="background-grey">Welcome</span> controller. You can set your own routes in the <span class="background-grey">application/config/routes.php</span> file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$route</span>[<span class="color-green">'default_controller'</span>] = <span class="color-green">'welcome'</span>;</p>
                            <p><span class="color-pink">$route</span>[<span class="color-green">'404_override'</span>] = <span class="color-green">''</span>;</p>
                            <p><span class="color-pink">$route</span>[<span class="color-green">'translate_uri_dashes'</span>] = <span class="color-blue">FALSE</span>;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-4 mb-3'>Step 10: Start the Development Server</h5>
                <p>If you’re using a local server (like XAMPP or WAMP), simply navigate to the URL <span class="background-grey">http://localhost/your_project_name</span> in your web browser. You should see your welcome message.</p>

            </div>
        </section>
    )
}