import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/reading-a-file";

export default function ReadingFile() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Reading a file in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/file-handling',
            'next': '/php/writing-a-file'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Reading a file</h3>
            <div className='mt-4 mb-5'>
                <p>PHP provides several ways to read the contents of a file:</p>
                <h5 className='mt-5 mb-3'>Reading a File Line by Line</h5>
                <p>To read a file line by line, you can use the <span class="background-grey">fgets()</span> function inside a loop.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"r"</span>);</p>
                            <p><span class="color-blue">while</span> (!<span class="color-red">feof</span>(<span class="color-pink">$file</span>)) {</p>
                            <p class="ml-30">    <span class="color-pink">$line</span> = <span class="color-red">fgets</span>(<span class="color-pink">$file</span>);</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$line</span> . <span class="color-green">"&lt;br&gt;"</span>;</p>
                            <p>}</p>
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">feof($file)</span>: Checks if the end of the file has been reached.</li>
                    <li><span class="background-grey">fgets($file)</span>: Reads a single line from the file.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Reading the Entire File into a String</h5>
                <p>
                    To read the entire content of a file into a string, you can use the <span class="background-grey">fread()</span> function.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$file</span> = <span class="color-red">fopen</span>(<span class="color-green">"example.txt"</span>, <span class="color-green">"r"</span>);</p>
                            <p><span class="color-pink">$contents</span> = <span class="color-red">fread</span>(<span class="color-pink">$file</span>, <span class="color-red">filesize</span>(<span class="color-green">"example.txt"</span>));</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$contents</span>;</p>
                            <p><span class="color-red">fclose</span>(<span class="color-pink">$file</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <span class="background-grey">fread($file, filesize("example.txt"))</span> reads the file content based on its size.
                </p>

                <h5 className='mt-5 mb-3'>Reading the Entire File into an Array</h5>
                <p>
                    If you want to read the file and store each line as an array element, you can use the <span class="background-grey">file()</span> function.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$lines</span> = <span class="color-red">file</span>(<span class="color-green">"example.txt"</span>);</p>
                            <p><span class="color-blue">foreach</span> (<span class="color-pink">$lines</span> <span class="color-blue">as</span> <span class="color-pink">$line</span>) {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$line</span> . <span class="color-green">"<br>"</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <span class="background-grey">file("example.txt")</span> reads the file into an array, where each element is a line from the file.
                </p>

                <h5 className='mt-5 mb-3'>Reading the Entire File at Once</h5>
                <p>
                    If you want to read the entire file content as a string in one go, use the <span class="background-grey">file_get_contents()</span> function.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$contents</span> = <span class="color-red">file_get_contents</span>(<span class="color-green">"example.txt"</span>);</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$contents</span>;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}