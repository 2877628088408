import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_column";

export default function ArrayColumn() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_column() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_chunk',
            'next': '/php/func_array_combine'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_column() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_column() function in PHP is used to extract a specific column of values from a multidimensional array. It's especially helpful when working with arrays of data, such as database result sets, where each row is an array and you want to retrieve a particular field from each row.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_column</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">string</span>|<span class="color-blue">int</span>|<span class="color-blue">null</span> <span class="color-pink">$column_key</span>, <span class="color-blue">string</span>|<span class="color-blue">int</span>|<span class="color-blue">null</span> <span class="color-pink">$index_key</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array, typically a multidimensional array (like rows from a database).</li>
                    <li><strong>$column_key</strong>: The key of the column you want to retrieve. This can be a string (if the array uses associative keys) or an integer (for indexed arrays). If set to <span className='background-grey'>null</span>, the entire row (sub-array) will be returned.</li>
                    <li><strong>$index_key</strong>: (Optional) The key to use as the index/keys for the returned array. If omitted, the array will have the default numeric keys (starting from 0).</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Extracting a Specific Column</h5>
                <p>Let's say you have a list of users, and you want to extract just the "name" field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$users</span> = [</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">1</span>, <span class="color-green">'name'</span> => <span class="color-green">'Alice'</span>, <span class="color-green">'age'</span> => <span class="color-pink">25</span>],</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">2</span>, <span class="color-green">'name'</span> => <span class="color-green">'Bob'</span>, <span class="color-green">'age'</span> => <span class="color-pink">30</span>],</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">3</span>, <span class="color-green">'name'</span> => <span class="color-green">'Charlie'</span>, <span class="color-green">'age'</span> => <span class="color-pink">35</span>],</p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$names</span> = <span class="color-red">array_column</span>(<span class="color-pink">$users</span>, <span class="color-green">'name'</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$names</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => Alice</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => Bob</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => Charlie</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Here in this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function extracts the values of the <span className='background-grey'>"name"</span> column from each sub-array.</li>
                    <li>The returned array contains only the names, indexed numerically (0, 1, 2).</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Using an Index Key</h5>
                <p>You can also specify an index key to use as the keys of the returned array. For example, you might want to use the <span className='background-grey'>"id"</span> as the key instead of the default numeric keys.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$users</span> = [</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">1</span>, <span class="color-green">'name'</span> => <span class="color-green">'Alice'</span>, <span class="color-green">'age'</span> => <span class="color-pink">25</span>],</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">2</span>, <span class="color-green">'name'</span> => <span class="color-green">'Bob'</span>, <span class="color-green">'age'</span> => <span class="color-pink">30</span>],</p>
                            <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">3</span>, <span class="color-green">'name'</span> => <span class="color-green">'Charlie'</span>, <span class="color-green">'age'</span> => <span class="color-pink">35</span>],</p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$names_with_id</span> = <span class="color-red">array_column</span>(<span class="color-pink">$users</span>, <span class="color-green">'name'</span>, <span class="color-green">'id'</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$names_with_id</span>);</p>
                        `
                    }}></div>
                </div>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => Alice</p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => Bob</p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => Charlie</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className='background-grey'>array_column()</span> function now uses the <span className='background-grey'>"id"</span> column as the index keys.</li>
                    <li>The resulting array contains the names as values, and their corresponding <span className='background-grey'>"id"</span> values as the array keys.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 3: Working with Numeric Keys</h5>
                <p>If the input array uses numeric keys, you can still use <span className='background-grey'>array_column()</span> to extract a column of data.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$products</span> = [</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>, <span class="color-green">'Laptop'</span>, <span class="color-pink">1000</span>],</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>, <span class="color-green">'Phone'</span>, <span class="color-pink">500</span>],</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>, <span class="color-green">'Tablet'</span>, <span class="color-pink">750</span>],</p>
                        <p>];</p>
                        <br />
                        <p><span class="color-pink">$prices</span> = <span class="color-red">array_column</span>(<span class="color-pink">$products</span>, <span class="color-pink">2</span>); <span class="color-grey">// Extract the 3rd column (price)</span></p>
                        <br />
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$prices</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">1000</span></p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">500</span></p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">750</span></p>
                        <p>)</p>
                        
                        `
                    }}></div>
                </div>
                <p>The function extracts the third column (index 2) from each sub-array, which contains the prices of the products.</p>


                <h5 className="mt-5 mb-3">Example 4: Returning Entire Rows</h5>
                <p>If you set the <span className='background-grey'>$column_key</span> to <span className='background-grey'>null</span>, <span className='background-grey'>array_column()</span> will return the entire row (sub-array) instead of extracting a specific column.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$users</span> = [</p>
                        <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">1</span>, <span class="color-green">'name'</span> => <span class="color-green">'Alice'</span>, <span class="color-green">'age'</span> => <span class="color-pink">25</span>],</p>
                        <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">2</span>, <span class="color-green">'name'</span> => <span class="color-green">'Bob'</span>, <span class="color-green">'age'</span> => <span class="color-pink">30</span>],</p>
                        <p class="ml-30">    [<span class="color-green">'id'</span> => <span class="color-pink">3</span>, <span class="color-green">'name'</span> => <span class="color-green">'Charlie'</span>, <span class="color-green">'age'</span> => <span class="color-pink">35</span>],</p>
                        <p>];</p>
                        <br />
                        <p><span class="color-pink">$rows</span> = <span class="color-red">array_column</span>(<span class="color-pink">$users</span>, <span class="color-blue">null</span>, <span class="color-green">'id'</span>);</p>
                        <br />
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$rows</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [id] => <span class="color-pink">1</span></p>
                        <p class="ml-90">            [name] => Alice</p>
                        <p class="ml-90">            [age] => <span class="color-pink">25</span></p>
                        <p class="ml-60">        )</p>
                        <br />
                        <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [id] => <span class="color-pink">2</span></p>
                        <p class="ml-90">            [name] => Bob</p>
                        <p class="ml-90">            [age] => <span class="color-pink">30</span></p>
                        <p class="ml-60">        )</p>
                        <br />
                        <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-red">Array</span></p>
                        <p class="ml-60">        (</p>
                        <p class="ml-90">            [id] => <span class="color-pink">3</span></p>
                        <p class="ml-90">            [name] => Charlie</p>
                        <p class="ml-90">            [age] => <span class="color-pink">35</span></p>
                        <p class="ml-60">        )</p>
                        <p>)</p>
                        
                        `
                    }}></div>
                </div>
                <p>Here, <span className='background-grey'>array_column()</span> returns the entire rows (sub-arrays), but uses the "id" field as the keys for the resulting array.</p>
            </div>
        </section>
    )
}