import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_merge";

export default function ArrayMerge() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_merge() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_map',
            'next': '/php/func_array_pad'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_merge() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_merge() function in PHP is used to merge one or more arrays into a single array. It appends the elements of the arrays in the order they are passed to the function. If the input arrays have the same string keys, the later values will overwrite the previous ones. If the arrays have numeric keys, array_merge() will reindex the result.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_merge</span>(<span class="color-blue">array</span> <span class="color-pink">...$arrays</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$arrays</strong> is One or more arrays to merge.</p>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"Apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"Banana"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"c"</span> => <span class="color-green">"Cherry"</span>, <span class="color-green">"d"</span> => <span class="color-green">"Date"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_merge</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [a] => Apple</p>
                            <p class="ml-30">    [b] => Banana</p>
                            <p class="ml-30">    [c] => Cherry</p>
                            <p class="ml-30">    [d] => Date</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The elements of <span className='background-grey'>$array1</span> and <span className='background-grey'>$array2</span> are combined into a single array.</li>
                    <li>The keys and values are retained in the merged array.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Handling Numeric Keys</h5>
                <p>When arrays have numeric keys, array_merge() reindexes the keys in the resulting array.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-pink">4</span>, <span class="color-pink">5</span>, <span class="color-pink">6</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_merge</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">3</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">4</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">5</span></p>
                            <p class="ml-30">    [<span class="color-pink">5</span>] => <span class="color-pink">6</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The two arrays are merged into one, and their elements are reindexed with sequential numeric keys.</p>


                <h5 className="mt-5 mb-3">Example 3: Overwriting Values with the Same String Keys</h5>
                <p>If multiple arrays have the same string keys, the values from the later arrays will overwrite those from the earlier ones.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array1</span> = [<span class="color-green">"a"</span> => <span class="color-green">"Apple"</span>, <span class="color-green">"b"</span> => <span class="color-green">"Banana"</span>];</p>
                            <p><span class="color-pink">$array2</span> = [<span class="color-green">"b"</span> => <span class="color-green">"Berry"</span>, <span class="color-green">"c"</span> => <span class="color-green">"Cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_merge</span>(<span class="color-pink">$array1</span>, <span class="color-pink">$array2</span>);</p>
                            <br />
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [a] => Apple</p>
                            <p class="ml-30">    [b] => Berry</p>
                            <p class="ml-30">    [c] => Cherry</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The key <span className='background-grey'>"b"</span> exists in both arrays. The value <span className='background-grey'>"Berry"</span> from <span className='background-grey'>$array2</span> overwrites the value <span className='background-grey'>"Banana"</span> from <span className='background-grey'>$array1</span>.</li>
                    <li>The values with unique keys are retained.</li>
                </ul>
            </div>
        </section>
    )
}