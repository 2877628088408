import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/setup/environment";

export default function SetupEnvironment() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Setting Up the Environment in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/introduction',
            'next': '/php/first/script'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Setting Up the Environment</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To start using PHP, you need to set up a local development environment. This usually involves installing a web server, PHP, and a database system. You can use packages like XAMPP, WAMP, or MAMP, which bundle these components together.
                </p>
                <h5 className="mt-5 mb-3">Installation Steps:</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Download XAMPP/WAMP/MAMP</strong>: Choose based on your operating system:
                        <ul style={{listStyle:'disc'}}>
                            <li><strong>XAMPP</strong>: Available for Windows, macOS, and Linux.</li>
                            <li><strong>WAMP</strong>: Windows only.</li>
                            <li><strong>MAMP</strong>: macOS and Windows.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Install the Package</strong>:
                        The official PHP website (PHP.net) has installation instructions for PHP is <a href="http://php.net/manual/en/install.php">http://php.net/manual/en/install.php</a>.  Follow the installation instructions provided on the websites.
                    </li>

                    <li>
                        <strong>Start the Server</strong>: Launch the control panel for your package and start the Apache (web server) and MySQL (database server) services.
                    </li>

                    <li>
                        <strong>Test PHP Installation</strong>: Create a file named info.php in the web server’s root directory (e.g., htdocs for XAMPP) with the following content:

                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;?php</p>
                                    <p><span class="color-pink">phpinfo</span>();</p>
                                    <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>
                    Navigate to <span class="background-grey">http://localhost/info.php</span> in your browser. If PHP is installed correctly, you’ll see a page with detailed information about your PHP setup.
                </p>
            </div>
        </section>
    )
}