import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_floor";

export default function Floor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP floor() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ceil',
            'next': '/php/func_round'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP floor() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The floor() function in PHP is used to round a floating-point number down to the nearest integer. Unlike ceil(), which rounds upwards, floor() rounds the number down regardless of the decimal part. If the number is already an integer, it remains unchanged. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">floor</span>(<span class="color-blue">float</span> <span class="color-pink">$number</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$number</strong> is the input number, which can be a positive or negative floating-point number or an integer.</p>
                <p>The function returns the largest integer less than or equal to the given number. In other words, it always rounds down to the nearest whole number.</p>

                <h5 className="mt-5 mb-3">Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">floor</span>(<span class="color-pink">4.8</span>);    <span class="color-grey">// Output: 4</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">floor</span>(<span class="color-pink">9.999</span>);  <span class="color-grey">// Output: 9</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">floor</span>(<span class="color-pink">-3.2</span>);   <span class="color-grey">// Output: -4</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">floor(4.8)</span> returns <span className="background-grey">4</span> because it rounds 4.8 down to the nearest whole number.</li>
                    <li><span className="background-grey">floor(9.999)</span> returns <span className="background-grey">9</span>, rounding it down from 9.999.</li>
                    <li><span className="background-grey">floor(-3.2)</span> returns <span className="background-grey">-4</span>, rounding down from <span className="background-grey">-3.2</span> to the next smallest integer, which is <span className="background-grey">-4</span>.</li>
                </ul>


            </div>
        </section>
    )
}