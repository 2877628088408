import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/html";

export default function HTML() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <html> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;html&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;html&gt;</span> tag is the root element in an HTML document, serving as the container for all other HTML elements on the page. It defines the entire HTML document, allowing browsers to recognize and render the content inside it properly.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;html&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;html&gt;</span> tag is the root-level container for an HTML document.</li>
                    <li>The lang attribute on the <span class="background-grey">&lt;html&gt;</span> tag specifies the language of the document, helping search engines and assistive technologies provide a better user experience.</li>
                    <li>Inside the <span class="background-grey">&lt;html&gt;</span> tag, the <span class="background-grey">&lt;head&gt;</span> and <span class="background-grey">&lt;body&gt;</span> sections organize metadata and the visible content, respectively.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60 color-grey">        &lt;!-- Metadata, title, and link elements go here --&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60 color-grey">        &lt;!-- Content displayed on the webpage goes here --&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Attributes of the <span class="background-grey">&lt;html&gt;</span> tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <span class="background-grey">lang</span>: Specifies the primary language of the document (e.g., <span class="background-grey">en</span> for English, <span class="background-grey">es</span> for Spanish). Setting the <span class="background-grey">lang</span> attribute improves accessibility for screen readers and helps search engines with language-specific content indexing.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <span class="background-grey">xmlns</span> (XML namespace): Used primarily in XHTML documents, this attribute specifies the XML namespace.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span> <span class="color-pink">xmlns</span>=<span class="color-green">"http://www.w3.org/1999/xhtml"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>Example Usage of <span class="background-grey">&lt;html&gt;</span> tag</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Sample HTML Document&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h1&gt;Welcome to My Website&lt;h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is a sample HTML document structure.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}