import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/body";

export default function Body() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <body> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;body&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;body&gt;</span> tag in HTML represents the main content of a web page that is visible to users in the browser. Everything within the <span class="background-grey">&lt;body&gt;</span> tag is rendered on the page, including text, images, links, forms, tables, and other media elements. It comes after the <span class="background-grey">&lt;head&gt;</span> tag in the HTML document structure and is required for every HTML page.
                </p>

                <h5 className='mt-5 mb-3'>Structure and Purpose of <span class="background-grey">&lt;body&gt;</span></h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;body&gt;</span> contains everything a user sees and interacts with on the page.</li>
                    <li>The browser renders the content inside <span class="background-grey">&lt;body&gt;</span>, so it’s where all visual and interactive elements are placed.</li>
                    <li>While <span class="background-grey">&lt;head&gt;</span> holds metadata and links to resources, <span class="background-grey">&lt;body&gt;</span> holds the actual page content.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>The <span class="background-grey">&lt;body&gt;</span> tag wraps all the visible content and interactive elements on the web page:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;body&gt;</p>
                        <p class="ml-30">    &lt;!-- Visible content goes here --&gt;</p>
                        <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Example Structure of an HTML Document with <span class="background-grey">&lt;body&gt;</span></h5>
                        


            </div>
        </section>
    )
}