import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_trigger_error";

export default function TriggerError() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP trigger_error() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_set_exception_handler',
            'next': '/php/func_exception'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP trigger_error() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">trigger_error()</span> function in PHP is used to generate a user-level error message. This function allows developers to manually trigger an error in their scripts, making it useful for debugging and for handling specific error conditions in a controlled manner.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">trigger_error</span>(<span class="color-blue">string</span> <span class="color-pink">$message</span> [, <span class="color-blue">int</span> <span class="color-pink">$error_type</span> = E_USER_NOTICE]);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$message</strong>: A string containing the error message that you want to display or log.</li>
                    <li>
                        <strong>$error_type</strong> (optional): The type of error to trigger. This parameter is optional and defaults to <span className="background-grey">E_USER_NOTICE</span>. The possible values are:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">E_USER_ERROR</span>: Generates a fatal error.</li>
                            <li><span className="background-grey">E_USER_WARNING</span>: Generates a warning.</li>
                            <li><span className="background-grey">E_USER_NOTICE</span>: Generates a notice (default).</li>
                        </ul>
                    </li>
                </ul>
                <p>The function returns <span className="background-grey">true</span> if the error was successfully triggered, otherwise, <span className="background-grey">false</span>.</p>


                <h5 className="mt-5 mb-3">Example: Triggering Different Error Types</h5>
                <p>Here’s an example showing how to use <span className="background-grey">trigger_error()</span> to generate different types of errors.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">divide</span>(<span class="color-pink">$dividend</span>, <span class="color-pink">$divisor</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$divisor</span> == <span class="color-pink">0</span>) {</p>
                        <p class="ml-90 color-grey">            // Trigger a user-level error</p>
                        <p class="ml-90">            <span class="color-red">trigger_error</span>(<span class="color-green">"Cannot divide by zero"</span>, E_USER_ERROR);</p>
                        <p class="ml-90">            <span class="color-blue">return false</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">return</span> <span class="color-pink">$dividend</span> / <span class="color-pink">$divisor</span>;</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // This will trigger a fatal error because of division by zero</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">divide</span>(<span class="color-pink">10</span>, <span class="color-pink">0</span>);</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">Fatal error</span>: Cannot divide by zero in ... on line ...</p>
                        `
                    }}></div>
                </div>
                <p>In the above example, <span className="background-grey">trigger_error()</span> generates a fatal error (since <span className="background-grey">E_USER_ERROR</span> is used), which stops the script execution.</p>


            </div>
        </section>
    )
}