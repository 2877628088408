import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/section";

export default function Section() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <section> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;section&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;section&gt;</span> tag in HTML is used to define a distinct section of content within a document, typically with a common theme or purpose. It’s a <strong>semantic</strong> HTML5 element that helps organize content into logical groups, making it easier for both users and search engines to understand the structure and meaning of the webpage. Each <span class="background-grey">&lt;section&gt;</span> should ideally contain content that can stand on its own, like articles, services, or features.
                </p>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;section&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Content for this section goes here --&gt;</p>
                        <p>&lt;/section&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Purpose of <span class="background-grey">&lt;section&gt;</span></h5>
                <p>Use <span class="background-grey">&lt;section&gt;</span> when you need to:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Group related content that fits together under a common theme.</li>
                    <li>Define a block of content with a specific heading.</li>
                    <li>Create sections that can be organized or styled independently.</li>
                </ul>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Website with Sections&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;header&gt;</p>
                        <p class="ml-90">            &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;/header&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;section&gt;</p>
                        <p class="ml-90">            &lt;h2&gt;About Us&lt;/h2&gt;</p>
                        <p class="ml-90">            &lt;p&gt;This section provides information about our company and mission.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/section&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;section&gt;</p>
                        <p class="ml-90">            &lt;h2&gt;Our Services&lt;/h2&gt;</p>
                        <p class="ml-90">            &lt;p&gt;We offer a variety of services to help you succeed.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/section&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;section&gt;</p>
                        <p class="ml-90">            &lt;h2&gt;Contact Us&lt;/h2&gt;</p>
                        <p class="ml-90">            &lt;p&gt;You can reach us via email or phone for further inquiries.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;/section&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>

                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Website with Sections</title>
                            </head>
                            <body>
                            
                                <header>
                                    <h1>Welcome to My Website</h1>
                                </header>
                            
                                <section>
                                    <h2>About Us</h2>
                                    <p>This section provides information about our company and mission.</p>
                                </section>
                                
                                <section>
                                    <h2>Our Services</h2>
                                    <p>We offer a variety of services to help you succeed.</p>
                                </section>
                                
                                <section>
                                    <h2>Contact Us</h2>
                                    <p>You can reach us via email or phone for further inquiries.</p>
                                </section>
                            
                            </body>
                        </html>
                        
                        `
                    }}></div>
                </div>

                <p>In this example-</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;section&gt;</span> tag is used three times to define separate sections: "About Us," "Our Services," and "Contact Us."</li>
                    <li>Each section has a heading and some content, helping to break up the page into logical, readable sections.</li>
                </ul>


            </div>
        </section>
    )
}