import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/input/validation";

export default function InputValidation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Input Validation - PHP Security | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/regular-expressions',
            'next': '/php/password/hashing'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Input Validation - PHP Security</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Input validation in PHP is crucial for securing web applications. It's the process of ensuring that user inputs are safe and meet certain criteria before processing or storing them. If not handled properly, user inputs can lead to serious security issues such as SQL injection, cross-site scripting (XSS), or command injection.
                </p>
                <p>Here’s how you can perform input validation in PHP to enhance security:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Validate Input Types</strong>
                        <p>Ensure that the input matches the expected type, such as a string, integer, or email. PHP provides built-in functions to validate various types of data.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Example for integer validation</strong>:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$age</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'age'</span>];</p>
                                            <br />
                                            <p><span class="color-blue">if</span> (<span class="color-red">filter_var</span>(<span class="color-pink">$age</span>, FILTER_VALIDATE_INT)) {</p>
                                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid age"</span>;</p>
                                            <p>} <span class="color-blue">else</span> {</p>
                                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid age"</span>;</p>
                                            <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Example for email validation</strong>:
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$email</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'email'</span>];</p>
                                            <br />
                                            <p><span class="color-blue">if</span> (<span class="color-red">filter_var</span>(<span class="color-pink">$email</span>, FILTER_VALIDATE_EMAIL)) {</p>
                                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid email"</span>;</p>
                                            <p>} <span class="color-blue">else</span> {</p>
                                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid email"</span>;</p>
                                            <p>}</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sanitize Input</strong>
                        <p>Sanitizing removes unwanted characters from user input, ensuring that only valid data gets processed. PHP provides <span className='background-grey'>filter_var()</span> with sanitization filters for this purpose.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Example for sanitizing a string:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-pink">$name</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'name'</span>];</p>
                                        <p><span class="color-pink">$sanitized_name</span> = <span class="color-red">filter_var</span>(<span class="color-pink">$name</span>, FILTER_SANITIZE_STRING);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Example for sanitizing an email:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p><span class="color-pink">$email</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'email'</span>];</p>
                                        <p><span class="color-pink">$sanitized_email</span> = <span class="color-red">filter_var</span>(<span class="color-pink">$email</span>, FILTER_SANITIZE_EMAIL);</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Use htmlspecialchars() to Prevent XSS</strong>
                        <p>Cross-Site Scripting (XSS) is a vulnerability that occurs when an attacker injects malicious scripts into web pages viewed by other users. To prevent this, escape user input before outputting it in HTML.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$comment</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'comment'</span>];</p>
                                    <br />
                                    <p><span class="color-grey">// Convert special characters to HTML entities</span></p>
                                    <p><span class="color-pink">$safe_comment</span> = <span class="color-red">htmlspecialchars</span>(<span class="color-pink">$comment</span>, ENT_QUOTES, <span class="color-green">'UTF-8'</span>);</p>
                                    <br />
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$safe_comment</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>This ensures that any <span className='background-grey'>&lt;script&gt;</span> tags entered by users will not be executed but displayed as plain text.</p>
                    </li>
                    <li>
                        <strong>Use Prepared Statements for SQL Queries</strong>
                        <p>To prevent SQL injection, always use prepared statements with parameterized queries. This ensures that user input is not executed as part of the SQL query.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Example with MySQLi:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$stmt</span> = <span class="color-pink">$conn</span>-><span class="color-red">prepare</span>(<span class="color-green">"SELECT * FROM users WHERE email = ?"</span>);</p>
                                            <p><span class="color-pink">$stmt</span>-><span class="color-red">bind_param</span>(<span class="color-green">"s"</span>, <span class="color-pink">$email</span>);</p>
                                            <p><span class="color-pink">$stmt</span>-><span class="color-red">execute</span>();</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Example with PDO:</strong>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-pink">$stmt</span> = <span class="color-pink">$pdo</span>-><span class="color-red">prepare</span>(<span class="color-green">"SELECT * FROM users WHERE email = :email"</span>);</p>
                                            <p><span class="color-pink">$stmt</span>-><span class="color-red">bindParam</span>(<span class="color-green">':email'</span>, <span class="color-pink">$email</span>);</p>
                                            <p><span class="color-pink">$stmt</span>-><span class="color-red">execute</span>();</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Limit Input Size</strong>
                        <p>Always define maximum length for input fields to avoid buffer overflow attacks and limit resource consumption. You can set maximum limits both on the server and in your HTML form.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">strlen</span>(<span class="color-pink">$username</span>) > <span class="color-pink">20</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Username is too long."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <p>Also, use the <span className='background-grey'>maxlength</span> attribute in HTML input fields:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">maxlength</span>=<span class="color-green">"20"</span>&gt;</p>
                                `
                            }}></div>
                        </div>

                    </li>
                    <li>
                        <strong>Allow Only Whitelisted Inputs</strong>
                        <p>For certain inputs (like dropdowns, checkboxes, or radio buttons), only allow pre-defined values, ensuring the user cannot submit anything other than what is expected.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$allowed_colors</span> = [<span class="color-green">'red'</span>, <span class="color-green">'green'</span>, <span class="color-green">'blue'</span>];</p>
                                    <br />
                                    <p><span class="color-blue">if</span> (<span class="color-red">in_array</span>(<span class="color-pink">$_POST</span>[<span class="color-green">'color'</span>], <span class="color-pink">$allowed_colors</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid color"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid color"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Use Regular Expressions for Advanced Validation</strong>
                        <p>If you need to validate specific patterns (like phone numbers or postal codes), regular expressions can be used.</p>
                        <h6>Example for phone number validation:</h6>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$phone</span> = <span class="color-pink">$_POST</span>[<span class="color-green">'phone'</span>];</p>
                                    <br />
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-green">'/^[0-9]{10}$/'</span>, <span class="color-pink">$phone</span>)) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Valid phone number"</span>;</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid phone number"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Avoid Directly Using User Input in File Operations</strong>
                        <p>If your application involves handling files, be cautious with file paths and names derived from user input to avoid path traversal attacks.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$filename</span> = <span class="color-red">basename</span>(<span class="color-pink">$_POST</span>[<span class="color-green">'filename'</span>]);  <span class="color-grey">// Strip directory path</span></p>
                                    <p><span class="color-pink">$filepath</span> = <span class="color-green">"/uploads/"</span> . <span class="color-pink">$filename</span>;</p>
                                    <br />
                                    <p class="color-grey">// Only allow certain extensions</p>
                                    <p><span class="color-blue">if</span> (<span class="color-red">preg_match</span>(<span class="color-green">'/\.(jpg|jpeg|png)$/'</span>, <span class="color-pink">$filename</span>)) {</p>
                                    <p class="ml-30">    <span class="color-red">move_uploaded_file</span>(<span class="color-pink">$_FILES</span>[<span class="color-green">'file'</span>][<span class="color-green">'tmp_name'</span>], <span class="color-pink">$filepath</span>);</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid file type"</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Use Tokens to Prevent CSRF (Cross-Site Request Forgery)</strong>
                        <p>To protect against CSRF attacks, generate a token and include it in forms. When the form is submitted, check if the token matches the one stored in the session.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_start</span>();</p>
                                    <p><span class="color-pink">$token</span> = <span class="color-red">md5</span>(<span class="color-red">uniqid</span>(<span class="color-red">mt_rand</span>(), <span class="color-blue">true</span>));</p>
                                    <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'token'</span>] = <span class="color-pink">$token</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>In your form:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"hidden"</span> <span class="color-pink">name</span>=<span class="color-green">"token"</span> <span class="color-pink">value</span>="&lt;?php <span class="color-blue">echo</span> <span class="color-pink">$_SESSION</span>[<span class="color-green">'token'</span>]; ?>"></p>
                                `
                            }}></div>
                        </div>
                        <p>On form submission:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$_POST</span>[<span class="color-green">'token'</span>] === <span class="color-pink">$_SESSION</span>[<span class="color-green">'token'</span>]) {</p>
                                    <p class="ml-30 color-grey">    // Process form</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Invalid request."</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Error Messages</strong>
                        <p>Avoid displaying detailed error messages that reveal system information, as they could help attackers exploit vulnerabilities. Instead, log detailed errors and show generic messages to users.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">try</span> {</p>
                                    <p class="ml-30 color-grey">    // Some code that might throw an exception</p>
                                    <p>} <span class="color-blue">catch</span> (Exception <span class="color-pink">$e</span>) {</p>
                                    <p class="ml-30">    <span class="color-red">error_log</span>(<span class="color-pink">$e</span>-><span class="color-red">getMessage</span>());  <span class="color-grey">// Log error for internal review</span></span>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"An error occurred. Please try again later."</span>;  <span class="color-grey">// Generic message for users</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}