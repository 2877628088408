import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_chunk";

export default function ArrayChunk() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_chunk() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_change_key_case',
            'next': '/php/func_array_column'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_chunk() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_chunk() function in PHP splits an array into smaller "chunks" or sub-arrays of a specified size. It’s useful when you need to break an array into smaller parts for processing or display.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_chunk</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>, <span class="color-blue">int</span> <span class="color-pink">$size</span>, <span class="color-blue">bool</span> <span class="color-pink">$preserve_keys</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The input array to be split into chunks.</li>
                    <li><strong>$size</strong>: The size of each chunk (i.e., the number of elements in each sub-array).</li>
                    <li><strong>$preserve_keys</strong>: (Optional) If <span className='background-grey'>true</span>, the original array keys are preserved. If <span className='background-grey'>false</span>, the keys are reset (default is <span className='background-grey'>false</span>).</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Un-Preserving Keys</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>, <span class="color-pink">5</span>, <span class="color-pink">6</span>, <span class="color-pink">7</span>, <span class="color-pink">8</span>, <span class="color-pink">9</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_chunk</span>(<span class="color-pink">$numbers</span>, <span class="color-pink">3</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-red">Array</span></p>
                            <p class="ml-60">        (</p>
                            <p class="ml-90">            [<span class="color-pink">0</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-90">            [<span class="color-pink">1</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-90">            [<span class="color-pink">2</span>] => <span class="color-pink">3</span></p>
                            <p class="ml-60">        )</p>
                            <br />
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-red">Array</span></p>
                            <p class="ml-60">        (</p>
                            <p class="ml-90">            [<span class="color-pink">0</span>] => <span class="color-pink">4</span></p>
                            <p class="ml-90">            [<span class="color-pink">1</span>] => <span class="color-pink">5</span></p>
                            <p class="ml-90">            [<span class="color-pink">2</span>] => <span class="color-pink">6</span></p>
                            <p class="ml-60">        )</p>
                            <br />
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-red">Array</span></p>
                            <p class="ml-60">        (</p>
                            <p class="ml-90">            [<span class="color-pink">0</span>] => <span class="color-pink">7</span></p>
                            <p class="ml-90">            [<span class="color-pink">1</span>] => <span class="color-pink">8</span></p>
                            <p class="ml-90">            [<span class="color-pink">2</span>] => <span class="color-pink">9</span></p>
                            <p class="ml-60">        )</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2: Preserving Keys</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [<span class="color-pink">10</span> => <span class="color-green">'a'</span>, <span class="color-pink">11</span> => <span class="color-green">'b'</span>, <span class="color-pink">12</span> => <span class="color-green">'c'</span>, <span class="color-pink">13</span> => <span class="color-green">'d'</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_chunk</span>(<span class="color-pink">$numbers</span>, <span class="color-pink">2</span>, <span class="color-blue">true</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">0</span>] => <span class="color-red">Array</span></p>
                            <p class="ml-60">        (</p>
                            <p class="ml-90">            [<span class="color-pink">10</span>] => a</p>
                            <p class="ml-90">            [<span class="color-pink">11</span>] => b</p>
                            <p class="ml-60">        )</p>
                            <br />
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-red">Array</span></p>
                            <p class="ml-60">        (</p>
                            <p class="ml-90">            [<span class="color-pink">12</span>] => c</p>
                            <p class="ml-90">            [<span class="color-pink">13</span>] => d</p>
                            <p class="ml-60">        )</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className='background-grey'>array_chunk()</span> preserves the original keys of the array by setting the third argument to <span className='background-grey'>true</span>.</p>
            </div>
        </section>
    )
}