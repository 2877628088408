import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/first/script";

export default function FirstScript() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Your First PHP Script | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/setup/environment',
            'next': '/php/variables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Your First PHP Script</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To create your first script in PHP, you have to follow the steps. For example, Create a file named <span class="background-grey">hello.php</span> with the following content:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">echo</span> <span class="color-green">"Hello, World!"</span>;</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Save this file in the <span class="background-grey">htdocs</span> directory and access it via <span class="background-grey">http://localhost/hello.php.</span> The browser should display "Hello, World!".
                </p>
            </div>
        </section>
    )
}