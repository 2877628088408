import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_log";

export default function Log() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP log() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_exp',
            'next': '/php/func_sin'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP log() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The log() function in PHP is used to calculate the logarithm of a number. By default, it computes the natural logarithm (base 𝑒), but it can also calculate logarithms of other bases if specified.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">log</span>(number, base)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>number</strong>: The value for which you want to calculate the logarithm. This must be a positive number.</li>
                    <li><strong>base</strong>: (Optional) The base of the logarithm. If not provided, the function returns the natural logarithm (base 𝑒). If the base is specified, it must be greater than 1.</li>
                </ul>
                <p>
                    The <span className="background-grey">log()</span> function returns the logarithm of the specified number to the given base. If the number is less than or equal to zero, or if the base is less than or equal to one, the function will return <span className="background-grey">NAN</span> (Not A Number).
                </p>

        
                <h5 className="mt-5 mb-3">Example 1: Natural Logarithm</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">log</span>(<span class="color-pink">10</span>);  <span class="color-grey">// Output: 2.302585092994</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">log(10)</span> calculates the natural logarithm (base 𝑒) of <span className="background-grey">10</span>, which is approximately <span className="background-grey">2.302585092994</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Logarithm with a Specific Base</h5>
                <p>To calculate the logarithm of a number to a specific base, you can provide the base as the second argument</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">log</span>(<span class="color-pink">100</span>, <span class="color-pink">10</span>);  <span class="color-grey">// Output: 2</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">log(100, 10)</span> calculates the logarithm of <span className="background-grey">100</span> to the base <span className="background-grey">10</span>, which results in <span className="background-grey">2</span> (since 10<sup>2</sup>=100).</p>

            </div>
        </section>
    )
}