import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/session/security";

export default function SessionSecurity() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Session - PHP Security | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/password/hashing',
            'next': '/php/frameworks'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Session - PHP Security</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Securing sessions in PHP is crucial to protect web applications from various types of attacks like session hijacking, session fixation, and cross-site scripting (XSS). Sessions store information about users (e.g., login state) and are used throughout their interaction with a web application. If session data is compromised, attackers can impersonate users, steal sensitive data, or gain unauthorized access.
                </p>

                <p>Here are essential techniques and best practices for session security in PHP:</p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator'>
                        <h5>Start Sessions Securely</h5>
                        <p>Always call <span className='background-grey'>session_start()</span> at the beginning of each script that uses sessions. However, there are ways to configure session handling securely:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_start</span>();</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Use HTTPS to Secure Session Data</h5>
                        <p>
                            Enable HTTPS on your site to ensure that all data, including session cookies, is transmitted securely. HTTPS encrypts the data between the user’s browser and the server, making it harder for attackers to intercept or tamper with session data.
                        </p>
                        <p>Ensure sessions are transmitted only over secure HTTPS connections:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">'session.cookie_secure'</span>, <span class="color-pink">1</span>);  <span class="color-grey">// Cookies will only be sent over HTTPS</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Set Session Cookie Flags</h5>
                        <p>Session cookies should be protected using specific flags to prevent exposure to client-side scripts or non-secure connections.</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>HttpOnly Flag</strong>: This flag prevents JavaScript from accessing the session cookie, reducing the risk of XSS attacks.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-red">ini_set</span>(<span class="color-green">'session.cookie_httponly'</span>, <span class="color-pink">1</span>);  <span class="color-grey">// Prevents JavaScript access to the session cookie</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>Secure Flag</strong>: Ensure the session cookie is sent only over secure HTTPS connections.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-red">ini_set</span>(<span class="color-green">'session.cookie_secure'</span>, <span class="color-pink">1</span>);  <span class="color-grey">// Ensures the cookie is sent over HTTPS only</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>SameSite Attribute</strong>: Prevents the session cookie from being sent along with cross-site requests, helping to prevent CSRF (Cross-Site Request Forgery) attacks.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-red">ini_set</span>(<span class="color-green">'session.cookie_samesite'</span>, <span class="color-green">'Strict'</span>);  <span class="color-grey">// Strict or Lax based on your need</span></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='operator'>
                        <h5>Regenerate Session ID Regularly</h5>
                        <p>To prevent session fixation and hijacking attacks, regenerate the session ID whenever a significant change occurs (like after login). This ensures that an attacker cannot predict or use an existing session ID.</p>
                        <p>Regenerate the session ID after login or other critical actions:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_regenerate_id</span>(<span class="color-blue">true</span>);  <span class="color-grey">// True deletes the old session, making it more secure</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This should be done especially after a user logs in or when sensitive actions are performed.</p>
                    </li>
                    <li className='operator'>
                        <h5>Set Session Timeout</h5>
                        <p>Limit the lifetime of a session to reduce the risk of session hijacking. Sessions that remain active for too long increase the chance of an attacker using an abandoned session</p>
                        <p>To set session expiration time</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">'session.gc_maxlifetime'</span>, <span class="color-pink">1800</span>);  <span class="color-grey">// 1800 seconds = 30 minutes</span></p>
                                `
                            }}></div>
                        </div>
                        <p>You can also force session expiration after a certain time of inactivity:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (<span class="color-red">isset</span>(<span class="color-pink">$_SESSION</span>[<span class="color-green">'last_activity'</span>]) && (<span class="color-red">time</span>() - <span class="color-pink">$_SESSION</span>[<span class="color-green">'last_activity'</span>]) > <span class="color-pink">1800</span>) {</p>
                                    <p class="ml-30">    <span class="color-red">session_unset</span>();     <span class="color-grey">// Unset session variables</span></p>
                                    <p class="ml-30">    <span class="color-red">session_destroy</span>();   <span class="color-grey">// Destroy the session</span></p>
                                    <p>}</p>
                                    <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'last_activity'</span>] = <span class="color-red">time</span>();  <span class="color-grey">// Update the last activity time</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Limit Session Storage to Server-Side Only</h5>
                        <p>Avoid storing sensitive data in cookies, as they are sent to the client. Store important data, like user IDs, roles, or authentication tokens, only in session variables on the server.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'user_id'</span>] = <span class="color-pink">$user_id</span>;</p>
                                    <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'role'</span>] = <span class="color-green">'admin'</span>;  <span class="color-grey">// Securely stored on the server</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Validate Session Data</h5>
                        <p>To prevent session hijacking, validate session data against known values such as user IP address, browser agent, or other identifiers. This makes it harder for an attacker to reuse a stolen session ID.</p>
                        <p>For example,</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (!<span class="color-red">isset</span>(<span class="color-pink">$_SESSION</span>[<span class="color-green">'user_agent'</span>])) {</p>
                                    <p class="ml-30">    <span class="color-pink">$_SESSION</span>[<span class="color-green">'user_agent'</span>] = <span class="color-pink">$_SERVER</span>[<span class="color-green">'HTTP_USER_AGENT'</span>];</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">if</span> (<span class="color-pink">$_SESSION</span>[<span class="color-green">'user_agent'</span>] !== <span class="color-pink">$_SERVER</span>[<span class="color-green">'HTTP_USER_AGENT'</span>]) {</p>
                                    <p class="ml-30 color-grey">    // Possible session hijacking attempt</p>
                                    <p class="ml-30">    <span class="color-red">session_unset</span>();</p>
                                    <p class="ml-30">    <span class="color-red">session_destroy</span>();</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Destroy Sessions Securely</h5>
                        <p>When a user logs out or a session is no longer needed, destroy the session completely. This ensures the session data is removed and cannot be reused.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-red">session_unset</span>();  <span class="color-grey">// Unset all session variables</span></p>
                                <p><span class="color-red">session_destroy</span>();  <span class="color-grey">// Destroy the session</span></p>
                                `
                            }}></div>
                        </div>
                        <p>Also, clear the session cookie by setting it to expire immediately</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">setcookie</span>(<span class="color-red">session_name</span>(), <span class="color-green">''</span>, <span class="color-red">time</span>() - <span class="color-pink">3600</span>, <span class="color-green">'/'</span>);  <span class="color-grey">// Delete the session cookie</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Use Session Encryption</h5>
                        <p>Although session data is typically stored on the server, adding encryption to session data stored in cookies or other storage mechanisms provides an additional layer of security. In PHP, the session data itself can be encrypted by handling custom session storage.</p>
                    </li>
                    <li className='operator'>
                        <h5>Use Custom Session Handlers</h5>
                        <p>
                            In some cases, you may want more control over how session data is stored (e.g., using a database instead of file storage). PHP allows you to implement custom session handlers using the <span className='background-grey'>SessionHandlerInterface</span>.
                        </p>
                        <p>
                            This provides additional flexibility to encrypt, monitor, or restrict access to session data more tightly.
                        </p>
                    </li>
                    <li className='operator'>
                        <h5>Prevent Session Fixation Attacks</h5>
                        <p>Session fixation attacks occur when an attacker forces a session ID on a user and then later takes control of that session. PHP's <span className='background-grey'>session_regenerate_id()</span> can mitigate this by regenerating the session ID at critical points (e.g., login).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_regenerate_id</span>(<span class="color-blue">true</span>);  <span class="color-grey">// Regenerate session ID after login</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='operator'>
                        <h5>Store Minimal Data in Session</h5>
                        <p>Do not store sensitive data like passwords, credit card information, or large amounts of data in sessions. Instead, store identifiers (e.g., user ID) that can be referenced securely on the server.</p>
                    </li>
                </ul>

            </div>
        </section>
    )
}