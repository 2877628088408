import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/geolocation";

export default function Geolocation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Geolocation | Aspirant's Home");
        const urls = {
            'previous': '/html/canvas',
            'next': '/html/drag-drop'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Geolocation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Geolocation in HTML refers to the ability of web applications to determine a user's geographical location through their device's GPS, IP address, or other location services. The Geolocation API provides a simple and standardized way to access this information in web browsers, enabling features such as location-based services and applications. Here’s a detailed overview of how it works:
                </p>
                <h5 className='mt-5 mb-3'>How Geolocation Works</h5>
                <p>
                    The Geolocation API allows web developers to request the geographical location of a user's device. The user's permission is required before the location can be accessed. The API provides methods to retrieve the location, handle errors, and watch for changes in the user's location.
                </p>
                <h5 className='mt-5 mb-3'>Basic Usage</h5>
                <p>To use the Geolocation API, you typically follow these steps:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Check for Support</strong>: Ensure the user's browser supports the Geolocation API.</li>
                    <li><strong>Request Location</strong>: Use the <span className='background-grey'>getCurrentPosition()</span> method to get the current location.</li>
                    <li><strong>Handle the Result</strong>: Process the location data returned by the API.</li>
                    <li><strong>Handle Errors</strong>: Implement error handling to manage cases where location access is denied or unavailable.</li>
                </ul>

                <p>Here’s a simple example demonstrating how to use the Geolocation API:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Geolocation Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <br />    
                        <p class="ml-60">    &lt;h1&gt;Geolocation Example&lt;/h1&gt;</p>
                        <p class="ml-60">    &lt;button <span class="color-pink">id</span>=<span class="color-green">"getLocation"</span>&gt;Get My Location&lt;/button&gt;</p>
                        <p class="ml-60">    &lt;p <span class="color-pink">id</span>=<span class="color-green">"output"</span>&gt;</p>
                        <br />    
                        <p class="ml-60">    &lt;script&gt;</p>
                        <p class="ml-90">        document.<span class="color-red">getElementById</span>(<span class="color-green">"getLocation"</span>).<span class="color-red">addEventListener</span>(<span class="color-green">"click"</span>, <span class="color-blue">function</span>() {</p>
                        <p class="ml-120 color-grey">            // Check if Geolocation is supported</p>
                        <p class="ml-120">            <span class="color-blue">if</span> (<span class="color-green">"geolocation"</span> in navigator) {</p>
                        <p class="ml-150 color-grey">                // Request the user's current position</p>
                        <p class="ml-150">                navigator.geolocation.<span class="color-red">getCurrentPosition</span>(success, error);</p>
                        <p class="ml-120">            } <span class="color-blue">else</span> {</p>
                        <p class="ml-150">                document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">"Geolocation is not supported by this browser."</span>;</p>
                        <p class="ml-120">            }
                        <p class="ml-90">        });</p>
                        <br />    
                        <p class="ml-90">        <span class="color-blue">function</span> <span class="color-red">success</span>(position) {</p>
                        <p class="ml-120">            <span class="color-blue">const</span> latitude = position.coords.latitude; <span class="color-grey">// Get latitude</span></p>
                        <p class="ml-120">            <span class="color-blue">const</span> longitude = position.coords.longitude; <span class="color-grey">// Get longitude</span></p>
                        <p class="ml-120">            document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">&#96;Latitude: &#36;{latitude}, Longitude: &#36;{longitude}&#96;</span>;</p>
                        <p class="ml-90">        }</p>
                        <br />    
                        <p class="ml-90">        <span class="color-blue">function</span> <span class="color-red">error</span>(err) {</p>
                        <p class="ml-120">            <span class="color-blue">switch</span> (err.code) {</p>
                        <p class="ml-150">                <span class="color-blue">case</span> err.PERMISSION_DENIED:</p>
                        <p class="ml-180">                    document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">"User denied the request for Geolocation."</span>;</p>
                        <p class="ml-180">                    <span class="color-blue">break</span>;</p>
                        <p class="ml-150">                <span class="color-blue">case</span> err.POSITION_UNAVAILABLE:</p>
                        <p class="ml-180">                    document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">"Location information is unavailable."</span>;</p>
                        <p class="ml-180">                    <span class="color-blue">break</span>;</p>
                        <p class="ml-150">                <span class="color-blue">case</span> err.TIMEOUT:</p>
                        <p class="ml-180">                    document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">"The request to get user location timed out."</span>;</p>
                        <p class="ml-180">                    <span class="color-blue">break</span>;</p>
                        <p class="ml-150">                <span class="color-blue">case</span> err.UNKNOWN_ERROR:</p>
                        <p class="ml-180">                    document.<span class="color-red">getElementById</span>(<span class="color-green">"output"</span>).textContent = <span class="color-green">"An unknown error occurred."</span>;</p>
                        <p class="ml-180">                    <span class="color-blue">break</span>;</p>
                        <p class="ml-120">           }</p>
                        <p class="ml-90">        }</p>
                        <p class="ml-60">    &lt;/script&gt;</p>
                        <br />    
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Check for Support</strong>: The script checks if the Geolocation API is available in the user's browser using <span className='background-grey'>"geolocation" in navigator</span>.</li>

                    <li><strong>Request Location</strong>: The <span className='background-grey'>getCurrentPosition()</span> method is called to request the user's current location. It takes two callback functions: one for success and one for error handling.</li>

                    <li><strong>Success Callback</strong>: In the <span className='background-grey'>success()</span> function, the latitude and longitude are retrieved from the <span className='background-grey'>position.coords</span> object, and then displayed on the page.</li>

                    <li><strong>Error Callback</strong>: The <span className='background-grey'>error()</span> function handles various error cases, such as permission denial or timeout, providing appropriate feedback to the user.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Watching for Location Changes</h5>
                <p>You can also monitor changes in the user's location using the <span className='background-grey'>watchPosition()</span> method:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> watchId = navigator.geolocation.<span class="color-red">watchPosition</span>(success, error);</p>
                        `
                    }}></div>
                </div>
                <p>To stop watching, use:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>navigator.geolocation.<span class="color-red">clearWatch</span>(watchId);</p>
                        `
                    }}></div>
                </div>

                <p>The Geolocation API is a powerful tool for web developers, allowing the creation of location-aware applications and services. By leveraging this API, you can enhance user experience with features like maps, location tracking, and personalized content based on the user’s geographical location.</p>


            </div>
        </section>
    )
}