import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_pop";

export default function ArrayPop() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_pop() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_pad',
            'next': '/php/func_array_push'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_pop() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_pop() function in PHP is used to remove and return the last element of an array. This function reduces the array by one element, and the keys are not reindexed. If the array is empty, null is returned.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_pop</span>(<span class="color-blue">array</span> &<span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>$array: The input array from which the last element will be removed.</li>
                    <li>Return Value: The function returns the last element of the array. If the array is empty, null is returned.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <br />
                            <p><span class="color-pink">$lastElement</span> = <span class="color-red">array_pop</span>(<span class="color-pink">$array</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$lastElement</span>; <span class="color-grey">// Outputs: cherry</span></p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$array</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>cherry</p>
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">0</span>] => apple</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => banana</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The function removes <span className='background-grey'>"cherry"</span> (the last element) from the <span className='background-grey'>$array</span> and returns it.</li>
                    <li>The <span className='background-grey'>$array</span> is now reduced to two elements: <span className='background-grey'>"apple"</span> and <span className='background-grey'>"banana"</span>.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Using array_pop() in a Loop</h5>
                <p>You can use <span className='background-grey'>array_pop()</span> in a loop to remove and process each element of the array from the end.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"cherry"</span>];</p>
                            <br />
                            <p><span class="color-blue">while</span> (!<span class="color-blue">empty</span>(<span class="color-pink">$array</span>)) {</p>
                            <p class="ml-30">    <span class="color-pink">$element</span> = <span class="color-red">array_pop</span>(<span class="color-pink">$array</span>);</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$element</span> . <span class="color-green">"\n"</span>; <span class="color-grey">// Outputs each element</span></p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>cherry</p>
                            <p>banana</p>
                            <p>apple</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The loop continues until the array is empty.</li>
                    <li>array_pop() removes the last element on each iteration and returns it.</li>
                    <li>The elements are printed in reverse order as they are removed from the array.</li>
                </ul>
            </div>
        </section>
    )
}