import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_restore_error_handler";

export default function RestoreErrorHandler() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP restore_error_handler() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_error_reporting',
            'next': '/php/restore_exception_handler'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP restore_error_handler() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">restore_error_handler()</span> function in PHP is used to restore the previous error handler that was in place before any custom handler was set using <span className="background-grey">set_error_handler()</span>. When a custom error handler is no longer required, this function reverts the error handling behavior to its original state, which is typically the default built-in PHP error handler.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">restore_error_handler</span>();</p>
                        `
                    }}></div>
                </div>
                <p>It returns a boolean. If the previous error handler was successfully restored it returns <strong>true</strong>. If no previous error handler exists, it returns <strong>false</strong>.</p>



                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <p>Let’s demonstrate how to use a custom error handler and then restore the default error handling behavior.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Define a custom error handler function</p>
                        <p class="ml-30">    <span class="color-blue">function</span> <span class="color-red">customErrorHandler</span>(<span class="color-pink">$errno</span>, <span class="color-pink">$errstr</span>, <span class="color-pink">$errfile</span>, <span class="color-pink">$errline</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Custom Error [$errno]: $errstr in $errfile on line $errline&#92;n"</span>;</p>
                        <p class="ml-60">        <span class="color-blue">return</span> <span class="color-blue">true</span>; <span class="color-grey">// Prevents the default error handler from being called</span></p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Set the custom error handler</p>
                        <p class="ml-30">    <span class="color-red">set_error_handler</span>(<span class="color-green">"customErrorHandler"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Trigger an error to test the custom handler</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$undefinedVariable</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Restore the previous (default) error handler</p>
                        <p class="ml-30">    <span class="color-red">restore_error_handler</span>();</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Trigger another error to show the default handler is restored</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$anotherUndefinedVariable</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span className="background-grey">customErrorHandler()</span> function is defined to handle PHP errors. It displays a custom error message when an error occurs.</li>
                    <li>The <span className="background-grey">set_error_handler()</span> function sets <span className="background-grey">customErrorHandler</span> as the active error handler.</li>
                    <li>The line <span className="background-grey">echo $undefinedVariable;</span> triggers a PHP notice (because <span className="background-grey">$undefinedVariable</span> is not defined). The custom handler catches this and displays a custom error message.</li>
                    <li>After the custom handler has done its job, the <span className="background-grey">restore_error_handler()</span> function restores the previous error handler, which in most cases is PHP’s default handler.</li>
                    <li>The next error (<span className="background-grey">echo $anotherUndefinedVariable;</span>) is handled by PHP’s default handler, since the previous error handler has been restored.</li>
                </ul>

                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">Custom Error</span> [<span class="color-pink">8</span>]: Undefined variable: undefinedVariable in /path/to/file.php on line <span class="color-pink">12</span></p>
                            <p><span class="color-red">Notice</span>: Undefined variable: anotherUndefinedVariable in /path/to/file.php on line <span class="color-pink">16</span></p>
                        `
                    }}></div>
                </div>


            </div>
        </section>
    )
}