import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_rtrim";

export default function RTrim() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP rtrim() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ltrim',
            'next': '/php/func_explode'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP rtrim() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The rtrim() function in PHP is used to remove whitespace or specific characters from the right side (end) of a string. This function is useful when you need to clean up strings where unnecessary characters or spaces are present at the end. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">rtrim</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">string</span> <span class="color-pink">$characters</span> = <span class="color-green">" \t\n\r\0\x0B"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string from which trailing whitespace or characters will be removed.</li>
                    <li><strong>$characters</strong>: (Optional) A list of characters to remove from the end of the string. If not provided, the default characters to be removed are spaces, tabs (<span className="background-grey">\t</span>), newlines (<span className="background-grey">\n</span>), carriage returns (<span className="background-grey">\r</span>), null bytes (<span className="background-grey">\0</span>), and vertical tabs (<span className="background-grey">\x0B</span>).</li>
                    <li><strong>Return Value</strong>: It returns a new string with the specified characters removed from the end.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Removing Trailing Spaces</h5>
                <p>Sometimes, when working with user input or text data, trailing spaces might be present. You can use <span className="background-grey">rtrim()</span> to clean up the input:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$username</span> = <span class="color-green">"JohnDoe   "</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$trimmedUsername</span> = <span class="color-red">rtrim</span>(<span class="color-pink">$username</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$trimmedUsername</span>; <span class="color-grey">// Outputs: "JohnDoe"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Cleaning File Paths or URLs</h5>
                <p>If a file path or URL has unnecessary characters at the end, you can use <span className="background-grey">rtrim()</span> to remove them.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$path</span> = <span class="color-green">"/var/www/html/"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$cleanedPath</span> = <span class="color-red">rtrim</span>(<span class="color-pink">$path</span>, <span class="color-green">"/"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$cleanedPath</span>; <span class="color-grey">// Outputs: "/var/www/html"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, the trailing <span className="background-grey">/</span> at the end of the path is removed.</p>

                <h5 className="mt-5 mb-3">Example 3 : Removing Custom Characters</h5>
                <p>You can remove trailing symbols or other specific characters from strings</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"Hello, world!!!"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$cleanedText</span> = <span class="color-red">rtrim</span>(<span class="color-pink">$text</span>, <span class="color-green">"!"</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$cleanedText</span>; <span class="color-grey">// Outputs: "Hello, world"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}