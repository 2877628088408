import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_fmod";

export default function FMod() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP fmod() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_mt_rand',
            'next': '/php/func_pi'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP fmod() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The fmod() function in PHP is used to calculate the floating-point remainder of the division of two numbers. It is particularly useful for situations where you need the remainder of a division operation involving floating-point numbers. It has a basic syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">fmod</span>(dividend, divisor)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>dividend</strong>: The number you want to divide.</li>
                    <li><strong>divisor</strong>: The number by which you want to divide.</li>
                </ul>
                <p>The <span className="background-grey">fmod()</span> function returns the floating-point remainder of the division of the <span className="background-grey">dividend</span> by the <span className="background-grey">divisor</span>. If the divisor is zero, the function will return <span className="background-grey">NAN</span> (Not A Number).</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">fmod</span>(<span class="color-pink">5.3</span>, <span class="color-pink">2</span>);  <span class="color-grey">// Output: 1.3</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <span className="background-grey">fmod(5.3, 2)</span> calculates the remainder of <span className="background-grey">5.3</span> divided by <span className="background-grey">2</span>, which results in <span className="background-grey">1.3</span>.</p>

            </div>
        </section>
    )
}