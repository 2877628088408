import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/embed-multimedia";

export default function EmbedMultimedia() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Embed Multimedia | Aspirant's Home");
        const urls = {
            'previous': '/html/audio-element',
            'next': '/html/svg'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Embed Multimedia</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Embedding multimedia in HTML allows you to incorporate various types of media—such as audio, video, images, and interactive content—directly into web pages. This enhances user experience by providing rich content without requiring users to navigate away from the page.
                </p>
                <p>
                    Here’s a breakdown of how to embed different types of multimedia in HTML:
                </p>

                <ul style={{ listStyle: 'decimal' }}>
                    <li className='operator mb-4'>
                        <h5>Images</h5>
                        <p>To embed images, you can use the <span className='background-grey'>&lt;img&gt;</span> tag.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description of image"</span> <span class="color-pink">width</span>=<span class="color-green">"500"</span> <span class="color-pink">height</span>=<span class="color-green">"300"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>src</span>: Specifies the path to the image file.</li>
                            <li><span className='background-grey'>alt</span>: Provides alternative text for the image if it cannot be displayed.</li>
                            <li><span className='background-grey'>width</span> and <span className='background-grey'>height</span>: Set the dimensions of the image.</li>
                        </ul>
                    </li>
                    <li className='operator mb-4'>
                        <h5>Audio</h5>
                        <p>To embed audio files, use the &lt;audio&gt; element.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;audio <span class="color-pink">controls</span>&gt;</p>
                                <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.mp3"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/mpeg"</span>&gt;</p>
                                <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"audio.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"audio/ogg"</span>&gt;</p>
                                <p class="ml-30">    Your browser does not support the audio tag.</p>
                                <p>&lt;/audio&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>controls</span>: Adds playback controls (play, pause, volume).</li>
                            <li>You can use attributes like <span className='background-grey'>autoplay</span>, <span className='background-grey'>loop</span>, and <span className='background-grey'>muted</span> as needed.</li>
                        </ul>
                    </li>
                    <li className='operator mb-4'>
                        <h5>Video</h5>
                        <p>To embed video content, use the &lt;video&gt; element.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;video <span class="color-pink">width</span>=<span class="color-green">"640"</span> <span class="color-pink">height</span>=<span class="color-green">"360"</span> <span class="color-pink">controls</span>&gt;</p>
                                <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.mp4"</span> <span class="color-pink">type</span>=<span class="color-green">"video/mp4"</span>&gt;</p>
                                <p class="ml-30">    &lt;source <span class="color-pink">src</span>=<span class="color-green">"movie.ogg"</span> <span class="color-pink">type</span>=<span class="color-green">"video/ogg"</span>&gt;</p>
                                <p class="ml-30">    Your browser does not support the video tag.</p>
                                <p>&lt;/video&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>controls</span>: Provides video playback controls.</li>
                            <li>You can also include attributes like <span className='background-grey'>autoplay</span>, <span className='background-grey'>loop</span>, and <span className='background-grey'>poster</span> for better user experience.</li>
                        </ul>
                    </li>
                    <li className='operator mb-4'>
                        <h5>Iframes</h5>
                        <p>To embed external content, such as another webpage or interactive elements, use the &lt;iframe&gt; tag.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;iframe <span class="color-pink">src</span>=<span class="color-green">"https://www.example.com"</span> <span class="color-pink">width</span>=<span class="color-green">"600"</span> <span class="color-pink">height</span>=<span class="color-green">"400"</span> <span class="color-pink">title</span>=<span class="color-green">"Description of iframe content"</span>&gt;&lt;/iframe&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>src</span>: Specifies the URL of the page to embed.</li>
                            <li><span className='background-grey'>title</span>: Provides a description for accessibility.</li>
                            <li><span className='background-grey'>width</span> and <span className='background-grey'>height</span>: Define the dimensions of the iframe.</li>
                        </ul>
                    </li>
                    <li className='operator mb-4'>
                        <h5>Object Tag</h5>
                        <p>The &lt;object&gt; tag can embed multimedia files, including images, audio, video, and Flash content.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;object <span class="color-pink">data</span>=<span class="color-green">"movie.mp4"</span> <span class="color-pink">type</span>=<span class="color-green">"video/mp4"</span> <span class="color-pink">width</span>=<span class="color-green">"640"</span> <span class="color-pink">height</span>=<span class="color-green">"360"</span>&gt;</p>
                                <p class="ml-30">    &lt;p&gt;Your browser does not support the object tag.&lt;/p&gt;</p>
                                <p>&lt;/object&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Where,</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><span className='background-grey'>data</span>: Specifies the URL of the file to embed.</li>
                            <li><span className='background-grey'>type</span>: Indicates the MIME type of the content.</li>
                        </ul>
                    </li>
                    <li className='operator mb-4'>
                        <h5>SVG (Scalable Vector Graphics)</h5>
                        <p>You can embed SVG images directly in HTML.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;svg <span class="color-pink">width</span>=<span class="color-green">"100"</span> <span class="color-pink">height</span>=<span class="color-green">"100"</span>&gt;</p>
                                <p class="ml-30">    &lt;circle <span class="color-pink">cx</span>=<span class="color-green">"50"</span> <span class="color-pink">cy</span>=<span class="color-green">"50"</span> <span class="color-pink">r</span>=<span class="color-green">"40"</span> <span class="color-pink">fill</span>=<span class="color-green">"red"</span> /&gt;</p>
                                <p>&lt;/svg&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <p>Embedding multimedia in HTML enhances the visual and auditory experience of a webpage. Using the appropriate tags and attributes allows developers to control how content is presented and interacted with, making web pages more engaging and informative.</p>



            </div>
        </section>
    )
}