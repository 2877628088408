import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_file_put_contents";

export default function FilePutContents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP file_put_contents() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_file_get_contents',
            'next': '/php/func_file_exists'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP file_put_contents() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">file_put_contents()</span> function in PHP is used to write data to a file. If the file does not exist, it will be created. If the file already exists, its contents will be overwritten unless the <span className="background-grey">FILE_APPEND</span> flag is specified. This function is a convenient way to handle file writing in PHP without needing to manually open, write, and close the file.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">file_put_contents</span>(<span class="color-blue">string</span> <span class="color-pink">$filename</span>, <span class="color-blue">mixed</span> <span class="color-pink">$data</span>, <span class="color-blue">int</span> <span class="color-pink">$flags</span> = <span class="color-pink">0</span>, resource <span class="color-pink">$context</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$filename</strong> (required): The name of the file where you want to write the data. This can be a path to a local file or a URL.</li>

                    <li><strong>$data</strong> (required): The data you want to write to the file. This can be a string, an array, or any data type that can be converted to a string.</li>

                    <li><strong>$flags</strong> (optional): An integer that controls the behavior of the function. Commonly used flags include:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">FILE_APPEND</span>: If set, the data will be appended to the end of the file instead of overwriting it.</li>
                            <li><span className="background-grey">LOCK_EX</span>: If set, an exclusive lock will be placed on the file while writing to it.</li>
                        </ul>
                    </li>
                    <li><strong>$context</strong> (optional): A context resource created with <span className="background-grey">stream_context_create()</span>, which can modify the behavior of the file stream. The default is null.</li>
                </ul>
                <p>The function returns the number of bytes written to the file on success. It returns <span className="background-grey">false</span> on failure (e.g., if the file cannot be created or written to).</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage of <span className="background-grey">file_put_contents()</span></h5>
                <p>Here’s a simple example of how to use <span className="background-grey">file_put_contents()</span> to write a string to a file:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Data to write</p>
                        <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-green">"Hello, World!"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Write data to 'example.txt'</p>
                        <p class="ml-30">    <span class="color-pink">$bytes_written</span> = <span class="color-red">file_put_contents</span>(<span class="color-green">"example.txt"</span>, <span class="color-pink">$data</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$bytes_written</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Data written successfully. Bytes written: "</span> . <span class="color-pink">$bytes_written</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error writing to the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2: Appending Data to a File</h5>
                <p>To append data instead of overwriting the file, you can use the <span className="background-grey">FILE_APPEND</span> flag:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Data to append</p>
                        <p class="ml-30">    <span class="color-pink">$data</span> = <span class="color-green">"&#92;nThis is an appended line."</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Append data to 'example.txt'</p>
                        <p class="ml-30">    <span class="color-pink">$bytes_written</span> = <span class="color-red">file_put_contents</span>(<span class="color-green">"example.txt"</span>, <span class="color-pink">$data</span>, FILE_APPEND);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$bytes_written</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Data appended successfully. Bytes written: "</span> . <span class="color-pink">$bytes_written</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Error appending to the file."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>



            </div>
        </section>
    )
}