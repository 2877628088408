import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_chroot";

export default function ChRoot() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP chroot() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_chdir',
            'next': '/php/func_closedir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP chroot() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">chroot()</span> function in PHP is used to change the root directory of the current process to a specified directory. This function is primarily used for security purposes, as it creates a "sandboxed" environment, isolating the current process from the rest of the filesystem. After calling <span className="background-grey">chroot()</span>, the script can only access files and directories inside the new root directory, providing a level of protection against file access vulnerabilities.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">chroot</span>(directory)</p>
                        `
                    }}></div>
                </div>
                <p>Where, directory is the path to the new root directory. The path must be an absolute path, and the directory must exist.</p>
                <p>It returns true on success and returns false on failure.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">chroot()</span> Function</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // Changing the root directory</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">chroot</span>(<span class="color-green">"/path/to/new/root"</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Successfully changed the root directory.&#92;n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to change the root directory.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30 color-grey">    // After chroot(), the script can only access files inside /path/to/new/root</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">file_exists</span>(<span class="color-green">"example.txt"</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"File found.&#92;n"</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"File not found in the new root.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The script attempts to change the root directory to <span className="background-grey">/path/to/new/root</span> using <span className="background-grey">chroot()</span>. If successful, the new root directory becomes <span className="background-grey">/path/to/new/root</span>, and the script can only access files inside this directory.</li>
                    <li>After changing the root, the script checks for the existence of <span className="background-grey">example.txt</span> within the new root directory. Any file operations will be limited to the new root directory.</li>
                </ul>

            </div>
        </section>
    )
}