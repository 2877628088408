import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_delete";

export default function FtpDelete() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_delete() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_get',
            'next': '/php/func_ftp_mkdir'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_delete() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_delete()</span> function in PHP is used to delete a file from a remote FTP server. It allows you to remove files from the server's file system, making it a useful function for managing files on an FTP server.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_delete</span>(ftp_connection, file_path)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource, which is created using <span className="background-grey">ftp_connect()</span>. This represents the active FTP connection.</li>
                    <li><strong>file_path</strong> (Required): The path to the file on the FTP server that you want to delete. This should be the full path to the file you want to remove.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_delete()</span> Function</h5>
                <p>This is an example of deleting a file from an FTP server</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // File to be deleted on the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$remote_file</span> = <span class="color-green">"server_path/file_to_delete.txt"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Delete the file</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_delete</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$remote_file</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File deleted successfully.\n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File deletion failed.\n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.\n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We establish a connection to the FTP server using <span className="background-grey">ftp_connect()</span>.</li>
                    <li>We log in to the server using <span className="background-grey">ftp_login()</span>.</li>
                    <li>We delete the file <span className="background-grey">server_path/file_to_delete.txt</span> from the FTP server using <span className="background-grey">ftp_delete()</span>.</li>
                    <li>Finally, we close the connection using <span className="background-grey">ftp_close()</span>.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>File deleted successfully.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}