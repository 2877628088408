import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_getPrevious";

export default function GetPrevious() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP getPrevious() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_getLine',
            'next': '/php/func_getTrace'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP getPrevious() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">getPrevious()</span> function in PHP's Exception class is used to retrieve the previous exception that was thrown in a chain of exceptions. This is especially useful when dealing with exception chaining, where one exception is thrown in response to another exception, allowing you to track the sequence of errors that occurred.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$exception</span>-><span class="color-red">getPrevious</span>();</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$exception</strong> refers to the exception object.</li>
                    <li><strong>getPrevious()</strong> returns the previous exception object or <span className="background-grey">null</span> if no previous exception exists.</li>
                </ul>
                <p>
                    The <span className="background-grey">getPrevious()</span> function helps you work with a series of exceptions. When an exception is thrown, sometimes it is caused by another exception. PHP allows you to link these exceptions together in a chain. By using <span className="background-grey">getPrevious()</span>, you can trace back through the chain of exceptions to understand the full history of the error.
                </p>


                <h5 className="mt-5 mb-3">Example: Basic Usage of <span className="background-grey">getPrevious()</span></h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">try</span> {</p>
                        <p class="ml-60">        <span class="color-blue">try</span> {</p>
                        <p class="ml-90 color-grey">            // First exception</p>
                        <p class="ml-90">            <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"First exception occurred."</span>);</p>
                        <p class="ml-60">        } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {
                        <p class="ml-90 color-grey">            // Second exception (caused by the first)
                        <p class="ml-90">            <span class="color-blue">throw new</span> <span class="color-yellow">Exception</span>(<span class="color-green">"Second exception occurred."</span>, <span class="color-pink">0</span>, <span class="color-pink">$e</span>);
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    } <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Current Exception: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>() . <span class="color-green">"&lt;br&gt;"</span>;</p>
                        <br />    
                        <p class="ml-60 color-grey">        // Check if there is a previous exception</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$e</span>-><span class="color-red">getPrevious</span>()) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Previous Exception: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getPrevious</span>()-><span class="color-red">getMessage</span>();</p>
                        <p class="ml-60">        }</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Current Exception: Second exception occurred.</p>
                            <p>Previous Exception: First exception occurred.</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first exception is thrown with the message "First exception occurred."</li>
                    <li>Inside the <span className="background-grey">catch</span> block, a second exception is thrown with the message "Second exception occurred." The first exception is passed as the third parameter to the second exception, linking them together.</li>
                    <li>The <span className="background-grey">getPrevious()</span> method retrieves the first exception.</li>
                </ul>



            </div>
        </section>
    )
}