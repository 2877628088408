import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_sum";

export default function ArraySum() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_sum() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_splice',
            'next': '/php/func_array_product'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_sum() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_sum() function in PHP calculates the sum of all the values in an array. It works with both indexed and associative arrays and can handle both integers and floats. The function ignores any non-numeric values in the array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_sum</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The array containing the values to sum.</li>
                    <li><strong>Return Value</strong>: The sum of all numeric values in the array. The result can be an integer or a float depending on the input values.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage with Numeric Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [<span class="color-pink">2</span>, <span class="color-pink">4</span>, <span class="color-pink">6</span>, <span class="color-pink">8</span>, <span class="color-pink">10</span>];</p>
                            <p><span class="color-pink">$sum</span> = <span class="color-red">array_sum</span>(<span class="color-pink">$numbers</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$sum</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>30</p>
                        `
                    }}></div>
                </div>
                <p>Where, The function adds all the values in the array: <span className='background-grey'>2 + 4 + 6 + 8 + 10 = 30</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Associative Array</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [</p>
                            <p class="ml-30">    <span class="color-green">"apples"</span> => <span class="color-pink">3</span>,</p>
                            <p class="ml-30">    <span class="color-green">"bananas"</span> => <span class="color-pink">5</span>,</p>
                            <p class="ml-30">    <span class="color-green">"cherries"</span> => <span class="color-pink">7</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$sum</span> = <span class="color-red">array_sum</span>(<span class="color-pink">$fruits</span>);</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$sum</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>15</p>
                        `
                    }}></div>
                </div>
                <p>Where, The keys ("apples", "bananas", "cherries") are ignored, and the function sums the values <span className='background-grey'>3 + 5 + 7 = 15</span>.</p>
            </div>
        </section>
    )
}