import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_substr";

export default function Substr() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP substr() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_str_replace',
            'next': '/php/func_strpos'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP substr() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The substr() function in PHP is used to <strong>extract a part of a string</strong>, also known as a substring. It allows you to get a specific portion of a string by specifying a starting position and an optional length. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">substr</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$start</span>, <span class="color-blue">int</span> <span class="color-pink">$length</span> = <span class="color-blue">null</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The original string from which the substring will be extracted.</li>
                    <li><strong>$start</strong>: The starting position (index) from where to begin extracting the substring. The index starts at 0. A negative value can be used to start from the end of the string.</li>
                    <li><strong>$length</strong> (optional): The number of characters to extract from the starting position. If omitted, the rest of the string is returned. A negative value means to stop that many characters from the end of the string.</li>
                    <li><strong>Return Value</strong>: Returns the extracted part of the string as a new string.</li>
                </ul>


                <h5 className="mt-5 mb-3">How the <span className="background-grey">$start</span> Parameter Works</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Positive</strong> <span className="background-grey">$start</span> : If <span className="background-grey">$start</span> is a positive number, the function will begin extracting from that index, starting from the left of the string.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;?php</p>
                                <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">substr</span>(<span class="color-green">"abcdef"</span>, <span class="color-pink">1</span>); <span class="color-grey">// Output: "bcdef"</span></p>
                                <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>Here, the substring starts at index 1, so <span className="background-grey">"bcdef"</span> is returned.</p>
                    </li>
                    <li>
                        <strong>Negative</strong> <span className="background-grey">$start</span>: If $start is negative, the function will begin extracting from the end of the string.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;?php</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">substr</span>(<span class="color-green">"abcdef"</span>, <span class="color-pink">-3</span>); <span class="color-grey">// Output: "def"</span></p>
                                    <p>?&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1 : Extracting a File Extension</h5>
                <p>You can extract the file extension from a file name using <span className="background-grey">substr()</span>.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$filename</span> = <span class="color-green">"document.pdf"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$extension</span> = <span class="color-red">substr</span>(<span class="color-pink">$filename</span>, <span class="color-pink">-3</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$extension</span>; <span class="color-grey">// Output: "pdf"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2 : Extracting a Domain from an Email</h5>
                <p>You can extract the domain part of an email address using <span className="background-grey">substr()</span> and <span className="background-grey">strpos()</span></p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$email</span> = <span class="color-green">"user@example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$domain</span> = <span class="color-red">substr</span>(<span class="color-pink">$email</span>, <span class="color-red">strpos</span>(<span class="color-pink">$email</span>, <span class="color-green">"@"</span>) + <span class="color-pink">1</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$domain</span>; <span class="color-grey">// Output: "example.com"</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 3 : Truncating Long Strings</h5>
                <p>If you have a long string that needs to be truncated to fit within a certain character limit, you can use <span className="background-grey">substr()</span> to cut it off</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$longText</span> = <span class="color-green">"This is an example of a long sentence that needs truncating."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$shortText</span> = <span class="color-red">substr</span>(<span class="color-pink">$longText</span>, <span class="color-pink">0</span>, <span class="color-pink">20</span>);</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$shortText</span> . <span class="color-green">"..."</span>; <span class="color-grey">// Output: "This is an example..."</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}