import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_count_values";

export default function ArrayColumn() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_count_values() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_combine',
            'next': '/php/func_array_diff'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_count_values() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_count_values() function in PHP is used to count the number of occurrences of each value in an array. It processes the array and returns an associative array where the keys are the unique values from the original array, and the corresponding values represent how many times each key appeared in the original array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">array_count_values</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>$array</strong> is the input array whose values you want to count.</p>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [<span class="color-green">"apple"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"apple"</span>, <span class="color-green">"orange"</span>, <span class="color-green">"banana"</span>, <span class="color-green">"apple"</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_count_values</span>(<span class="color-pink">$fruits</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [apple] => <span class="color-pink">3</span></p>
                            <p class="ml-30">    [banana] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [orange] => <span class="color-pink">1</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The array <span className='background-grey'>["apple", "banana", "apple", "orange", "banana", "apple"]</span> contains 3 apples, 2 bananas, and 1 orange.</li>
                    <li>The <span className='background-grey'>array_count_values()</span> function counts these occurrences and returns an associative array where each key is a unique fruit, and the value is the count of how many times that fruit appears in the original array.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 2: Counting Numeric Values</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">3</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_count_values</span>(<span class="color-pink">$numbers</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">3</span>] => <span class="color-pink">3</span></p>
                            <p class="ml-30">    [<span class="color-pink">4</span>] => <span class="color-pink">1</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Here, The array <span className='background-grey'>[1, 2, 2, 3, 3, 3, 4]</span> contains 1 occurrence of <span className='background-grey'>1</span>, 2 occurrences of <span className='background-grey'>2</span>, 3 occurrences of <span className='background-grey'>3</span>, and 1 occurrence of <span className='background-grey'>4</span>. The function counts each unique number and returns an associative array with these counts.</p>

                <h5 className="mt-5 mb-3">Example 3: Mixed Data Types</h5>
                <p>You can also use <span className='background-grey'>array_count_values()</span> with arrays containing mixed data types (strings, integers, etc.).</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$mixed</span> = [<span class="color-pink">1</span>, <span class="color-green">"apple"</span>, <span class="color-pink">1</span>, <span class="color-green">"banana"</span>, <span class="color-green">"apple"</span>, <span class="color-pink">2</span>, <span class="color-green">"banana"</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_count_values</span>(<span class="color-pink">$mixed</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [<span class="color-pink">1</span>] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [apple] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [banana] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [<span class="color-pink">2</span>] => <span class="color-pink">1</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The array contains both integers and strings.</li>
                    <li>The function counts occurrences of both types, treating them as separate values.</li>
                    <li>The result shows that <span className='background-grey'>1</span> appears twice, <span className='background-grey'>"apple"</span> appears twice, <span className='background-grey'>"banana"</span> appears twice, and <span className='background-grey'>2</span> appears once.</li>
                </ul>
            </div>
        </section>
    )
}