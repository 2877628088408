import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/working-with-session";

export default function PHPSessions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Understanding Sessions in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/prepared-statement',
            'next': '/php/working-with-cookies'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Understanding Sessions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A session in PHP is like a temporary storage that allows you to keep track of information (data) across different pages of a website while a user is browsing. It's useful when you want to store data about the user or their activities, such as login status, shopping cart contents, or preferences, and access it across multiple pages.
                </p>

                <h5 className='mt-5 mb-3'>How Sessions Work</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Starting a Session</strong>: When a user visits a website, you can start a session. PHP assigns a unique session ID to the user, which helps to identify them across different pages.</li>

                    <li><strong>Storing Data in the Session</strong>: You can store data in the session that you want to keep track of. This data is stored on the server, and only the session ID is stored on the user's computer in the form of a cookie.</li>

                    <li><strong>Accessing Session Data</strong>: You can access the stored session data on any page of the website as long as the session is active.</li>

                    <li><strong>Ending a Session</strong>: When the user logs out or when you decide to end the session, you can clear the session data.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Session Functions</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Start a Session</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_start</span>();</p>
                                `
                            }}></div>
                        </div>
                        <p>This function starts a session. It must be called at the beginning of the PHP script, before any HTML or output.</p>
                    </li>
                    <li>
                        <strong>Set Session Variables</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'key'</span>] = <span class="color-green">'value'</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>This code saves data into the session. For example:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">$_SESSION</span>[<span class="color-green">'username'</span>] = <span class="color-green">'JohnDoe'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Access Session Variables</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">$username</span> = <span class="color-pink">$_SESSION</span>[<span class="color-green">'username'</span>];</p>
                                `
                            }}></div>
                        </div>
                        <p>This code retrieves the data stored in the session.</p>
                    </li>
                    <li>
                        <strong>Destroy a Session</strong>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">session_unset</span>(); <span class="color-grey>// Unset all session variables</span></p>
                                    <p><span class="color-red">session_destroy</span>(); <span class="color-grey>// Destroy the session</span></p>
                                `
                            }}></div>
                        </div>
                        <p>This code clears all session data and ends the session.</p>
                    </li>
                </ul>
                <p>These are the all steps to use session in your PHP code. For better understanding let's look at a real life senario, where we can use the session.</p>


                <h5 className='mt-5 mb-3'>Example of Using Sessions</h5>
                <p>Imagine you have a login system, and you want to remember the user after they log in:</p>

                <h6 className='mt-4'>In Login Page(login.php)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-red">session_start</span>(); <span class="color-grey">// Start the session</span></p>
                        <br />    
                        <p class="ml-30 color-grey">    // Assume login details are correct</p>
                        <p class="ml-30">    <span class="color-pink">$_SESSION</span>[<span class="color-green">'loggedin'</span>] = <span class="color-blue">true</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$_SESSION</span>[<span class="color-green">'username'</span>] = <span class="color-green">'JohnDoe'</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <p>In this <span class="background-grey">login.php</span> page, we can set the session variables. Now these session variables can be used in allover the php pages.</p>

                <h6 className='mt-4'>In Dashboard Page(dashboard.php)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-red">session_start</span>(); <span class="color-grey">// Start the session</span></p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-pink">$_SESSION</span>[<span class="color-green">'loggedin'</span>] == <span class="color-blue">true</span>) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Welcome, "</span> . <span class="color-pink">$_SESSION</span>[<span class="color-green">'username'</span>];</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Please log in first."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-4'>In Logout Page(logout.php)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-red">session_start</span>(); <span class="color-grey">// Start the session</span></p>
                        <br />
                        <p class="ml-30">    <span class="color-red">session_unset</span>();  <span class="color-grey">// Remove all session variables</span></p>
                        <p class="ml-30">    <span class="color-red">session_destroy</span>(); <span class="color-grey">// End the session</span></p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"You have been logged out."</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}