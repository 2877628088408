import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_sqrt";

export default function Sqrt() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP sqrt() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_pow',
            'next': '/php/func_rand'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP sqrt() Function</h3>
            <div className='mt-4 mb-5'>
                <p>The sqrt() function in PHP is used to calculate the square root of a number. It has a basic syntax -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">sqrt</span>(number)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>number</strong> is the number for which the square root is to be calculated. This number must be non-negative, as square roots for negative numbers are not supported by this function.</p>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">sqrt</span>(<span class="color-pink">9</span>);  <span class="color-grey">// Output: 3</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Here, <span className="background-grey">sqrt(9)</span> returns <span className="background-grey">3</span> because the square root of 9 is 3.</p>

            </div>
        </section>
    )
}