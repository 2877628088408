import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/error/handling";

export default function ErrorHandling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Error Handling in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/oops/static-methods-properties',
            'next': '/php/working-with-apis'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Error Handling</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Error handling in PHP is the process of managing errors and exceptions that occur during the execution of a script. Proper error handling ensures that your application can gracefully handle unexpected situations, providing meaningful feedback to users and maintaining the stability of the application.
                </p>
                <p>
                    Let's discuss about types of errors in PHP.
                </p>

                <h5 className="mt-5 mb-3">Types of Errors in PHP</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Parse Errors (Syntax Errors):</strong>
                        <p>It occurs when there is a mistake in the syntax of your script, like a missing semicolon.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">echo</span> <span class="color-green">"Hello World  // Missing closing quote</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Fatal Errors</strong>
                        <p>It occurs when PHP encounters a problem that it cannot recover from, such as calling a non-existent function.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">nonExistentFunction</span>();  <span class="color-grey">// Fatal Error: Call to undefined function</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Warning Errors</strong>
                        <p>This error indicate that something is wrong, but the script can continue running.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">include</span>(<span class="color-green">"missingfile.php"</span>);  <span class="color-grey">// Warning: include() [function.include]: failed to open stream</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Notice Errors</strong>
                        <p>This error is a minor errors, often indicating uninitialized variables or other small issues. The script continues to execute.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">echo</span> <span class="color-pink">$undefinedVariable</span>;  <span class="color-grey">// Notice: Undefined variable</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Basic Error Handling Techniques</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Using <span className='background-grey'>die()</span> and <span className='background-grey'>exit()</span> Functions:</strong>
                        <p>These functions stop the script execution and can be used to display a custom error message.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">if</span> (!<span class="color-red">file_exists</span>(<span class="color-green">"test.txt"</span>)) {</p>
                                    <p class="ml-30">    <span class="color-red">die</span>(<span class="color-green">"File not found. Exiting script."</span>);</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Custom Error Handling with <span className='background-grey'>set_error_handler()</span>:</strong>
                        <p>You can define your own function to handle errors using <span className='background-grey'>set_error_handler()</span>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">function</span> <span class="color-red">customError</span>(<span class="color-pink">$errno</span>, <span class="color-pink">$errstr</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Error: [$errno] $errstr"</span>;</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-red">set_error_handler</span>(<span class="color-green">"customError"</span>);</p>
                                    <br />
                                    <p><span class="color-blue">echo</span>(<span class="color-pink">$test</span>);  <span class="color-grey">// Triggers an error because $test is not </span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Error Logging</strong>
                        <p>PHP can log errors to a file using the <span className='background-grey'>error_log()</span> function.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-red">error_log</span>(<span class="color-green">"An error occurred"</span>, <span class="color-pink">3</span>, <span class="color-green">"errors.log"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Using try-catch for Exception Handling:</strong>
                        <p>Exceptions provide a way to handle errors more cleanly. PHP uses <span className='background-grey'>try</span>, <span className='background-grey'>catch</span>, and <span className='background-grey'>throw</span> blocks for exception handling.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">try</span> {</p>
                                    <p class="ml-30">    <span class="color-blue">if</span> (!<span class="color-red">file_exists</span>(<span class="color-green">"test.txt"</span>)) {</p>
                                    <p class="ml-60">        <span class="color-blue">throw new</span> <span class="color-blue">Exception</span>(<span class="color-green">"File not found."</span>);</p>
                                    <p class="ml-30">    }</p>
                                    <p>} <span class="color-blue">catch</span> (<span class="color-yellow">Exception</span> <span class="color-pink">$e</span>) {</p>
                                    <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Caught exception: "</span> . <span class="color-pink">$e</span>-><span class="color-red">getMessage</span>();</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Error Reporting Levels</h5>
                <p>PHP has several predefined constants that specify the level of error reporting:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>E_ALL</span>: All errors and warnings (includes <span className='background-grey'>E_STRICT</span>).</li>
                    <li><span className='background-grey'>E_ERROR</span>: Fatal run-time errors.</li>
                    <li><span className='background-grey'>E_WARNING</span>: Run-time warnings (non-fatal errors).</li>
                    <li><span className='background-grey'>E_NOTICE</span>: Notices (minor issues like using an uninitialized variable).</li>
                </ul>
                <p>You can control the error reporting level with the <span className='background-grey'>error_reporting()</span> function:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">error_reporting</span>(E_ALL);  <span class="color-grey">// Report all errors</span></p>
                            <p><span class="color-red">error_reporting</span>(<span class="color-pink">0</span>);  <span class="color-grey">// Turn off error reporting</span></p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Displaying vs. Logging Errors</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Displaying Errors</strong>: Useful during development, you can show errors on the screen by setting <span className='background-grey'>display_errors</span> to <span className='background-grey'>On</span> in your <span className='background-grey'>php.ini</span> file or using <span className='background-grey'>ini_set()</span>.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">'display_errors'</span>, <span class="color-pink">1</span>);</p>
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">'display_startup_errors'</span>, <span class="color-pink">1</span>);</p>
                                    <p><span class="color-red">error_reporting</span>(E_ALL);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Logging Errors</strong>: In a production environment, it's better to log errors to a file instead of displaying them to the user.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">"log_errors"</span>, <span class="color-pink">1</span>);</p>
                                    <p><span class="color-red">ini_set</span>(<span class="color-green">"error_log"</span>, <span class="color-green">"/path/to/error.log"</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}