import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_round";

export default function Round() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP round() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_floor',
            'next': '/php/func_max'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP round() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The round() function in PHP is used to round a floating-point number to a specified number of decimal places. By default, it rounds the number to the nearest integer. You can also specify how many decimal points you want the number to be rounded to. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">round</span>(<span class="color-blue">float</span> <span class="color-pink">$number</span>, <span class="color-blue">int</span> <span class="color-pink">$precision</span> = <span class="color-pink">0</span>, <span class="color-blue">int</span> <span class="color-pink">$mode</span> = PHP_ROUND_HALF_UP)</p>
                        `
                    }}></div>
                </div>
                <p>Where, </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$number</strong>: The number to be rounded.</li>
                    <li><strong>$precision</strong>: The number of decimal digits to round to (optional). The default is <span className="background-grey">0</span>, meaning the number is rounded to the nearest integer.</li>
                    <li><strong>$mode</strong>: (Optional) Specifies the rounding mode. There are several modes available:
                        <ul style={{listStyle:'disc'}}>
                            <li><span className="background-grey">PHP_ROUND_HALF_UP</span>: Rounds up if the decimal part is 0.5 or higher (default behavior).</li>
                            <li><span className="background-grey">PHP_ROUND_HALF_DOWN</span>: Rounds down if the decimal part is 0.5 or lower.</li>
                            <li><span className="background-grey">PHP_ROUND_HALF_EVEN</span>: Rounds towards the nearest even number when the decimal part is exactly 0.5.</li>
                            <li><span className="background-grey">PHP_ROUND_HALF_ODD</span>: Rounds towards the nearest odd number when the decimal part is exactly 0.5.</li>
                        </ul>
                    </li>
                </ul>


                <h5 className="mt-5 mb-3">Example 1: Basic Rounding</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.7</span>);  <span class="color-grey">// Output: 5</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.3</span>);  <span class="color-grey">// Output: 4</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">round(4.7)</span> rounds to <span className="background-grey">5</span>, as 0.7 is closer to the next whole number.</li>
                    <li><span className="background-grey">round(4.3)</span> rounds to <span className="background-grey">4</span>, as 0.3 is closer to the lower whole number.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Rounding with Decimal Precision</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.567</span>, <span class="color-pink">2</span>);  <span class="color-grey">// Output: 4.57</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round(<span class="color-pink">4.563</span>, <span class="color-pink">2</span>);  <span class="color-grey">// Output: 4.56</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">round(4.567, 2)</span> rounds to <span className="background-grey">4.57</span> because it rounds the number to two decimal places, and 0.67 rounds up.</li>
                    <li><span className="background-grey">round(4.563, 2)</span> rounds to <span className="background-grey">4.56</span> because the third decimal is less than 5.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 3: Specifying Different Rounding Modes</h5>
                <p>By default, PHP uses the <span className="background-grey">PHP_ROUND_HALF_UP</span> mode, but you can change the mode using the third parameter:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.5</span>, <span class="color-pink">0</span>, PHP_ROUND_HALF_UP);   <span class="color-grey">// Output: 5 (default behavior)</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.5</span>, <span class="color-pink">0</span>, PHP_ROUND_HALF_DOWN); <span class="color-grey">// Output: 4</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">4.5</span>, <span class="color-pink">0</span>, PHP_ROUND_HALF_EVEN); <span class="color-grey">// Output: 4 (rounds to nearest even number)</span></p>
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-red">round</span>(<span class="color-pink">5.5</span>, <span class="color-pink">0</span>, PHP_ROUND_HALF_EVEN); <span class="color-grey">// Output: 6</span></p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className="background-grey">PHP_ROUND_HALF_UP</span>: Rounds up when the decimal is <span className="background-grey">0.5</span> or greater.</li>
                    <li><span className="background-grey">PHP_ROUND_HALF_DOWN</span>: Rounds down when the decimal is <span className="background-grey">0.5</span> or smaller.</li>
                    <li><span className="background-grey">PHP_ROUND_HALF_EVEN</span>: Rounds <span className="background-grey">4.5</span> down to <span className="background-grey">4</span> because <span className="background-grey">4</span> is an even number, and <span className="background-grey">5.5</span> up to <span className="background-grey">6</span> because <span className="background-grey">6</span> is the nearest even number.</li>
                </ul>

            </div>
        </section>
    )
}