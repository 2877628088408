import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/functions";

export default function PHPFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Functions in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/form-handling',
            'next': '/php/built-in/functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In PHP, a function is a block of code that performs a specific task, encapsulated into a single entity. Functions help in organizing and reusing code, making it more modular and easier to maintain. Once a function is defined, it can be called multiple times from different parts of a program, reducing code duplication.
                </p>
                <p>
                    A PHP function is defined using the <span class="background-grey">function</span> keyword, followed by a name, parentheses <span class="background-grey">( )</span>, and a block of code enclosed in curly braces <span class="background-grey">&#123; &#125;</span>.
                </p>
                <h5 className="mt-5 mb-3">Creating a Function</h5>
                <p>We have a basic syntax to create an function in PHP. Lets see in the example -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">function</span> <span class="color-red">greet</span>() {</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Hello, world!"</span>;</p>
                            <p>}</p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">function greet() &#123; ... &#125;</span>: Defines a function named <span class="background-grey">greet</span>.</li>
                    <li><span class="background-grey">echo "Hello, world!";</span>: The function prints "Hello, world!" when called.</li>
                </ul>

                <h5 className="mt-5 mb-3">Calling a Function</h5>
                <p>
                    To execute the code inside a function, you need to "call" the function by using its name followed by parentheses.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-red">greet</span>(); <span class="color-grey">// Outputs: Hello, world!</span></p>
                            <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Functions with Parameters</h5>
                <p>
                    A functions can accept parameters, which are variables passed to the function when it is called. These parameters allow the function to work with different data.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;?php</p>
                            <p><span class="color-blue">function</span> <span class="color-red">greet</span>(<span class="color-pink">$name</span>) </p>
                            <p>{</p>
                            <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-green">"Hello, "</span> . <span class="color-pink">$name</span> . <span class="color-green">"!"</span>;</p>
                            <p>}</p>
                            ?>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span class="background-grey">greet($name)</span>: The function <span class="background-grey">greet</span> takes one parameter named <span class="background-grey">$name</span>.</li>
                    <li><span class="background-grey">echo "Hello, " . $name . "!";</span>: The function prints <span class="background-grey">"Hello, "</span> followed by the value of <span class="background-grey">$name</span>.</li>
                </ul>
            </div>
        </section>
    )
}