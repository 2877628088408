import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/mail";

export default function Mail() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mail in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_json_encode',
            'next': '/php/func_abs'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mail in PHP</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className='background-grey'>mail()</span> function in PHP is used to send emails directly from a PHP script. It provides a simple way to send plain-text emails, and it's widely used for contact forms, notifications, or any other situation where automated email sending is required. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">mail</span>(to, subject, message, headers, parameters);</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>to</span>: The recipient's email address (or multiple addresses).</li>
                    <li><span className='background-grey'>subject</span>: The subject of the email.</li>
                    <li><span className='background-grey'>message</span>: The body of the email (plain text).</li>
                    <li><span className='background-grey'>headers</span> (optional): Additional headers, such as "From", "CC", "BCC", "Reply-To", and MIME type.</li>
                    <li><span className='background-grey'>parameters</span> (optional): Extra parameters for the email, such as setting the return path.</li>
                </ul>

                <h5 className="mt-5 mb-3">A Simple Example of Sending Mail</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$to</span> = <span class="color-green">"recipient@example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$subject</span> = <span class="color-green">"Test Email"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$message</span> = <span class="color-green">"This is a test email sent using PHP's mail function."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> = <span class="color-green">"From: sender@example.com"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">mail</span>(<span class="color-pink">$to</span>, <span class="color-pink">$subject</span>, <span class="color-pink">$message</span>, <span class="color-pink">$headers</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Email sent successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to send email."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>You can add multiple headers for a more customized email. Here are some common headers:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$to</span> = <span class="color-green">"recipient@example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$subject</span> = <span class="color-green">"Test Email"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$message</span> = <span class="color-green">"This is a test email with additional headers."</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-pink">$headers</span> = <span class="color-green">"From: sender@example.com\r\n"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> .= <span class="color-green">"Reply-To: replyto@example.com\r\n"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> .= <span class="color-green">"CC: cc@example.com\r\n"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> .= <span class="color-green">"BCC: bcc@example.com\r\n"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">mail</span>(<span class="color-pink">$to</span>, <span class="color-pink">$subject</span>, <span class="color-pink">$message</span>, <span class="color-pink">$headers</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Email sent successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to send email."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Sending HTML Emails</h5>
                <p>By default, the <span className='background-grey'>mail()</span> function sends plain text emails. To send HTML emails, you need to specify the <span className='background-grey'>Content-type</span> header.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$to</span> = <span class="color-green">"recipient@example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$subject</span> = <span class="color-green">"HTML Email Test"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Message body with HTML content</p>
                        <p class="ml-30">    <span class="color-pink">$message</span> = <span class="color-green">"</span></p>
                        <p class="ml-30">   <span class="color-green">&lt;html&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;head&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;title&gt;HTML Email&lt;/title&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;/head&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;body&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;h1&gt;This is an HTML email&lt;/h1&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;p&gt;Here is a paragraph with &lt;b&gt;bold&lt;/b&gt; text.</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;/body&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">&lt;/html&gt;</span></p>
                        <p class="ml-30">    <span class="color-green">"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Headers to specify the content type</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> = <span class="color-green">"MIME-Version: 1.0"</span> . <span class="color-green">"\r\n"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> .= <span class="color-green">"Content-type:text/html;charset=UTF-8"</span> . <span class="color-green">"\r\n"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Additional headers</p>
                        <p class="ml-30">    <span class="color-pink">$headers</span> .= <span class="color-green">"From: sender@example.com"</span> . <span class="color-green">"\r\n"</span>;</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">mail</span>(<span class="color-pink">$to</span>, <span class="color-pink">$subject</span>, <span class="color-pink">$message</span>, <span class="color-pink">$headers</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"HTML email sent successfully."</span>;</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Failed to send HTML email."</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Customizing the From Address:</h5>
                <p>The <span className='background-grey'>From</span> header lets you specify who the email appears to come from.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$headers</span> = <span class="color-green">"From: Your Name <yourname@example.com>"</span>;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Handling Multiple Recipients:</h5>
                <p>You can send emails to multiple recipients by separating email addresses with a comma in the to field.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$to</span> = <span class="color-green">"recipient1@example.com, recipient2@example.com"</span>;</p>
                        `
                    }}></div>
                </div>

                <p>PHP's <span className='background-grey'>mail()</span> function is a quick and simple way to send emails, especially for smaller tasks like contact forms and notifications.</p>

            </div>
        </section>
    )
}