import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_rename";

export default function FtpRename() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_rename() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_nlist',
            'next': '/php/func_ftp_size'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_rename() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_rename()</span> function in PHP is used to rename a file or directory on a remote FTP server. It allows you to change the name of an existing file or directory to a new name.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_rename</span>(ftp_connection, old_name, new_name)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>ftp_connection</strong> (Required): The FTP connection resource created by ftp_connect(). This represents the active connection to the remote FTP server.</li>
                    <li><strong>old_name</strong> (Required): The current name of the file or directory you want to rename. This should include the full path if the file or directory is not in the current working directory.</li>
                    <li><strong>new_name</strong> (Required): The new name that you want to give to the file or directory. If you want to move the file to a different directory, you should provide the full path for the new name.</li>
                </ul>

                <p>It returns <span className="background-grey">true</span> on success and <span className="background-grey">false</span> on failure.</p>

                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_rename()</span> Function</h5>
                <p>This is an example of renaming a file on ftp server.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.&#92;n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Current file name</p>
                        <p class="ml-60">        <span class="color-pink">$old_file</span> = <span class="color-green">"old_file.txt"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // New file name</p>
                        <p class="ml-60">        <span class="color-pink">$new_file</span> = <span class="color-green">"new_file.txt"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Rename the file</p>
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-red">ftp_rename</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$old_file</span>, <span class="color-pink">$new_file</span>)) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"File renamed successfully to $new_file.&#92;n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to rename file.&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the FTP connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to the FTP server and log in using <span className="background-grey">ftp_connect()</span> and <span className="background-grey">ftp_login()</span>.</li>
                    <li>The file <span className="background-grey">old_file.txt</span> is renamed to <span className="background-grey">new_file.txt</span> using <span className="background-grey">ftp_rename()</span>.</li>
                    <li>If the operation is successful, the message <span className="background-grey">"File renamed successfully"</span> is displayed.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>File renamed successfully to new_file.txt.</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}