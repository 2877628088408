import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/html/tag/header";

export default function Header() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <header> Tag | Aspirant's Home");
        const urls = {
            'previous': '/html/tag-list'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - &lt;header&gt; Tag</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span class="background-grey">&lt;header&gt;</span> tag in HTML is used to define the header section of a webpage or a specific section within a page. It typically contains introductory content, navigation links, branding elements like logos, or headings for the section. The <span class="background-grey">&lt;header&gt;</span> element is a semantic element, meaning it provides additional meaning and structure to the content, which improves accessibility and SEO.
                </p>

                <h5 className='mt-5 mb-3'>Structure of <span class="background-grey">&lt;header&gt;</span> Tag</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;header&gt;</span> tag can contain elements like <span class="background-grey">&lt;h1&gt;</span>, <span class="background-grey">&lt;nav&gt;</span>, <span class="background-grey">&lt;img&gt;</span>, and other elements related to heading and navigation.</li>
                    <li>It can be used at the top of the webpage as a main header or at the beginning of sections within the page (e.g., <span class="background-grey">&lt;article&gt;</span>, <span class="background-grey">&lt;section&gt;</span>).</li>
                </ul>

                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;<span class="color-blue">header</span>&gt;</p>
                        <p class="ml-30 color-grey">    &lt;!-- Content inside the header --&gt;</p>
                        <p>&lt;/<span class="color-blue">header</span>&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>A Simple Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;header&gt;</p>
                        <p class="ml-30">    &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">    &lt;nav&gt;</p>
                        <p class="ml-90">        &lt;ul&gt;</p>
                        <p class="ml-120">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#home"</span>&gt;Home&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#about"</span>&gt;About&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#services"</span>&gt;Services&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#contact"</span>&gt;Contact&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-90">        &lt;/ul&gt;</p>
                        <p class="ml-60">    &lt;/nav&gt;</p>
                        <p>&lt;/header&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be the following -</p>
                <div className='outputPalateBox mt-2 mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <header>
                            <h1>Welcome to My Website</h1>
                            <nav>
                                <ul>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#about">About</a></li>
                                    <li><a href="#services">Services</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </ul>
                            </nav>
                        </header>
                        `
                    }}></div>
                </div>
                <p>In this example -</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The <span class="background-grey">&lt;header&gt;</span> element contains an <span class="background-grey">&lt;h1&gt;</span> element for the page title and a <span class="background-grey">&lt;nav&gt;</span> element for the site’s navigation links.</li>
                </ul>



            </div>
        </section>
    )
}