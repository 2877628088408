import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_ftp_pwd";

export default function FtpPwd() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP ftp_pwd() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_ftp_chdir',
            'next': '/php/func_ftp_nlist'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP ftp_pwd() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <span className="background-grey">ftp_pwd()</span> function in PHP is used to retrieve the current working directory on a remote FTP server. It returns the full path of the directory that you are currently working in, which is useful for navigation and verification when working with directories and files on an FTP server.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">ftp_pwd</span>(ftp_connection)</p>
                        `
                    }}></div>
                </div>
                <p>Where, The FTP connection resource, created by <span className="background-grey">ftp_connect()</span>. This represents the active FTP connection to the server.</p>


                <h5 className="mt-5 mb-3">Example of <span className="background-grey">ftp_pwd()</span> Function</h5>
                <p>This is an example of getting the current working directory</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30 color-grey">    // FTP server details</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_server</span> = <span class="color-green">"ftp.example.com"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_username</span> = <span class="color-green">"your_username"</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_password</span> = <span class="color-green">"your_password"</span>;</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Connect to the FTP server</p>
                        <p class="ml-30">    <span class="color-pink">$ftp_conn</span> = <span class="color-red">ftp_connect</span>(<span class="color-pink">$ftp_server</span>) or <span class="color-red">die</span>(<span class="color-green">"Could not connect to $ftp_server"</span>);</p>
                        <br />    
                        <p class="ml-30 color-grey">    // Login to the FTP server</p>
                        <p class="ml-30">    <span class="color-blue">if</span> (<span class="color-red">ftp_login</span>(<span class="color-pink">$ftp_conn</span>, <span class="color-pink">$ftp_username</span>, <span class="color-pink">$ftp_password</span>)) {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Connected to FTP server.\n"</span>;</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Get the current working directory</p>
                        <p class="ml-60">        <span class="color-pink">$current_dir</span> = <span class="color-red">ftp_pwd</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <br />        
                        <p class="ml-60">        <span class="color-blue">if</span> (<span class="color-pink">$current_dir</span> !== <span class="color-blue">false</span>) {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Current directory is: $current_dir&#92;n"</span>;</p>
                        <p class="ml-60">        } <span class="color-blue">else</span> {</p>
                        <p class="ml-90">            <span class="color-blue">echo</span> <span class="color-green">"Failed to retrieve current directory.&#92;n"</span>;</p>
                        <p class="ml-60">        }</p>
                        <br />        
                        <p class="ml-60 color-grey">        // Close the connection</p>
                        <p class="ml-60">        <span class="color-red">ftp_close</span>(<span class="color-pink">$ftp_conn</span>);</p>
                        <p class="ml-30">    } <span class="color-blue">else</span> {</p>
                        <p class="ml-60">        <span class="color-blue">echo</span> <span class="color-green">"Login failed.&#92;n"</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>In this example,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>We connect to an FTP server using <span className="background-grey">ftp_connect()</span> and log in using <span className="background-grey">ftp_login()</span>.</li>
                    <li>The <span className="background-grey">ftp_pwd()</span> function is called to retrieve the current directory path.</li>
                    <li>The path is displayed if the function succeeds. If the function fails, an error message is shown.</li>
                    <li>The connection is closed using <span className="background-grey">ftp_close()</span>.</li>
                </ul>

                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Connected to FTP server.</p>
                            <p>Current directory is: /public_html</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}