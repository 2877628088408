import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_flip";

export default function ArrayFilter() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_flip() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_filter',
            'next': '/php/func_array_key_exists'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_flip() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_flip() function in PHP swaps the keys and values of an array. It transforms the array so that the original values become the keys, and the original keys become the values. This function is useful when you want to reverse the relationship between keys and values in an array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red"> array_flip</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where <strong>$array</strong> is the input array to flip (swap keys and values).</p>


                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-pink">1</span>, <span class="color-green">"b"</span> => <span class="color-pink">2</span>, <span class="color-green">"c"</span> => <span class="color-pink">3</span>];</p>
                            <p><span class="color-pink">$result</span> = <span class="color-red">array_flip</span>(<span class="color-pink">$array</span>);</p>
                            <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => a</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => b</p>
                        <p class="ml-30">    [<span class="color-pink">3</span>] => c</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The original array <span className='background-grey'>["a" =&gt; 1, "b" =&gt; 2, "c" =&gt; 3]</span> is flipped.</li>
                    <li>The keys <span className='background-grey'>"a"</span>, <span className='background-grey'>"b"</span>, and <span className='background-grey'>"c"</span> become the values, while the values <span className='background-grey'>1</span>, <span className='background-grey'>2</span>, and <span className='background-grey'>3</span> become the keys.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example 2: Handling Duplicate Values</h5>
                <p>If there are duplicate values in the input array, <span className='background-grey'>array_flip()</span> will retain only the last key for that value.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-green">"a"</span> => <span class="color-pink">1</span>, <span class="color-green">"b"</span> => <span class="color-pink">2</span>, <span class="color-green">"c"</span> => <span class="color-pink">1</span>];</p>
                        <p><span class="color-pink">$result</span> = <span class="color-red">array_flip</span>(<span class="color-pink">$array</span>);</p>
                        <p><span class="color-red">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">Array</span></p>
                        <p>(</p>
                        <p class="ml-30">    [<span class="color-pink">1</span>] => c</p>
                        <p class="ml-30">    [<span class="color-pink">2</span>] => b</p>
                        <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The original array has two keys (<span className='background-grey'>"a"</span> and <span className='background-grey'>"c"</span>) with the value <span className='background-grey'>1</span>.</li>
                    <li>In the flipped array, only the last occurrence of the value <span className='background-grey'>1</span> (from key <span className='background-grey'>"c"</span>) is retained, while key <span className='background-grey'>"a"</span> is discarded.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 3: Working with Strings</h5>
                <p>The <span className='background-grey'>array_flip()</span> function works with both numeric and string values.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-pink">$array</span> = [<span class="color-pink">1</span> => <span class="color-green">"apple"</span>, <span class="color-pink">2</span> => <span class="color-green">"banana"</span>, <span class="color-pink">3</span> => <span class="color-green">"cherry"</span>];</p>
                        <p><span class="color-pink">$result</span> = <span class="color-red">array_flip</span>(<span class="color-pink">$array</span>);</p>
                        <p><span class="color-pink">print_r</span>(<span class="color-pink">$result</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">Array</span></p>
                            <p>(</p>
                            <p class="ml-30">    [apple] => <span class="color-pink">1</span></p>
                            <p class="ml-30">    [banana] => <span class="color-pink">2</span></p>
                            <p class="ml-30">    [cherry] => <span class="color-pink">3</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The input array has numeric keys and string values.</li>
                    <li>After flipping, the string values <span className='background-grey'>"apple"</span>, <span className='background-grey'>"banana"</span>, and <span className='background-grey'>"cherry"</span> become keys, and their corresponding numeric keys become the values.</li>
                </ul>

            </div>
        </section>
    )
}