import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_wordwrap";

export default function Wordwrap() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP wordwrap() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_str_repeat',
            'next': '/php/func_str_pad'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP wordwrap() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The wordwrap() function in PHP is used to wrap a string into lines of a specified width. This can be especially useful when displaying long strings in a readable format or ensuring that text fits within a certain width, such as when outputting to a console, sending an email, or formatting text for web output. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-red">wordwrap</span>(<span class="color-blue">string</span> <span class="color-pink">$string</span>, <span class="color-blue">int</span> <span class="color-pink">$width</span> = <span class="color-pink">75</span>, <span class="color-blue">string</span> <span class="color-pink">$break</span> = <span class="color-green">"\n"</span>, <span class="color-blue">bool</span> <span class="color-pink">$cut</span> = <span class="color-blue">false</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$string</strong>: The input string you want to wrap.</li>
                    <li><strong>$width</strong>: The number of characters at which the string will be wrapped. The default value is <span className="background-grey">75</span>.</li>
                    <li><strong>$break</strong>: The string used to break lines. By default, this is a newline character (<span className="background-grey">\n</span>).</li>
                    <li><strong>$cut</strong>: A boolean that determines whether words longer than the width should be split. If set to <span className="background-grey">true</span>, the function will split the word; if <span className="background-grey">false</span>, it will not break long words but move them to the next line.</li>
                </ul>


                <h5 className="mt-5 mb-3">Example : Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;?php</p>
                        <p class="ml-30">    <span class="color-pink">$text</span> = <span class="color-green">"PHP is an amazing scripting language that is widely used for web development."</span>;</p>
                        <p class="ml-30">    <span class="color-pink">$wrappedText</span> = <span class="color-red">wordwrap</span>(<span class="color-pink">$text</span>, <span class="color-pink">20</span>);</p>
                        <br />    
                        <p class="ml-30">    <span class="color-blue">echo</span> <span class="color-pink">$wrappedText</span>;</p>
                        <p>?&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be following -</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>PHP is an amazing</p>
                            <p>scripting language</p>
                            <p>that is widely used</p>
                            <p>for web development.</p>
                        `
                    }}></div>
                </div>
                <p>In this example, the string is broken into multiple lines, each with a maximum width of 20 characters.</p>

            </div>
        </section>
    )
}