import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/array";

export default function PHPArray() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is array in PHP | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/working-with-apis',
            'next': '/php/func_array_change_key_case'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Array</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An array is a special variable that can hold more than one value at a time. Instead of creating separate variables for each value, you can store all related values in an array.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">// Without an array</p>
                        <p><span class="color-pink">$name1</span> = <span class="color-green">"Alice"</span>;</p>
                        <p><span class="color-pink">$name2</span> = <span class="color-green">"Bob"</span>;</p>
                        <p><span class="color-pink">$name3</span> = <span class="color-green">"Charlie"</span>;</p>
                        <br />
                        <p class="color-grey">// With an array</p>
                        <p><span class="color-pink">$names</span> = <span class="color-blue">array</span>(<span class="color-green">"Alice"</span>, <span class="color-green">"Bob"</span>, <span class="color-green">"Charlie"</span>);</p>
                        `
                    }}></div>
                </div>
                <p>In the example above, the <span className='background-grey'>$names</span> array holds three values: "Alice," "Bob," and "Charlie."</p>

                <h5 className="mt-5 mb-3">Types of Arrays in PHP</h5>
                <p>PHP supports three types of arrays:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Indexed Arrays</strong>: Arrays with numeric indexes.</li>
                    <li><strong>Associative Arrays</strong>: Arrays with named keys.</li>
                    <li><strong>Multidimensional Arrays</strong>: Arrays that contain other arrays.</li>
                </ul>

                <h5 className="mt-5 mb-3">Indexed Array</h5>
                <p>In an indexed array, each value is assigned a numeric index, starting from 0.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// Creating an indexed array</p>
                            <p><span class="color-pink">$fruits</span> = <span class="color-blue">array</span>(<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Orange"</span>);</p>
                            <br />
                            <p class="color-grey">// Accessing elements</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$fruits</span>[<span class="color-pink">0</span>]; <span class="color-grey">// Outputs: Apple</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$fruits</span>[<span class="color-pink">1</span>]; <span class="color-grey">// Outputs: Banana</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>$fruits[0]</span>: Refers to the first element in the array, "Apple."</li>
                    <li><span className='background-grey'>$fruits[1]</span>: Refers to the second element, "Banana."</li>
                </ul>

                <p>You can also create indexed arrays using the shorthand syntax:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [<span class="color-green">"Apple"</span>, <span class="color-green">"Banana"</span>, <span class="color-green">"Orange"</span>];</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Associative Array</h5>
                <p>Associative arrays use named keys instead of numeric indexes. This allows you to associate values with specific names, making it easier to understand what each value represents.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// Creating an associative array</p>
                            <p><span class="color-pink">$ages</span> = <span class="color-blue">array</span>(<span class="color-green">"Alice"</span> => <span class="color-pink">25</span>, <span class="color-green">"Bob"</span> => <span class="color-pink">30</span>, <span class="color-green">"Charlie"</span> => <span class="color-pink">35</span>);</p>
                            <br />
                            <p class="color-grey">// Accessing elements</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$ages</span>[<span class="color-green">"Alice"</span>]; <span class="color-grey">// Outputs: 25</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$ages</span>[<span class="color-green">"Bob"</span>];   <span class="color-grey">// Outputs: 30</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>$ages["Alice"]</span>: Refers to Alice's age, which is 25.</li>
                    <li><span className='background-grey'>$ages["Bob"]</span>: Refers to Bob's age, which is 30.</li>
                </ul>
                <p>Associative arrays can also be created using the shorthand syntax:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$ages</span> = [<span class="color-green">"Alice"</span> => <span class="color-pink">25</span>, <span class="color-green">"Bob"</span> => <span class="color-pink">30</span>, <span class="color-green">"Charlie"</span> => <span class="color-pink">35</span>];</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Multidimensional Arrays</h5>
                <p>A multidimensional array is an array that contains other arrays. This allows you to store data in a grid or matrix format.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// Creating a multidimensional array</p>
                            <p><span class="color-pink">$students</span> = <span class="color-blue">array</span>(</p>
                            <p class="ml-30">    <span class="color-blue">array</span>(<span class="color-green">"Alice"</span>, <span class="color-green">"Math"</span>, <span class="color-pink">85</span>),</p>
                            <p class="ml-30">    <span class="color-blue">array</span>(<span class="color-green">"Bob"</span>, <span class="color-green">"Science"</span>, <span class="color-pink">90</span>),</p>
                            <p class="ml-30">    <span class="color-blue">array</span>(<span class="color-green">"Charlie"</span>, <span class="color-green">"History"</span>, <span class="color-pink">78</span>)</p>
                            <p>);</p>
                            <br />
                            <p class="color-grey">// Accessing elements</p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$students</span>[<span class="color-pink">0</span>][<span class="color-pink">0</span>]; <span class="color-grey">// Outputs: Alice</span></p>
                            <p><span class="color-blue">echo</span> <span class="color-pink">$students</span>[<span class="color-pink">1</span>][<span class="color-pink">1</span>]; <span class="color-grey">// Outputs: Science</span></p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><span className='background-grey'>$students[0][0]</span>: Refers to the first student's name, "Alice."</li>
                    <li><span className='background-grey'>$students[1][1]</span>: Refers to the subject "Science."</li>
                </ul>
            </div>
        </section>
    )
}