import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/php/func_array_product";

export default function ArrayProduct() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("PHP array_product() Function | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/php/func_array_sum',
            'next': '/php/func_array_unique'
        }
        path.setPreviousNext(urls);;
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>PHP array_product() Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The array_product() function in PHP calculates the product of all the values in an array. It multiplies all the elements in the array together and returns the result. This function works with both integers and floats, and only the numeric values in the array are considered. If the array is empty, the function returns <span className='background-grey'>1</span>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-red">array_product</span>(<span class="color-blue">array</span> <span class="color-pink">$array</span>)</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>$array</strong>: The array containing the values to multiply.</li>
                    <li><strong>Return Value</strong>: The product of the values. The result can be an integer or float depending on the array elements.</li>
                </ul>

                <h5 className="mt-5 mb-3">Example 1: Basic Usage</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$numbers</span> = [<span class="color-pink">2</span>, <span class="color-pink">3</span>, <span class="color-pink">4</span>];</p>
                            <p><span class="color-pink">$product</span> = <span class="color-red">array_product</span>(<span class="color-pink">$numbers</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$product</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>24</span>
                        `
                    }}></div>
                </div>
                <p>Where, The function multiplies all the values in the array: <span className='background-grey'>2 * 3 * 4 = 24</span>.</p>


                <h5 className="mt-5 mb-3">Example 2: Associative Array</h5>
                <p><span className='background-grey'>array_product()</span> can be used with associative arrays, where only the values are multiplied.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-pink">$fruits</span> = [</p>
                            <p class="ml-30">    "apples" => <span class="color-pink">2</span>,</p>
                            <p class="ml-30">    "bananas" => <span class="color-pink">3</span>,</p>
                            <p class="ml-30">    "cherries" => <span class="color-pink">4</span></p>
                            <p>];</p>
                            <br />
                            <p><span class="color-pink">$product</span> = <span class="color-red">array_product</span>(<span class="color-pink">$fruits</span>);</p>
                            <br />
                            <p><span class="color-blue">echo</span> <span class="color-pink">$product</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>24</p>
                        `
                    }}></div>
                </div>
                <p>Where, The function ignores the keys and multiplies the values: <span className='background-grey'>2 * 3 * 4 = 24</span>.</p>
            </div>
        </section>
    )
}